import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Button, message, Dropdown, Menu, Icon, Modal, Input, Upload } from 'antd';
import copy from 'copy-to-clipboard';
import { DownOutlined } from '@ant-design/icons';

import { getEventByProject, delBadge, delEvent, addBlindBox,getParticipants } from '../../../services/badgeManage'
import { getBadgeListByProject } from '../../../services/badge'
import { abbrTxHash } from "../../../utils/format";
import { dataURLtoBlob } from '../../../utils/dataURLtoBlob';
import { erc20TokenList, getDecimalByAddress } from "../../../utils/tokenList";
import { chainTypeComImgObj, chainTxtObj, handleHistoryAddress } from '../../../utils/networkConnect';
import { useActiveWeb3React } from "../../../web3";

import iconUpload from "assets/img/creatBadge/icon_upload.svg"
import copyIcon from "assets/img/header/copy.svg";
import iconManage from "assets/img/creatBadge/icon_manage.svg"
import { ReactComponent as IconDownload } from "assets/img/creatBadge/icon_subtract.svg";
import { ReactComponent as IconEdit } from "assets/img/creatBadge/edit.svg";
import { ReactComponent as IconDel } from "assets/img/creatBadge/delete.svg";
import moment from 'moment';
import BigNumber from 'bignumber.js';
import styles from './style.module.scss'

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export default function EventDetailPage() {
  const { account } = useActiveWeb3React()
  const history = useHistory()
  const { eventId } = useParams()
  const [delModal, setDelModal] = useState(false);
  const [delBadgeModal, setDelBadgeModal] = useState(false);
  const [delBadgeType, setDelBadgeType] = useState('');
  const [imageUrl, setImageUrl] = useState();
  const [eventInfo, setEventInfo] = useState({})
  const [badgeList, setBadgeList] = useState([])
  const [taskList, setTaskList] = useState([])
  const [isPublished, setIsPublished] = useState(false)
  const [isPushed, setIsPushed] = useState(false)

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
        uploadBlindCover(url)
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
        uploadBlindCover(url)
      });
    }
  }
  const uploadBlindCover = async (imageUrl) => {
    let formData = new FormData()
    formData.append('originalEventId', eventId)
    formData.append('image', dataURLtoBlob(imageUrl))
    let res = await addBlindBox(eventId, formData)
    getEventInfo()
  }

  const reductionBigNum = (value, decimals) => {
    const val = new BigNumber(value)
    return val.div(new BigNumber(10).pow(decimals)).toString()
  }

  const getEventInfo = async () => {
    let res = await getEventByProject(eventId)
    if (res.creator != account) {
      history.push('/badgeManage')
      return false
    }
    if (res.status !== "draft") {
      setIsPublished(true)
    }
    setEventInfo(res)
    setImageUrl(res.blindBox.image)
    if (res.blindBox.eligibility === 'task') {
      res.blindBox.tasks.map(item => {
        if (item.type === 'token_balance') {
          item.balance = reductionBigNum(item.balance, getDecimalByAddress(item.token.address, erc20TokenList))
        }
      })
      setTaskList(res.blindBox.tasks)
    }
    if (res.blindBox.transactionHash) {
      setIsPushed(true)
    }
  }
  const handleCopy = () => {
    copy(eventInfo.contractAddress);
    message.success('Copied!')
  }
  const gotoCreateBadge = () => {
    history.push(`/badgeManage/createBlindBadge/${eventId}`)
  }
  const getList = async () => {
    let res = await getBadgeListByProject(eventId)
    setBadgeList(res.list)
  }
  const openDelBadgeModal = (badgeType) => {
    setDelBadgeType(badgeType)
    setDelBadgeModal(true)
  }

  const removeBadge = async (badgeType) => {
    let res = await delBadge(eventId, badgeType)
    setDelBadgeModal(false)
    if (res) {
      message.success('Success')
      getList()
    }
  }
  const gotoCreateEvent = () => {
    history.push(`/badgeManage/createEvent?eventId=${eventId}`)
  }
  const removeEvent = async () => {
    let res = await delEvent(eventId)
    if (res) {
      history.push('/badgeManage')
    }
  }
  const gotoBadgeInfo = (badgeType) => {
    history.push(`/badgeManage/createBlindBadge/${eventId}?badgeType=${badgeType}`)
  }
  const gotoPublish = () => {
    if (!eventInfo.blindBox.image) {
      return message.error("Please upload mystery box cover")
    }
    if (badgeList.length === 0) {
      return message.error("Please create badge")
    }
    history.push(`/badgeManage/publishBlind/${eventId}`)
  }
  const matchTwitterUrl = (url) => {
    const regex = /^https?:\/\/twitter\.com\/(.+?)\/status\/\d+$/;
    const match = url.match(regex);
    if (match && match.length === 2) {
      return match[1];
    }
    return null;
  }
  const downloadCSV = async (type) => {
    let url = await getParticipants(type)
    let domA = document.createElement('a'); 
    domA.setAttribute('download', ''); 
    domA.setAttribute('href', url);
    domA.click();
  }
  useEffect(() => {
    getEventInfo()
    getList()
  }, [])
  return (
    <>
      <Modal visible={delModal} title={'Delete event'} width={500} footer={null} onCancel={() => { setDelModal(false) }}>
        <div>The badge in the event will also be cleared,  Are you sure you want to delete this event?</div>
        <div className='space-between mt20'>
          <Button className={`w200  h50 btn_border`} onClick={() => { setDelModal(false) }}>Cancel</Button>
          <Button type={'primary'} className={`w200  h50`} onClick={removeEvent}>Delete event</Button>
        </div>
      </Modal>
      <Modal visible={delBadgeModal} title={'Delete badge'} width={500} footer={null} onCancel={() => { setDelBadgeModal(false) }}>
        <div>Are you sure you want to delete this badge?</div>
        <div className='space-between mt20'>
          <Button className={`w200  h50 btn_border`} onClick={() => { setDelBadgeModal(false) }}>Cancel</Button>
          <Button type={'primary'} className={`w200  h50`} onClick={() => { removeBadge(delBadgeType) }}>Delete badge</Button>
        </div>
      </Modal>
      <div className={styles.eventDetailPage}>
        <div className={styles.eventBlind_baseInfo}>
          <div className={styles.box_left}>
            <div className={styles.upload_cover} style={{ backgroundImage: `url(${imageUrl})` }}>
              {
                !imageUrl && <img src={iconUpload}></img>
              }
              {
                !isPublished &&
                <Upload className='mt10' customRequest={() => { }} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}><Button className={`btn_border h40 fw600`}>Upload mystery box cover</Button></Upload>
              }
            </div>
            {
              eventInfo.blindBox?.contractAddress &&
              <div className={`space-between mt24 ${styles.bg_box}`}>
                <div className={`fs14`}>Contract Address</div>
                <div className={`df_center cp`}>
                  <div className="pr10">
                    <a className={`c_blue fs14`} target="_blank" href={handleHistoryAddress(eventInfo.chainType, eventInfo.blindBox.contractAddress)} >{abbrTxHash(eventInfo.blindBox.contractAddress)}</a>
                  </div>
                  <img
                    width={14}
                    onClick={handleCopy}
                    src={copyIcon}
                    alt=""
                  />
                </div>
              </div>
            }
            <div className='df mt10'>
              <div className={`${styles.info_item} tac f1 mr10`}>
                <div>{chainTxtObj[eventInfo.chainType]}</div>
                <img src={chainTypeComImgObj[eventInfo.chainType]}></img>
              </div>
              <div className={`${styles.info_item} tac f1`}>
                <div>Claimed</div>
                <div className='fw600'>{eventInfo.claimedCount}/{eventInfo.totalCount}</div>
              </div>
              {/* <div className={`${styles.info_item} tac f1`}>
                <div>Owners</div>
                <div className={'fw600'}>0</div>
              </div> */}
            </div>
            <div className='space-between mt30'>
              <Dropdown className={`cp ${isPublished ? styles.manage_disable : ''} `} disabled={isPublished}
                overlay={
                  <Menu>
                    <Menu.Item key='1' onClick={gotoCreateEvent} disabled={isPushed}>Edit Event</Menu.Item>
                    <Menu.Item key='2' onClick={gotoCreateBadge} disabled={isPushed}>Create Badge</Menu.Item>
                    <Menu.Item key='4' onClick={() => { setDelModal(true) }}>Delete draft</Menu.Item>
                  </Menu>
                }>
                <div className={styles.eventManage}><div><img className='pr8' src={iconManage}></img>Manage </div><DownOutlined /> </div>
              </Dropdown>
              {
                isPublished ? <Button disabled className={`w200 h50 btn_border`}>Published</Button> :
                  <Button className={styles.publish_btn} onClick={gotoPublish}>Publish</Button>
              }
            </div>
          </div>
          <div className={styles.box_right}>
            <div className='fs32 fw600'>{eventInfo.title}</div>
            <div className='fs14'>{eventInfo.description}</div>
            <div className='space-between mt20'>
              <div className={`${styles.info_item} mr10 f1`}>
                <div>Start time</div>
                <div className='fw600'>{moment(eventInfo.eventStartTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
              <div className={`${styles.info_item} ml10 f1`}>
                <div>End time</div>
                <div className='fw600'>{moment(eventInfo.eventEndTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
            </div>
            {
              taskList.map(item => (
                <>
                  {
                    item.type === 'follow_on_twitter' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Follow @{item.twitterId} on Twitter</div>
                    </div>
                  }
                  {
                    item.type === 'retweet' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Retweet @{matchTwitterUrl(item.url)} on Twitter </div>
                    </div>
                  }
                  {
                    item.type === 'tweet' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Post a Tweet</div>
                    </div>
                  }
                  {
                    item.type === 'like_tweet' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Like a Tweet by @{matchTwitterUrl(item.url)}</div>
                    </div>
                  }
                  {
                    item.type === 'quote_tweet' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Quote tweet @{matchTwitterUrl(item.url)}</div>
                    </div>
                  }
                  {
                    item.type === 'join_discord' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Join a Discord server</div>
                    </div>
                  }
                  {
                    item.type === 'join_telegram' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Join {item.twitterId} on Telegram</div>
                    </div>
                  }
                  {
                    item.type === 'visit_website' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Visit a page</div>
                    </div>
                  }
                  {
                    item.type === 'token_balance' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Hold {item.balance} {item.token.symbol} on {chainTxtObj[item.chainType]}</div>
                    </div>
                  }
                  {
                    item.type === 'total_gas_fee' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>Spend {item.balance} gas fee on {chainTxtObj[item.chainType]}</div>
                    </div>
                  }
                  {
                    item.type === 'transaction_count' &&
                    <div key={item.id} className='mt20'>
                      <div className={styles.task_item}>{item.count} transactions on {chainTxtObj[item.chainType]} </div>
                    </div>
                  }
                </>
              ))
            }
          </div>
        </div>
        <div className={styles.badgeManage}>
          <div className='fs20 fw600 cp'>Badge</div>
          <div className={styles.badgeList}>
            {
              badgeList.map(item => (
                <div key={item.type} className={styles.badge_item}>
                  <div className={styles.badge_box}>
                    <img className={styles.badge_img} src={item.image}></img>
                    {
                      (!isPublished && !isPushed) ?
                        <div className={styles.badge_func}>
                          <div className='f1 tac cp'>
                            <IconEdit fill={'#fff'} onClick={() => { gotoBadgeInfo(item.type) }}></IconEdit>
                          </div>
                          <div className={`f1 tac cp ${styles.line_left}`} onClick={() => { openDelBadgeModal(item.type) }}>
                            <IconDel></IconDel>
                          </div>
                        </div> :
                        <div className={styles.badge_func}>
                          <div className={`f1 cp ${styles.download}`} onClick={() => { downloadCSV(item.type) }}>
                            <IconDownload fill={'#fff'}></IconDownload>
                            <span className='pl10 c_fff'>Participants List</span>
                          </div>
                        </div>
                    }
                  </div>
                  <div>{item.name}</div>
                </div>
              ))
            }
            {
              (!isPublished && !isPushed) &&
              <div className='cp' onClick={gotoCreateBadge}>
                <div className={styles.add_box}>+</div>
                <div>Create badge</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
