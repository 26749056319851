import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";

import { useList } from "../../../src/services/topic"
import { formatAmountWithDecimal } from "utils/format"
import hot from 'assets/img/topic/hot_red.svg'
import styles from './styles.module.scss'

export default function TopicHot() {
    const { list, total } = useList(1, 6)
    const history = useHistory()

    const changeTopic = (content) => {
        history.push(`/topic/${encodeURIComponent(content)}`)
    }
    const dealNftName = (name) => {
        let str = ''
        if(!name) {
            return '--'
        }else if(name.length>20){
            str = name.substring(0,20)+'...'
            return str
        }else {
            return name
        }
    }

  return (
    <div className={styles.topic_box}>
        <div className={styles.title}>Topic</div>
        {
            list.map((item, index) => (
                <div className={`space-between-center cp ${index>2?'fw500':'fw700'} ${styles.topic_item}`} onClick={()=>{changeTopic(item.topic)}}>
                    <div>{index+1}. {dealNftName(item.topic)}</div>
                    <div>
                        {
                            index<3 &&
                            <img className={styles.mt_f2} src={hot}></img>
                        }
                        <span className={`pl5`}>{formatAmountWithDecimal(item.activityCount, 0, 0)} Wows</span>
                    </div>
                </div>
            ))
        }
    </div>
  )
}
