import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";

import {  HANDLE_NOTICE_NUM } from "../../../../const";
import { mainContext } from "../../../../reducer";
import NoData from "components/NoData";
import { formatAmountWithDecimal } from "../../../../utils/format";
import { useList, queryNoticeStatus } from "../../../../services/message"
import activity from "assets/img/message/activity.svg"
import styles from "../../styles.module.scss";
import moment from 'moment';

export default function Activity() {
    const history = useHistory();
    const { state, dispatch } = useContext(mainContext);
    const [loading, setLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const { list, total } = useList('sale', pageNo, pageSize, setLoading)

    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const gotoNFT = (item) => {
        history.push(`/marketplace/${item.nftInfo.contractAddress}/${item.nftInfo.chainType}/${item.nftInfo.id}`)
    }
    const dealNftName = (name) => {
        let str = ''
        if(!name) {
            return '--'
        }else if(name.length>16){
            str = name.substring(0,16)+'...'
            return str
        }else {
            return name
        }
    }
    const initNotice = async () => {
        let res = await queryNoticeStatus()
        dispatch({
          type: HANDLE_NOTICE_NUM,
          noticeNum: {
            nftactivitynum: 0,
            commentnum: res.activity,
            likemenum: res.like
          }
        });
    }
    useEffect(()=> {
        initNotice()
    },[])
    return (
        <>
            {/* <div className={styles.notice_title}>NFT Activity</div> */}
            <div className={`${styles.notice_list}`}>
                {
                    loading ? <Spin className={`wp100 mt40`} size="large"></Spin> :
                        <InfiniteScroll
                            dataLength={list.length}
                            next={getList}
                            hasMore={list.length < total}
                            loader={
                                <div style={{ width: 216, textAlign: 'center', margin: '10px auto' }}>
                                    <Spin size={`large`} />
                                </div>
                            }
                        >
                            {
                                list.length > 0 && list.map(item => (
                                    <div key={item.notificationTime} className={styles.notice_item}>
                                        <div className={styles.item_time}>{moment(item.notificationTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        <div className={`${styles.item_main} fs14`} >
                                            <img className={styles.notice_icon} width={30} src={activity}></img>
                                            {
                                                item.type === "bid_failed" &&
                                                <div className={styles.pl10_pc}>
                                                    <div className={styles.sale_type}>NFT Auction Updates!</div>
                                                    <div>
                                                        <span>Your bid on &nbsp;
                                                            <span className={styles.item_name}>{dealNftName(item.nftInfo.name)}</span>
                                                            &nbsp;auction failed, bid price {formatAmountWithDecimal(item.saleInfo.price, item.saleInfo.priceToken.decimals)} {item.saleInfo.priceToken.symbol} .</span>
                                                        <span className={`c_blue pl5 cp`} onClick={() => { gotoNFT(item) }}>View &gt;</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                item.type === "bid_success" &&
                                                <div className={styles.pl10_pc}>
                                                    <div className={styles.sale_type}>NFT Auction Updates!</div>
                                                    <div>
                                                        <span>Congrats! Your bid of  {formatAmountWithDecimal(item.saleInfo.price, item.saleInfo.priceToken.decimals)} {item.saleInfo.priceToken.symbol} on the &nbsp;
                                                            <span className={styles.item_name}>{dealNftName(item.nftInfo.name)}</span>
                                                            &nbsp;auction succeed.</span>
                                                        <span className={`c_blue pl5 cp`} onClick={() => { gotoNFT(item) }}>Claim the asset &gt;</span>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                item.type === "auction_bid" &&
                                                <div className={styles.pl10_pc}>
                                                    <div className={styles.sale_type}>NFT Auction Updates!</div>
                                                    <div>
                                                        <span>
                                                            New bid on your &nbsp;
                                                            <span className={styles.item_name}>{dealNftName(item.nftInfo.name)}</span>
                                                            &nbsp;at {formatAmountWithDecimal(item.saleInfo.price, item.saleInfo.priceToken.decimals)} {item.saleInfo.priceToken.symbol}.
                                                        </span>
                                                        <span className={`c_blue pl5 cp`} onClick={() => { gotoNFT(item) }}>View &gt;</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                item.type === "auction_success" &&
                                                <div className={styles.pl10_pc}>
                                                    <div className={styles.sale_type}>NFT Auction Updates!</div>
                                                    <div>
                                                        <span> Congrats! Your &nbsp;
                                                            <span className={styles.item_name}>{dealNftName(item.nftInfo.name)}</span>
                                                            &nbsp;is sold at the bid price {formatAmountWithDecimal(item.saleInfo.price, item.saleInfo.priceToken.decimals)} {item.saleInfo.priceToken.symbol}.</span>
                                                        <span className={`c_blue pl5 cp`} onClick={() => { gotoNFT(item) }}>Claim the asset &gt;</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                item.type === "swapped" &&
                                                <div className={styles.pl10_pc}>
                                                    <div className={styles.sale_type}>NFT Sales Updates!</div>
                                                    <div>
                                                        <span >Congrats! Your &nbsp;
                                                            <span className={styles.item_name}>{dealNftName(item.nftInfo.name)}</span>
                                                            &nbsp;&nbsp;is sold at the bid price {formatAmountWithDecimal(item.saleInfo.price, item.saleInfo.priceToken.decimals)} {item.saleInfo.priceToken.symbol}.</span>
                                                        <span className={`c_blue pl5 cp`} onClick={() => { gotoNFT(item) }}>View &gt;</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                list.length === 0 && <div className={styles.empty}><NoData></NoData></div>
                            }
                        </InfiniteScroll>
                }

            </div>
        </>
    )
}
