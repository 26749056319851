import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Form, Row, Col, Steps, Upload, message, Button, Input, Select, DatePicker, Tabs, Radio } from 'antd'

import { getEventByProject, addContractAddress, getContractAddress, addBadge, publishEvent, addBlindBox, saveEvent } from '../../../services/badgeManage'
import { getBadgeListByProject } from '../../../services/badge'
import matic from "assets/img/chain/com_polygon.svg";
import eth from "assets/img/chain/com_eth.svg";
import bnb from "assets/img/chain/com_bnb.svg";
import trx from "assets/img/chain/com_tron.svg";
import klaytn from "assets/img/chain/com_klaytn.svg";
import noFinished from "assets/img/creatBadge/noFinished.png"
import styles from '../style.module.scss'
import moment from 'moment';
import { useActiveWeb3React } from "../../../web3";
import { getChainType } from "../../../web3/address";
import { deployDeFineBadgeForBlindBox, getDeFineBadgeForBlindBoxContract, deployDeFineBadgeBlindBox, getDeFineBlindBoxContract } from '../../../utils/handleContract'
import { mainContext } from "../../../reducer";
import { chainTxtObj,chainFun } from '../../../utils/networkConnect';
import {
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  waitingForConfirm,
} from "../../../const";

const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;

const eligibilityName = {
  all: 'All Users',
  task: 'Task',
  whitelist: 'WhiteList'
}

export default function Publish() {
  const { eventId } = useParams()
  const { dispatch } = useContext(mainContext);
  const history = useHistory()
  const [stepBox, setStepBox] = useState(1);
  const [eventInfo, setEventInfo] = useState({})
  const [badgeList, setBadgeList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [blindBoxAddressList, setBlindBoxAddressList] = useState([])
  const [currentBlindBoxContractAddress, setCurrentBlindBoxContractAddress] = useState('')
  const [currentBadgeContractAddress, setCurrentBadgeContractAddress] = useState('')
  const [succeedAll, setSucceedAll] = useState(true)
  const { library, account, chainId } = useActiveWeb3React()
  const [finishedAll, setFinishedAll] = useState(false)
  const [stepPublish, setStepPublish] = useState(0);
  const [blindBoxAmount, setBlindBoxAmount] = useState(0);


  const nextStep = (step) => {
    setStepBox(step)
  }

  const getEventInfo = async () => {
    console.log(eventId);
    let res = await getEventByProject(eventId)
    setEventInfo(res)
    getList()

    if (res.blindBox.contractAddress) {
      setCurrentBlindBoxContractAddress(res.blindBox.contractAddress)
      setStepPublish(1)
      // setDisableSelectBlindBox(true)
    }
    if (res.blindBox.transactionHash) {
      setStepPublish(3)
      setFinishedAll(true)
    }
    getContractAddressList('blindBox', res.chainType)
    getContractAddressList('badge1155', res.chainType)
  }
  const getList = async () => {
    let res = await getBadgeListByProject(eventId)
    let num = 0
    let amount = 0
    if (res.list[0].contractAddress) {
      setCurrentBadgeContractAddress(res.list[0].contractAddress)
      setStepPublish(2)
      if (finishedAll) {
        setStepPublish(3)
      }
    }
    res.list.map(item => {
      amount += item.maxCount
      if (item.transactionHash) {
        num++
      }
    })
    setBlindBoxAmount(amount)
    console.log(num);
    if (res.list.length === num) {
      setSucceedAll(false)
    }
    setBadgeList(res.list)
  }
  const beforeDeploy = () => {
    let chainType = getChainType(chainId)
    if (eventInfo.chainType !== chainType) {
      message.error(`please switch to ${chainTxtObj[eventInfo.chainType]}`)
      chainFun[chainTxtObj[eventInfo.chainType]]&&chainFun[chainTxtObj[eventInfo.chainType]]()
      return false
    }
    if (eventInfo.creator !== account) {
      message.error("The account is not the creator")
      return false
    }
    return true
  }

  const deployBlindBoxContract = async () => {
    if(!beforeDeploy()) return false
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    let res = await deployDeFineBadgeBlindBox(library, account)
    // console.log(res);
    message.success("Deploy blindBox contract successed")
    saveBlindBoxContractAddress(res)
    addContractToBlindBox(res.address)
    setCurrentBlindBoxContractAddress(res.address)
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { show: false }
    });
  }
  const addContractToBlindBox = async (address) => {
    let formData = new FormData()
    formData.append('originalEventId', eventId)
    formData.append('contract', address)
    // formData.append('transactionHash', info.transactionHash)
    let res = await addBlindBox(eventId, formData)
    console.log(res);
    message.success("Add blindBox ContractAddress successed")
  }
  const saveBlindBoxContractAddress = async (contractInfo) => {
    let obj = {
      address: contractInfo.address,
      chainType: eventInfo.chainType,
      type: 'blindBox',
      transactionHash: contractInfo.transactionHash,
      blockNumber: contractInfo.blockNumber,
    }
    let res = await addContractAddress(obj)
    console.log(res);
    getContractAddressList('blindBox', eventInfo.chainType)
  }
  const deployBadgeContract = async () => {
    if(!beforeDeploy()) return false
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    let res = await deployDeFineBadgeForBlindBox(library, account, eventInfo.chainType)
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { show: false }
    });
    if (res) {
      saveContractAddress(res)
      setCurrentBadgeContractAddress(res.address)
      addBadgeContractToEvent(res.address)
    }
  }
  const addBadgeContractToEvent = async (address) => {
    let formData = new FormData()
    formData.append('originalEventId', eventId)
    formData.append('badgeContract', address)
    let res = await saveEvent(formData)
    console.log(res);
    message.success("Add Badge ContractAddress successed")
  }
  const saveContractAddress = async (contractInfo) => {
    let obj = {
      address: contractInfo.address,
      chainType: eventInfo.chainType,
      type: 'badge1155',
      transactionHash: contractInfo.transactionHash,
      blockNumber: contractInfo.blockNumber,
    }
    let res = await addContractAddress(obj)
    console.log(res);
    getContractAddressList('badge1155', eventInfo.chainType)
  }
  const getContractAddressList = async (type, chainType) => {
    let list = await getContractAddress(chainType, type)
    console.log(list);
    if (type === 'blindBox') {
      setBlindBoxAddressList(list)
    } else {
      setAddressList(list)
    }
  }
  const selectBlindBoxContract = async (e) => {
    setCurrentBlindBoxContractAddress(e)
    setStepPublish(1)
    addContractToBlindBox(e)
  }
  const selectBadgeContract = async (e) => {
    console.log(e);
    setCurrentBadgeContractAddress(e)
    setStepPublish(2)
    addBadgeContractToEvent(e)
  }
  const creatPoolBaseOfBlindBox = async () => {
    if(!beforeDeploy()) return false
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    const myContract = await getDeFineBlindBoxContract(library, currentBlindBoxContractAddress)
    // console.log(myContract);
    let info = eventInfo.blindBox
    let levelNums = []
    let levelTypes = []
    const currency = '0x0000000000000000000000000000000000000000';
    const fee_rate = '0';
    badgeList.map(item => {
      levelNums.push(item.maxCount)
      levelTypes.push(item.type)
    })
    const startTime = eventInfo.eventStartTime / 1000
    const endTime = eventInfo.eventEndTime / 1000
    const beneficiary = account
    const nftAddress = currentBadgeContractAddress
    myContract.methods.createPool(
      info.type,
      info.price ? info.price : 0,
      currency,
      startTime,
      endTime,
      levelNums,
      levelTypes,
      fee_rate,
      beneficiary,
      nftAddress
    )
      .send({ from: account })
      .on('transcationHash', () => { })
      .on('receipt', (receipt) => {
        console.log(receipt);
        setStepPublish(3)
        addHashToBlindBox(receipt.transactionHash)
      })
      .on('error', () => { })
  }
  const addHashToBlindBox = async (transactionHash) => {
    let formData = new FormData()
    formData.append('originalEventId', eventId)
    formData.append('transactionHash', transactionHash)
    let res = await addBlindBox(eventId, formData)
    message.success("Add blindBox transactionHash successed")
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { show: false }
    });
    getEventInfo()
  }

  const deployBadge = async (item) => {
    if(!beforeDeploy()) return false
    if (!finishedAll) { return false }
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    const myContract = await getDeFineBadgeForBlindBoxContract(library, currentBadgeContractAddress)
    // console.log(myContract);
    myContract.methods.newBadge(item.type, item.maxCount, item.claimStartTime / 1000, item.claimEndTime / 1000, 1, currentBlindBoxContractAddress)
      .send({ from: account })
      .on('transcationHash', () => { })
      .on('receipt', (receipt) => {
        console.log(receipt);
        addAddressToBadge(item.type, receipt)
      })
      .on('error', () => { })
  }
  const addAddressToBadge = async (originalBadgeType, receipt) => {
    let formData = new FormData();
    formData.append('originalBadgeType', originalBadgeType)
    // formData.append('contract', currentBadgeContractAddress)
    formData.append('transactionHash', receipt.transactionHash)
    let res = await addBadge(eventId, formData)
    if (res) {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
      message.success("Success")
      getList()
    }
  }
  const publish = async () => {
    let res = await publishEvent(eventId)
    message.success("Publish successed")
    history.push('/badgeManage')
  }
  useEffect(() => {
    getEventInfo()
  }, [])
  return (
    <div className={styles.createBadge}>
      <div className='space-between-center'>
        <div className={`fs36 fw600`}>Publish <span className='fs20 fw500'>/ {eventId}</span></div>
        <Steps className={styles.steps} current={stepBox - 1}>
          <Step />
          <Step />
        </Steps>
      </div>
      <Form layout={'vertical'}>
        <div className={`${styles.step_box} ${stepBox === 1 ? styles.step_box_show : styles.step_box_hidden}`}>
          <div className={styles.box_header}>Step1 Information</div>
          <div className={styles.box_content}>
            <div className={'space-between'}>
              <div className={`${styles.publishInfo_item} mr10`}>
                <div>Blockchain</div>
                <div className='fw600'>{chainTxtObj[eventInfo.chainType]}</div>
              </div>
              <div className={styles.publishInfo_item}>
                <div>Eligibility to participate in the quest</div>
                <div className='fw600'>{eligibilityName[eventInfo.eligibility]}</div>
              </div>
            </div>
            <div className={'space-between'}>
              <div className={`${styles.publishInfo_item} mr10`}>
                <div>Event type </div>
                <div className='fw600'>{eventInfo.type === 'general' ? 'General' : 'Mystery Box'}</div>
              </div>
              <div className={styles.publishInfo_item}>
                <div>Event ID</div>
                <div className='fw600'>{eventId}</div>
              </div>
            </div>
            <div className={'space-between'}>
              <div className={`${styles.publishInfo_item} mr10`}>
                <div>Start time</div>
                <div className='fw600'>{moment(eventInfo.eventStartTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
              <div className={styles.publishInfo_item}>
                <div>End time</div>
                <div className='fw600'>{moment(eventInfo.eventEndTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
            </div>

            <div className={styles.publish_badges}>
              <div className={styles.table_title}>
                <div className='f2'>Badge</div>
                <div className='f2'>Claiming time</div>
                <div className='f1'>Max amount</div>
              </div>
              {
                badgeList.map((item, index) => (
                  <>
                    {
                      <div className={`${styles.table_item} ${index % 2 > 0 ? styles.bg_gray : styles.bg_white}`}>
                        <div className='f2 df_align_center'>
                          <img width={60} src={item.image}></img>
                          <div className={'pl20 pr20'}>{item.name}</div>
                        </div>
                        <div className='f2'>{moment(eventInfo.eventStartTime).format("YYYY-MM-DD HH:mm:ss")}-{moment(eventInfo.eventEndTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                        <div className='f1'>{item.maxCount}</div>
                      </div>
                    }
                  </>
                ))
              }
            </div>
            {/* <div className={styles.blindBox_info}>
                            <div className='f1'>
                                <div>Mystery Box Cover</div>
                                <img className={styles.blindBox_cover} src={eventInfo.blindBox?.image}></img>
                            </div>
                            <div className='f1 pl40'>
                                <div  className='mb10'>Number Of Mystery Box</div>
                                <Input disabled value={blindBoxAmount}></Input>
                                <div className='mt20 mb10'>Limit Per Address</div>
                                <Input></Input>
                                <div  className='mt20 mb10'>Mystery Box Sell Price</div>
                                <Input></Input><Select></Select>
                            </div>
                        </div> */}
          </div>
          <div className='space-between-center p30'>
            <span className={styles.preview}></span>
            <Button type="primary" className={`w200 h50`} onClick={() => { nextStep(2) }}>Next</Button>
          </div>
        </div>
        <div className={`${styles.step_box} ${stepBox === 2 ? styles.step_box_show : styles.step_box_hidden}`}>
          <div className={styles.box_header}>Step2 Contract</div>
          <div className={`${styles.box_content} ${styles.publish_contract}`}>
            <div className='df f1'>
              <Steps className={styles.steps_vertical} direction="vertical" current={finishedAll ? 3 : stepPublish}>
                <Step></Step>
                <Step></Step>
                <Step></Step>
              </Steps>
              <div>

                <div className='pl20 pr20'>
                  <div className='fs18'>Select or create a mystery box contracts</div>
                  <div className={styles.text_tip}>A badge contract can be used multiple times after successfully being created.</div>
                  <Select disabled={finishedAll} value={currentBlindBoxContractAddress} defaultValue={currentBlindBoxContractAddress} className='mt10 mb10 wp100' onChange={selectBlindBoxContract}>
                    {
                      blindBoxAddressList.map(item => (
                        <Option value={item.address}>{item.address}</Option>
                      ))
                    }
                  </Select>
                  <div>
                    <Button disabled={finishedAll} className='wp100 h40' type={'primary'} onClick={deployBlindBoxContract}>Create Mystery Box Contract</Button>
                  </div>
                </div>
                <div className='pl20 pr20 mt20'>
                  <div className='fs18'>Select or create an available badge contract</div>
                  <div className={styles.text_tip}>A badge contract can be used multiple times after successfully being created.</div>
                  <Select disabled={finishedAll} value={currentBadgeContractAddress} defaultValue={currentBadgeContractAddress} className='mt10 mb10 wp100' onChange={selectBadgeContract}>
                    {
                      addressList.map(item => (
                        <Option value={item.address}>{item.address}</Option>
                      ))
                    }
                  </Select>
                  <div>
                    <Button disabled={!currentBlindBoxContractAddress || finishedAll} className='wp100 h40' type={'primary'} onClick={deployBadgeContract}>Create Badge Contract</Button>
                  </div>
                </div>
                <div className='pl20 pr20 mt20'>
                  <div className='fs18'>Customize your mystery box on the contract</div>
                  <div>
                    {
                      finishedAll ? <Button className={`wp100 h40 ${styles.btn_success}`} >Push Succeed</Button>
                        :
                        <Button disabled={!currentBlindBoxContractAddress && !currentBadgeContractAddress} className='wp100 h40' type={'primary'} onClick={creatPoolBaseOfBlindBox}>Push</Button>
                    }
                  </div>
                </div>
              </div>

            </div>
            <div className='f1 pr'>
              <div className={styles.publish_badges}>
                <div className={styles.table_title}>
                  <div>Deploy badges on the blockchain</div>
                </div>
                {
                  badgeList.map((item, index) => (
                    <>
                      {
                        <div className={`${styles.table_item} ${index % 2 > 0 ? styles.bg_gray : styles.bg_white}`}>
                          <div className='f2 df_align_center'>
                            <img width={60} src={item.image}></img>
                            <div className={'pl40 pr40'}>
                              <div className='fs16'>{item.name}</div>
                              <div>{item.maxCount}</div>
                            </div>
                          </div>
                          <div className='f1'>
                            {
                              item.transactionHash ?
                                <Button className={`btn_border w100 h30 ${styles.btn_success}`}>Succeed</Button> :
                                <Button className={`btn_border w100 h30`} onClick={() => { deployBadge(item) }}>Deploy</Button>
                            }
                          </div>
                        </div>
                      }
                    </>
                  ))
                }
              </div>
              {
                !finishedAll &&
                <div className={styles.noFinished}>
                  <img width={70} src={noFinished}></img>
                  <div className={`mt20 w200 tac ${styles.ft_purple}`}>You need to complete the 3 steps on the left first.</div>
                </div>
              }
            </div>
          </div>
          <div className='space-between-center p30'>
            <Button className={`btn_border w200 h50`} onClick={() => { nextStep(1) }}>Prev</Button>
            <Button disabled={succeedAll} type={'primary'} className={`${styles.create_btn} w200 h50`} onClick={publish}>Publish This Event</Button>
          </div>
        </div>
      </Form>
    </div>
  )
}
