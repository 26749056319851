import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {
  Tabs, Spin, Button, Table, message,
  Modal, Row, Col, Input, Form, Popover
} from 'antd';
import Zmage from 'react-zmage';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import Web3 from 'web3'
import copy from 'copy-to-clipboard';

import { chainTxtObj, chainFun, handleHistoryAddress, handleHistoryTx } from '../../utils/networkConnect';
import { mainContext } from "../../reducer";
import { getPoolLeftTime, getTime } from "utils/time";
import { getContract, useActiveWeb3React } from "../../web3";
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { getNftDetail, useNftHistory } from '../../services/nftDetail';
import { useNeedSign } from "hooks/account"
import BlockInfo from './compontents/BlockInfo';
import Countdown from 'components/CountDown';
import BlackTable from 'components/BlackTable';
import NoData from 'components/NoData';
import { NftBuyModal, NftSellModal, NftTransferModal, NftTransferSuccessModal } from 'components/Modals';
import { formatAmountWithDecimal, formatAmount, abbrTxHash } from 'utils/format';
import { isSelf } from 'utils/index';
import ERC20 from '../../web3/abi/ERC20.json'
import DFA721NFT from '../../web3/abi/DFA721NFT.json'
import DFA721NFT_TRON from "../../web3/tronAbi/DFA721NFT.json"
import ERC721 from '../../web3/abi/ERC721.json'
import ERC1155 from '../../web3/abi/ERC1155.json'
import FixedSwapNFT from "../../web3/abi/FixedSwapNFT.json";
import FixedSwapNFT_TRON from '../../web3/tronAbi/FixedSwapNFT.json'
import EnglishAuctionNFT from '../../web3/abi/EnglishAuctionNFT.json'
import EnglishAuctionNFT_TRON from '../../web3/tronAbi/EnglishAuctionNFT.json'
import { creatorClaimBeforeEnd, sellNFT, auctionNFT, getERC721Contract, getERC1155Contract,transferNft } from "../../utils/handleContract";
import { getFixswapAddress, getEnglishAuctionNFTAddress, getNFTTokenAddress, getChainType } from "../../web3/address";
import styles from "./styles.module.scss";
import { ReactComponent as CopyIcon } from "assets/img/header/copy.svg";
import like from "assets/img/activity/like.svg";
import share from "assets/img/activity/share.svg";
import commentsIcon from "assets/img/comments.png";
import BlockProperty from './compontents/BlockProperty';
import CommentModule from './compontents/CommentModule';
import {
  HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
  HANDLE_SHOW_TRANSACTION_MODAL,
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  waitingForConfirm,
  waitingForInit,
  waitingPending
} from "../../const";
import { handleIsSignExpired } from 'utils/txSign';

const { TabPane } = Tabs;

const TimerBox = ({ startAt, closeAt }) => {
  const [left, setLeft] = useState()
  const [timer, setTimer] = useState()

  useEffect(() => {
    if (startAt * 1000 > Date.now()) {
      setTimer(setInterval(() => {
        if (startAt * 1000 > Date.now()) {
          setLeft(getPoolLeftTime(startAt))
        } else {
          setLeft(null)
          clearInterval(timer)
        }
      }, 1000))
    }

    return () => {
      clearInterval(timer)
    }
  }, [startAt, closeAt])

  return left ? (
    <span className="color_text">Starting in {left.days > 0 ? `${left.days}d${left.hours}:${left.minutes}` : `${left.hours}:${left.minutes}:${left.seconds}`}</span>
  ) : (
    <span>{moment(closeAt * 1000).format('YYYY-MM-DD HH:mm:SS')}</span>
  );
}

const collectionObj = {
  sandbox: 'puzzle',
  warrants: "warrants",
  warrants: "mysteryBox",
}


export default function NftDetail() {
  const { t } = useTranslation()
  const history = useHistory();
  const { contractAddress, chainType, tokenId } = useParams()
  const { state, dispatch } = useContext(mainContext);
  const { account, library, chainId } = useActiveWeb3React()
  const { tronAccount, tronChainId } = useActiveTronWeb()
  const [detailInfo, setDetailInfo] = useState()
  const { historyList } = useNftHistory(contractAddress, chainType, tokenId)
  const [tableList, setTableList] = useState([])
  const [tableOffers, setTableOffers] = useState([])
  const [bidLoading, setBidLoading] = useState(false)
  const [dataType, setDataType] = useState('')
  const [timeType, setTimeType] = useState('start')
  const [time, setTime] = useState(0)
  const [symbol, setSymbol] = useState('ETH')
  const [balance, setBalance] = useState(0)
  const [priceType, setPriceType] = useState('')
  const [showCountDown, setShowCountDown] = useState(false)
  const [auctionHasBid, setAuctionHasBid] = useState(false)
  const [minBid, setMinBid] = useState(0)
  const [mine, setMine] = useState(false)
  const { toWei } = Web3.utils;
  const [buyModalVisible, setBuyModalVisible] = useState(false)
  const [buyModalData, setBuyModalData] = useState({})
  const [sellModalVisible, setSellModalVisible] = useState(false)
  const [sellModalData, setSellModalData] = useState({})
  const [transferModalVisible, setTransferModalVisible] = useState(false)
  const { needSign } = useNeedSign();
  const [ defaultActiveKey, setDefaultActiveKey ] = useState('1')
  const location = useLocation();
  const [ successInfo, setSuccessInfo] = useState({})
  const [ transferSuccessModalVisible, setTransferSuccessModalVisible ] = useState(false)

  const tableColumns = [
    {
      title: 'Name', align: "center", key: "operator", dataIndex: "operator",width:120, render: operator => (
        <Link to={`/profile/${operator.address}`}>
          {operator.name}
        </Link>
      )
    },
    {
      title: 'Hash', align: "center", key: "hash", dataIndex: "hash", width:175, render: hash => (
        <a
          href={handleHistoryTx(detailInfo.chainType, hash)}
          target="_blank"
        >{abbrTxHash(hash, 6, 6)}</a>
      )
    },
    { title: 'Price', align: "center", key: "price", dataIndex: "price",width:120, },
    { title: 'Time', align: "center", key: "time", dataIndex: "time", width:175 },
    { title: 'Type', align: "center", key: "type", dataIndex: "type",width:120, },
  ]

  const handelRowClassName = (record, index) => {
    if (index % 2 > 0) {
      return 'rowBgWhite'
    } else {
      return 'rowBgGray'
    }
  }

  const goToCollection = () => {
    history.push(`/collection/${detailInfo.collection}`)
  }

  const showBuyModal = (rowData) => {
    needSign(() => {

      if (getChainType(chainId || tronChainId) === detailInfo.chainType) {
        setBuyModalData(rowData)
        setDataType(detailInfo.nftType === 'ERC1155' ? 'erc1155' : '')
        setBuyModalVisible(true)
      } else {
        if (tronChainId && chainTxtObj[detailInfo.chainType] !== 'Tron') {
          return message.success('Please log out and try again.')
        }

        if (chainTxtObj[detailInfo.chainType] && chainFun[chainTxtObj[detailInfo.chainType]]) {
          chainFun[chainTxtObj[detailInfo.chainType]]()
        } else if (chainTxtObj[detailInfo.chainType] === 'Tron') {
          message.success('Please log out and connect TronLink')
        }
      }

    })
  }

  const handleBid = () => {
    needSign(async () => {

      if (getChainType(chainId || tronChainId) === detailInfo.chainType) {
        setBidLoading(true)
        await updateDetailInfo();
        setBidLoading(false)
        setBuyModalData(detailInfo.saleInfoList[0])
        setDataType(detailInfo.nftType === 'ERC1155' ? 'erc1155' : '')
        setBuyModalVisible(true)
      } else {
        if (tronChainId && chainTxtObj[detailInfo.chainType] !== 'Tron') {
          return message.success('Please log out and try again.')
        }

        if (chainTxtObj[detailInfo.chainType] && chainFun[chainTxtObj[detailInfo.chainType]]) {
          chainFun[chainTxtObj[detailInfo.chainType]]()
        } else if (chainTxtObj[detailInfo.chainType] === 'Tron') {
          message.success('Please log out and connect TronLink')
        }
      }

    })
  }



  const handleCancel = () => {
    setBuyModalVisible(false)
  }



  const onSwapFinish = async (values, isMine, num) => {
    needSign(async () => {
      if (getChainType(chainId || tronChainId) === detailInfo.chainType) {

        let erc20Contract;
        try {
          if (library) {
            erc20Contract = getContract(library, ERC20.abi, values.token1.address)
          }
          if (tronAccount) {
            erc20Contract = window.tronWeb.contract(ERC20.abi, values.token1.address)
          }
        } catch (err) {
          console.log(err)
        }


        if (isMine) {
          const onTx = (hash) => {
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: { ...waitingPending, hash }
            });
          }
          const onReceipt = (_, receipt) => {
            console.log('bid success')
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForInit
            });
            dispatch({
              type: HANDLE_SHOW_TRANSACTION_MODAL,
              showTransactionModal: true
            });
          }
          const onError = (err, receipt) => {
            dispatch({
              type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
              showFailedTransactionModal: true
            });
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForInit
            });
          }
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
          });
          if (account) {
            creatorClaimBeforeEnd(values.poolId, account, library, chainId, {
              _onTranscationHash: onTx,
              _onReceipt: onReceipt,
              _onError: onError
            })
          }

          if (tronAccount && !account) {
            const fixedswapInstance = window.tronWeb.contract(FixedSwapNFT_TRON.abi, getFixswapAddress(tronChainId))
            fixedswapInstance.creatorClaim(values.poolId).send({
              shouldPollResponse: true
            }).then(res => {
              onReceipt()
            }).catch(err => {
              onError()
              message.error(err.error)
            })
          }
          return;
        }

        if (account) {
          const swap = async () => {
            const contract = getContract(library, FixedSwapNFT.abi, getFixswapAddress(chainId))
            try {
              dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: waitingForConfirm
              });
              const eth_value = values.token1.address === '0x0000000000000000000000000000000000000000' ? BigNumber(values.amountTotal1).div(values.token0Amount).times(num) : toWei('0');
              await contract.methods.swap(values.poolId, num)
                .send({ from: account, value: eth_value })
                .on('transactionHash', hash => {
                  dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: { ...waitingPending, hash }
                  });
                })
                .on('receipt', (_, receipt) => {
                  console.log('bid success')
                  dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: waitingForInit
                  });
                  dispatch({
                    type: HANDLE_SHOW_TRANSACTION_MODAL,
                    showTransactionModal: true
                  });
                })
                .on('error', (err, receipt) => {
                  console.log('bid error', err)
                  dispatch({
                    type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                    showFailedTransactionModal: true
                  });
                  dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: waitingForInit
                  });
                })

            } catch (err) {
              dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: waitingForInit
              });
              if (err.code === 4001) {
                dispatch({
                  type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                  showFailedTransactionModal: true
                });
              } else {
                dispatch({
                  type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                  showFailedTransactionModal: true
                });
              }
              console.log('err', err);
            }
          }

          try {
            let approved = 0;
            if (values.token1.address !== "0x0000000000000000000000000000000000000000") {
              approved = await erc20Contract.methods.allowance(account, getFixswapAddress(chainId)).call()
            }
            if (approved > 0 || values.token1.address == "0x0000000000000000000000000000000000000000") {
              swap()
            } else {
              dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: waitingForConfirm
              });
              await erc20Contract.methods.approve(getFixswapAddress(chainId), "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                .send({ from: account })
                .on('receipt', (_, receipt) => {
                  swap()
                })
            }
          } catch (err) {
            console.log(err)
          }
        }

        if (tronAccount && !account) {
          const fixedswapInstance = window.tronWeb.contract(FixedSwapNFT_TRON.abi, getFixswapAddress(tronChainId))
          const swap = async () => {
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForConfirm
            });
            const trx_value = values.token1.address === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb' ? BigNumber(values.amountTotal1).div(values.token0Amount).times(num) : window.tronWeb.toSun('0')
            fixedswapInstance.swap(values.poolId, num).send({
              shouldPollResponse: true,
              callValue: trx_value
            }).then(res => {
              dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: waitingForInit
              });
              dispatch({
                type: HANDLE_SHOW_TRANSACTION_MODAL,
                showTransactionModal: true
              });
            })
          }
          try {
            let approved = 0;
            if (values.token1.address !== 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
              approved = await erc20Contract.allowance(tronAccount, getFixswapAddress(tronChainId)).call()
            }

            if (approved > 0 || values.token1.address === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
              swap()
            } else {
              dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: waitingForConfirm
              });
              erc20Contract.approve(getFixswapAddress(tronChainId), "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
                shouldPollResponse: true
              }).then(res => {
                swap()
              })
            }
          } catch (err) {
            console.log(err)
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForConfirm
            });
          }
        }

      } else {
        if (tronChainId && chainTxtObj[detailInfo.chainType] !== 'Tron') {
          return message.success('Please log out and try again.')
        }

        if (chainTxtObj[detailInfo.chainType] && chainFun[chainTxtObj[detailInfo.chainType]]) {
          chainFun[chainTxtObj[detailInfo.chainType]]()
        } else if (chainTxtObj[detailInfo.chainType] === 'Tron') {
          message.success('Please log out and connect TronLink')
        }
      }
    })
  }


  const onAuctionFinish = async (values) => {
    const saleInfo = detailInfo.saleInfoList[0]

    let erc20Contract;
    try {
      if (library) {
        erc20Contract = getContract(library, ERC20.abi, saleInfo.token1.address)
      }
      if (tronAccount) {
        erc20Contract = window.tronWeb.contract(ERC20.abi, saleInfo.token1.address)
      }
    } catch (err) {
      console.log(err)
    }


    if (account) {
      const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))
      const bid = async () => {
        const eth_value = saleInfo.token1.address === '0x0000000000000000000000000000000000000000' ? values.amount : new BigNumber(0);
        await contract.methods.bid(saleInfo.poolId, values.amount)
          .send({ from: account, value: eth_value })
          .on('transactionHash', hash => {
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: { ...waitingPending, hash }
            });
          })
          .on('receipt', (_, receipt) => {
            console.log('bid success')
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForInit
            });
            dispatch({
              type: HANDLE_SHOW_TRANSACTION_MODAL,
              showTransactionModal: true
            });
          })
          .on('error', (err, receipt) => {
            console.log('bid error', err)
            dispatch({
              type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
              showFailedTransactionModal: true
            });
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForInit
            });
          });
      }
      try {
        let approved = 0;
        if (saleInfo.token1.address !== "0x0000000000000000000000000000000000000000") {
          approved = await erc20Contract.methods.allowance(account, getEnglishAuctionNFTAddress(chainId)).call()
        }
        if (approved > 0 || saleInfo.token1.address == "0x0000000000000000000000000000000000000000") {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
          });
          bid()
        } else {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
          });
          await erc20Contract.methods.approve(getEnglishAuctionNFTAddress(chainId), "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
            .send({ from: account })
            .on('receipt', (_, receipt) => {
              bid()
            })
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (tronAccount && !account) {
      const auctionInstance = window.tronWeb.contract(EnglishAuctionNFT_TRON.abi, getEnglishAuctionNFTAddress(tronChainId))
      const bid = async () => {
        const trx_value = saleInfo.token1.address === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb' ? values.amount : new BigNumber(0);
        auctionInstance.bid(saleInfo.poolId, values.amount).send({
          shouldPollResponse: true,
          callValue: trx_value
        }).then(res => {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForInit
          });
          dispatch({
            type: HANDLE_SHOW_TRANSACTION_MODAL,
            showTransactionModal: true
          });
        })
      }
      try {
        let approved = 0;
        if (saleInfo.token1.address !== 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
          approved = await erc20Contract.allowance(tronAccount, getEnglishAuctionNFTAddress(tronChainId)).call()
        }

        if (approved > 0 || saleInfo.token1.address === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
          });
          bid()
        } else {
          erc20Contract.approve(getEnglishAuctionNFTAddress(tronChainId), "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
            shouldPollResponse: true
          }).then(res => {
            bid()
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
  };








  const handleShowSellModal = () => {
    needSign(() => {
      if (getChainType(chainId || tronChainId) === detailInfo.chainType) {
        setDataType(detailInfo.nftType === 'ERC1155' ? 'erc1155' : '')
        setSellModalVisible(true)
      } else {
        if (tronChainId && chainTxtObj[detailInfo.chainType] !== 'Tron') {
          return message.success('Please log out and try again.')
        }

        if (chainTxtObj[detailInfo.chainType] && chainFun[chainTxtObj[detailInfo.chainType]]) {
          chainFun[chainTxtObj[detailInfo.chainType]]()
        } else if (chainTxtObj[detailInfo.chainType] === 'Tron') {
          message.success('Please log out and connect TronLink')
        }
      }
    })
  }
 


  const handleCancelSellModal = () => {
    setSellModalVisible(false)
  }



  const onSellFinish = async (values, { weiPrice, weiIncr, token1 }) => {
    let { auctionStartTime, auctionEndTime, fixswapStartTime } = values;
    if (auctionStartTime) {
      auctionStartTime = auctionStartTime.valueOf()
    }
    if (auctionEndTime) {
      auctionEndTime = auctionEndTime.valueOf()
    }
    if (fixswapStartTime) {
      fixswapStartTime = fixswapStartTime.valueOf()
    }

    handleCancelSellModal();
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    const address = detailInfo.contractAddress;

    if (account) {
      const dfanft = getContract(library, DFA721NFT.abi, address)
      const to = values.type == 'bid' ? getEnglishAuctionNFTAddress(chainId) : getFixswapAddress(chainId)
      const isApproved = await dfanft.methods.isApprovedForAll(account, to).call()
      if (!isApproved) {
        await dfanft.methods.setApprovalForAll(to, true).send({ from: account });
      }
      if (values.type === 'bid') {
        const onTx = (hash) => {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { ...waitingPending, hash }
          });
        }
        const onReceipt = (_, receipt) => {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { ...waitingForInit, link: `/marketplace/${detailInfo.contractAddress}/${detailInfo.chainType}/${detailInfo.id}` }
          });
          dispatch({
            type: HANDLE_SHOW_TRANSACTION_MODAL,
            showTransactionModal: true
          });
        }
        const onError = (err, receipt) => {
          dispatch({
            type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
            showFailedTransactionModal: true
          });
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForInit
          });
        }
        auctionNFT('ERC721', account, {
          name: "NFT",
          token0: address,
          token1,
          tokenId: detailInfo.id,
          amountMin1: weiPrice.toString(),
          amountMinIncr1: weiIncr.toString(),
          confirmTime: getTime(auctionEndTime, auctionStartTime),
          addTime: false,
          startTime: parseInt(auctionStartTime / 1000),
          _onTranscationHash: onTx,
          _onReceipt: onReceipt,
          _onError: onError
        }, library, chainId)
      }

      if (values.type === 'swap') {
        const onTx = (hash) => {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { ...waitingPending, hash }
          });
        }
        const onReceipt = (_, receipt) => {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { ...waitingForInit, link: `/marketplace/${detailInfo.contractAddress}/${detailInfo.chainType}/${detailInfo.id}` }
          });
          dispatch({
            type: HANDLE_SHOW_TRANSACTION_MODAL,
            showTransactionModal: true
          });
        }
        const onError = (err, receipt) => {
          dispatch({
            type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
            showFailedTransactionModal: true
          });
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForInit
          });
        }
        if (dataType === 'erc1155') {
          sellNFT('ERC1155', account, {
            name: "NFT",
            token0: address,
            token1,
            tokenId: detailInfo.id,
            amountTotal0: values.quantity,
            amountTotal1: weiPrice.times(values.quantity).toString(),
            duration: 86400 * 30,
            startTime: parseInt(fixswapStartTime / 1000),
            _onTranscationHash: onTx,
            _onReceipt: onReceipt,
            _onError: onError
          }, library, chainId)
        } else {
          sellNFT('ERC721', account, {
            name: "NFT",
            token0: address,
            token1,
            tokenId: detailInfo.id,
            amountTotal1: weiPrice.toString(),
            duration: 86400 * 30,
            startTime: parseInt(fixswapStartTime / 1000),
            _onTranscationHash: onTx,
            _onReceipt: onReceipt,
            _onError: onError
          }, library, chainId)
        }
      }
    }

    if (tronAccount) {
      const nftAddress = getNFTTokenAddress(tronChainId)
      const instance = window.tronWeb.contract(DFA721NFT_TRON.abi, nftAddress)
      const to = values.type == 'bid' ? getEnglishAuctionNFTAddress(tronChainId) : getFixswapAddress(tronChainId)
      const isApproved = await instance.isApprovedForAll(tronAccount, to).call()
      if (!isApproved) {
        await instance.setApprovalForAll(to, true).send({ shouldPollResponse: true });
      }

      if (values.type === 'bid') {
        const auctionAddress = getEnglishAuctionNFTAddress(tronChainId)
        const auctionInstance = window.tronWeb.contract(EnglishAuctionNFT_TRON.abi, auctionAddress)
        auctionInstance.createErc721WithStartTime(
          "NFT",
          address,
          token1,
          detailInfo.id,
          weiPrice.toString(),
          weiIncr.toString(),
          getTime(auctionEndTime, auctionStartTime),
          false,
          parseInt(auctionStartTime / 1000)
        ).send({
          shouldPollResponse: true
        }).then(res => {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { ...waitingForInit, link: `/marketplace/${detailInfo.contractAddress}/${detailInfo.chainType}/${detailInfo.id}` }
          });
          dispatch({
            type: HANDLE_SHOW_TRANSACTION_MODAL,
            showTransactionModal: true
          });
        })
      } else {
        if (values.type === 'swap') {
          const fixedswapAddress = getFixswapAddress(tronChainId)
          const fixedswapInstance = window.tronWeb.contract(FixedSwapNFT_TRON.abi, fixedswapAddress)
          if (dataType === 'erc1155') {
            fixedswapInstance.createErc1155WithStartTime(
              "NFT",
              address,
              token1,
              detailInfo.id,
              values.quantity,
              weiPrice.times(values.quantity).toString(),
              86400 * 30,
              parseInt(fixswapStartTime / 1000),
              false
            ).send({
              shouldPollResponse: true
            }).then(res => {
              dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { ...waitingForInit, link: `/marketplace/${detailInfo.contractAddress}/${detailInfo.chainType}/${detailInfo.id}` }
              });
              dispatch({
                type: HANDLE_SHOW_TRANSACTION_MODAL,
                showTransactionModal: true
              });
            })
          } else {
            fixedswapInstance.createErc721WithStartTime(
              "NFT",
              address,
              token1,
              detailInfo.id,
              weiPrice.toString(),
              86400 * 30,
              parseInt(fixswapStartTime / 1000),
              false
            ).send({
              shouldPollResponse: true
            }).then(res => {
              dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { ...waitingForInit, link: `/marketplace/${detailInfo.contractAddress}/${detailInfo.chainType}/${detailInfo.id}` }
              });
              dispatch({
                type: HANDLE_SHOW_TRANSACTION_MODAL,
                showTransactionModal: true
              });
            })
          }
        }
      }

    }
  }

  const onClaim = (func) => {
    if (getChainType(chainId || tronChainId) !== detailInfo.chainType) {
      if (tronChainId && chainTxtObj[detailInfo.chainType] !== 'Tron') {
        return message.success('Please log out and try again.')
      }

      if (chainTxtObj[detailInfo.chainType] && chainFun[chainTxtObj[detailInfo.chainType]]) {
        chainFun[chainTxtObj[detailInfo.chainType]]()
      } else if (chainTxtObj[detailInfo.chainType] === 'Tron') {
        message.success('Please log out and connect TronLink')
      }
      return;
    }

    const saleInfo = detailInfo.saleInfoList[0]
    if (account) {
      const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))
      try {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForConfirm
        });
        contract.methods[func](saleInfo.poolId)
          .send({ from: account })
          .on('transactionHash', hash => {
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: { ...waitingPending, hash }
            });
          })
          .on('receipt', (_, receipt) => {
            console.log('claim success')
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForInit
            });
            dispatch({
              type: HANDLE_SHOW_TRANSACTION_MODAL,
              showTransactionModal: true
            });
          })
          .on('error', (err, receipt) => {
            console.log('claim error', err)
            dispatch({
              type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
              showFailedTransactionModal: true
            });
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForInit
            });
          })

      } catch (err) {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForInit
        });
        if (err.code === 4001) {
          dispatch({
            type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
            showFailedTransactionModal: true
          });
        } else {
          dispatch({
            type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
            showFailedTransactionModal: true
          });
        }
        console.log('err', err);
      }
    }

    if (tronAccount && !account) {
      const auctionInstance = window.tronWeb.contract(EnglishAuctionNFT_TRON.abi, getEnglishAuctionNFTAddress(tronChainId))
      try {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForConfirm
        });
        auctionInstance[func](saleInfo.poolId).send({
          shouldPollResponse: true
        }).then(res => {
          dispatch({
            type: HANDLE_SHOW_TRANSACTION_MODAL,
            showTransactionModal: true
          });
        })
      } catch (err) {
        console.log(err)
        dispatch({
          type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
          showFailedTransactionModal: true
        });
      }
    }
  };

  const onDealNow = async () => {
    if (getChainType(chainId || tronChainId) !== detailInfo.chainType) {
      if (tronChainId && chainTxtObj[detailInfo.chainType] !== 'Tron') {
        return message.success('Please log out and try again.')
      }

      if (chainTxtObj[detailInfo.chainType] && chainFun[chainTxtObj[detailInfo.chainType]]) {
        chainFun[chainTxtObj[detailInfo.chainType]]()
      } else if (chainTxtObj[detailInfo.chainType] === 'Tron') {
        message.success('Please log out and connect TronLink')
      }
      return;
    }

    const saleInfo = detailInfo.saleInfoList[0]
    if (account) {
      const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))
      try {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForConfirm
        });
        contract.methods.closeAuction(saleInfo.poolId)
          .send({ from: account })
          .on('transactionHash', hash => {
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: { ...waitingPending, hash }
            });
          })
          .on('receipt', (_, receipt) => {
            console.log('claim success')
            onClaim('creatorClaim')
          })
          .on('error', (err, receipt) => {
            console.log('claim error', err)
            dispatch({
              type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
              showFailedTransactionModal: true
            });
            dispatch({
              type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
              showWaitingWalletConfirmModal: waitingForInit
            });
          })

      } catch (err) {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForInit
        });
        if (err.code === 4001) {
          dispatch({
            type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
            showFailedTransactionModal: true
          });
        } else {
          dispatch({
            type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
            showFailedTransactionModal: true
          });
        }
        console.log('err', err);
      }
    }

    if (tronAccount && !account) {
      const auctionInstance = window.tronWeb.contract(EnglishAuctionNFT_TRON.abi, getEnglishAuctionNFTAddress(tronChainId))
      try {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForConfirm
        });
        auctionInstance.closeAuction(saleInfo.poolId).send({
          shouldPollResponse: true
        }).then(res => {
          onClaim('creatorClaim')
        })
      } catch (err) {
        console.log(err)
        dispatch({
          type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
          showFailedTransactionModal: true
        });
      }
    }
  };


  const handleShowTransferModal = () => {
    needSign(() => {
      if (getChainType(chainId || tronChainId) === detailInfo.chainType) {
        setDataType(detailInfo.nftType === 'ERC1155' ? 'erc1155' : '')
        setTransferModalVisible(true)
      } else {
        if (tronChainId && chainTxtObj[detailInfo.chainType] !== 'Tron') {
          return message.success('Please log out and try again.')
        }

        if (chainTxtObj[detailInfo.chainType] && chainFun[chainTxtObj[detailInfo.chainType]]) {
          chainFun[chainTxtObj[detailInfo.chainType]]()
        } else if (chainTxtObj[detailInfo.chainType] === 'Tron') {
          message.success('Please log out and connect TronLink')
        }
      }
    })
  }

  const handleTransferCancel = () => {
    setTransferModalVisible(false)
  }

  const onTransferFinish = async (values) => {
    handleTransferCancel();
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    const onTx = (hash) => {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { ...waitingPending, hash }
      });
    }
    const onReceipt = (receipt) => {
      setTimeout(() => {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        let obj = {
          image: detailInfo.image,
          address: values.address,
          transactionHash: tronAccount ?receipt: receipt.transactionHash,
          chainType: chainType
        }
        setSuccessInfo(obj)
        setTransferSuccessModalVisible(true)
      },3000)
    }
    const onError = (err, receipt) => {
      dispatch({
        type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
        showFailedTransactionModal: true
      });
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: waitingForInit
      });
    }
    let params = {
      from: account,
      to: values.address,
      tokenId: detailInfo.id,
      ammount: values.quantity,
      data: "0x00",
      _onTranscationHash: onTx,
      _onReceipt: onReceipt,
      _onError: onError
    }
    if(account) {
      transferNft(detailInfo.nftType,account,library,detailInfo.contractAddress,params)
    }
    if(tronAccount && !account){
      params.from = tronAccount
      if(detailInfo.nftType === "ERC1155"){
        const transferInstance = window.tronWeb.contract(ERC1155.abi, detailInfo.contractAddress)
        transferInstance.safeTransferFrom(
          params.from,
          params.to,
          params.tokenId,
          params.ammount,
          params.data
        )
        .send({ from: tronAccount }).then(res => {
          onReceipt(res)
        }).catch(err => {
          onError()
        })
      }else {
        const transferInstance = window.tronWeb.contract(ERC721.abi, detailInfo.contractAddress)
        transferInstance.safeTransferFrom(
          params.from,
          params.to,
          params.tokenId 
        )
        .send({ from: tronAccount }).then(res=> {
          onReceipt(res)
         
        }).catch(err => {
          onError()
        })
      }
    }
  }



  const columnsErc1155 = [
    {
      title: 'From',
      align: "center",
      key: 'user',
      width: 90,
      render: data => (
        <Link to={`/profile/${data.creator.address}`} className={styles.link}>
          {data.creator.name}
        </Link>
      ),
    },
    {
      title: t('unit_price'),
      align: "center",
      key: 'price',
      width: 90,
      render: data => (
        `${formatAmountWithDecimal(BigNumber(data.amountTotal1).div(data.token0Amount), data.token1.decimals)} ${data.token1.symbol}`
      ),
    },
    {
      title: 'Quantity',
      align: "center",
      key: 'token0Amount',
      width: 90,
      render: data => data.token0Amount - data.swappedAmount0,
    },
    {
      title: 'Expiration',
      align: "center",
      width: 175,
      key: 'expiration',
      render: data => <TimerBox startAt={data.startAt} closeAt={data.closeAt} />
    },
    {
      title: '',
      align: "center",
      key: 'expiration',
      fixed: 'right',
      width: 135,
      render: data => {
        const { startAt, creator: { address } } = data;
        const currentAccount = account || tronAccount;

        if (address && currentAccount) {
          if (address.toLocaleLowerCase() !== currentAccount.toLocaleLowerCase()) {
            return <Button
              disabled={startAt * 1000 > Date.now()}
              className={styles.btn}
              type="primary"
              size="small"
              onClick={() => { showBuyModal(data) }}
            >{t('buy_now')}</Button>
          } else {
            return <Button
              className={`btn_danger`}
              size="small"
              onClick={() => { onSwapFinish(data, true) }}
            >Cancel Listings</Button>
          }
        }
      },
    },
  ];

  const dealTime = () => {
    const now = Date.now() / 1000;
    const info = detailInfo.saleInfoList && detailInfo.saleInfoList[0];
    if (info) {
      let date;
      if (info.startAt - now > 0) {
        date = info.startAt;
        setTimeType('start')
      } else {
        date = info.closeAt;
        setTimeType('end')
      }

      if (date - now > 0) {
        setTime(date)
        setShowCountDown(true)
      } else {
        setTime(0)
        setShowCountDown(false)
        setTimeType('overtime')
      }
    }
  }

  const dealPrice = () => {

    const info = detailInfo.saleInfoList && detailInfo.saleInfoList[0]
    if (info) {
      if (info.type === 'auction') {
        if (info.closeAt * 1000 - Date.now() < 0) {
          // is overtime
          if (info.bidHistories && info.bidHistories.length > 0) {
            setBalance(formatAmountWithDecimal(
              info.bidHistories[info.bidHistories.length - 1].amount1,
              info.token1?.decimals
            ))
            setAuctionHasBid(true)
          } else {
            if (detailInfo.lastPrice) {
              setBalance(formatAmountWithDecimal(
                detailInfo.lastPrice.price,
                detailInfo.lastPrice.priceToken?.decimals
              ))
              setSymbol(detailInfo.lastPrice.priceToken?.symbol)
            }
          }
        } else {
          if (info.bidHistories && info.bidHistories.length > 0) {
            setBalance(formatAmountWithDecimal(
              info.bidHistories[info.bidHistories.length - 1].amount1,
              info.token1?.decimals
            ))
            setMinBid(formatAmount(
              BigNumber(info.bidHistories[info.bidHistories.length - 1].amount1).plus(info.amountMinIncr1),
              info.token1?.decimals
            ))
            setAuctionHasBid(true)
          } else {
            setBalance(formatAmountWithDecimal(
              info.amountMin1,
              info.token1?.decimals
            ))
            setMinBid(formatAmount(
              info.amountMin1,
              info.token1?.decimals
            ))
          }
        }
        setPriceType('auction')

      } else {
        setBalance(formatAmountWithDecimal(info.amountTotal1, info.token1?.decimals))
        setPriceType('fixedSwap')
      }
      setSymbol(info.token1?.symbol)

    } else if (detailInfo.lastPrice) {
      setBalance(formatAmountWithDecimal(
        detailInfo.lastPrice.price,
        detailInfo.lastPrice.priceToken?.decimals
      ))
      setSymbol(detailInfo.lastPrice.priceToken?.symbol)
      setPriceType('last')
    }

  }

  const needShowBtn = () => {
    if (priceType === 'auction') {
      if (mine) {
        if (timeType === 'overtime') {
          if (auctionHasBid) {
            return <Button onClick={() => { onClaim('creatorClaim') }} style={{ width: 200 }} type="primary" >Get assets</Button>

          } else {
            return <Button onClick={() => { onClaim('creatorClaim') }} style={{ width: 200 }} type="primary" >Keep my NFT</Button>

          }
        } else {
          if (auctionHasBid) {
            return <Button onClick={() => { onDealNow() }} style={{ width: 200 }} type="primary" >Deal Now</Button>

          } else {
            return <Button onClick={() => { onDealNow() }} style={{ width: 200 }} className="btn_border" >Cancel Listings</Button>

          }
        }

      } else {
        if (timeType === 'overtime') {
          const bidList = detailInfo.saleInfoList[0].bidHistories;
          if (auctionHasBid && isSelf(bidList[bidList.length - 1].bidder.address, account, tronAccount)) {
            return <Button onClick={() => { onClaim('bidderClaim') }} style={{ width: 200 }} type="primary" >Keep my NFT</Button>

          }
        } else {
          return <Button loading={bidLoading} onClick={handleBid} disabled={timeType === 'start'} style={{ width: 200 }} type="primary" >Place Bid</Button>

        }

      }
    } else if (priceType === 'fixedSwap') {
      if (mine) {
        return <Button onClick={() => { onSwapFinish(detailInfo.saleInfoList[0], true) }} style={{ width: 200 }} className="btn_border" >Cancel Listings</Button>

      } else {
        return <Button onClick={() => { onSwapFinish(detailInfo.saleInfoList[0], false, 1) }} disabled={timeType === 'start'} style={{ width: 200 }} type="primary" >Buy Now</Button>

      }

    } else {
      if (mine) {
        return <Button onClick={handleShowSellModal} className={`${styles.bnt_h5}`} type="primary" >Sell</Button>

      }
    }
  }

  const showPriceLabel = () => {
    if (priceType === 'auction') {
      if (timeType === 'overtime') {
        return 'Last Price'
      } else {
        if (auctionHasBid) {
          return 'Min Bid'
        } else {
          return 'Starting Price'
        }
      }
    } else if (priceType === 'fixedSwap') {
      return 'Price'
    } else {
      return 'Last Price'
    }
  }

  const showPriceValue = () => {
    if (priceType === 'auction' && timeType !== 'overtime') {
      return `${minBid.toFormat(3, 1)} ${symbol}`
    } else {
      return `${balance} ${symbol}`
    }
  }

  const updateDetailInfo = async () => {
    await getNftDetail(contractAddress, chainType, tokenId).then(res => {
      setDetailInfo(res)
    })
  }

  const initNewShow =  () => {
    if(location.search) {
      setDefaultActiveKey('2')
    }else {
      setDefaultActiveKey('1')
    }
  }
  useEffect(() => {
    initNewShow()
    if (contractAddress && chainType && tokenId) {
      updateDetailInfo()
    }
  }, [contractAddress, chainType, tokenId])

  useEffect(() => {
    if (detailInfo) {
      dealTime()
      dealPrice()
      
      if (detailInfo.saleInfoList && detailInfo.saleInfoList[0]) {
        if (isSelf(detailInfo.saleInfoList[0].creator.address, account, tronAccount)) {
          setMine(true)
        }
      } else {
        if (isSelf(detailInfo.owner?.address, account, tronAccount)) {
          setMine(true)
        }
      }
    }
  }, [detailInfo, account, tronAccount])


  const handleTransferSuccessCancel = async () => {
    setTransferSuccessModalVisible(false)
    if(dataType === 'erc1155'){
      updateDetailInfo()
    }else {
      setMine(false)
    }
  }


  return (
    <div className={`page_content_h5_1280 ${styles.main}`}>
      {
        !detailInfo ? <div className={`tac mt40`}><Spin size={`large`}></Spin></div> :
          <>
            <div className={`space-between-h5 ${styles.nftDetail}`}>
              <div className={styles.img_box}>

                <div className={`page_content_title ${styles.detail_title}`}>{detailInfo.name}</div>
                <div
                  style={{ display: detailInfo.collection ? 'block' : 'none' }}
                  className={`link_color cp`}
                  onClick={goToCollection}
                >Collection</div>
                <div className={`${styles.cover_module} mt10`}>
                  {detailInfo.image && (!detailInfo.image.includes('.mp4') && !detailInfo.image.includes('.mov')) &&
                    <Zmage className={styles.nft_img} src={detailInfo.image} />
                  }
                  {detailInfo.image && (detailInfo.image.includes('.mp4') || detailInfo.image.includes('.mov')) &&
                    <video className={styles.nft_img} controls><source src={detailInfo.image} />Sorry, your browser doesn't support embedded videos.</video>
                  }
                  {/* <div className={styles.like_share}>
                    <div className={styles.like}>
                      <img src={like}></img>
                      <div className={`fs12`}>12.3k</div>
                    </div>
                    <div className={styles.share}>
                      <img src={share}></img>
                      <div className={`fs12`}>Twitter</div>
                    </div>
                  </div> */}
                </div>
                <div className={`${styles.detail_des} mt10`}>
                  <Tabs defaultActiveKey="1" style={{ minHeight: 168 }}>
                    <TabPane tab="Description" key="1" >
                      {detailInfo.description}
                    </TabPane>
                    <TabPane tab="Properties" key="2">
                      {
                        detailInfo.attributes ?
                          <div className={styles.blockProperty}>
                            {
                              detailInfo.attributes.map(item => (
                                <BlockProperty key={item.name} item={item}></BlockProperty>
                              ))
                            }
                          </div>
                          :
                          <div className={styles.noData}>
                            <NoData></NoData>
                          </div>
                      }
                    </TabPane>
                    <TabPane tab="On chain info" key="3">
                      <div className={`pl20 pr20`}>
                        <div className={`space-between`}>
                          <div>Contract Address</div>
                          <div className={`c_blue cp`}>
                            <a href={handleHistoryAddress(detailInfo.chainType, detailInfo.contractAddress)} target="_blank" className={`pr5`}>{abbrTxHash(detailInfo.contractAddress)}</a>
                            <CopyIcon onClick={() => {
                              copy(detailInfo.contractAddress);
                              message.success('copied!')
                            }} />
                          </div>
                        </div>
                        <div className={`space-between`}>
                          <div>Token ID</div>
                          <div className={`fw700`}>{detailInfo.id}</div>
                        </div>
                        <div className={`space-between`}>
                          <div>Assets Protocol</div>
                          <div className={`fw700`}>{detailInfo.nftType}</div>
                        </div>
                        <div className={`space-between`}>
                          <div>Blockchain</div>
                          <div className={`fw700`}>{chainTxtObj[detailInfo.chainType]}</div>
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
              {
                detailInfo.nftType === 'ERC1155' ?

                  <div className={styles.detail_info}>
                    <Tabs type="card" defaultActiveKey={defaultActiveKey}>
                      <TabPane tab="Trading Info" key="1" >
                        <div className={`space-between p20 ${styles.block_info}`}>
                          <BlockInfo blockType={`Blockchain`} chainType={detailInfo.chainType}></BlockInfo>
                          <BlockInfo blockType={`Creator`} url={detailInfo.creator.avatar} name={detailInfo.creator.name} isArtist={detailInfo.creator.artist} isOwnedNft={detailInfo.creator.avatarFromOwnedNft}></BlockInfo>
                          <div className={`df tac ${styles.block_1155}`}>
                            <div className={`pl10 pr10`}>
                              <div>Items</div>
                              <div>{detailInfo.count}</div>
                            </div>
                            <div className={`pl10 pr10`}>
                              <div>Owner</div>
                              <div>{detailInfo.ownerCount}</div>
                            </div>
                          </div>
                        </div>

                        <div style={{ padding: '40px 40px 0' }} className={`space-between`}>
                          <div>
                            <div>Listings</div>
                            <div className="fs26 fwb">{detailInfo.onSaleCountByCurrentUser}</div>
                          </div>
                          <div>
                            <div>Not Listed</div>
                            <div className="fs26 fwb">{detailInfo.ownedCountByCurrentUser}</div>
                          </div>
                          <span> </span>
                          {/* {
                            !!detailInfo.ownedCountByCurrentUser &&
                            <Button onClick={handleShowSellModal} style={{ width: 200 }} type="primary" >Sell</Button>
                          } */}
                        </div>
                        {
                          !!detailInfo.ownedCountByCurrentUser &&
                          <div style={{ padding: '40px 40px 0' }} >
                            <Button onClick={handleShowSellModal} className={` mb10 ${styles.bnt_h5}  ${styles.mr20_mr10}`} type="primary" >Sell</Button>
                            <Button onClick={handleShowTransferModal} className={`btn_border ${styles.bnt_h5} `} >Transfer</Button>
                          </div>
                        }
                        <div className={styles.block_table}>
                          <Tabs type="card" defaultActiveKey="1" >
                            <TabPane tab="Listing" key="1">
                              <Table
                                className={styles.erc1155_table}
                                scroll={{ x: 400}}
                                pagination={false}
                                columns={columnsErc1155}
                                dataSource={detailInfo.saleInfoList}
                                rowKey="poolId"
                              ></Table>
                            </TabPane>
                            <TabPane tab="Offers" key="2">
                              <Table
                                scroll={{ x: 400 }}
                                columns={columnsErc1155}
                              ></Table>
                            </TabPane>
                          </Tabs>
                        </div>
                      </TabPane>
                      <TabPane tab="Comment" key="2" style={{ height: 200 }}>
                        <div className={styles.comment_body}>
                          <CommentModule></CommentModule>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div> :

                  <div className={styles.detail_info}>
                    <Tabs type="card" defaultActiveKey={defaultActiveKey}>
                      <TabPane tab="Trading Info" key="1" >
                        <div className={`space-between p20 ${styles.block_info}`}>
                          <BlockInfo blockType={`Blockchain`} chainType={detailInfo.chainType}></BlockInfo>
                          <BlockInfo blockType={`Creator`} address={detailInfo.creator.address} url={detailInfo.creator.avatar} name={detailInfo.creator.name} isArtist={detailInfo.creator.artist} isOwnedNft={detailInfo.creator.avatarFromOwnedNft}></BlockInfo>
                          <BlockInfo
                            blockType={`Owner`}
                            address={detailInfo.owner.avatar ? detailInfo.owner.address : (detailInfo.saleInfoList && detailInfo.saleInfoList[0] && detailInfo.saleInfoList[0].creator.address)}
                            url={detailInfo.owner.avatar || (detailInfo.saleInfoList && detailInfo.saleInfoList[0] && detailInfo.saleInfoList[0].creator.avatar)}
                            name={detailInfo.owner.name || (detailInfo.saleInfoList && detailInfo.saleInfoList[0] && detailInfo.saleInfoList[0].creator.name)}
                            isArtist={detailInfo.owner.artist || (detailInfo.saleInfoList && detailInfo.saleInfoList[0] && detailInfo.saleInfoList[0].creator.artist)}
                            isOwnedNft={detailInfo.owner.avatarFromOwnedNft || (detailInfo.saleInfoList && detailInfo.saleInfoList[0] && detailInfo.saleInfoList[0].creator.avatarFromOwnedNft)}
                          ></BlockInfo>
                        </div>

                        <div className={`space-between ${styles.block_info_2}`}>
                          {
                            showCountDown && (
                              <div className={`f1`}>
                                <div>{timeType === 'start' ? 'Start Time' : 'Ending in'}</div>
                                <div className={`countDown fs26`}>
                                  <Countdown onEnd={dealTime} time={time}></Countdown>
                                </div>
                              </div>
                            )
                          }
                          {
                            !!balance ? (
                              <div className={`f1`}>
                                <div>{showPriceLabel()}</div>
                                <div className={`fw700 fs26`}>{showPriceValue()}</div>
                              </div>
                            ) : <div style={{ color: '#9a9a9a', fontSize: 26, fontWeight: 'bold' }}>Not sold</div>
                          }
                        </div>
                        <div className="pl40 pr40">
                          {needShowBtn()}
                          {/* <Button style={{ width: 200 }} className={`btn_border ml20`}>{t('redeem')}</Button> */}
                          {
                            mine &&(priceType!=='auction')&&(priceType!=='fixedSwap')&&
                            <Button onClick={handleShowTransferModal} className={`${styles.bnt_h5} btn_border ml10`} >Transfer</Button>
                          }

                        </div>
                        <div className={styles.block_table}>
                          <Tabs type="card" defaultActiveKey="1">
                            <TabPane tab="Offers" key="2">
                              <Table scroll={{ x: 400 }}
                                columns={columnsErc1155}
                              ></Table>
                            </TabPane>
                          </Tabs>
                        </div>
                      </TabPane>
                      <TabPane tab="Comment" key="2" style={{ height: 200 }}>
                        <div className={styles.comment_body}>
                          {/* <img width="68%" src={commentsIcon} /> */}
                          <CommentModule></CommentModule>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
              }
            </div>
            <div className={styles.detail_history}>
              <div className={`${styles.history_title}`}>Transaction history</div>
              <BlackTable rowKey="transactionHash" tableColumns={tableColumns} tableList={historyList}></BlackTable>
            </div>
          </>
      }

      {
        detailInfo &&
        <NftSellModal
          dataType={dataType}
          detailInfo={detailInfo}
          onCancel={handleCancelSellModal}
          onSellFinish={onSellFinish}
          visible={sellModalVisible}
        />
      }
      {
        detailInfo &&
        <NftBuyModal
          minBid={minBid.toString()}
          dataType={dataType}
          detailInfo={detailInfo}
          buyModalData={buyModalData}
          onCancel={handleCancel}
          visible={buyModalVisible}
          onSwapFinish={onSwapFinish}
          onAuctionFinish={onAuctionFinish}
        />
      }
      {
        detailInfo &&
        <NftTransferModal
          dataType={dataType}
          detailInfo={detailInfo}
          onCancel={handleTransferCancel}
          visible={transferModalVisible}
          onTransferFinish={onTransferFinish}
        />
      }
      {
        detailInfo &&
        <NftTransferSuccessModal
        visible={transferSuccessModalVisible}
        successInfo={successInfo}
        onCancel={handleTransferSuccessCancel}
        detailInfo={detailInfo}
      />
      }
    </div>
  )
}
