import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd"

import { HANDLE_SHOW_FAILED_TRANSACTION_MODAL } from "../../const";
import { mainContext } from "../../reducer";
import styles from "./styles.module.scss";

export const FailedTransactionModal = ({ visible }) => {
  const { dispatch } = useContext(mainContext);
  const { t } = useTranslation()

  const handleCancel = () => {
    dispatch({
      type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
      showFailedTransactionModal: false
    });
  }

  return (
    <Modal
      width={450}
      className={styles.modal_main_tip}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div className={`${styles['emoji']} ${styles['emoji--sad']} mt30`}>
        <div className={`${styles['emoji__face']}`}>
          <div className={`${styles['emoji__eyebrows']}`} />
          <div className={`${styles['emoji__eyes']}`} />
          <div className={`${styles['emoji__mouth']}`} />
        </div>
      </div>

      <h3>{t('transcation_failed_please_try_again')}</h3>

      <button className={styles.failed_btn} onClick={handleCancel}>
        {t('close')}
      </button>
    </Modal>
  );
};
