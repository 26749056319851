import React from 'react';

import styles from './style.module.scss';
import publishIcon from '../../assets/img/draft/publish.png';
import delIcon from '../../assets/img/draft/del.png';

export default function DraftsCard({ item, goToEdit, handleDeleteDraft }) {


  return (
    <div className={styles.draft_card}>
      <img className={styles.cover} src={item.preview}></img>
      <div className={`${styles.draft_num} ell`}>{item.name}</div>
      <div className={styles.draft_func}>
        <div className={`tac mr10 cp`} onClick={() => {goToEdit(item)}}>
          <img width={17} src={publishIcon}></img>
          <div className={`fs14 mt5`}>Edit</div>
        </div>
        <div className={`${styles.draft_publish}`}></div>
        <div className={`ml10 tac cp`} onClick={() => {handleDeleteDraft(item.id)}}>
          <img width={20} src={delIcon}></img>
          <div className={`fs14 mt5`}>Delete</div>
        </div>
      </div>
    </div>
  )
}
