import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Spin, message, Skeleton,Radio, Select } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BigNumber from 'bignumber.js';

import CountDown from 'components/CountDown';
import { useVoteList, queryDfaPrice } from "../../services/vote"
import { useActiveWeb3React } from "../../web3";
import { setupEthMainnet } from '../../utils/networkConnect';
import { useNeedSign } from "hooks/account"
import { getERC20Contract } from '../../utils/handleContract';

import styles from "./styles.module.scss";

import tip from '../../assets/img/dao/tip.png'
// import switchChain from "../../assets/img/switchChain.png";
import voteIcon from "../../assets/img/dao/vote_icon.png";
import clockIcon from "../../assets/img/dao/clock.png";
import { ReactComponent as Svgbank } from "assets/img/dao/bank.svg";
import env from '../../env';
import { formatAmountWithDecimal } from "../../utils/format";

const { Option } = Select;

export default function Vote() {
    const { t } = useTranslation()
    const history = useHistory();
    const { chainId } = useActiveWeb3React()
    const [loading, setLoading] = useState(true)
    const testList = [1,2,3,4,5]
    const [refresh, setRefresh] = useState(0)
    const [ contractAddress, setContractAddress ] = useState('')
    const [ status, setStatus ] = useState('')
    const { voteList } = useVoteList(setLoading,refresh,contractAddress,status)
    const { needSign } = useNeedSign();
    const [totalPrice, setTotalPrice ] = useState()
    const filterList = {
        production: [
            {label:"All Types", value: ""},
            {label:"On-chain", value: "0x9b1DdF053136bC9710C81a2A89162B9Fa0A2640d,0x63124FF062881888B6fbc348E5932F74B7cF3AA0"},
            {label:"Off-chain", value: "0x506A5dcf47A27f6A48a23512afa6B32B0f612ce7,0xab8e8d9bb650a0c3f91bac1357f90d5efab0d5f7,0x1e2a9456e5f6b63b6b55232010b9ae9dcf22caaf"},
        ],
        development: [
            {label:"All Types", value: ""},
            {label:"On-chain", value: "0xFdaBE319f98db4a45C8c56E8CF66E4A41673EE8a,0x8A1bc81D1eA6e6072f565b825dAD9dD993E68eBc,0x0Cc1cBe4cDe1ebBE7789E95A50ef297Beb2dA413"},
            {label:"Off-chain", value: "0xD971c877bD66f0438511Fe771c8eaa5EA273576d,0x09E5B8821869DD8DDD8d704F4271ec4c8Edc9Abe,0xb298128923edc08f5542E9Db66e2515192c56a4b"},
        ]
    } 
    const filterStatusList = [
        {label:"All Status", value: ""},
        {label:"Review", value: "Review"},
        {label:"Active", value: "Active"},
        {label:"Passed", value: "Passed"},
        {label:"Failed", value: "Failed"},
        {label:"Canceled", value: "Canceled"},
        {label:"Queued", value: "Queued"},
        {label:"Executed", value: "Executed"},
    ]

    const goToDetail = (item) => {
        history.push(`/vote/${item.chainType}/${item.contractAddress}/${item.id}`)
    }
    const goToCreat = () => {
        needSign(() => {
            if (chainId !== 1 && chainId !== 4) {
                message.error("please switch to Ether")
                setupEthMainnet()
                return;
            }
            history.push(`/vote/create`)
        })
    }
    const onEnd = () => {
        setRefresh(refresh+1)
    }

    const filterChange = (e) => {
        setContractAddress(e)
    }
    const filterStatusChange = (e) => {
        setStatus(e)
    }
    const queryDFA = async () => {
        let res = await queryDfaPrice('dfa')
        let price = res.data.DFA.quote.USD.price
        let count = await getERC20Contract('', '0x62959c699a52ec647622c91e79ce73344e4099f5','mainnet').methods.balanceOf('0x047835a98Acf0F6FA018Cc0d2eb01c418C8dc8dD').call()
        count = new BigNumber(count).div(new BigNumber(10).pow(18))
        let totalPrice = new BigNumber(count * price)
        setTotalPrice(totalPrice)
    }
    const goToTreasury = () => {
        history.push('/treasury')
    }

    useEffect( () => {
        queryDFA()
    },[refresh])

    return (
        <div className={`pt30 page_content_h5 `}>
            <div className={`${styles.vote_banner} space-between-center cp`} onClick={goToTreasury}>
                <div className={`pr16 ${styles.vote_filter_pc}`}><Svgbank></Svgbank></div>
                <div className='space-between-center f1 pl4'>
                    <div className={`space-between-center-h5 f1 pr20`}>
                        <div className={`fs16 fw600 w240`}>Build a robust ecosystem for DeFine</div>
                        <div>
                            <div className={`fs12 lh16 tar`}>DAO Treasury balance</div>
                            <div className={`fs22 fw600 lh32`}>${totalPrice?formatAmountWithDecimal(totalPrice,0,2):'--'}</div>
                        </div>
                    </div>
                    <RightOutlined />
                </div>
            </div>
            <div className={`space-between-center-h5 mt30 mb20 ${styles.vote_title}`}>
                <div className={`${styles.vote_title_box}`}>
                    <div className={`page_content_title`}>{t('proposals')}</div>
                    <div className={`${styles.vote_title_tip} df_center`}>
                        <img className={'mr5'} width={15} height={15} src={tip}></img>
                        <a className={`c_blue`} target="_blank" href="https://docs.define.one/define-a/tutorial-and-guideline/user-how-to-create-a-proposal-and-vote-for-against-a-proposal"> About Governance</a>
                    </div>
                </div>
                <div className={`${styles.vote_filter}`}>
                    <Select className={'w120'} defaultValue={""} onChange={filterChange}>
                        {
                            filterList[env.ENV].length>0 && filterList[env.ENV].map((item, index) => {
                                return (<Option key={item.label+index} value={item.value}>{item.label}</Option>)
                            })
                        }
                    </Select>
                    <Select className={'w120 ml10'} defaultValue={""} onChange={filterStatusChange}>
                        {
                            filterStatusList.length>0 && filterStatusList.map((item, index) => {
                                return (<Option key={item.label+index} value={item.value}>{item.label}</Option>)
                            })
                        }
                    </Select>
                </div>
            </div>
            <div className={`vote_list mt30`}>
                {
                        <Row gutter={16}>
                            <Col xs={24} md={12} lg={12}>
                                <div className={`${styles.vote_item_creat} mb20`}>
                                    <img width={40} height={40} src={voteIcon}></img>
                                    <div className={`wp100 fs14 pt10 tac ${styles.creat_tip_mb}`}>{t('vote_creat_tip')}</div>
                                    <Button className={`btn_multicolour w180 h40`} onClick={goToCreat}>{t("creat_a_proposal")}</Button>
                                </div>
                            </Col>
                            {
                                loading?testList.map(v => (
                                    <Col key={v} xs={24} md={12} >
                                        <div className={`${styles.vote_item} mb20 cp br4  "vote_item_bg" : null}`} >
                                        <Skeleton paragraph={{ rows: 2 }} loading={true}>
                                        </Skeleton>
                                        </div>
                                    </Col>
                                )):
                                <>
                                    {
                                        voteList.length > 0 ? voteList.map(item => (
                                            item &&
                                            <Col key={item.contractAddress+item.id} xs={24} md={12} >
                                                <div className={`${styles.vote_item} mb20 cp br4 ${(item.statusObj.s != 'soon' && item.statusObj.s != 'voting') ? "vote_item_bg" : null}`} onClick={() => { goToDetail(item) }}>
                                                    <div className={styles.sip}>{item.sip}</div>
                                                    <div className={`fw600 fs18 mt2 ${styles.vote_item_title} status_${item.statusObj.s}_title`}>{item.title}</div>
                                                    <div className={`fs12 ${styles.vote_item_info}`}>{item.description}</div>
                                                    <div className={`space-between fs14 mt10 ${styles.vote_item_footer}`}>
                                                        <div className={`${styles.item_status} status_${item.statusObj.s}`}>
                                                            <div className={`status_${item.statusObj.s}_txt`}>{item.statusObj.t}</div>
                                                        </div>
                                                        <div className={`df_center`}>
                                                            {
                                                                (item.statusObj.s === "voting" || item.statusObj.s === "soon") && <img width={14} src={clockIcon}></img>
                                                            }
                                                            {
                                                                (item.statusObj.s != "canceled") && <div className={`pl5 status_${item.statusObj.s}_time`}>
                                                                    {/* {item.statusObj.s === "soon" ? "Start at" : "Ended at"} {moment(item.statusObj.s === "soon" ? item.startTime * 1000 : item.endTime * 1000).format("YYYY-MM-DD HH:mm:ss")} */}
                                                                    {
                                                                        item.statusObj.s === "soon" && <div className={styles.count_down}><CountDown onEnd={onEnd} type="vote" time={item.startTime}></CountDown> until voting</div>
                                                                    }
                                                                    {
                                                                        item.statusObj.s === "voting" && <div className={styles.count_down}><CountDown onEnd={onEnd} type="vote" time={item.endTime}></CountDown> left</div>
                                                                    }
                                                                    {
                                                                        item.statusObj.s === "queued" && <div className={styles.count_down}>
                                                                            {moment(item.eta*1000)>Date.now() ?
                                                                            
                                                                                <>Executable after <CountDown onEnd={onEnd} type="vote" time={item.eta}></CountDown></>:
                                                                                <>Executable now</>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        item.statusObj.s === "executed" && <div className={styles.count_down}>Executed {moment(item.executedTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
                                                                    }
                                                                    {
                                                                        item.statusObj.s === "passed" && <div>Passed {moment(item.endTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
                                                                    }
                                                                    {
                                                                        (item.statusObj.s !== "soon" && item.statusObj.s !== "voting" && item.statusObj.s !== "executed" && item.statusObj.s !== "passed" && item.statusObj.s !== "queued") && <div>Failed {moment(item.endTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        
                                        )):
                                        <></>
                                    }
                                </>
                            }
                        </Row>
                }
            </div>

        </div>

    )
}
