import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom'
import { Spin, Button, message, Input, Popover } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Picker from 'emoji-picker-react';
import InfiniteScroll from "react-infinite-scroll-component";

import { useNeedSign  } from 'hooks/account';
import { getContract, useActiveWeb3React } from "../../../../web3";
import { publishComment, getCommentList, like, unlike, delComment } from '../../../../services/nftDetail';
import styles from "../../styles.module.scss"
import CommentItem from '../CommentItem';
import { ReactComponent as EmojiIcon } from 'assets/img/home/emoji.svg'
import safer from "assets/img/safer.png"

const { TextArea } = Input;

export default function CommentModule() {
    const { contractAddress, chainType, tokenId } = useParams()
    const { account, library, chainId } = useActiveWeb3React()
    const [activityText, setActivityText] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sort, setSort] = useState('timeDesc')
    const [sortTime, setSortTime] = useState('desc')
    const [sortHot, setSortHot] = useState('desc')
    const [commentList, setCommentList] = useState([])
    const [total, setTotal] = useState(0)
    const [focused, setFocused] = useState(false)
    const [placeholder, setPlaceholder] = useState('Write your comment')
    const scrollRef = useRef()
    const { needSign } = useNeedSign()


    const onEmojiClick = (event, emojiObject) => {
        event.nativeEvent.stopImmediatePropagation()
        setActivityText(old => old + emojiObject.emoji)
    };
    const handleTextChange = event => {
        setActivityText(event.target.value)
    }

    const publish = async () => {
        if(account&&library){
            let res = await publishComment(chainType, contractAddress, tokenId, activityText, library, account)
            message.success("success")
            let arr = []
            arr = [res]
            setCommentList(oldList => [...arr, ...oldList])
            scrollRef.current.scrollTop = 0
            setActivityText('')
        }else {
            needSign()
        }
    }
    const queryCommentList = async () => {
        let res = await getCommentList(chainType, contractAddress, tokenId, pageNo, pageSize, sort)
        if (pageNo === 1) {
            setCommentList([])
            setCommentList(res.list)
        } else {
            setCommentList(oldList => [...oldList, ...res.list])
        }
        setTotal(res.totalCount)
    }
    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const onDelete = (id) => {
        console.log(id);
        setCommentList(prev => {
            const items = prev.filter(v => v.id != id)
            return items
        })
    }
    const timeSort = () => {
        let reg = /hot/
        if(reg.test(sort)){
            setSort('timeAsc')
            setSortTime('asc')
        }else if(sort === 'timeAsc'){
            setSort('timeDesc')
            setSortTime('desc')
        }else {
            setSort('timeAsc')
            setSortTime('asc')
        }
        setPageNo(1)
    }
    const hotSort = () => {
        let reg = /time/
        if(reg.test(sort)){
            setSort('hotAsc')
            setSortHot('asc')
        }else if(sort === 'hotAsc'){
            setSort('hotDesc')
            setSortHot('desc')
        }else {
            setSort('hotAsc')
            setSortHot('asc')
        }
        setPageNo(1)
    }
    useEffect(() => {
        queryCommentList()
    }, [pageNo, sort])
    return (
        <div className={styles.comment_main}>
            <div className={styles.comment_sort}>
                <div className={styles.sort_item} onClick={timeSort}>Time {sortTime === 'asc' ? <ArrowUpOutlined /> : <ArrowDownOutlined />}  </div>
                <div className={styles.sort_item} onClick={hotSort}>Hot {sortHot === 'asc' ? <ArrowUpOutlined /> : <ArrowDownOutlined />} </div>
            </div>
            <div ref={scrollRef}  className={`f1 pb20 ${styles.comment_list}`}>
                {
                    commentList.length>0?
                    <InfiniteScroll
                        dataLength={commentList.length}
                        next={getList}
                        hasMore={commentList.length < total}
                        loader={
                            <div style={{ width: 216, textAlign: 'center', margin: 'auto' }}>
                                <Spin size={`small`} />
                            </div>
                        }
                    >
                    {
                        commentList.length > 0 && commentList.map((item,index) => (
                            <CommentItem key={item.id+index} item={item} onDelete={onDelete}></CommentItem>
                        ))
                        
                    }
                    </InfiniteScroll>:
                    <div className={`df_column_center hp100`}>
                        <img width={100} src={safer}></img>
                        <p>Be the first to comment!</p>
                    </div>
                }
            </div>
            <div className={`space-between-center ${styles.comment_publish}`}>
                <Popover overlayClassName={styles.pop_emoji} trigger="click" content={<Picker onEmojiClick={onEmojiClick} />}>
                    <EmojiIcon className="cp" />
                </Popover>
                <TextArea
                    autoSize={false}
                    bordered={false}
                    showCount
                    placeholder={placeholder}
                    maxLength="280"
                    value={activityText}
                    onChange={e => { handleTextChange(e) }}
                    className={`df_center f1 pl10 pr10 ${styles.comment_textarea} `}
                    onFocus={() => { setPlaceholder('') }}
                    onBlur={() => { setPlaceholder('Write your comment') }}
                />
                <Button className={styles.comment_publish_btn} onClick={publish} type={'primary'}>Publish</Button>
            </div>
        </div>
    )
}
