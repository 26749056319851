import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col,Spin } from 'antd';

import { useList } from "../../services/sypool";
import { ReactComponent as SvgTw } from "../../assets/img/sidebar/twitter.svg"
import { ReactComponent as SvgMd } from "../../assets/img/sidebar/medium.svg"
import styles from "./styles.module.scss";
import BackButton from "components/BackButton";
import NftPlusCard from "components/NftPlusCard";
import RadioFilter from "components/RadioFilter";
import BlackTable from "components/BlackTable";

export default function Sypool() {
    const history = useHistory();
    const { t } = useTranslation()
    const [filterType, setFilterType] = useState(0)
    const [loading, setLoading] = useState(false)
    const list = useList('sypool',setLoading)
    const tableColumns = [
        { title: list.length > 0 && list[filterType].displayName, align: "center", key: "name", dataIndex: "name", width: 200, },
        { title: "N", align: "center", key: "n", dataIndex: "n", width: 110, },
        { title: "R", align: "center", key: "r", dataIndex: "r", width: 160, },
        { title: "SSR", align: "center", key: "ssr", dataIndex: "ssr", width: 160, },
    ]
    const tableList = [
        { name: t('sypool_total'), n: 66, r: 15, ssr: 1 },
        { name: t('sypool_per_nft'), n: '2,500 SYP', r: '5,000 SYP', ssr: '10,000 SYP' },
        { name: t('sypool_per_syp'), n: '$0.04', r: '$0.04', ssr: '$0.04' }
    ]
    const onChange = (index) => {
        setFilterType(index)
    }
    const goToItem = (item) => {
        console.log(item);
        history.push(`/collection/${item.name}`)
    }

    return (
        <div className={`page_content_h5`}>

            <div>
                <BackButton></BackButton>
            </div>
            <h1 className={'page_content_title mt20'}>SYPool</h1>
            {
                loading?<Spin className={`wp100 tac mt20`} size="large"></Spin>:
                <div className={styles.card_list}>
                    <Row gutter={20}>
                        {
                            list.map((item, index) => {
                                return (
                                    <Col xs={12} md={8} lg={6}>
                                        <NftPlusCard onClick={goToItem} key={index} item={item}></NftPlusCard>
                                    </Col>
                                )
                            })
                        }
                    </Row>
            </div>
            }
            <div>
                <h1 className={`page_content_title mt50`}>{t('sypool_about_title')}</h1>
                {list.length > 0 && <RadioFilter onChange={onChange} list={list}></RadioFilter>}
                <BlackTable tableColumns={tableColumns} tableList={tableList}></BlackTable>
                <div className={`${styles.des}`}>
                    <div className={`mt20 mb20`}>
                        <h3>{t('sypool_rule')}</h3>
                        <ul>
                            <li className={'fs14'}>{t('sypool_rule_1')}</li>
                            <li className={'fs14'}>{t('sypool_rule_4')}</li>
                            <li className={'fs14'}>{t('sypool_rule_2')} </li>
                            <li className={'fs14'}>{t('sypool_rule_3')}</li>
                        </ul>
                    </div>
                    <div className={`p20 ${styles.des_content}`}>
                        <h3>{t('sypool_des')}</h3>
                        <div >
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('sypool_des_content1') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('sypool_des_content2') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('sypool_des_content3') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'}>{t('more_info')}<a target="_blank" href="https://sypool-protocol.medium.com/sypool-cooperates-with-define-to-launch-nft-plus-65a9809ec45f">https://sypool-protocol.medium.com/sypool-cooperates-with-define-to-launch-nft-plus-65a9809ec45f</a></p>
                        </div>
                        <div>
                            <SvgTw width={18} height={18} className={"dib mr20 cp"} onClick={() => {
                                window.open('https://twitter.com/SYP_Protocol', '_blank')
                            }} />
                            <SvgMd width={18} height={18} className={"dib mr20 cp"} onClick={() => {
                                window.open('https://sypool-protocol.medium.com', '_blank')
                            }} />
                        </div>
                        <h3>{t('disclaimer')}</h3>
                        <p>{t('disclaimer_content')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
