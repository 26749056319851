import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { Menu, Dropdown, message, Card, Button, Modal, Form, Input } from 'antd';
import Zmage from 'react-zmage'
import copy from 'copy-to-clipboard';

import { getContract, useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { useNeedSign } from "hooks/account"
import Avatar from "components/Avatar";
import { ReactComponent as DotIcon } from "assets/img/activity/dot.svg";
import { ReactComponent as CommentIcon } from "assets/img/activity/comment.svg";
import { ReactComponent as LikeIcon } from "assets/img/activity/like.svg";
import { ReactComponent as LikedIcon } from "assets/img/activity/liked.svg";
import { ReactComponent as ShareIcon } from "assets/img/activity/share.svg";
import { ReactComponent as DelIcon } from 'assets/img/activity/delete.svg';
import { deleteComment, unlikeComment, likeComment } from "services/home"
import { HANDLE_SHOW_COMMENT_MODAL1, HANDLE_SHOW_COMMENT_MODAL2 } from "../../const";
import { mainContext } from "../../reducer";
import { formatCreateTime } from "utils/format"
import { isSelf } from "utils/index";
import styles from "./styles.module.scss";
import tag from 'assets/img/artist/tag.png';

const CommentCard = ({ data, cardType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { library, account } = useActiveWeb3React()
  const { tronLibrary, tronAccount } = useActiveTronWeb()
  const [isDelete, setIsDelete] = useState(false)
  const [likeObj, setLikeObj] = useState({ status: data.likedByCurrentUser, num: data.likeCount })
  const { needSign } = useNeedSign();
  const { dispatch, state } = useContext(mainContext);

  const handleDelete = () => {
    deleteComment(data.activityId, data.id).then(res => {
      if (res === 'success') {
        setIsDelete(true)
      }
    })
  }

  const handleLike = () => {
    needSign(() => {
      if (!likeObj.status) {
        likeComment(library || tronLibrary, account || tronAccount, data.activityId, data.id)
          .then(res => {
            if (res === 'success') {
              setLikeObj(old => ({
                status: true,
                num: old.num + 1
              }))
            }
          })
      } else {
        unlikeComment(library || tronLibrary, account || tronAccount, data.activityId, data.id)
          .then(res => {
            if (res === 'success') {
              setLikeObj(old => ({
                status: false,
                num: old.num - 1
              }))
            }
          })
      }
    })
  }

  const goComment = () => {
    needSign(() => {
      if (cardType === 'second') {
        dispatch({
          type: HANDLE_SHOW_COMMENT_MODAL2,
          showCommentModal2: {
            show: true,
            activityId: data.activityId,
            replyTo: data.id,
            replyUser: data.creatorName
          }
        });
      } else {
        dispatch({
          type: HANDLE_SHOW_COMMENT_MODAL1,
          showCommentModal1: {
            show: true,
            activityId: data.activityId,
            replyTo: data.id,
            replyUser: data.creatorName
          }
        });
      }
    })
  };


  if (isDelete) {
    return "";
  }

  return (
    <div className={`${styles.card} ${styles.add_border}`}>
      <div className="space-between-center">

        <div className="df_align_center">
          <Avatar isOwnedNft={data.creatorInfo && data.creatorInfo.avatarFromOwnedNft} address={data.creator} url={data.creatorAvatar} size={40} />
          <div className="ml10">
            <div className="fs16 fwb df_align_center">
              <Link to={`/profile/${data.creator}`}><span>{data.creatorName}</span> 
              {
                data.creatorInfo && data.creatorInfo.artist && <img className="mt-2" src={tag} width={16}></img>
              }
              </Link>
            </div>
            <div className="fs14 c_9a">{formatCreateTime(data.createTime, t)}</div>
          </div>
        </div>

        {
          isSelf(data.creator, account, tronAccount) &&
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 4 }}>
                <Menu.Item className={styles.card_more_box} key="2" onClick={handleDelete}>
                  <DelIcon style={{marginRight: 5}} />{t('delete')}
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <DotIcon className="cp" />
          </Dropdown>
        }
      </div>
      <div className={styles.content_box}>
        {
          data.quotedContent && (<>
            <div className={styles.quoted_box}>{data.quotedContent}</div>
            <div className={styles.quoted_hr}></div>
          </>)
        }
        <div className="content_text">{data.content}</div>
        {
          data.image && <div className={styles.imgBox}>
            {
              data.tokenId ? <>
                <div className={styles.nftTag}>NFT</div>
                <Link to={`/marketplace/${data.contractAddress || data.tokenAddress}/${data.chainType}/${data.tokenId}`}>
                  <img className={styles.cardImage} src={data.preview} alt="" />
                </Link>
              </> : <>
                {(data.image.includes('.gif') || data.image.includes('.png') || data.image.includes('.jpg') || data.image.includes('.jpeg')) &&
                  <Zmage
                    className={styles.cardImage}
                    src={data.preview}
                    set={[{ src: data.image }]}
                  />
                }
                {(data.image.includes('.mp4') || data.image.includes('.mov')) &&
                  <video className={styles.cardImage} controls poster={data.preview}><source src={data.image} />Sorry, your browser doesn't support embedded videos.</video>
                }
              </>
            }
          </div>
        }
      </div>

      <div className={styles.bottom_box}>
        <div style={{ visibility: "hidden" }} className={styles.cell_module}></div>
        <div style={{ visibility: "hidden" }} className={styles.cell_module}></div>
        <div style={{ visibility: "hidden" }} className={styles.cell_module}></div>
        <div className={styles.cell_module} onClick={goComment}>
          <CommentIcon className={styles.icon} />
          <span className={data.commentCount ? 'ml5' : 'hide'}>{data.commentCount}</span>
        </div>
        <div className={styles.cell_module} onClick={handleLike}>
          {likeObj.status ? <LikedIcon className={styles.icon} /> : <LikeIcon className={styles.icon} />}
          <span className={likeObj.num ? 'ml5' : 'hide'}>{likeObj.num}</span>
        </div>
      </div>
    </div>
  )
}

export default CommentCard;