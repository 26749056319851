import React, { useRef } from 'react';
import styles from './styles.module.scss'
import defaultImg from "assets/img/defaultImg.jpg";

export default function BadgeItem({ image }) {
    const bg = useRef()

    return (
        <div className={styles.badgeBox}>
            <img className={styles.badgeSrc}  src={image}></img>
        </div>
    )
}