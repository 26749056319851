import React, { useState, useRef } from 'react';
import { Spin, Input } from 'antd';
import InfiniteScroll from "react-infinite-scroll-component";

import { useActiveWeb3React } from "../../web3";
import { useList } from '../../services/artist';
import NoData from "components/NoData";
import Avatar from "components/Avatar";
import FollowButton from "components/FollowButton";
import { ReactComponent as SearchIcon } from "assets/img/search.svg";
import styles from './styles.module.scss';

import creatBg from '../../assets/img/artist/creater.jpg'
import pen from '../../assets/img/artist/pen.png'
import tag from '../../assets/img/artist/tag.png'
import { style } from 'dom-helpers';
// import { ReactComponent as Tag } from "../../assets/img/artist/tag.svg";

export default function Artist() {
    const { library, account } = useActiveWeb3React()
    const [pageNo, setPageNo] = useState(1)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const { list, total } = useList(pageNo, 22, search, setLoading)
    const timer = useRef()

    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const tobeCreator = () => {
        if (window.localStorage.getItem('define_lang') === 'zh') {
            window.open('https://jinshuju.net/f/X4atof')
        } else {
            window.open('https://docs.google.com/forms/d/e/1FAIpQLSf0JUhMtHhn72nh390EyZN4pyi2Dl3xp7ZiCI7gnmJSfWM01w/viewform')
        }
    }
    const handleSearch = (e) => {
        // console.log(e);
        let val = e.target.value
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setPageNo(1)
            setSearch(val)
        }, 600);
    }
    return (
        <div className={styles.page_content_artist}>
            <div className={`space-between-center ${styles.artist_header}`}>
                <div className={`page_content_title `}>Artist</div>
                <div className={`mr20`}>
                    <Input onChange={handleSearch} style={{ width: 280, height: 40 }} placeholder="search name" prefix={<SearchIcon style={{ fontSize: 16, marginRight: 6 }} />} />
                </div>
            </div>
            {
                loading ? <Spin className={`wp100 mt40`} size="large" /> :

                    <InfiniteScroll
                        dataLength={list.length}
                        next={getList}
                        hasMore={list.length < total}
                        loader={
                            <div style={{ width: 216, textAlign: 'center', margin: 'auto' }}>
                                <Spin size={`large`} />
                            </div>
                        }
                    >
                        <div className={styles.content}>
                            {
                                !search && <div style={{ backgroundImage: `url(${creatBg})` }} onClick={tobeCreator} className={`${styles.creater} cp`}>
                                    <div className={`fs26 c_f fw700 w200 lh32`}>Apply to become a</div>
                                    <div className={`df_align_center`}>
                                        <span className={`fs60 c_f fw700 mr16`}>Creater</span>
                                        <img width={37} height={42} src={pen}></img>
                                    </div>
                                </div>
                            }

                            {
                                list.length > 0 && list.map(item => (
                                    <div key={item.address} className={styles.artist_item}>
                                        <div className={styles.item_avatar}>
                                            <Avatar url={item.avatar} isOwnedNft={item.avatarFromOwnedNft} address={item.address} bottomP={0} leftP={10}></Avatar>
                                        </div>
                                        <div className={`df_center`}>
                                            <div className={`fw600 tac text_hidden_1 mt2`}>{item.name}</div>
                                            {
                                                item.artist && <img src={tag} width={16}></img>
                                            }
                                        </div>
                                        <div className={`space-between-center mt10 mb10`}>
                                            <div>
                                                <div className={`fs12`}>Following</div>
                                                <div className={`fw700 tac`}>{item.followingCount ? item.followingCount : 0}</div>
                                            </div>
                                            <div className={styles.line}></div>
                                            <div className={styles.follow}>
                                                <div className={`fs12`}>Follower</div>
                                                <div className={`fw700 tac`}>{item.followerCount ? item.followerCount : 0}</div>
                                            </div>
                                        </div>
                                        <div className={`tac`}>
                                            <FollowButton followed={item.followedByCurrentUser} userId={item.address} />
                                            {/* <Button type={'primary'} className={`w100`}>+ Follow</Button> */}
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                list.length === 0 && <div className={`wp100 df_center`}><NoData></NoData></div>
                            }
                        </div>
                    </InfiniteScroll>
            }

        </div>
    )
}
