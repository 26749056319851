import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Row, Col } from 'antd';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import "swiper/swiper-bundle.min.css";
// import 'swiper/css/autoplay';

import styles from "./styles.module.scss";
import bg from "assets/img/kbw/kbw_banner.png"
import x from "assets/img/kbw/x.png"
import how from "assets/img/kbw/how.png"
import ball1 from "assets/img/kbw/ball_1.png"
// import ball1 from "assets/img/kbw/ball_1.svg"
import ball2 from "assets/img/kbw/ball_2.png"
import line1 from "assets/img/kbw/line.png"
import line2 from "assets/img/kbw/line_2.png"
import badge1 from "assets/img/kbw/badge.svg"

import bobaNetwork from "assets/img/kbw/bobaNetwork.png"
import coinLive from "assets/img/kbw/coinLive.png"
import cyberGalz from "assets/img/kbw/cyberGalz.png"
import deSpread from "assets/img/kbw/deSpread.png"
// import dvision from "assets/img/kbw/dvision.png"
import dwgGuild from "assets/img/kbw/dwgGuild.png"
import fireAntCrypto from "assets/img/kbw/fireAntCrypto.png"
import hgVentures from "assets/img/kbw/hgVentures.png"
import hyperWallet from "assets/img/kbw/hyperWallet.png"
import impossibleFinance from "assets/img/kbw/impossibleFinance.png"
import imToken from "assets/img/kbw/imToken.png"
import monergrid from "assets/img/kbw/monergrid.png"
import mtdz from "assets/img/kbw/mtdz.png"
import raiFinance from "assets/img/kbw/raiFinance.png"
import stpNetwork from "assets/img/kbw/stpNetwork.png"
import sypool from "assets/img/kbw/sypool.png"
import trinityBlockCapital from "assets/img/kbw/trinityBlockCapital.png"
import projectWith from "assets/img/kbw/with.png"
import meta2150s from "assets/img/kbw/meta2150s.png"
import oxgen from "assets/img/kbw/oxgen.png"

import pbobaNetwork from "assets/img/kbw/boba_p.png"
import pcoinLive from "assets/img/kbw/Coinlive_p.png"
import pcyberGalz from "assets/img/kbw/Cybergalz_p.png"
import pdeSpread from "assets/img/kbw/despreat_p.png"
// import pdvision from "assets/img/kbw/DvisionNetwork_p.png"
import pdwgGuild from "assets/img/kbw/dwg_p.png"
import pfireAntCrypto from "assets/img/kbw/fireAntCrypto_p.png"
import phgVentures from "assets/img/kbw/HG Ventures_p.png"
import phyperWallet from "assets/img/kbw/hyper_p.png"
import pimpossibleFinance from "assets/img/kbw/ImpossibleFinance_p.png"
import pimToken from "assets/img/kbw/imtoken_p.png"
import pmonergrid from "assets/img/kbw/moneygrid_p.png"
import pmtdz from "assets/img/kbw/mtdz_p.png"
import praiFinance from "assets/img/kbw/rai_p.png"
import pstpNetwork from "assets/img/kbw/STP_p.png"
import psypool from "assets/img/kbw/sypool_p.png"
import ptrinityBlockCapital from "assets/img/kbw/TrinityBlockcapital_p.png"
import pprojectWith from "assets/img/kbw/with_p.png"
import pmeta2150s from "assets/img/kbw/meta2150s_p.png"
import poxgen from "assets/img/kbw/oxgen_p.png"

SwiperCore.use([Autoplay])
export default function Anniversary() {

    const history = useHistory();
    const organizerList = [
        { name: 'bobaNetwork', src: bobaNetwork, type: 'KBW2022-BOBANETWORK' },
        { name: 'coinLive', src: coinLive, type: 'KBW2022-COINLIVE' },
        { name: 'cyberGalz', src: cyberGalz, type: 'KBW2022-CYBERGALZ' },
        { name: 'deSpread', src: deSpread, type: 'KBW2022-DESPREAD' },
        // { name: 'Dvision', src: dvision, type: 'dvision' },
        { name: 'dwgGuild', src: dwgGuild, type: 'KBW2022-DWGGUILD' },
        { name: 'Fire Ant Crypto', src: fireAntCrypto, type: 'KBW2022-FIREANTCRYPTO' },
        { name: 'HG Ventures',src: hgVentures, type: 'KBW2022-HGVENTURES' },
        // { name: 'hyperWallet', src: hyperWallet, type: 'KBW2022-HYPERWALLET' },
        { name: 'impossibleFinance', src: impossibleFinance, type: 'KBW2022-IMPOSSIBLEFINANCE', },
        { name: 'imToken', src: imToken, type: 'KBW2022-IMTOKEN' },
        { name: 'monergrid', src: monergrid, type: 'KBW2022-MONEYGRID' },
        { name: 'MTDZ', src: mtdz, type: 'KBW2022-MTDZ' },
        { name: 'raiFinance', src: raiFinance, type: 'KBW2022-RAIFINANCE' },
        { name: 'stpNetwork', src: stpNetwork, type: 'KBW2022-STPNETWORK' },
        { name: 'Sypool', src: sypool, type: 'KBW2022-SYPOOL' },
        { name: 'trinityBlockCapital', src: trinityBlockCapital, type: 'KBW2022-TRINITYBLOCKCAPITAL' },
        { name: 'Project With', src: projectWith, type: 'KBW2022-PROJECTWITH' },
        { name: 'Meta2150s', src: meta2150s, type: 'KBW2022-META2150S' },
        { name: '0xGen', src: oxgen, type: 'KBW2022-0XGEN' },
    ]
    const partnerList = [
        { name: 'Boba Network', type: 'KBW2022-BOBANETWORK', simg: pbobaNetwork },
        { name: 'Coin Live', type: 'KBW2022-COINLIVE', simg: pcoinLive },
        { name: 'CyberGalz', type: 'KBW2022-CYBERGALZ', simg: pcyberGalz },
        { name: 'DeSpread', type: 'KBW2022-DESPREAD', simg: pdeSpread },
        // { name: 'Dvision', type: 'dvision', simg: pdvision },
        { name: 'DWG Guild', type: 'KBW2022-DWGGUILD', simg: pdwgGuild },
        { name: 'Fire ANt Crypto', type: 'KBW2022-FIREANTCRYPTO', simg: pfireAntCrypto },
        { name: 'HG Ventures', type: 'KBW2022-HGVENTURES', simg: phgVentures },
        // { name: 'Hyper Wallet', type: 'KBW2022-HYPERWALLET', simg: phyperWallet },
        { name: 'Impossible Finance', type: 'KBW2022-IMPOSSIBLEFINANCE', simg: pimpossibleFinance },
        { name: 'imToken', type: 'KBW2022-IMTOKEN', simg: pimToken },
        { name: 'Moneygrid', type: 'KBW2022-MONEYGRID', simg: pmonergrid },
        { name: 'MTDZ', type: 'KBW2022-MTDZ', simg: pmtdz },
        { name: 'RAI Finance', type: 'KBW2022-RAIFINANCE', simg: praiFinance },
        { name: 'STP Network', type: 'KBW2022-STPNETWORK', simg: pstpNetwork },
        { name: 'Sypool', type: 'KBW2022-SYPOOL', simg: psypool },
        { name: 'Trinity Block capital', type: 'KBW2022-TRINITYBLOCKCAPITAL', simg: ptrinityBlockCapital },
        { name: 'With', type: 'KBW2022-PROJECTWITH', simg: pprojectWith },
        { name: 'Meta2150s', type: 'KBW2022-META2150S', simg: pmeta2150s },
        { name: '0xGen', type: 'KBW2022-0XGEN', simg: poxgen },
       
    ]
    const [sliderViews, setSliderViews] = useState(5)

    const initData = () => {
        if (document.body.clientWidth < 800) {
            setSliderViews(2)
        } else if (document.body.clientWidth > 800 && document.body.clientWidth < 1145) {
            setSliderViews(3)
        } else if (document.body.clientWidth > 1144 && document.body.clientWidth < 1431) {
            setSliderViews(4)
        } else if (document.body.clientWidth > 1430 && document.body.clientWidth < 1717) {
            setSliderViews(5)
        } else if (document.body.clientWidth > 1716 && document.body.clientWidth < 1921) {
            setSliderViews(6)
        }
    }
    const gotoClaim = (type) => {
        if (type) {
            history.push(`/badge/korea-blockchain-week-2022/${type}`)
        }
    }

    useEffect(() => {
        initData()
    })
    return (
        <div className={styles.kbw}>
            <div className={`${styles.page_head_img}`} style={{ backgroundImage: `url(${bg})` }}>
                <div className={styles.head_title}>
                    <div className={styles.title}><div className={styles.title_img}><div className={`${styles.title_1}`}>DeFine</div> <img className={`mt-2 ${styles.x}`} src={x}></img></div> <div className={styles.title_2}> KBW2022 Carnival</div></div>
                    <div className={styles.subtitle}>To celebrate Korea Blockchain Week, DeFine launches DeFine x KBW2022 Carnival with partners! </div>
                </div>
                <div>
                    <img className={styles.kbw_badge} src={badge1}></img>
                </div>
            </div>
            <div className={styles.organizer}>
                <div className={`c_f fw700 tac ${styles.organizer_title}`}>Partner Badges</div>
                <Swiper className={`df mt20 `}
                    autoplay={{
                        delay: 0,
                        stopOnLastSlide: false,
                        disableOnInteraction: true,
                    }}
                    speed={6000}
                    loop={true}
                    spaceBetween={0}
                    slidesPerView={sliderViews}
                    onSwiper={(swiper) => {
                        swiper.$el[0].addEventListener('mouseover', () => swiper.autoplay.stop());
                        swiper.$el[0].addEventListener('mouseleave', () => swiper.autoplay.start());
                    }}
                >
                    {
                        organizerList.map(item => (
                            <SwiperSlide><img onClick={() => { gotoClaim(item.type) }} className={`cp ${styles.slide_img}`} src={item.src}></img></SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div className={styles.kbw_body}>
                <div className={styles.how}>
                    <div>How to get</div>
                    <div>Define x KBW2022 Badge??</div>
                </div>
                <div className={styles.intro}>
                    <div className={styles.intro_main}>DeFine will run a joint campaign with partners. Users who complete the tasks of each partner will be eligible to claim a partner badge. When the user collects at least 3 partner badges, the user will receive an airdrop of DeFine x KBW2022 Badge through the polygon network. DeFine x KBW2022 badge holders will be eligible to a share 10,000 DFA reward pool.</div>
                    {/* <div className={styles.more_btn}>
                        <Button className={`btn_border`}>
                            <a target='_blank' href='https://gleam.io/ttXhZ/define-join-our-treasure-hunt-and-win-dfa-tokens?__cf_chl_tk=vd.rMRs2I7XPvfUDTveivUMNmF2ean9fw2BtPmXyeVI-1659595003-0-gaNycGzNB70'>More details &gt;</a></Button>
                    </div> */}
                </div>
                <img className={styles.how_img} src={how}></img>
                <div className={styles.more_btn}>
                        <Button className={`btn_border`}>
                            <a target='_blank' href='https://app.define.one/badge/korea-blockchain-week-2022'>More details &gt;</a></Button>
                    </div>
                <div className={`pr ${styles.fd1}`}>
                    <img width={328} className={styles.ball1} src={ball1}></img>
                    <img width={462} className={styles.line1} src={line1}></img>
                </div>
            </div>
            <div className={`pr`}>
                <div className={styles.kbw_partner}>
                    <div className={styles.partner_title}> KBW 2022 Partners</div>
                    <Row className={`mt40 ${styles.partnerList}`}>
                        {
                            partnerList.map(item => (
                                <Col xs={12} lg={6}>
                                    <div className={styles.partner_box} onClick={() => { gotoClaim(item.type) }} >
                                        <img className={styles.partner_img} src={item.simg} width={100}></img>
                                        <div className={`mt10 fw700`}>{item.name}</div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
                <div className={`pr ${styles.fd2}`}>
                    <img className={styles.line2} src={line2}></img>
                    <img className={styles.ball2} src={ball2}></img>
                </div>
            </div>
        </div>
    )
}
