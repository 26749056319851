import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Row, Col,Spin } from 'antd';


import { useList } from "../../services/mysteryBox";
import BackButton from "components/BackButton";
import NftPlusCard from "components/NftPlusCard";
import RadioFilter from "components/RadioFilter";
import BlackTable from "components/BlackTable";
import styles from "./styles.module.scss";
import traitsInfo from "../../assets/img/mysteryBox/traits_info.jpg"

export default function MysteryBox() {
    const history = useHistory();
    const { t } = useTranslation()
    const [filterType, setFilterType] = useState(0)
    const [loading, setLoading] = useState(false);
    const list = useList(setLoading)
    const tableColumns = [
        { title: list.length > 0 && list[filterType].displayName, align: "center", key: "name", dataIndex: "name", width: 200, },
        { title: 'N', align: "center", key: "n", dataIndex: "n", width: 100, },
        { title: "SR", align: "center", key: "sr", dataIndex: "sr", width: 100, },
        { title: "SSR", align: "center", key: "ssr", dataIndex: "ssr", width: 100, },
        { title: "legendary", align: "center", key: "legendary", dataIndex: "legendary", width: 125, },
    ]
    const tableList = [
        { name: "Total NFTs", n: 988, sr: 111, ssr: 11, legendary: 1 },
    ]
    const onChange = (index) => {
        setFilterType(index)
    }
    const goToItem = (item) => {
        console.log(item);
        history.push(`/collection/${item.type}`)
    }
    const goShardSplice = () => {
        history.push("/shardsplice")
    }
    return (
        <div className={`page_content_h5`}>
            <div>
                <BackButton></BackButton>
            </div>
            <div className={`mb10`}>
                <div className={'page_content_title mt20 mb20'}>MysteryBox</div>
            </div>
            {
                loading?<Spin className={`wp100 tac mt20`} size="large"></Spin>:
                <Row gutter={20}>
                    {
                        list.length > 0 && list.map((item, index) => {
                            return (
                                <Col xs={12} md={6} lg={6}>
                                    <NftPlusCard onClick={goToItem} key={index} item={item}></NftPlusCard>
                                </Col>
                            )
                        })
                    }
                </Row>
            }
            <div>
                <h1 className={`page_content_title mt30`}>{t('sypool_about_title')}</h1>
                {list.length > 1 && <RadioFilter onChange={onChange} list={list}></RadioFilter>}
                <BlackTable tableColumns={tableColumns} tableList={tableList}></BlackTable>
                <div className={`${styles.des}`}>
                    <div className={`p20 mt20 ${styles.rule}`}>
                        <h3>{t('sypool_rule')}</h3>
                        <ul>
                            <li className={'fs14'}>{t('mysteryBox_rule1')}</li>
                            <li className={'fs14'}>{t('mysteryBox_rule2')} </li>
                        </ul>
                    </div>
                    <div className={`p20 ${styles.des_content}`}>
                        <h3>{t('sypool_des')}</h3>
                        <div >
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_1') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_2') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <img className={`${styles.des_img} mb10`} src={traitsInfo}></img>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_3') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_4') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_5') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_6') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_7') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_8') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_9') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_10') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('mysteryBox_des_11') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                            <p className={'fs14'}>Discord - <a className={`c_blue`} target="_blank" href="https://discord.gg/PVc5RRYaYt">https://discord.gg/PVc5RRYaYt</a></p>
                            <p className={'fs14'}>Twitter - <a className={`c_blue`} target="_blank" href="https://twitter.com/STREETH_NFT">https://twitter.com/STREETH_NFT</a></p>
                            <p className={'fs14'}>Founder & main artist - <a className={`c_blue`} target="_blank" href="https://twitter.com/MostNft">https://twitter.com/MostNft</a></p>
                        </div>

                        <h3>{t('disclaimer')}</h3>
                        <p>{t('disclaimer_content')}</p>
                    </div>
                </div>
            </div>
        </div>
    )

}
