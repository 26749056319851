import React, { useEffect, useState, useRef } from 'react'
import { Button, Input, message, Form } from 'antd'
import { useParams, useHistory } from 'react-router-dom';

import { getBadgeInfo, badgeApply } from "../../services/badgeDetail";
import { formatAmountWithDecimal } from "../../utils/format";
import bg from 'assets/img/token2049/bg.jpg'
import bgh5 from 'assets/img/token2049/bg-h5.jpg'
import aboutIcon from 'assets/img/token2049/about_icon.svg';
import aboutDefine from 'assets/img/token2049/about_define.jpg';
import gift from 'assets/img/token2049/reward.png'
import styles from './styles.module.scss'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment';


export default function SandboxEmail() {
    const inputValue = useRef()
    const [info, setInfo] = useState({})
    const [value, setValue] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [flag, setFlag] = useState(true)
    const [flagEmail, setFlagEmail] = useState(true)
    const [visible, setVisible] = useState(false)
    const [disabledValue, setDisabledValue] = useState(false)
    const [checked, setChecked] = useState(false)
    const { badgeType } = useParams()
    const history = useHistory()
    const [form] = Form.useForm();

    const goToBadgeEntry = () => {
        history.push('/badgeEntry')
    }
    const handleInputEmail = (e) => {
        let email = e.target.value
        setEmailAddress(e.target.value)
    }

    const collectAddress = async () => {
      
        if (emailAddress) {
            let reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
            if (!reg.test(emailAddress)) {
                return message.error("Invalid email address")
            }
        } else {
            return message.error("Please enter the correct email address")
        }
        let res = await badgeApply(badgeType, value, emailAddress)
        if (res === 'success') {
            message.success({
                content: <div class='p10'><div style={{ color: '#229C1F', fontSize: '18px' }}>이벤트 참여에 성공하였습니다.</div><div style={{ fontSize: '14px' }}>행사일 이후에 이메일을 확인하세요.</div></div>,
                className: 'custom-class',
                duration: 3,
                style: {
                    marginTop: '45vh',
                },
            });
        }
    }
    const filterChainType = (chainType) => {
        if (chainType === 'bsctestnet' || chainType === 'bsc') {
            return "BSC"
        }
        if (chainType === 'rinkeby' || chainType === 'mainnet') {
            return "Ethereum"
        }
        if (chainType === 'mumbai' || chainType === 'polygon') {
            return "Polygon"
        }
        if (chainType === 'shasta' || chainType === 'tron') {
            return "Tron"
        }
    }
   
    useEffect(() => {
        getBadgeInfo(badgeType).then(res => {
            setInfo(res)
        })
    }, [])
    return (
        <>
            <div className={styles.token2049} style={{ backgroundImage: `url(${bg})` }}>
                <div className={styles.token_main}>
                    <img width={216} src={info.image}></img>
                    <div className={`c_fff fs24 fw700`}>{info.name}</div>
                    <div className='df_center mt10'>
                        <div className={styles.line_right}>
                            <div className={`c_fff fs24 fw700`}>{info.grantedCount}</div>
                            <div className={styles.text1}>Claimed</div>
                        </div>
                        <div className='pl14'>
                            <div className={`c_fff fs24 fw700 df_center`}>
                                <span className='pl6'>{formatAmountWithDecimal(info.maxCount - info.grantedCount, 0, 0) < 0 ? 0 : formatAmountWithDecimal(info.maxCount - info.grantedCount, 0, 0)}</span>
                            </div>
                            <div className={styles.text1}>Badge left</div>
                        </div>
                    </div>
                    <div className='mt10 c_fff lh26'>SBXG와 DeFine이 함께하는 DeFine Cup 이벤트를 응원해 주세요! 이메일 주소만 남겨주시면 NFT 뱃지와 함께 추첨을 통해 총 500만원 상당의 경품을 나누어 드립니다.</div>
                    <div className={styles.text2}>Event period: {moment(info.claimStartTime).format("MM/DD")} - {moment(info.claimEndTime).format("MM/DD")} </div>

                    <div className='c_fff mt10'>
                        <img width={320} src={gift}></img>
                        <div>1등 플레이스테이션5 디스크에디션 1명</div>
                        <div>2등 닌텐도 스위치 2명</div>
                        <div>3등 게이밍 마우스 5명</div>
                        <div>4등 문화상품권 5만원 20명</div>
                        <div>5등 문화상품권 1만원 200명</div>
                        <div className='fs12'>(제세공과금 22% 당첨자 부담)</div>
                    </div>
                    {/* <div className={'mt20 c_fff fw700 tal'}>폴리곤 네트워크를 지원하는 크립토 지갑(메타마스크 등)의 주소를 입력해 주세요.</div> */}

                    <div className={'mt20 c_fff fw700 tal'}>이메일 주소를 입력 해 주세요.</div>
                    <TextArea onChange={handleInputEmail} vlaue={emailAddress} className='mt10' placeholder='email' rows={2}></TextArea>
                    <div className={'mt10 c_fff fw700 tal'}>위 메일로 디파인컵 뱃지 NFT가 일회용 지갑에 담겨 전달 예정이며, 경품 당첨 시에도 메일로 연락을 드립니다.
                    <br/>반드시 늘 사용하는 메일 주소를 남겨주세요. </div>
                    <div className='mt30'>
                        <Button onClick={collectAddress} disabled={formatAmountWithDecimal(info.maxCount - info.grantedCount, 0, 0) < 1 || info.claimStartTime > Date.now()} className={`wp100 h50 fs16 fw600 ${styles.claimBtn_border}`} type="primary">신청하기</Button>
                    </div>
                    <div className='mt16'>
                        <Button onClick={goToBadgeEntry} className={styles.more_btn}>디파인 뱃지 더 살펴보기</Button>
                    </div>
                    <div className='mt16 mb30'>
                        <a href={aboutDefine} className={styles.about_btn}><img className="pr10" src={aboutIcon} alt="" />디파인 알아보기</a>
                    </div>
                </div>
            </div>
        </>
    )
}
