import React, { useState } from "react";
import { Spin, Switch, Button, Select, Radio } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getChainType } from "../../web3/address";
import { useActiveWeb3React } from "../../web3";
import NftCard from 'components/NftCard';
import { useList } from "../../services/aoz";
import styles from "./styles.module.scss";
import filter from "../../assets/img/Vector.svg";
import quantumBg from "../../assets/img/events/quantum_bg.jpg"
// import empty from "../../assets/img/empty.svg"
import NoData from "components/NoData";


let timer;
const { Option } = Select;

const Quantum = () => {
    const [loading, setLoading] = useState(false);
    const { library, chainId, account } = useActiveWeb3React()
    const chainType = getChainType(chainId)
    const [current, setCurrent] = useState(1);
    const [isOnSale, setIsOnSale] = useState('')
    const { t } = useTranslation()
    const [collection, setCollection] = useState('QuantumForce')
    // const { list, total } = useList(current, 30, setLoading, isOnSale, 'aoz')
    const { list, total } = useList(current, 30, setLoading, isOnSale, collection)


    const toggleOnSale = (checked) => {
        setCurrent(1);
        setIsOnSale(checked ? 'sale' : '')
    }

    const getList = () => {
        setCurrent(current + 1)
    }

    const onChangeRadio = (e) => {
        setCurrent(1);
        setCollection(e.target.value)
    }

    return (
        <div className={styles.container}>
            <div className={`tac auto ${styles.page_head_img}`} style={{ backgroundImage: `url(${quantumBg})` }}>
                <div className={`${styles.page_body}`}>
                    <div className={`${styles.title}`}>Meta2150s NFT Public Sale</div>
                    <div className="subtitle_wrapper">
                        <div className={`${styles.subtitle}`}>
                            {t('meta2150s')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.page_body}`}>
                <div className={`space-between-center mt20`}>
                    <div className={styles.page_tab_radio}>
                        <Radio.Group size={'large'} defaultValue="QuantumForce" onChange={onChangeRadio} buttonStyle="solid">
                            <Radio.Button value="QuantumForce">Quantum Force</Radio.Button>
                            <Radio.Button value="HyperQuantumForce">Hyper Quantum Force</Radio.Button>
                        </Radio.Group>
                    </div>
                    {/* <div>
                        <Switch onChange={toggleOnSale} />
                        <span className={"fs14 c_l ml10 fw600"}>{t('on_sale')}</span>
                    </div> */}
                </div>
                <div className={`page_collection_list`}>
                    {
                        (loading && current === 1) ? <Spin className={`wp100 mt40`} size="large"></Spin> :
                            <InfiniteScroll
                                dataLength={list.length}
                                next={getList}
                                hasMore={list.length < total}
                                loader={
                                    <div style={{ width: 216, textAlign: 'center', margin: 'auto' }}>
                                        <Spin size={`large`} />
                                    </div>
                                }
                            >
                                {
                                    list.length > 0 && list.map(item => (
                                        <NftCard key={item.contractAddress + item.id} tokenInfo={item} />
                                    ))
                                }
                                {
                                    list.length === 0 && <div className={`wp100 tac mt50`}><NoData></NoData></div>
                                }
                            </InfiniteScroll>
                    }
                </div>
                <div>
                    <div className={`${styles.des}`}>
                        <div className={`p20 c_000`}>
                            <h3>{t('about')}</h3>
                            <ul>
                                {
                                  collection === 'QuantumForce'
                                  ?
                                  <li className={'fs14'}>Quantum Force serves as a primary means for acquiring Mitcoin, a utility token. Its underlying technology is centered on a quantum chip, which has become a staple in computer processing and computation as of year 2150.</li>
                                  :
                                  <li className={'fs14'}>Hyper's performance surpasses that of Quantum Force, positioning it as a pioneering innovation. Despite its composition of several mining machines, Hyper's computational power vastly exceeds the sum of its parts.</li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Quantum;
