import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, message } from "antd"

import Avatar from 'components/Avatar';
import { ReactComponent as LikedIcon } from "assets/img/activity/liked.svg";
import { ReactComponent as LikeIcon } from "assets/img/activity/like.svg";
import { formatCreateTime } from "utils/format";
import { like, unlike, delComment } from '../../../../services/nftDetail';
import { useActiveWeb3React } from "../../../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import styles from "../../styles.module.scss";
import del from "assets/img/draft/del_b.png"
import tag from 'assets/img/artist/tag.svg'

export default function CommentItem({ item, onDelete }) {
    const { t } = useTranslation()
    const { library, account } = useActiveWeb3React()
    const { tronLibrary, tronAccount } = useActiveTronWeb()
    const [itemObj, setItemObj] = useState(item)
    
    const handleLike = () => {
        if (!itemObj.likedByCurrentUser) {
            like(library || tronLibrary, account || tronAccount, item.id).then(res => {
                if (res === 'success') {
                    setItemObj(old => ({
                        ...old,
                        likedByCurrentUser: true,
                        likeCount: old.likeCount + 1
                    }))
                }
            })
        } else {
            unlike(library || tronLibrary, account || tronAccount, item.id).then(res => {
                if (res === 'success') {
                    setItemObj(old => ({
                        ...old,
                        likedByCurrentUser: false,
                        likeCount: old.likeCount - 1
                    }))
                }
            })
        }
    }
    const handleDel = async () => {
        let res = await delComment(library || tronLibrary, account || tronAccount, item.id)
        message.success('Delete Success')
        onDelete(item.id)
    }
    return (
        <>
            <div className={`df ${styles.commentItem}`}>
                <Avatar isOwnedNft={itemObj.creator.avatarFromOwnedNft} address={itemObj.creator.address} size={40} tagSize={30} url={itemObj.creator.avatar}></Avatar>
                <div className={`f1 pl10 pr30`}>
                    <div className={`df_align_center_h5`}>
                        <div className={`fw600 pr14 df_center`}>
                            <span className='pr2'>{itemObj.creator.name}</span>
                            {
                                itemObj.creator.artist && <img className='mt-2' src={tag} width={16}></img>
                            }
                        </div>
                        <div className={`c_9a fs12`}>{formatCreateTime(itemObj.createTime, t)}</div>
                    </div>
                    <div className={styles.comment_content}>{itemObj.content}</div>
                    {
                        account&&account.toLocaleLowerCase() === item.creator.address.toLocaleLowerCase() &&
                        <span className={`${styles.comment_del}`} onClick={handleDel}>
                            <img className='mr4' width={10} src={del}></img>
                            Delete
                        </span>
                    }
                </div>
                <div className='pt10' onClick={handleLike}>
                    {
                        itemObj.likedByCurrentUser ? <LikedIcon></LikedIcon> : <LikeIcon className={`cp`} />
                    }
                    <div className='tac'>{itemObj.likeCount}</div>
                </div>
            </div>
        </>
    )
}
