import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { Menu, Dropdown, message, Popover, Card, Button, Modal, Form, Input } from 'antd';
import Zmage from 'react-zmage'
import copy from 'copy-to-clipboard';

import { getContract, useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { useNeedSign } from "hooks/account"
import Avatar from "components/Avatar";
import { ReactComponent as DotIcon } from "assets/img/activity/dot.svg";
import { ReactComponent as CommentIcon } from "assets/img/activity/comment.svg";
import { ReactComponent as LikeIcon } from "assets/img/activity/like.svg";
import { ReactComponent as LikedIcon } from "assets/img/activity/liked.svg";
import { ReactComponent as ShareIcon } from "assets/img/activity/share.svg";
import { ReactComponent as DelIcon } from 'assets/img/activity/delete.svg';
import twitterLogo from 'assets/img/twitter/twitter_logo.svg';
import { deleteActivity, unlikeActivity, likeActivity } from "services/home"
import { formatCreateTime } from "utils/format"
import { isSelf } from "utils/index";
import styles from "./styles.module.scss";
import tag from 'assets/img/artist/tag.png';

const ActivityCard = ({ data, cardType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { library, account } = useActiveWeb3React()
  const { tronLibrary, tronAccount } = useActiveTronWeb()
  const [isDelete, setIsDelete] = useState(false)
  const [likeObj, setLikeObj] = useState({ status: data.likedByCurrentUser, num: data.likeCount })
  const { needSign } = useNeedSign();
  const reg = /.gif/

  const handleDelete = () => {
    deleteActivity(data.id).then(res => {
      if (res === 'success') {
        if (cardType === 'comment') {
          history.go(-1)
          return;
        }
        setIsDelete(true)
      }
    })
  }

  const handleLike = () => {
    needSign(() => {
      if (!likeObj.status) {
        likeActivity(library || tronLibrary, account || tronAccount, data.id).then(res => {
          if (res === 'success') {
            setLikeObj(old => ({
              status: true,
              num: old.num + 1
            }))
          }
        })
      } else {
        unlikeActivity(library || tronLibrary, account || tronAccount, data.id).then(res => {
          if (res === 'success') {
            setLikeObj(old => ({
              status: false,
              num: old.num - 1
            }))
          }
        })
      }
    })
  }

  const copyUrl = () => {
    copy(`${window.location.origin}/activity/${data.id}`);
    message.success('URL copied!')
  };

  const goComment = () => {
    if (cardType === 'comment') {
      return;
    }
    history.push(`/activity/${data.id}`)
  };
  const searchTopic = () => {
    let str = data.content, reg = /#{1}[a-zA-Z]+([a-zA-Z|[&]|\d)+/g, match, lastIndex = 0, ret = [], i = 0;

    if (str && str.match(reg)) {
      while (match = reg.exec(str)) {

        if (match.index !== lastIndex) {
          ret.push(React.createElement("span", { key: i++ }, str.slice(lastIndex, match.index)));

        }
        let content = JSON.parse(JSON.stringify(match))[0]
        ret.push(<span className={`c_blue cp`} key={i++} onClick={() => { if(data.twitter){window.open(`https://twitter.com/${data.creatorUniqueName}/status/${data.id}`)}else{gotoDetail(content)} }} >{content}</span>);
        lastIndex = match.index + match[0].length

      }
      if (lastIndex !== str.length) {
        ret.push(str.slice(lastIndex))
      }
      return ret;
    } else {
      return str
    }
  }

  const gotoDetail = (topicContent) => {
    history.push(`/topic/${encodeURIComponent(topicContent)}`)
  }

  useEffect(() => {
    // searchTopic()
    setLikeObj({ status: data.likedByCurrentUser, num: data.likeCount })
  }, [data])


  if (isDelete) {
    return "";
  }

  return (
    <div className={`${styles.card} ${cardType ? '' : styles.add_border}`}>
      <div className="space-between-center">

        <div className="df_align_center">
          <Avatar twitter={data.twitter && data} isOwnedNft={data.creatorInfo && data.creatorInfo.avatarFromOwnedNft} address={data.creator} url={data.creatorAvatar} size={40} tagSize={30} />
          <div className="ml10">
            <div className="df_align_center">
              {
                data.twitter ?
                  <a className="fs16 fwb" href={`https://twitter.com/${data.creatorUniqueName}`} target="_blank">
                    {data.creatorName}
                  </a> :
                  <Link className="fs16 fwb" to={`/profile/${data.creator}`}>
                    {data.creatorName}
                    {
                      data.creatorInfo && data.creatorInfo.artist && <img className="mt-2" src={tag} width={16}></img>
                    }
                  </Link>
              }
              {data.permission === 'private' && <span className={styles.permission_tag}>Only followers</span>}
            </div>
            <div className="fs14">
              <span className="c_9a">{formatCreateTime(data.createTime * 1000, t)}{data.twitter ? ' | from ' : ''}</span>
              {
                data.twitter && <a href={`https://twitter.com/${data.creatorUniqueName}/status/${data.id}`} target="_blank">
                  <img style={{ width: 16 }} src={twitterLogo} alt="" />
                  <span style={{ color: '#1d9bf0' }}>{'Twitter·check detail >>'}</span>
                </a>
              }
            </div>
          </div>
        </div>

        {
          isSelf(data.creator, account, tronAccount) &&
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 4 }}>
                <Menu.Item className={styles.card_more_box} key="1" onClick={handleDelete}>
                  <DelIcon style={{ marginRight: 5 }} />{t('delete')}
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <DotIcon className="cp" />
          </Dropdown>
        }
      </div>
      <div className={styles.content_box}>
        {/* <div className="content_text"  dangerouslySetInnerHTML={{ __html: searchTopic() }}></div> */}
        <div className="content_text" >{searchTopic()}</div>
        {
          data.image && <div className={data.tokenId ? '' : styles.imgBox}>
            {
              data.tokenId ? <>
                {/* <Popover
                  placement="top"
                  content={
                    <Link to={`/marketplace/${data.contractAddress || data.tokenAddress}/${data.chainType}/${data.tokenId}`}>
                      <img style={{ width: 300 }} src={reg.test(data.preview) ? data.image : data.preview} alt="" />
                    </Link>
                  }
                  trigger="hover"
                >
                  <span style={{ color: '#1673E1', cursor: 'pointer' }}>
                    {data.nftName}
                  </span>
                </Popover> */}
                <Link to={`/marketplace/${data.contractAddress || data.tokenAddress}/${data.chainType}/${data.tokenId}`}>
                  <div className={styles.comment_nftCard}>
                    <div className={styles.nft_tag}>NFT</div>
                    <img className={styles.nft_cover} width={133} height={133} src={reg.test(data.preview) ? data.image : data.preview}></img>
                    <div className={styles.nft_info}>
                      <div className={`fw600 lh13 mb20`}>{data.nftName}</div>
                      <div className={`fs14 ${styles.nft_des}`}>{data.nftDescription}</div>
                    </div>
                  </div>
                </Link>
                {/* <div className={styles.nftTag}>NFT</div>
                <Link to={`/marketplace/${data.contractAddress || data.tokenAddress}/${data.chainType}/${data.tokenId}`}>
                  <img className={styles.cardImage} src={reg.test(data.preview)?data.image:data.preview} alt="" />
                </Link> */}
              </> : <>
                {(data.image.includes('.gif') || data.image.includes('.png') || data.image.includes('.jpg') || data.image.includes('.jpeg')) &&
                  <Zmage
                    className={styles.cardImage}
                    src={data.preview || data.image}
                    set={[{ src: data.image }]}
                  />
                }
                {(data.image.includes('.mp4') || data.image.includes('.mov')) &&
                  <video className={styles.cardImage} controls poster={data.preview}><source src={data.image} />Sorry, your browser doesn't support embedded videos.</video>
                }
              </>
            }
          </div>
        }
      </div>

      {
        !data.twitter && <div className={styles.bottom_box}>
          <div className={styles.cell_module} onClick={handleLike}>
            {likeObj.status ? <LikedIcon className={styles.icon} /> : <LikeIcon className={styles.icon} />}
            <span className={likeObj.num ? 'ml5' : 'hide'}>{likeObj.num}</span>
          </div>
          <div className={styles.cell_module} onClick={goComment}>
            <CommentIcon className={styles.icon} />
            <span className={data.commentCount ? 'ml5' : 'hide'}>{data.commentCount}</span>
          </div>
          <div className={styles.cell_module} style={{ width: 'auto' }} onClick={copyUrl}>
            <ShareIcon className={styles.icon} />
          </div>
        </div>
      }
    </div>
  )
}

export default ActivityCard;