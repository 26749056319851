import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Modal } from 'antd';
import { useTranslation } from "react-i18next";
import { clearCache } from 'react-router-cache-route'

import { HANDLE_HIDE_EVENT_MODAL } from "../../const";
import { mainContext } from "../../reducer";
import Avatar from "components/Avatar";
import { isMobile } from "../../utils"
import FollowButton from "components/FollowButton";
import { ReactComponent as RefreshIcon } from "assets/img/refresh.svg";
import arrows from "assets/img/arrows.png";
import { useRecommendArtists, getBannerList } from "services/home"
import styles from "./styles.module.scss";
import tag from '../../assets/img/artist/tag.png'
import bitkeep from "assets/img/banner/bitkeep_define.jpg";
import halloween from "assets/img/banner/halloween.jpg";
import world_cup from "assets/img/banner/world_cup.jpg";
import sandbox from "assets/img/banner/define_sandbox.jpg";
import ama from "assets/img/banner/ama.jpg";
import quantum from "assets/img/banner/quantum.jpg";

// const eventList = [
//   { link: '/event/quantum', img: quantum },
//   { link: '/badge/Meta2150s/DeFine-META2150S', img: ama },
//   // { url: 'https://www.youtube.com/watch?v=2gjHcTuaSJY&feature=youtu.be', img: sandbox },
//   // { link: '/events/halloween', img: halloween },
//   // { url: 'https://twitter.com/DeFinePlatform/status/1575776556219105280?s=20&t=fqOO7SBawmWsshaKB16B8A', img: bitkeep },
// ]

const RecommendBar = ({ recommendType }) => {
  const [pageNo, setPageNo] = useState(1)
  // const [visible, setVisible] = useState(false)
  const { state, dispatch } = useContext(mainContext);
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { randomArtistList } = useRecommendArtists(pageNo, 5, setLoading);
  const location = useLocation();
  const [isNotice, setIsNotice] = useState(true)
  const [bannerList, setBannerList] = useState([])

  const handleRefresh = () => {
    setPageNo(pageNo + 1)
  }

  const queryBanners = async () => {
    let list = await getBannerList()
    setBannerList(list)
  }

  const handleCancel = () => {
    dispatch({
      type: HANDLE_HIDE_EVENT_MODAL,
      hideEventModal: true
    });
    // setVisible(false)
    clearCache()
  }
  const initNewShow = () => {
    if (location.search) {
      setIsNotice(true)
    } else {
      setIsNotice(false)
    }
  }
  useEffect(() => {
    initNewShow()
    queryBanners()
    // if (isMobile() && !state.hideEventModal) {
    //   setVisible(true)
    // }
  }, [])

  return (
    <div>
      <div className={styles.recommend_box}>
        <div className={styles.recommend_box_fixed}>
          <div className={styles.title}>What’s New</div>
          {
            bannerList.map(item => {
              if (item.link) {
                return <Link key={item.link} to={item.link}><img className={styles.recommend_img} src={item.image} /></Link>
              } else {
                return <a key={item.url} href={item.url} target="_blank"><img className={styles.recommend_img} src={item.image} /></a>
              }
            })
          }
          <div className={styles.more_box}>
            <Link to="/events" className={styles.more_btn}>
              <span>View All Event</span>
              <img className={styles.arrows} src={arrows} alt="" />
            </Link>
          </div>

          {/* <div className="space-between-center">
            <span className={styles.title}>You might like</span>
            <RefreshIcon width={30} height={30} className={`cp ${loading ? styles.refreshIcon : null}`} onClick={handleRefresh} />
          </div>
          {
            randomArtistList.map(item => (
              <div key={item.address} className="space-between-center mb15">
                <div className="df_align_center">
                  <Avatar isOwnedNft={item.avatarFromOwnedNft} address={item.address} url={item.avatar} size={40} />
                  
                  <Link to={`/profile/${item.address}`} className={`${styles.user_name} ell df_align_center pr5`}>
                    <div className={`fw600 tac text_hidden_1 mt2 pr4`}>{item.name}</div>
                    {
                        item.artist && <img src={tag} width={16}></img>
                    }
                  </Link>
                </div>
                <FollowButton followed={item.followedByCurrentUser} userId={item.address} />
              </div>
            ))
          } */}
          {/* <div className={styles.more_box}>
            <Link to="/artists" className={styles.more_btn}>
              <span>View All</span>
              <img className={styles.arrows} src={arrows} alt="" />
            </Link>
          </div> */}
        </div>
      </div>

      <Modal
        visible={!isNotice && !state.hideEventModal && recommendType != "message"}
        onCancel={handleCancel}
        title={'What’s New'}
        footer={null}
      >
        {
          bannerList.map(item => {
            if (item.link) {
              return <Link key={item.link} onClick={handleCancel} to={item.link}><img className={styles.recommend_img} src={item.image} /></Link>
            } else {
              return <a key={item.url} onClick={handleCancel} href={item.url} target="_blank"><img className={styles.recommend_img} src={item.image} /></a>
            }
          })
        }
        <div className={styles.more_box}>
          <Link onClick={handleCancel} to="/events" className={styles.more_btn}>
            <span className="fs16">View All Event</span>
            <img className={styles.arrows} src={arrows} alt="" />
          </Link>
        </div>
      </Modal>
    </div>
  )
}
export default RecommendBar;
