import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Radio, Button, Row, Col, Spin } from 'antd'
import InfiniteScroll from "react-infinite-scroll-component";

import { useBadgeEventList } from "../../services/badgeManage"
import arrow from "assets/img/home/arrow.png"
import flowChart from "assets/img/creatBadge/flowChart.png"
import createCard from "assets/img/creatBadge/createCard.png"
import lamp from "assets/img/creatBadge/lamp.svg"
import arrowDown from "assets/img/creatBadge/arrow_down.svg"

import styles from './style.module.scss'

export default function CreatBadge() {
    const history = useHistory()
    const [pageNo, setPageNo] = useState(1)
    const [showFlow, setShowFlow] = useState(false)
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const { list, total } = useBadgeEventList(pageNo, 30, setLoading, status)

    const gotoCreate = () => {
        history.push('/badgeManage/createEvent')
    }
    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const showFlowChart = () => {
        setShowFlow(!showFlow)
    }
    const onChangeRadio = (e) => {
        setStatus(e.target.value)
    }
    const gotoDetail = (item) => {
        if (item.type === 'blindBox') {
            history.push(`/badgeManage/eventBlindDetail/${item.project}`)
        } else {
            history.push(`/badgeManage/eventDetail/${item.project}`)
        }
    }
    return (
        <div className={styles.badgeManage}>
            <div className='space-between'>
                <div className={`fs36 fw600`}>My Event</div>
                <div className='df_align_center'>
                    <Radio.Group defaultValue="" buttonStyle="solid" onChange={onChangeRadio}>
                        <Radio.Button value="">All</Radio.Button>
                        <Radio.Button value="active">Active</Radio.Button>
                        <Radio.Button value="soon">Soon</Radio.Button>
                        <Radio.Button value="draft">Draft</Radio.Button>
                        <Radio.Button value="ended">Completed</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            {
                loading ? <div className='tac mt40'><Spin></Spin></div> :
                    <>
                        {
                            list.length > 0 ?
                                <InfiniteScroll className='mt20'
                                    dataLength={list.length}
                                    next={getList}
                                    hasMore={list.length < total}>
                                    <Row>
                                        <Col span={6}>
                                            <div className={styles.create_card}>
                                                <img width={188} src={createCard}></img>
                                                <Button className={`${styles.create_btn} mt30`} onClick={gotoCreate}>Create Events &nbsp; <img src={arrow}></img></Button>
                                            </div>
                                        </Col>
                                        {
                                            list.map(item => (
                                                <Col span={6}>
                                                    <div className={styles.badge_card} style={{ backgroundImage: `url(${item.image})` }} onClick={() => { gotoDetail(item) }}>
                                                        <div className={styles.card_bg}>
                                                            <div className={styles.card_footer}>
                                                                <img className={styles.card_logo} width={54} src={item.logo} />
                                                                <span className='pl10 pr10 c_fff fw600'>{item.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    <div className={styles.showFlow} onClick={showFlowChart}><img src={lamp}></img> How to create badges <img src={arrowDown}></img></div>
                                </InfiniteScroll> :
                                <div className={styles.create_box_btn}>
                                    <div>You don't have an event yet</div>
                                    <div>please read tutorial and create your first event！</div>
                                    <Button className={`${styles.create_btn} mt10`} onClick={gotoCreate}>Create Events &nbsp; <img src={arrow}></img></Button>
                                </div>
                        }
                        {
                            (list.length === 0 || showFlow) &&
                            <div className='mt30'>
                                <img width={1280} src={flowChart}></img>
                            </div>
                        }
                    </>

            }
        </div>
    )
}
