
export const filterBackground = [ 
    {label: 'Gray', value: 'Gray', percent: '11.35%' },
    {label: 'New Punk Blue', value: 'New Punk Blue', percent: '12.42%' },
    {label: 'Army Green', value: 'Army Green', percent: '12.44%' },
    {label: 'Blue', value: 'Blue', percent: '12.45%'},
    {label: 'Aquamarine', value: 'Aquamarine', percent: '12.58%'},
    {label: 'Orange', value: 'Orange', percent: '12.73%'},
    {label: 'Purple', value: 'Purple', percent: '12.80%'},
    {label: 'Yellow', value: 'Yellow', percent: '13.23%'}
]
export const filterClothes= [ 
    {label: 'Black Suit', value: 'Black Suit', percent: '0.41%' },
    {label: 'Kings Robe', value: 'Kings Robe', percent: '0.65%' },
    {label: 'Admirals Coat', value: 'Admirals Coat', percent: '0.70%' },
    {label: 'Pimp Coat', value: 'Pimp Coat', percent: '0.70%' },
    {label: 'Prom Dress', value: 'Prom Dress', percent: '0.82%' },
    {label: 'Blue Dress', value: 'Blue Dress', percent: '1.02%' },
    {label: 'Space Suit', value: 'Space Suit', percent: '1.23%' },
    {label: 'Cowboy Shirt', value: 'Cowboy Shirt', percent: '1.27%' },
    {label: 'Tweed Suit', value: 'Tweed Suit', percent: '1.28%' },
    {label: 'Tie Dye', value: 'Tie Dye', percent: '1.30%' },
    {label: 'Caveman Pelt', value: 'Caveman Pelt', percent: '1.33%' },
    {label: 'Rainbow Supenders', value: 'Rainbow Supenders', percent: '1.36%' },
    {label: 'Hip Hop', value: 'Hip Hop', percent: '1.40%' },
    {label: 'Sleeveless Logo T', value: 'Sleeveless Logo T', percent: '1.42%' },
    {label: 'Service', value: 'Service', percent: '1.46%' },
    {label: 'Lab Coat', value: 'Lab Coat', percent: '1.49%' },
    {label: 'Bayc T Red', value: 'Bayc T Red', percent: '1.54%' },
    {label: 'Bandolier', value: 'Bandolier', percent: '1.56%' },
    {label: 'Leather Punk Jacket', value: 'Leather Punk Jacket', percent: '1.58%' },
    {label: 'Stunt Jacket', value: 'Stunt Jacket', percent: '1.91%' },
    {label: 'Bone Necklace', value: 'Bone Necklace', percent: '1.92%' },
    {label: 'Black Holes T', value: 'Black Holes T', percent: '1.93%' },
    {label: 'Lumberjack Shirt', value: 'Lumberjack Shirt', percent: '1.97%' },
    {label: 'Toga', value: 'Toga', percent: '2.04%' },
    {label: 'Bayc T Black', value: 'Bayc T Black', percent: '2.08%' },
    {label: 'Work Vest', value: 'Work Vest', percent: '2.09%' },
    {label: 'Leather Jacket', value: 'Leather Jacket', percent: '2.11%' },
    {label: 'Biker Vest', value: 'Biker Vest', percent: '2.19%' },
    {label: 'Guayabera', value: 'Guayabera', percent: '2.19%' },
    {label: 'Smoking Jacket', value: 'Smoking Jacket', percent: '2.31%' },
    {label: 'Tuxedo Tee', value: 'Tuxedo Tee', percent: '2.31%' },
    {label: 'Prison Jumpsuit', value: 'Prison Jumpsuit', percent: '2.32%' },
    {label: 'Wool Turleneck', value: 'Wool Turleneck', percent: '2.33%' },
    {label: 'Puffy Vest', value: 'Puffy Vest', percent: '2.39%' },
    {label: 'Vietnam Jacket', value: 'Vietnam Jacket', percent: '2.42%' },
    {label: 'Tanktop', value: 'Tanktop', percent: '2.47%' },
    {label: 'Bone Tee', value: 'Bone Tee', percent: '2.52%' },
    {label: 'Sailor Shirt', value: 'Sailor Shirt', percent: '2.61%' },
    {label: 'Sleeveless T', value: 'Sleeveless T', percent: '2.66%' },
    {label: 'Hawaiian', value: 'Hawaiian', percent: '2.85%' },
    {label: 'Black T', value: 'Black T', percent: '3.41%' },
    {label: 'Navy Strped Tee', value: 'Navy Strped Tee', percent: '3.59%' },
    {label: 'Striped Tee', value: 'Striped Tee', percent: '4.00%' },
    {label: 'Null', value: ' ', percent: '18.86%' },
]
export const filterEarring = [ 
    {label: 'Cross', value: 'Cross', percent: '1.73%' },
    {label: 'Diamond Stud', value: 'Diamond Stud', percent: '1.99%' },
    {label: 'Gold Hoop', value: 'Gold Hoop', percent: '4.68%' },
    {label: 'Gold Stud', value: 'Gold Stud', percent: '4.10%' },
    {label: 'Silver Hoop', value: 'Silver Hoop', percent: '8.47%' },
    {label: 'Silver Stud', value: 'Silver Stud', percent: '8.56%' },
    {label: 'Null', value: '', percent: '70.47%' },
]
export const filterEyes = [ 
    {label: 'Blue Beams', value: 'Blue Beams', percent: '0.49%' },
    {label: 'Laser Eyes', value: 'Laser Eyes', percent: '0.76%' },
    {label: 'Cyborg', value: 'Cyborg', percent: '1.20%' },
    {label: 'Holographic', value: 'Holographic', percent: '1.48%' },
    {label: 'X Eyes', value: 'X Eyes', percent: '2.32%' },
    {label: 'Hpnotized', value: 'Hpnotized', percent: '2.53%' },
    {label: 'Scumbag', value: 'Scumbag', percent: '2.54%' },
    {label: 'Blindfold', value: 'Blindfold', percent: '2.73%' },
    {label: 'Zombie', value: 'Zombie', percent: '3.00%' },
    {label: 'Eyepatch', value: 'Eyepatch', percent: '3.36%' },
    {label: 'Sunglasses', value: 'Sunglasses', percent: '3.73%' },
    {label: 'Robot', value: 'Robot', percent: '3.75%' },
    {label: 'Flower', value: 'Flower', percent: '3.97%' },
    {label: 'Crazy', value: 'Crazy', percent: '4.05%' },
    {label: 'Angry', value: 'Angry', percent: '4.23%' },
    {label: 'Coins', value: 'Coins', percent: '4.38%' },
    {label: '3D', value: '3D', percent: '4.79%' },
    {label: 'Wide Eyed', value: 'Wide Eyed', percent: '5.27%' },
    {label: 'Sad', value: 'Sad', percent: '5.34%' },
    {label: 'Closed', value: 'Closed', percent: '7.08%' },
    {label: 'Sleepy', value: 'Sleepy', percent: '7.79%' },
    {label: 'Bloodshot', value: 'Bloodshot', percent: '8.11%' },
    {label: 'Bored', value: 'Bored', percent: '17.10%' },
]
export const filterFur = [ 
    {label: 'Solid Gold', value: 'Solid Gold', percent: '0.37%' },
    {label: 'Trippy', value: 'Trippy', percent: '0.74%' },
    {label: 'Noise', value: 'Noise', percent: '1.77%' },
    {label: 'Death Bot', value: 'Death Bot', percent: '1.78%' },
    {label: 'Dmt', value: 'Dmt', percent: '2.05%' },
    {label: 'Robot', value: 'Robot', percent: '2.31%' },
    {label: 'Zombie', value: 'Zombie', percent: '3.13%' },
    {label: 'White', value: 'White', percent: '3.61%' },
    {label: 'Cheetah', value: 'Cheetah', percent: '4.13%' },
    {label: 'Red', value: 'Red', percent: '4.77%' },
    {label: 'Gray', value: 'Gray', percent: '4.83%' },
    {label: 'Blue', value: 'Blue', percent: '4.94%' },
    {label: 'Pink', value: 'Pink', percent: '5.18%' },
    {label: 'Tan', value: 'Tan', percent: '6.51%' },
    {label: 'Cream', value: 'Cream', percent: '6.67%' },
    {label: 'Golden Brown', value: 'Golden Brown', percent: '8.11%' },
    {label: 'Black', value: 'Black', percent: '12.20%' },
    {label: 'Brown', value: 'Brown', percent: '13.45%' },
    {label: 'Dark Brown', value: 'Dark Brown', percent: '13.45%' }
]
export const filterHat = [ 
    {label: "King'S Crown", value: "King'S Crown", percent: '0.56%' },
    {label: "Trippy Captain'S Hat", value: "Trippy Captain'S Hat", percent: '0.60%' },
    {label: 'Laurel Wreath', value: 'Laurel Wreath', percent: '0.72%' },
    {label: 'Bandana Blue', value: 'Bandana Blue', percent: '0.86%' },
    {label: 'Party Hat 2', value: 'Party Hat 2', percent: '0.99%' },
    {label: 'Ww2Pilot Helm', value: 'Ww2Pilot Helm', percent: '0.99%' },
    {label: 'Prarty Hat 1', value: 'Prarty Hat 1', percent: '1.09%' },
    {label: 'Bayc Hat Red', value: 'Bayc Hat Red', percent: '1.10%' },
    {label: "Girl'S Hair Pink", value: "Girl'S Hair Pink", percent: '1.17%' },
    {label: 'Prussian Helmet', value: 'Prussian Helmet', percent: '1.24%' },
    {label: 'Police Motorcycle Helmet', value: 'Police Motorcycle Helmet', percent: '1.38%' },
    {label: "Girl'S Hair Short", value: "Girl'S Hair Short" , percent: '1.47%' },
    {label: "Baby'S Bonnet", value: "Baby'S Bonnet", percent: '1.52%' },
    {label: 'Faux Hawk', value: 'Faux Hawk', percent: '1.52%' },
    {label: 'Stuntman Helmet', value: 'Stuntman Helmet', percent: '1.59%' },
    {label: 'Spinner Hat', value: 'Spinner Hat', percent: '1.74%' },
    {label: 'Safari', value: 'Safari', percent: '1.77%' },
    {label: 'Sushi Chef Headband', value: 'Sushi Chef Headband', percent: '1.87%' },
    {label: 'Bunny Ears', value: 'Bunny Ears', percent: '2.05%' },
    {label: 'Irish Boho', value: 'Irish Boho', percent: '2.06%' },
    {label: 'Bayc Hat Black', value: 'Bayc Hat Black', percent: '2.24%' },
    {label: 'Bayc Flipped Brim', value: 'Bayc Flipped Brim', percent: '2.27%' },
    {label: 'Bowler', value: 'Bowler', percent: '2.32%' },
    {label: 'S&M Hat', value: 'S&M Hat', percent: '2.52%' },
    {label: 'Horns', value: 'Horns', percent: '2.62%' },
    {label: 'Vietnam Era Helmet', value: 'Vietnam Era Helmet', percent: '2.64%' },
    {label: 'Commie Hat', value: 'Commie Hat', percent: '2.86%' },
    {label: 'Army Hat', value: 'Army Hat', percent: '2.88%' },
    {label: "Sea Captain'S Hat", value: "Sea Captain'S Hat", percent: '3.04%' },
    {label: 'Halo', value: 'Halo', percent: '3.09%' },
    {label: 'Short Mohawk', value: 'Short Mohawk', percent: '3.34%' },
    {label: 'Cowboyhat', value: 'Cowboyhat', percent: '3.43%' },
    {label: "Fisherman'S Hat", value: "Fisherman'S Hat", percent: '3.50%' },
    {label: 'Fez', value: 'Fez', percent: '4.04%' },
    {label: "Seaman'S Hat", value: "Seaman'S Hat", percent: '4.59%' },
    {label: 'Beanie', value: 'Beanie', percent: '5.65%' },
    {label: 'Null', value: '', percent: '22.68%' },
]
export const filterMouth = [ 
    {label: 'Bored Unshaven Dagger', value: 'Bored Unshaven Dagger', percent: '0.28%' },
    {label: 'Bored Unshaven Hotdog', value: 'Bored Unshaven Hotdog', percent: '0.35%' },
    {label: 'Bored Unshaven Lollipop', value: 'Bored Unshaven Lollipop', percent: '0.45%' },
    {label: 'Bored Dagger', value: 'Bored Dagger', percent: '0.54%' },
    {label: 'Bored Hotdog', value: 'Bored Hotdog', percent: '0.57%' },
    {label: 'Bored Unshaven Kazoo', value: 'Bored Unshaven Kazoo', percent: '0.62%' },
    {label: 'Bored Unshaven Bubblegum', value: 'Bored Unshaven Bubblegum', percent: '0.71%' },
    {label: 'Bored Kazoo', value: 'Bored Kazoo', percent: '0.73%' },
    {label: 'Grin Diamond Grill', value: 'Grin Diamond Grill', percent: '0.77%' },
    {label: 'Bored Lollipop', value: 'Bored Lollipop', percent: '0.93%' },
    {label: 'Grin Gold Grill', value: 'Grin Gold Grill', percent: '0.98%' },
    {label: 'Grin Multicolored', value: 'Grin Multicolored', percent: '1.05%' },
    {label: 'Bored Unshaven Cigar', value: 'Bored Unshaven Cigar', percent: '1.07%' },
    {label: 'Bored Unshaven Pipe', value: 'Bored Unshaven Pipe', percent: '1.16%' },
    {label: 'Bored Cigar', value: 'Bored Cigar', percent: '1.19%' },
    {label: 'Bored Bubblegum', value: 'Bored Bubblegum', percent: '1.38%' },
    {label: 'Bored Pipe', value: 'Bored Pipe', percent: '1.39%' },
    {label: 'Phoneme Wah', value: 'Phoneme Wah', percent: '1.61%' },
    {label: 'Tongue Out', value: 'Tongue Out', percent: '1.98%' },
    {label: 'Discomfort', value: 'Discomfort', percent: '1.99%' },
    {label: 'Phoneme Oh', value: 'Phoneme Oh', percent: '2.34%' },
    {label: 'Phoneme L', value: 'Phoneme L', percent: '2.46%' },
    {label: 'Phoneme Ooo', value: 'Phoneme Ooo', percent: '2.46%' },
    {label: 'Small Grin', value: 'Small Grin', percent: '2.81%' },
    {label: 'Jovial', value: 'Jovial', percent: '2.90%' },
    {label: 'Rage', value: 'Rage', percent: '2.95%' },
    {label: 'Phoneme Vuh', value: 'Phoneme Vuh', percent: '3.03%' },
    {label: 'Bored Unshaven Cigraette', value: 'Bored Unshaven Cigraette', percent: '4.06%' },
    {label: 'Dumbfounded', value: 'Dumbfounded', percent: '4.80%' },
    {label: 'Bored Cigarette', value: 'Bored Cigarette', percent: '7.12%' },
    {label: 'Grin', value: 'Grin', percent: '7.33%' },
    {label: 'Bored Unshaven', value: 'Bored Unshaven', percent: '16.02%' },
    {label: 'Bored', value: 'Bored', percent: '21.97%' },
]