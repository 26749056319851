import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import 'antd/dist/antd.css';
import { message } from 'antd';
import "easymde/dist/easymde.min.css";

import './i18n'
import "./assets/css/style.scss";
import wow from "assets/img/header/wow.svg";
import topic from "assets/img/header/topic.svg";
import notification from "assets/img/header/icon_notification.svg";
import notificationB from "assets/img/header/icon_notification2.svg";
import reward from "assets/img/header/reward.svg";
import wowB from "assets/img/header/wow_2.svg";
import topicB from "assets/img/header/topic_2.svg";
import rewardB from "assets/img/header/reward_2.svg";
import activity from "assets/img/message/activity.svg"
import likeme from "assets/img/message/likeme.svg"
import common from "assets/img/message/common.svg"
import { ContextProvider } from "./reducer";
import Layout from "./components/Layout";
import Home from "pages/Home";
import Comment from "pages/Comment";
import NftDetail from "pages/NftDetail";
import Marketplace from "pages/Marketplace";
import Vote from "pages/Vote";
import VoteCreat from "pages/Vote/Create/index";
import VoteDetail from "pages/Vote/Detail/index";
import Farm from "pages/Farm";
import FarmDetail from "pages/Farm/Detail";
import NFTPad from "pages/NftPlus";
import Sypool from "pages/Sypool";
import Season from "pages/Season";
import CollectionDetail from "pages/CollectionDetail";
import Events from "pages/Events";
import Sandbox from "pages/Sandbox";
import Shardsplice from "pages/ShardSplice";
import MysteryBox from "pages/MysteryBox";
import Punk from "pages/Punk";
import BadgeEntry from "pages/BadgeEntry";
import BadgeList from "pages/Badge";
import BadgeDetail from "pages/BadgeDetail";
import Artist from "pages/Artist";
import NftPlusRedeem from "pages/NftPlusRedeem";
// import CreateNft from "pages/CreateNft";
import Profile from "pages/Profile";
import Setting from "pages/Setting";
import Aoz from "pages/AOZ";
import DeesseEvent from "pages/DeesseEvent";
import SypoolChristmas from "pages/NftPlusChristmas";
import Treasury from "pages/Treasury";
import Anniversary from "pages/Anniversary"
import CollectionList from "pages/CollectionList";
import Message from "pages/Message";
import Topic from "pages/Topic";
import TopicDetail from "pages/Topic/TopicDetail"
import PublishBoxH5 from "pages/PublishBoxH5";
import BlindBadge from "pages/BlindBadge"
import KBW from "pages/KBW"
import ClaimDFA from "pages/ClaimDFA";
import ClaimDFAOld from "pages/ClaimDFAOld";
import Token2049 from "pages/Token2049";
import Halloween from "pages/Halloween"
import Quantum from "pages/Quantum"
import WorldCup from "pages/WorldCup2022"
import SandboxAddress from "pages/SandboxAddress"
import SandboxAddressList from "pages/SandboxAddressList"
import SandboxEmail from "pages/SandboxEmial"
import RelationGraph2D from "pages/RelationGraph2D"
import Wow from "pages/Wow"
import G6 from "pages/G6";
import BadgeManage from "pages/BadgeManage";
import CreateEvent from "pages/BadgeManage/CreateEvent";
import EventDetial from "pages/BadgeManage/EventDetail";
import EventBlindDetial from "pages/BadgeManage/EventBlindDetail"
import CreateBadge from "pages/BadgeManage/CreateBadge";
import PublishBadge from "pages/BadgeManage/Publish";
import PublishBlindBadge from "pages/BadgeManage/PublishBlind";
import CreateBlindBadge from "pages/BadgeManage/CreateBlindBadge";
import Auth from "pages/Auth";
import CollectionManage from "pages/CollectionManage";
import CreateCollection from "pages/CollectionManage/CreateCollection";
import CollectionManageDetail from "pages/CollectionManage/CollectionManageDetail"
import CreateNft from "pages/CollectionManage/CreateNft"

message.config({
  maxCount: 1
})

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

const routeArr = [
  {
    component: Home, path: '/', parent: 'home', cache: true
  },
  {
    component: Auth, path: '/auth'
  },
  {
    component: Wow, path: '/wow', parent: 'message', cache: false, hasTopic: true,
    navList: [
      { title: 'WoW', icon: wow, hoverIcon: wowB, link: '/wow' },
      { title: 'Topic', icon: topic, hoverIcon: topicB, link: '/topic' },
      { title: 'Notifications', icon: notification, hoverIcon: notificationB, link: '/message/likeme' }
    ]
  },
  {
    component: Topic, path: '/topic', parent: 'message', cache: false, hasTopic: true,
    navList: [
      { title: 'WoW', icon: wow, hoverIcon: wowB, link: '/wow' },
      { title: 'Topic', icon: topic, hoverIcon: topicB, link: '/topic' },
      { title: 'Notifications', icon: notification, hoverIcon: notificationB, link: '/message/likeme' }
    ]
  },
  {
    component: TopicDetail, path: '/topic/:topicContent', parent: 'home', cache: false, hasTopic: true,
    navList: [
      { title: 'WoW', icon: wow, hoverIcon: wowB, link: '/wow' },
      { title: 'Topic', icon: topic, hoverIcon: topicB, link: '/topic' },
      { title: 'Notifications', icon: notification, hoverIcon: notificationB, link: '/message/likeme' }
    ]
  },
  {
    component: Comment, path: '/activity/:activityId', parent: 'home',
    navList: [
      { title: 'WoW', icon: wow, hoverIcon: wowB, link: '/wow' },
      { title: 'Topic', icon: topic, hoverIcon: topicB, link: '/topic' },
      { title: 'Notifications', icon: notification, hoverIcon: notificationB, link: '/message/likeme' }
    ]
  },
  { component: PublishBoxH5, path: '/publish'},
  { component: NftDetail, path: '/marketplace/:contractAddress/:chainType/:tokenId', parent: 'market' },
  
  { component: Profile, path: '/profile/:handleName' },
  { component: Profile, path: '/p/:handleName' },
  { component: Setting, path: '/setting' },
  { component: Vote, path: '/vote', parent: 'dao', cache: true },
  { component: VoteCreat, path: '/vote/create', parent: 'dao' },
  { component: VoteDetail, path: '/vote/:chainType/:contractAddress/:id', parent: 'dao' },
  { component: Farm, path: '/farm' },
  { component: FarmDetail, path: '/farm/:chainType/:contractAddress/:poolId' },
  {
    component: NFTPad, path: '/nftplus', parent: 'nftPlus'
  },
  {
    component: Sypool, path: '/sypool', parent: 'nftPlus'
  },
  {
    component: SypoolChristmas, path: '/sypool-xmas', parent: 'nftPlus'
  },
  {
    component: Season, path: '/season', parent: 'nftPlus'
  },
  {
    component: CollectionDetail, path: '/collection/:type', parent: 'nftPlus'
  },
  {
    component: Events, path: '/events',
  },
  {
    component: Sandbox, path: '/sandbox',
  },
  {
    component: Shardsplice, path: '/shardsplice',
  },
  {
    component: MysteryBox, path: '/mysteryBox',
  },
  {
    component: Punk, path: '/punk',
  },
  {
    component: Aoz, path: '/event/aoz',
  },
  {
    component: DeesseEvent, path: '/event/deesse',
  },
  {
    component: BadgeEntry, path: '/badgeEntry', parent: 'badge', headerTheme: 'h5-dark'
  },
  {
    component: BadgeList, path: '/badge/:project', parent: 'badge'
  },
  {
    component: BadgeDetail, path: '/badge/:project/:type', parent: 'badge'
  },
  {
    component: BlindBadge, path: '/blindbadge/:project', parent: 'badge'
  },
  {
    component: Artist, path: '/artists'
  },
  {
    component: NftPlusRedeem, path: '/redeem', parent: 'nftPlus'
  },
  // {
  //   component: CreateNft, path: '/create'
  // },
  {
    component: Treasury, path: '/treasury'
  },
  {
    component: Anniversary, path: '/events/one-year-anniversary',
  },
  {
    component: CollectionList, path: '/collections', cache: true
  },
  {
    component: Message, path: '/message/:type', parent: 'message', cache: true,
    // navList: [
    //   { title: 'Notifications', hoverIcon: wowB,haveTitle:true, link: '' },
    //   { title: 'Like', icon: likeme, haveNotice:true, hoverIcon: likeme, link: '/message/likeme' },
    //   { title: 'Comment', icon: common, haveNotice:true, hoverIcon: common, link: '/message/comment' },
    //   { title: 'NFT Activity', icon: activity, haveNotice:true, hoverIcon: activity, link: '/message/activity' }
    // ]
    navList: [
      { title: 'WoW', icon: wow, hoverIcon: wowB, link: '/wow' },
      { title: 'Topic', icon: topic, hoverIcon: topicB, link: '/topic' },
      { title: 'Notifications', icon: notification, hoverIcon: notificationB, link: '/message/likeme' }
    ]
  },
  {
    component: KBW, path: '/events/KBW2022',
  },
  {
    component: ClaimDFA, path: '/claimDfa'
  },
  {
    component: ClaimDFAOld, path: '/claimDfa-old'
  },
  {
    component: Token2049, path: '/define-badge/:badgeType', alonePage: true,
  },
  {
    component: Halloween, path: '/events/halloween'
  },
  {
    component: Quantum, path: '/event/quantum'
  },
  {
    component: WorldCup, path: '/events/DeFine-FootballCampaign2022'
  },
  {
    component: SandboxAddress, path: '/collectionAddress/:badgeType', alonePage: true,
  },
  {
    component: SandboxAddressList, path: '/collectedAddressList/:badgeType',
  },
  {
    component: SandboxEmail, path: '/collectionEmail/:badgeType', alonePage: true,
  },
  // {
  //   component: RelationGraph2D, path:'/relation-graph-2d', alonePage: true,
  // },
  {
    component: G6, path:'/g6/:address', alonePage: true,
  },

  // v4 
  { component: Marketplace, path: '/marketplace', parent: 'market', headerTheme: 'h5-dark' },
  { component: BadgeManage, path: '/badgeManage' },
  { component: CreateEvent, path: '/badgeManage/createEvent' },
  { component: EventDetial, path: '/badgeManage/eventDetail/:eventId' },
  { component: EventBlindDetial, path: '/badgeManage/eventBlindDetail/:eventId' },
  { component: CreateBadge, path: '/badgeManage/createBadge/:eventId' },
  { component: CreateBlindBadge, path: '/badgeManage/createBlindBadge/:eventId' },
  { component: PublishBadge, path: '/badgeManage/publish/:eventId' },
  { component: PublishBlindBadge, path: '/badgeManage/publishBlind/:eventId' },
  { component: CollectionManage, path: '/collectionManage' },
  { component: CreateCollection, path: '/collectionManage/createCollection' },
  { component: CollectionManageDetail, path: '/collectionManage/collectionDetail/:collectionId' },
  { component: CreateNft, path: '/collectionManage/createNFT' },

]

function App() {
  return (
    <ContextProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <Layout routeArr={routeArr}>
            <CacheSwitch>
              <Route exact path="/discover">
                <Redirect to="/" />
              </Route>
              {
                routeArr.map(item => {
                  if (item.cache) {
                    return <CacheRoute cacheKey={item.path} key={item.path} exact {...item} />
                  }
                  return <Route key={item.path} exact {...item} />
                })
              }
            </CacheSwitch>
          </Layout>
        </Router>
      </Web3ReactProvider>
    </ContextProvider>
  );
}

export default App;
