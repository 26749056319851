import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import moment from 'moment';

import {  HANDLE_NOTICE_NUM } from "../../../../const";
import { mainContext } from "../../../../reducer";
import NoData from "components/NoData";
import { useList, queryNoticeStatus } from "../../../../services/message"
import likeme from "assets/img/message/likeme.svg"
import styles from "../../styles.module.scss";

export default function Activity() {
    const history = useHistory();
    const { state, dispatch } = useContext(mainContext);
    const [loading, setLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const { list,total } = useList('like', pageNo, pageSize, setLoading)

    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const goToActivity = (id) => {
        history.push(`/activity/${id}?origin=notice`)
    }
    const goToComment = (item) => {
        history.push(`/marketplace/${item.nftInfo.contractAddress}/${item.nftInfo.chainType}/${item.nftInfo.id}?origin=notice`)
    }
    const initNotice = async () => {
        let res = await queryNoticeStatus()
        dispatch({
          type: HANDLE_NOTICE_NUM,
          noticeNum: {
            nftactivitynum: res.sale,
            commentnum: res.activity,
            likemenum: 0
          }
        });
    }
    useEffect(() => {
        initNotice()
    }, [])
    return (
        <>
            {/* <div className={styles.notice_title}>Like me</div> */}
            <div className={`${styles.activityList} ${styles.notice_list}`}>
                {
                    loading ? <Spin className={`wp100 mt40`} size="large"></Spin> :
                        <InfiniteScroll
                            dataLength={list.length}
                            next={getList}
                            hasMore={list.length < total}
                            loader={
                                <div style={{ width: 216, textAlign: 'center', margin: '10px auto' }}>
                                    <Spin size={`large`} />
                                </div>
                            }
                        >
                            {
                                list.length > 0 &&list.map(item => (
                                    <div key={item.notificationTime} className={styles.notice_item}>
                                        <div className={styles.item_time}>{moment(item.notificationTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        <div className={`${styles.item_main}`}>
                                            <img className={styles.notice_icon} width={30} src={likeme}></img>
                                            <div className={styles.item_name}>{item.operator.name}</div>
                                            {
                                                item.type === 'like_activity' &&
                                                <div className={`text_hidden_1 ${styles.item_info}`} onClick={()=> {goToActivity(item.activityInfo.id)}}>liked your WOW  "
                                                    <span className='c_blue cp '>{item.activityInfo.content}</span> "
                                                </div>
                                            }
                                            {
                                                item.type === 'like_nft_comment' &&
                                                <div className={`text_hidden_1 ${styles.item_info}`} onClick={()=> {goToComment(item)}}> liked your comment  "
                                                    <span className='c_blue cp '>{item.originalCommentInfo.content}</span> "
                                                </div>
                                            }
                                            {
                                                item.type === 'like_activity_comment' &&
                                                <div className={`text_hidden_1 ${styles.item_info}`} onClick={()=> {goToComment(item)}}> liked your comment  "
                                                    <span className='c_blue cp '>{item.originalCommentInfo.content}</span> "
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                list.length === 0 && <div className={styles.empty}><NoData></NoData></div>
                            }
                        </InfiniteScroll>
                }

            </div>
        </>
    )
}
