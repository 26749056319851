import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton, Affix, Switch, Select, BackTop } from "antd";

import env from '../../env';
import styles from "./styles.module.scss";
import badgeIntro from "../../assets/img/badge/badgeIntro.png"
import bg1 from "assets/img/badge/badge_season.jpg";
import bg2 from "assets/img/badge/badge_christmas.jpg";
import bg3 from "assets/img/badge/badge_sypool.jpg";
import logo1 from "assets/img/badge/sypool.png";
import logo2 from "assets/img/badge/anniversary.jpg";
import icon from "../../assets/img/badge/icon_num.png";
import arrowLeft from "assets/img/home/arrow_left.png"
import arrowright from "assets/img/home/arrow_right.png"
import { chainTypeImgObj, chains } from '../../utils/networkConnect';
import { ReactComponent as IconCard } from "assets/img/badge/card.svg";
import { ReactComponent as IconList } from "assets/img/badge/list.svg";
import NoData from "components/NoData";
import NftCard from "components/NftCard";
import { useBadgeProjectList } from "../../services/badge"
import { useNftList } from "../../services/nftData"
import BadgeCard from "components/BadgeCard";
import EventItem from 'components/EventItem';

const { Option } = Select;
export default function BadgeEntry() {
  const history = useHistory()
  const { t } = useTranslation()
  const { show, setShow } = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [nftPageNo, setNftPageNo] = useState(1)
  const [allNftChain, setAllNftChain] = useState('')
  const [loading, setLoading] = useState(false)
  const [fixed, setFixed] = useState(false)
  const [nftType, setNftType] = useState('')
  const [nftChain, setNftChain] = useState('')
  const [onlyClaimable, setOnlyClaimable] = useState('')
  const { list, total } = useBadgeProjectList(pageNo, 30, setLoading, nftChain, nftType, onlyClaimable)
  const { list: nftList, total: nftTotal } = useNftList(nftPageNo, 30, { nftType: 'badge', nftChain: allNftChain, saleType: '', search: '' }, setLoading)
  const [badgeList, setBadgeList] = useState([])
  const [currentTab, setCurrentTab] = useState('event')
  const [currentLayout, setCurrentLayout] = useState('card')
  const loadingList = [1, 2, 3, 4, 5, 6]

  const initData = () => {
    if (!!nftChain || !!nftType || !!onlyClaimable) {
      setBadgeList(list)
      return false
    }
    let arr = [
      { name: "Popo at Christmas and New Year", project: "sypool-xmas", claimType: 'nftPlus', total: 300, image: bg2, logo: logo1, chainType: 'bsc', status: "ended" },
      { name: "SYPool Blind Boxes", project: "sypool", claimType: 'nftPlus', total: 492, image: bg3, logo: logo1, chainType: 'bsc', status: "ended" },
      { name: "DeFine Seasons Collection", project: "season", claimType: 'nftPlus', total: 500, image: bg1, logo: logo2, chainType: 'mainnet', status: "ended" },
    ]
    setBadgeList(list.concat(arr))
  }
  const goToNext = (item) => {
    if (item.claimType === 'blindbox') {
      history.push(`/blindbadge/${item.project}`)
    } else if (item.claimType === 'nftPlus') {
      history.push(`/${item.project}`)
    } else {
      if (item.types.length > 1) {
        history.push(`/badge/${item.project}`)
      } else {
        history.push(`/badge/${item.project}/${item.types[0]}`)
      }
    }
  }
  const getList = () => {
    setPageNo(pageNo + 1)
  }
  const getNftList = () => {
    setNftPageNo(nftPageNo + 1)
  }
  const onAffix = val => {
    setFixed(val)
  }
  const toggleOnSale = (checked) => {
    if (checked) {
      setOnlyClaimable(true)
    } else {
      setOnlyClaimable(false)
    }
  }
  const handleChainChange = (val) => {
    setPageNo(1)
    setNftChain(val)
  }
  const handleNftChainChange = (val) => {
    setNftPageNo(1)
    setAllNftChain(val)
  }
  const handleTypeChange = (val) => {
    setPageNo(1)
    setNftType(val)
  }

  const handleTab = (val) => {
    setCurrentTab(val)
  }
  const changeLayout = (val) => {
    setCurrentLayout(val)
  }

  useEffect(() => {
    initData()
  }, [list, nftChain, nftType, onlyClaimable])
  return (
    <>
      <BackTop style={{ bottom: 90 }} />
      <div className={styles.top_box}>
        <Affix offsetTop={0} onChange={onAffix}>
          <div className={fixed ? styles.box_bg : styles.no_bg}>
            <div className={`${styles.search_box} ${styles.content_box}`}>
              <div className={`${styles.top_title} page_content_title`}>
                <div onClick={() => {handleTab('event')}} className={`${styles.tab} ${currentTab === 'event' ? styles.tab_active : ''}`}>Event</div>
                <div className={styles.fence}></div>
                <div onClick={() => {handleTab('badge')}} className={`${styles.tab} ${currentTab === 'badge' ? styles.tab_active : ''}`}>Badge</div>
              </div>
              {/* {
                currentTab === 'event' &&
                <div className='fs14'>
                  <Switch className={styles.switch} onChange={toggleOnSale} />
                  <span className="ml8">Available to claim</span>
                </div>
              } */}
              <div className='df'>
              <Select
                className={`${styles.select} ${currentTab === 'event' ? styles.select_mg : styles.select_nft}`}
                value={currentTab === 'event' ? nftChain : allNftChain}
                onChange={(val) => {currentTab === 'event' ? handleChainChange(val) : handleNftChainChange(val)}}
              >
                <Option key="all" value="">
                  {'All chains'}
                </Option>
                {
                  chains.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
              {
                currentTab === 'event' &&
                <Select className={`${styles.select}`} value={nftType} onChange={handleTypeChange}>
                  <Option key="all" value="">
                    {'All status'}
                  </Option>
                  <Option key="active" value='active'>
                    Active
                  </Option>
                  <Option key="soon" value='soon'>
                    Soon
                  </Option>
                  <Option key="ended" value='ended'>
                    Ended
                  </Option>
                </Select>
              }
              {
                currentTab === 'event' &&
                <div className={styles.select_layout}>
                  <div className={`${currentLayout==='card'?styles.layout_active: styles.layout}`} onClick={()=>{changeLayout('card')}}>
                    <IconCard className={styles.title_icon} fill={`${currentLayout==='card' ? '#FFF' : '#E0CDEB'}`}></IconCard>
                  </div>
                  <div className={`${currentLayout==='list'?styles.layout_active: styles.layout}`} onClick={()=>{changeLayout('list')}}>
                    <IconList className={styles.title_icon} fill={`${currentLayout==='list' ? '#FFF' : '#E0CDEB'}`}></IconList>
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
        </Affix>
      </div>
      <div className={`${styles.content_box}`}>
        {
          loading ?
            <div className={styles.card_module} >
              {
                loadingList.map(v => (
                  <div key={v} className={styles.loading_skeleton}>
                    <Skeleton.Image />
                  </div>
                ))
              }
            </div>
            :
            currentTab === 'event' ?
            <InfiniteScroll
              dataLength={badgeList.length}
              next={getList}
              hasMore={badgeList.length < total}
              loader={
                <div className={styles.card_module} >
                  {
                    currentLayout==='card' && loadingList.map(v => (
                      <div key={v} className={styles.loading_skeleton}>
                        <Skeleton.Image />
                      </div>
                    ))
                  }
                  {
                    currentLayout==='list' && loadingList.map(v => (
                      <div key={v} className={styles.loading_skeleton}>
                        <Skeleton />
                      </div>
                    ))
                  }
                </div>
              }
              className={styles.card_module}
            >
              {
                badgeList.length === 0 && <div className={`wp100 df_center ${styles.marketplace_no_data}`}><NoData></NoData></div>

              }
              {
                 currentLayout==='card' && badgeList.length > 0 && badgeList.map(item => {
                  return (
                    <BadgeCard key={item.project} item={item} />
                  )
                })
              }
              {
                 currentLayout==='list' && badgeList.length > 0 &&
                 <div className={styles.layout_list}>
                  <div className={styles.layout_list_header}>
                    <div className='f2'>Event</div>
                    <div className='f1'><div className='ml40'>Blockchain</div></div>
                    <div className='f2 tac'>Event Period</div>
                    <div className='f1'>Creator</div>
                    <div className='f1 tac'>Status</div>
                  </div>
                  {
                  badgeList.length > 0 && badgeList.map(item => {
                    return (
                      <EventItem key={item.project} item={item} />
                    )
                  })
                  }
                 </div>
              }

            </InfiniteScroll>
            :
            <InfiniteScroll
              dataLength={nftList.length}
              next={getNftList}
              hasMore={nftList.length < nftTotal}
              loader={
                <div className={styles.list_box} >
                  {
                    loadingList.map(v => (
                      <div className={styles.loading_skeleton_nft}>
                        <div className={styles.chainType}>chainType</div>
                        <div className={styles.loading_img}>
                          <Skeleton.Image />
                        </div>
                        <Skeleton active paragraph={{ rows: 1 }}>
                        </Skeleton>
                      </div>
                    ))
                  }
                </div>
              }
              className={styles.card_module}
            >
              {
                nftList.length === 0 && <div className={`wp100 df_center ${styles.marketplace_no_data}`}><NoData></NoData></div>

              }
              {
                nftList.length > 0 && nftList.map(item => (
                  item && <NftCard key={item.contractAddress + item.id + item.chainType} nftType={'badge'} tokenInfo={item} />
                ))
              }

            </InfiniteScroll>
        }
        {/* </div> */}
        {/* <div className={`${styles.badge_des} space-between-h5`}>
          <div>
            <div className={`page_content_title mb20`}>About DeFine Badges</div>
            <div>Badge system is the first stepping stone to the DeFine Social Profile System in which eligible users will receive unique NFTs depending on their on-chain activity and behavior. The rarity of the NFT Badge correlates directly with their “rank” among the community.</div>
            <div className={'mt20 mb20'}>The Badge system can be seen as an NFT based identification feature which is an aggregation of the honor badge NFTs earned through the user's onchain activity history.</div>
          </div>
          <img className={styles.img} src={badgeIntro} />
        </div> */}
      </div>
    </>
  )
}
