import { useEffect } from "react";

import { getQueryString } from "utils/index";


export default function Auth() {
  useEffect(() => {
    window.localStorage.setItem('twitterCode', getQueryString('code'));
    window.close();
  }, [])
  return '';
}
