import React, { useEffect, useState, useContext } from 'react'
import Graphin, { IG6GraphEvent, Utils, Behaviors, GraphinContext, Components } from '@antv/graphin';
import IconLoader from '@antv/graphin-icons';
import { TooltipValue } from '@antv/graphin';
import '@antv/graphin-icons/dist/index.css';
import { Input, message } from 'antd';
import { useNftList } from 'services/nftData';
import { getOwnerList } from "services/badgeDetail";
import { queryOwnerNfts } from "services/nftData"
import { getArtistInfo } from "services/profile";
import { getBadgeInfo } from "services/badgeDetail";
import BackButton from "components/BackButton";
import styles from "./styles.module.scss";

const icons = Graphin.registerFontFamily(IconLoader);
const { Tooltip } = Components;
const { Search } = Input;

export default function G6() {
    const [loading, setLoading] = useState(false)
    const { list, total } = useNftList(1, 1, { nftType: "badge" }, setLoading)
    const defaultBadgeType = "HALLOWEEN-DEFINE"
    const [graphData, setGraphDate] = useState({})
    const [backType, setBackType] = useState('')
    const [backValue, setBackValue] = useState('')
    const layout = {
        type: 'graphin-force',
        animation: false,
        // center: [document.body.clientWidth /2, document.body.clientHeight/2]
    };

    const onSearch = (value) => {
        // console.log(value);
        let reg = /^0x/
        if (reg.test(value)) {
            dealOwnerCharts(value)
        }
        let reg2 = /-/
        if (reg2.test(value)) {
            dealChartsData(value)
        }
    }
    const dealOwnerCharts = (ownerAdress) => {
        let obj = {
            "nodes": [],
            "edges": []
        }
        getArtistInfo(ownerAdress).then(res => {
            let owner = {
                id: 'user' + '/' + ownerAdress,
                address: ownerAdress,
                relationType: 'user',
                relation: ownerAdress,
                style: {
                    keyshape: {
                        lineWidth: 0,
                        size: 60,
                        fill: "#fff",
                    },
                    icon: {
                        type: 'font',
                        fontFamily: 'graphin',
                        value: icons.user,
                        fill: 'gary',
                        size: 20,
                    },
                }

            }
            obj.nodes.push(owner)

            const ownerNfts = async () => {
                const res = await queryOwnerNfts(1, 200, { owner: ownerAdress })
                res.length > 0 && res.forEach((e, idx) => {
                    let nft = {
                        id: 'nft' + idx,
                        name: e.name,
                        chainType: e.chainType,
                        nftType: e.type,
                        image: e.image,
                        relationType: 'user',
                        relation: ownerAdress,
                        style: {
                            label: {
                                value: ''
                            },
                            keyshape: {
                                size: 20,
                                stroke: '#fff',
                                fill: '#fff',
                            },
                            icon: {
                                type: "image",
                                value: e.image,
                                size: [60, 60]
                            }

                        }
                    }
                    obj.nodes.push(nft)
                    let relation = {
                        source: 'user' + '/' + ownerAdress,
                        target: 'nft' + idx,
                        style: {
                            keyshape: {

                                stroke: '#f9f9f92e',
                            }
                        }
                    }
                    obj.edges.push(relation)
                })
                // console.log(obj);
                setTimeout(() => {
                    setGraphDate(obj)
                }, 1500)
            }
            ownerNfts()
            message.success('success')

        }).catch(error => {
            // console.log(error);
            message.error(error)
        })
    }

    const dealChartsData = (nftType) => {
        let obj = {
            "nodes": [],
            "edges": []
        }
        getBadgeInfo(nftType).then(res => {
            let nft = {
                id: 'nft' + 0 + res.type,
                name: `${res.name}(${res.chainType})`,
                chainType: res.chainType,
                nftType: res.type,
                image: res.image,
                relationType: 'nft',
                style: {
                    keyshape: {
                        lineWidth: 0,
                        size: 60,
                        fill: "#1f1f1f",
                    },
                    icon: {
                        type: "image",
                        value: res.image,
                        size: [60, 60]
                    }
                }
            }
            obj.nodes.push(nft)
            const dealOwner = async (index, type) => {
                const res = await getOwnerList({ current: 1, pageSize: 100 }, type)
                res.list.length > 0 && res.list.forEach((e, idx) => {
                    let owner = {
                        id: 'user' + type + idx + '/' + e.address,
                        address: e.address,
                        relationType: 'nft',
                        relation: nftType,
                        style: {
                            keyshape: {
                                size: 20,
                                stroke: '#fff',
                                fill: '#fff',
                            },
                            icon: {
                                type: 'font',
                                fontFamily: 'graphin',
                                value: icons.user,
                                fill: 'gary',
                                size: 20,
                            },
                        }
                    }
                    obj.nodes.push(owner)
                    let relation = {
                        source: 'nft' + index + type,
                        target: 'user' + type + idx + '/' + e.address,
                        style: {
                            keyshape: {
                                stroke: '#f9f9f92e',
                            }
                        }
                    }
                    obj.edges.push(relation)
                })
                // console.log(obj);
                setTimeout(() => {
                    setGraphDate(obj)
                }, 1500)
            }
            dealOwner(0, res.type)
            message.success('success')
        }).catch(error => {
            // console.log(error);
            message.error(error)
        })

    }

    const SampleBehavior = () => {
        const { graph, apis } = useContext(GraphinContext);

        useEffect(() => {
            const handleClick = (evt) => {
                const node = evt.item;
                const { model } = evt.item._cfg;
                console.log(model);
                let reg = /^nft/
                if (reg.test(model.id)) {
                    if (model.relationType === 'user') {
                        setBackType('user')
                        setBackValue(model.relation)
                        dealChartsData(model.nftType)
                    }
                    return null
                }
                let reg2 = /^user/
                if (reg2.test(model.id)) {
                    if (model.relationType === 'nft') {
                        setBackType('nft')
                        setBackValue(model.relation)
                        dealOwnerCharts(model.address)
                    }
                    return null
                }

            };
            // 每次点击聚焦到点击节点上
            graph.on('node:click', handleClick);
            return () => {
                graph.off('node:click', handleClick);
            };
        }, []);
        return null;
    };
    const filterAddress = (model) => {
        let reg = /^nft/
        if (reg.test(model.id)) {
            return `${model.name}(${model.chainType})`
        }
        let reg2 = /^user/
        if (reg2.test(model.id)) {
            return model.address
        }

    }

    const backRelation = () => {
        if (backType === "user") {
            setBackType('')
            dealOwnerCharts(backValue)
        }
        if (backType === "nft") {
            setBackType('')
            dealChartsData(backValue)
        }
    }

    useEffect(() => {
        dealChartsData(defaultBadgeType)
    }, [list])
    return (
        <div className={styles.relation2D}>
            <div className='p20'>
                <Search className={styles.graph_search} placeholder="input address" onSearch={onSearch} />
            </div>
            <div className='pl20 pb10'>
                {
                    backType &&
                    <BackButton onClick={backRelation}></BackButton>
                }
            </div>
            <Graphin fitCenter={true} width={document.body.clientWidth} height={document.body.clientHeight - 80} data={graphData} theme={{ mode: 'dark' }} layout={layout} >
                <Tooltip bindType="node" >
                    {(value) => {
                        // console.log(value);
                        if (value && value.model) {
                            const { model } = value;
                            return (
                                <div className='bg_fff'>
                                    <div className={`${styles.w320} fs12 bg_fff p6`}> {filterAddress(model)}</div>
                                </div>
                            );
                        }
                        return null;
                    }}
                </Tooltip>
                <SampleBehavior></SampleBehavior>
            </Graphin>
        </div>
    )
}
