import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Form, Steps, message, Button, Select, } from 'antd'
import Web3 from "web3";

import { getEventByProject, addContractAddress, getContractAddress, addBadge, publishEvent, queryAccount, queryBaseGasFee } from '../../../services/badgeManage'
import { getBadgeListByProject } from '../../../services/badge'
import moment from 'moment';
import { useActiveWeb3React } from "../../../web3";
import { getChainType } from "../../../web3/address";
import { deployDeFineBadgeForBlindBox, getDeFineBadgeForBlindBoxContract } from '../../../utils/handleContract'
import { mainContext } from "../../../reducer";
import { chainTxtObj, chainFun } from '../../../utils/networkConnect';
import { useActiveTronWeb } from "hooks/activeTronWeb";
import styles from '../style.module.scss'

import {
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  waitingForConfirm,
} from "../../../const";
import BigNumber from 'bignumber.js';

const { Step } = Steps;
const { Option } = Select;

const eligibilityName = {
  all: 'All Users',
  task: 'Task',
  whitelist: 'WhiteList'
}
const tokenObj = {
  mainnet: 'ETH',
  goerli: 'ETH',
  bsc: 'BNB',
  bsctestnet: 'BNB',
  polygon: 'MATIC',
  mumbai: 'MATIC',
  optimism: 'ETH',
  optimisticgoerli: 'ETH',
  arbitrum: 'ETH',
  nitrogoerli: 'ETH',
  zksyncera:'ETH',
  zksynceratest:'ETH',
  base:'ETH',
  basegoerli:'ETH',
  loot:'AGLD',
  loottest:'AGLD',
}

export default function Publish() {
  const { eventId } = useParams()
  const { dispatch } = useContext(mainContext);
  const history = useHistory()
  const [stepBox, setStepBox] = useState(1);
  const [eventInfo, setEventInfo] = useState({})
  const [badgeList, setBadgeList] = useState([])
  const [groupList, setGroupList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [currentAddress, setCurrentAddress] = useState('')
  const [disableSelect, setDisableSelect] = useState(false)
  const [succeedAll, setSucceedAll] = useState(true)
  const { library, account, chainId, active } = useActiveWeb3React()
  const { tronLibrary, tronAccount, tronActive } = useActiveTronWeb();
  const [executionAddress,setExecutionAddress] = useState()
  const [executionBalance,setExecutionBalance] = useState()
  const [publishStep,setPublishStep] = useState()
  const [gasFee,setGasFee] = useState()
  const [allCount,setAllCount] = useState()
  const [gasPrice,setGasPrice] = useState()
  const [publishStepStatus, setPublishStepStatus] = useState('wait')

  const goBack = () => {
    history.goBack()
  }
  const nextStep = (step) => {
    setStepBox(step)
  }

  const getEventInfo = async () => {
    let res = await getEventByProject(eventId)
    setGroupList([])
    if (res.groups) {
      setGroupList(res.groups)
    }
    setEventInfo(res)
    getContractAddressList(res.chainType)
    return res
  }
  const getList = async () => {
    let res = await getBadgeListByProject(eventId)
    let num = 0
    let count = 0
    res.list.map(item => {
      if (item.transactionHash) {
        setDisableSelect(true)
        setCurrentAddress(item.contractAddress)
        num++
        setPublishStep(1)
        setPublishStepStatus('process')
      }
      count += item.maxCount
    })
    setAllCount(count)
    if (res.list.length === num) {
      setSucceedAll(false)
      setPublishStepStatus('finish')
    }
    setBadgeList(res.list)
  }
  const beforeDeploy = () => {
    let chainType = getChainType(chainId)
    if (eventInfo.chainType !== chainType) {
      message.error(`please switch to ${chainTxtObj[eventInfo.chainType]}`)
      chainFun[chainTxtObj[eventInfo.chainType]] && chainFun[chainTxtObj[eventInfo.chainType]]()
      return false
    }
    if (eventInfo.creator !== account) {
      message.error("The account is not the creator")
      return false
    }
    return true
  }
  const deployContract = async () => {
    if (!beforeDeploy()) return false
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    let res = await deployDeFineBadgeForBlindBox(library, account, eventInfo.chainType)
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { show: false }
    });
    message.success("success")
    if (res) {
      saveContractAddress(res)
    }
  }
  const saveContractAddress = async (contractInfo) => {
    let obj = {
      address: contractInfo.address,
      chainType: eventInfo.chainType,
      type: 'badge1155',
      transactionHash: contractInfo.transactionHash,
      blockNumber: contractInfo.blockNumber,
    }

    let res = await addContractAddress(obj)
    getContractAddressList(eventInfo.chainType)
  }
  const getContractAddressList = async (chainType) => {
    let list = await getContractAddress(chainType, 'badge1155')
    setAddressList(list)
  }
  const selectContract = async (e) => {
    setCurrentAddress(e)
    setPublishStep(1)
    setPublishStepStatus('process')
  }
  const deployBadge = async (item) => {
    if (!beforeDeploy()) return false
    if (!currentAddress) { return message.error('Please create or select a contract') }
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    const myContract = await getDeFineBadgeForBlindBoxContract(library, currentAddress)
    // console.log(myContract);
    myContract.methods.newBadge(item.type, item.maxCount, item.claimStartTime / 1000, item.claimEndTime / 1000, 1, eventInfo.type==='airdrop'?executionAddress:currentAddress)
      .send({ from: account })
      .on('transcationHash', () => { })
      .on('receipt', (receipt) => {
        addAddressToBadge(item.type, receipt)
      })
      .on('error', () => { })
  }
  const addAddressToBadge = async (originalBadgeType, receipt) => {
    let formData = new FormData();
    formData.append('originalBadgeType', originalBadgeType)
    formData.append('contract', currentAddress)
    formData.append('transactionHash', receipt.transactionHash)
    let res = await addBadge(eventId, formData)
    if (res) {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
      message.success("Success")
      getList()
    }
  }
  const publish = async () => {
    let res = await publishEvent(eventId)
    if (res) {
      message.success("Publish successed")
      history.push('/badgeManage')
    }
  }
  const queryExecutionAccount = async () => {
    let res = await queryAccount()
    setExecutionAddress(res.address)
    queryBalance(res.address)
    if (res.gasPrices[eventInfo.chainType]) {
      setGasPrice(res.gasPrices[eventInfo.chainType])
      getGasFeeBase(eventInfo.chainType,res.gasPrices[eventInfo.chainType]).then(baseGasInfo => {
        countGaeFee(allCount, res.gasPrices[eventInfo.chainType], baseGasInfo.gasPrice, baseGasInfo.gasFee)
      })
    }else {
      getGasFeeBase(eventInfo.chainType,res.gasPrices[eventInfo.chainType]).then(baseGasInfo => {
        countGaeFee(allCount, baseGasInfo.gasPrice, baseGasInfo.gasPrice, baseGasInfo.gasFee)
      })
    }
  }
  const queryBalance = (address) => {
    if (window.ethereum && active) {
      const web3 = new Web3(library.provider);
      web3.eth.getBalance(address).then(res => {
        setExecutionBalance(res)
      })
    } else if (window.tronWeb && tronActive) {
      window.tronWeb.trx.getBalance(address).then(res => {
        setExecutionBalance(res)
      })
    }
  }
  const getGasFeeBase = async (chainType, gwei) => {
    let res = await queryBaseGasFee(chainType)
    if(!gwei) {
      setGasPrice(res.gasPrice)
    }
    return res
  }
  const countGaeFee = (maxCount, gwei,baseGwei,baseOneGasFee) => {
    let oneGasFee = new BigNumber(new BigNumber(gwei).div(new BigNumber(baseGwei)).multipliedBy(new BigNumber(baseOneGasFee))).toFixed()
    const gasFees = new BigNumber(maxCount * oneGasFee).toFixed()
    setGasFee(gasFees)
  }
  const formatBigNumberDecimal = (bigNumber) => {
    return new BigNumber(bigNumber).div(new BigNumber(10).pow(18)).toFixed()
  }
  const countPriceToGwei = (price) => {
    return new BigNumber(price).div(new BigNumber(10).pow(9)).toFixed()
  }
  const countExpectedGasFee = (gasFee, executionBalance) => {
    if (gasFee && executionBalance) {
      if(formatBigNumberDecimal(new BigNumber(gasFee).minus(new BigNumber(executionBalance)))>0){
        return formatBigNumberDecimal(new BigNumber(gasFee).minus(new BigNumber(executionBalance)))
      }else {
        return 0
      }
    }
  }
  const transferGas = () => {
    if (!beforeDeploy()) return false
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    const web3 = new Web3(library.provider);
    let exp = new BigNumber(new BigNumber(gasFee).minus(new BigNumber(executionBalance))).toFixed()
    let obj = {
      from: account,
      to: executionAddress,
      value: exp
    }
    web3.eth.sendTransaction(obj).then(res => {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
    }).catch(error => {
      
    })
  }
  useEffect(() => {
    Promise.all([getEventInfo(),getList()]).then(res => {
    })
  }, [])
  useEffect(()=>{
    if (eventInfo && allCount) {
      if(eventInfo.type === 'airdrop') {
        queryExecutionAccount()
      }
    }
  },[eventInfo,allCount])
  return (
    <div className={styles.createBadge}>
      <div className='space-between-center'>
        <div className={`fs36 fw600`}>Publish <span className='fs20 fw500'>/ {eventId}</span></div>
        <Steps className={styles.steps} current={stepBox - 1}>
          <Step />
          <Step />
        </Steps>
      </div>
      <Form layout={'vertical'}>
        <div className={`${styles.step_box} ${stepBox === 1 ? styles.step_box_show : styles.step_box_hidden}`}>
          <div className={styles.box_header}>Step1 Information</div>
          <div className={styles.box_content}>
            <div className={'space-between'}>
              <div className={`${styles.publishInfo_item} mr10`}>
                <div>Blockchain</div>
                <div className='fw600'>{chainTxtObj[eventInfo.chainType]}</div>
              </div>
              <div className={styles.publishInfo_item}>
                <div>Eligibility to participate in the quest</div>
                <div className='fw600'>{eligibilityName[eventInfo.eligibility]}</div>
              </div>
            </div>
            <div className={'space-between'}>
              <div className={`${styles.publishInfo_item} mr10`}>
                <div>Event type </div>
                <div className='fw600'>{eventInfo.type === 'general' ? 'General' : eventInfo.type === 'airdrop' ? 'Airdrop' : 'Mystery Box'}</div>
              </div>
              <div className={styles.publishInfo_item}>
                <div>Event ID</div>
                <div className='fw600'>{eventId}</div>
              </div>
            </div>
            <div className={'space-between'}>
              <div className={`${styles.publishInfo_item} mr10`}>
                <div>Start time</div>
                <div className='fw600'>{moment(eventInfo.eventStartTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
              <div className={styles.publishInfo_item}>
                <div>End time</div>
                <div className='fw600'>{moment(eventInfo.eventEndTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
            </div>

            <div className={styles.publish_badges}>
              <div className={styles.table_title}>
                <div className='f2'>Badge</div>
                <div className='f2'>Claiming time</div>
                <div className='f1'>Max amount</div>
              </div>
              {
                groupList.map((li, idx) => (
                  <div className={styles.badgeList}>
                    {/* <div className={styles.table_group}>{li.title}</div> */}
                    <div className={styles.table_group} style={{ display: badgeList.find((n) => n.group === li.title) ? 'block' : 'none' }}>{badgeList.length > 0 && badgeList.find((n) => n.group === li.title)?.group}</div>
                    <div>
                      {
                        badgeList.map((item, index) => (
                          <>
                            {
                              item.group === li.title &&
                              <div className={`${styles.table_item} ${index % 2 > 0 ? styles.bg_gray : styles.bg_white}`}>
                                <div className='f2 df_align_center'>
                                  <img className={styles.item_img} width={60} src={item.image}></img>
                                  <div className={'pl20 pr20'}>{item.name}</div>
                                </div>
                                <div className='f2'>{moment(item.claimStartTime).format("YYYY-MM-DD HH:mm:ss")}-{moment(item.claimEndTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                                <div className='f1'>{item.maxCount}</div>
                              </div>
                            }
                          </>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className='space-between-center p30'>
            <Button className={`w200 h50 btn_border`} onClick={goBack}>Back</Button>
            <Button type="primary" className={`w200 h50`} onClick={() => { nextStep(2) }}>Next</Button>
          </div>
        </div>
        <div className={`${styles.step_box} ${stepBox === 2 ? styles.step_box_show : styles.step_box_hidden}`}>
          <div className={styles.box_header}>Step2 Contract</div>
          <div className={`${styles.box_content} ${styles.publish_contract}`}>
            <div className='f1'>
              <div className='df'>
                <Steps className={styles.steps_vertical} direction="vertical" current={publishStep}>
                  <Step></Step>
                  {
                    eventInfo.type === 'airdrop' &&
                    <Step status={publishStepStatus}></Step>
                  }
                </Steps>
                <div className='pl20 pr20'>
                  <div className='fs18'>Select or create a mystery box contracts</div>
                  <div className={styles.text_tip}>A badge contract can be used multiple times after successfully being created.</div>
                  <Select disabled={disableSelect} value={currentAddress} className='mt10 mb10 wp100' onChange={selectContract}>
                    {
                      addressList.map(item => (
                        <Option value={item.address}>{item.address}</Option>
                      ))
                    }
                  </Select>
                  <div>
                    <Button disabled={disableSelect} className='wp100 h40' type={'primary'} onClick={deployContract}>Create badge contract</Button>
                  </div>
                  <div className='mt40 mb20 fs18'>Deploy badges on the blockchain</div>
                </div>
              </div>
              {
                eventInfo.type === 'airdrop' &&
                <div className='f1 pl60 pr40'>
                  <div className={styles.publish_badges}>
                    {
                      groupList.map((li, idx) => (
                        <div className={styles.badgeList}>
                          {/* <div className={styles.table_group}>{li.title}</div> */}
                          <div className={styles.table_group} style={{ display: badgeList.find((n) => n.group === li.title) ? 'block' : 'none' }}>{badgeList.length > 0 && badgeList.find((n) => n.group === li.title)?.group}</div>

                          <div>
                            {
                              badgeList.map((item, index) => (
                                <>
                                  {
                                    item.group === li.title &&
                                    <div className={`${styles.table_item} ${index % 2 > 0 ? styles.bg_gray : styles.bg_white}`}>
                                      <div className='f2 df_align_center'>
                                        <img className={styles.item_img} width={60} src={item.image}></img>
                                        <div className={'pl20 pr20'}>
                                          <div className='fs16 text_hidden_1'>{item.name}</div>
                                          <div>{item.maxCount}</div>
                                        </div>
                                      </div>
                                      <div className='f1 tac'>
                                        {
                                          item.transactionHash ?
                                            <Button className={`btn_border w100 h30 ${styles.btn_success}`}>Succeed</Button> :
                                            <Button className={`btn_border w100 h30`} onClick={() => { deployBadge(item) }}>Deploy</Button>
                                        }
                                      </div>
                                    </div>
                                  }
                                </>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
            </div>
            {
              eventInfo.type === 'general' &&
              <div className='f1'>
                <div className={styles.publish_badges}>
                  <div className={styles.table_title}>
                    <div>Deploy badges on the blockchain</div>
                  </div>
                  {
                    groupList.map((li, idx) => (
                      <div className={styles.badgeList}>
                        {/* <div className={styles.table_group}>{li.title}</div> */}
                        <div className={styles.table_group} style={{ display: badgeList.find((n) => n.group === li.title) ? 'block' : 'none' }}>{badgeList.length > 0 && badgeList.find((n) => n.group === li.title)?.group}</div>

                        <div>
                          {
                            badgeList.map((item, index) => (
                              <>
                                {
                                  item.group === li.title &&
                                  <div className={`${styles.table_item} ${index % 2 > 0 ? styles.bg_gray : styles.bg_white}`}>
                                    <div className='f2 df_align_center'>
                                      <img className={styles.item_img} width={60} src={item.image}></img>
                                      <div className={'pl20 pr20'}>
                                        <div className='fs16 text_hidden_1'>{item.name}</div>
                                        <div>{item.maxCount}</div>
                                      </div>
                                    </div>
                                    <div className='f1 tac'>
                                      {
                                        item.transactionHash ?
                                          <Button className={`btn_border w100 h30 ${styles.btn_success}`}>Succeed</Button> :
                                          <Button className={`btn_border w100 h30`} onClick={() => { deployBadge(item) }}>Deploy</Button>
                                      }
                                    </div>
                                  </div>
                                }
                              </>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
            {
              eventInfo.type === 'airdrop' &&
              <div className='f1 df fs18'>
                {/* <div className={styles.airdrop_step3}>3</div> */}
                <div className={styles.transfer_tip}>
                  <div className='fs18'>It is estimated that this airdrop will consume <span className='fw600'>{formatBigNumberDecimal(gasFee)} {tokenObj[eventInfo.chainType]}</span>, GasPrice: <span className='fw600'>{countPriceToGwei(gasPrice)}</span> GWEI, please transfer the required Gas fee to the execution address. After the event ends, the unconsumed gas fee can be claimed on the event management page.</div>
                  <div className='mt10 mb10 fs18'>* Insufficient Gas fee will cause the airdrop to fail.</div>
                  <div className={styles.airdrop_address}>
                    <div className='c_9a'>Execution address</div>
                    <div className='fw600 mt4'>{executionAddress}</div>
                    <div className='c_9a mt12'>Current balance</div>
                    <div className='fw600 mt4'>{formatBigNumberDecimal(executionBalance)} {tokenObj[eventInfo.chainType]}</div>
                  </div>
                  <div className='space-between-center mt10'>
                    <div className={`${styles.text_tip} w200`}>Skip,set on the badge page dashboard later</div>
                    <Button disabled={!countExpectedGasFee(gasFee,executionBalance)>0} className='btn_border w140' onClick={transferGas}>Transfer Gas</Button>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className='space-between-center p30'>
            <Button className={`btn_border w200 h50`} onClick={() => { nextStep(1) }}>Prev</Button>
            <Button disabled={succeedAll} type={'primary'} className={`${styles.create_btn} w200 h50`} onClick={publish}>Publish This Event</Button>
          </div>
        </div>
      </Form>
    </div>
  )
}
