import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { message } from 'antd';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { useFarmList } from "../../services/farm.js";
import { useActiveWeb3React } from "../../web3"
import { formatAmountWithDecimal } from "../../utils/format";
import { setupEthMainnet } from '../../utils/networkConnect';
import styles from "./styles.module.scss";

import dfa from "../../assets/img/dfa.png";
import translate from "../../assets/img/fram/translate.png";


export default function Stake() {
    const history = useHistory();
    const { t } = useTranslation()
    const { chainId } = useActiveWeb3React()
    const stakeList = useFarmList()

    const dealBigNum = (num, decimals, xs) => {
        return formatAmountWithDecimal(num, decimals, xs)
    }
    const goToNext = (item) => {
        if (chainId !== 1 && chainId !== 4) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        } else {
            history.push(`/farm/${item.chainType}/${item.contractAddress}/${item.poolId}`)
        }
    }

    useEffect(() => {
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(meta);

      return () => {
        meta.parentNode.removeChild(meta);
      }
    }, [])

    return (
        <div className={`page_content_h5`}>
            <div className={`page_content_title`}>Farm</div>
            <section className={`mt50 ${styles.card_list}`}>
                {
                    stakeList.length > 0 && stakeList.map(item => (
                        <div key={item.poolId+item.startTime} className={`${styles.card} ${item.status === 'closed' ? null : 'cp'} ${item.status === 'closed' ? styles.card_closed : null}`} onClick={() => { goToNext(item) }}>
                            <div className={`tac df ${styles.card_img}`}>
                                <div className={styles.card_img_stake}><img src={item.stakeToken.image} width={40}></img></div>
                                <div className={styles.card_img_trans}><img src={translate} width={30}></img></div>
                                <div className={styles.card_img_earn}><img src={item.rewardToken.image} width={40}></img></div>
                                {/* <img src={item.img} width={120}></img> */}
                            </div>
                            {
                                (item.status === 'closed') && <div className={`${styles.tag_closed}`}>Closed</div>
                            }
                            {
                                (item.status === 'active') && <div className={`${styles.tag_active}`}>
                                    <div className={styles.active_txt}>Active</div>
                                </div>
                            }

                            <div className={`mt40 fs24 fw600 tac ${styles.card_title}`}>{t("stake_title", { stakeName: item.stakeToken.symbol, rewardName: item.rewardToken.symbol })}</div>
                            
                            <div className={`mt20 tac p10 ${styles.card_main}`}>
                                <div className={`f1`}>
                                    <span className={`f1`}>APY</span>
                                    {/* <div className={`f1 fw600 ${styles.card_fs}`}>{dealBigNum(item.baseApy, 0, 2)}%~{dealBigNum(item.maxApy, 0, 2)}%</div> */}
                                    <div className={`f1 fw600 ${styles.card_fs}`}>{dealBigNum(item.maxApy, 0, 2)}%</div>
                                </div>
                                <div className={`f1`}>
                                    <span className={`f1`}>Earned</span>
                                    <div className={`f1 fw600 ${styles.card_fs} ${styles.flex_v_center}`}><img src={dfa} width={28} height={28}></img><span className={`ml10`}>{item.userStakeInfo ? dealBigNum(item.userStakeInfo.totalEarned, item.rewardToken.decimals, 3) : 0} DFA</span></div>
                                </div>
                            </div>
                            <div className={`mt20 space-between`}>
                                <div className={`f1 ${styles.card_time} mr10`}>
                                    <div>
                                        <div className={`fs12`}>{t("badge_sTime")}</div>
                                        <div className={`fs14 fw600`}>{moment(item.startTime * 1000).format("YYYY/MM/DD HH:mm:ss")}</div>
                                    </div>
                                </div>
                                <div className={`f1 ${styles.card_time} `}>
                                    <div className={`fs12`}>{t("badge_eTime")}</div>
                                    <div className={`fs14 fw600`}>{item.continuable?'--':moment(item.endTime * 1000).format("YYYY/MM/DD HH:mm:ss")}</div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </section>
        </div>
    )
}
