import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Row, Col } from 'antd';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import "swiper/swiper-bundle.min.css";
// import 'swiper/css/autoplay';

import styles from "./styles.module.scss";
import bg from "assets/img/halloween/bg.jpg"
import bgPartner from "assets/img/halloween/bg_partner.jpg";
import how from "assets/img/halloween/how.png"
import text from "assets/img/halloween/text.png"
import pump1 from "assets/img/halloween/pump1.png"
import pump2 from "assets/img/halloween/pump2.png"
import bat1 from "assets/img/halloween/bat1.png"
import bat2 from "assets/img/halloween/bat2.png"

import partnerBadge1 from "assets/img/halloween/partner_badge1.png"
import partnerBadge2 from "assets/img/halloween/partner_badge2.png"
import partnerBadge3 from "assets/img/halloween/partner_badge3.png"
import partnerBadge4 from "assets/img/halloween/partner_badge4.png"
import partnerBadge5 from "assets/img/halloween/partner_badge5.png"
import partnerBadge6 from "assets/img/halloween/partner_badge6.png"
import partnerBadge7 from "assets/img/halloween/partner_badge7.png"
import partnerBadge8 from "assets/img/halloween/partner_badge8.png"
import partnerBadge9 from "assets/img/halloween/partner_badge9.png"
import partnerBadge10 from "assets/img/halloween/partner_badge10.png"
import partnerBadge11 from "assets/img/halloween/partner_badge11.png"
import partnerBadge12 from "assets/img/halloween/partner_badge12.png"
import partnerBadge13 from "assets/img/halloween/partner_badge13.png"
import partnerBadge14 from "assets/img/halloween/partner_badge14.png"
import partnerBadge15 from "assets/img/halloween/partner_badge15.png"
import partnerBadge16 from "assets/img/halloween/partner_badge16.png"
import partnerBadge17 from "assets/img/halloween/partner_badge17.png"
import partnerBadge18 from "assets/img/halloween/partner_badge18.png"
import partnerBadge19 from "assets/img/halloween/partner_badge19.png"
import partnerBadge20 from "assets/img/halloween/partner_badge20.png"
import partnerBadge21 from "assets/img/halloween/partner_badge21.png"
import partnerBadge22 from "assets/img/halloween/partner_badge22.png"
import partnerBadge23 from "assets/img/halloween/partner_badge23.png"

import partner1 from "assets/img/halloween/partner1.png"
import partner2 from "assets/img/halloween/partner2.png"
import partner3 from "assets/img/halloween/partner3.png"
import partner4 from "assets/img/halloween/partner4.png"
import partner5 from "assets/img/halloween/partner5.png"
import partner6 from "assets/img/halloween/partner6.png"
import partner7 from "assets/img/halloween/partner7.png"
import partner8 from "assets/img/halloween/partner8.png"
import partner9 from "assets/img/halloween/partner9.png"
import partner10 from "assets/img/halloween/partner10.png"
import partner11 from "assets/img/halloween/partner11.png"
import partner12 from "assets/img/halloween/partner12.png"
import partner13 from "assets/img/halloween/partner13.png"
import partner14 from "assets/img/halloween/partner14.png"
import partner15 from "assets/img/halloween/partner15.png"
import partner16 from "assets/img/halloween/partner16.png"
import partner17 from "assets/img/halloween/partner17.png"
import partner18 from "assets/img/halloween/partner18.png"
import partner19 from "assets/img/halloween/partner19.png"
import partner20 from "assets/img/halloween/partner20.png"
import partner21 from "assets/img/halloween/partner21.png"
import partner22 from "assets/img/halloween/partner22.png"
import partner23 from "assets/img/halloween/partner23.png"


SwiperCore.use([Autoplay])
export default function Anniversary() {

  const history = useHistory();
  const organizerList = [
    { name: 'WeCryptoTogether', src: partnerBadge7, type: 'HALLOWEEN-WECRYPTOTOGETHER' },
    { name: 'KooKooKoob', src: partnerBadge1, type: 'HALLOWEEN-KOOKOOKOOB' },
    { name: 'CryptoNote', src: partnerBadge8, type: 'HALLOWEEN-CRYPTONOTE' },
    { name: 'Chiko in the Box', src: partnerBadge5, type: 'HALLOWEEN-CHIKOINTHEBOX' },
    { name: 'Dolgom Magic', src: partnerBadge4, type: 'HALLOWEEN-DOLGOMMAGIC' },
    { name: 'Rice Been', src: partnerBadge2, type: 'HALLOWEEN-RICEBEEN' },
    { name: 'Sandbank', src: partnerBadge3, type: 'HALLOWEEN-SANDBANK' },
    { name: 'MoneyGrid', src: partnerBadge6, type: 'HALLOWEEN-MONEYGRID' },
    { name: 'iZUMi', src: partnerBadge9, type: 'HALLOWEEN-IZUMI' },
    { name: 'repit!', src: partnerBadge10, type: 'HALLOWEEN-REPIT' },
    { name: 'ZKSpace', src: partnerBadge11, type: 'HALLOWEEN-ZKSPACE' },
    { name: 'Oases', src: partnerBadge12, type: 'HALLOWEEN-OASES' },
    { name: 'Web3.com', src: partnerBadge13, type: 'HALLOWEEN-WEB3' },
    { name: 'ZetaChain', src: partnerBadge14, type: 'HALLOWEEN-ZETACHAIN' },
    { name: 'DWG Guild', src: partnerBadge15, type: 'HALLOWEEN-DWGGUILD' },
    { name: 'TUNA.GG', src: partnerBadge16, type: 'HALLOWEEN-TUNAGG' },
    { name: 'Dynasty', src: partnerBadge17, type: 'HALLOWEEN-DYNASTY' },
    { name: 'EnjoyMyHobby', src: partnerBadge18, type: 'HALLOWEEN-ENJOYMYHOBBY' },
    { name: 'Fire Ant Crypto', src: partnerBadge19, type: 'HALLOWEEN-FIREANTCRYPTO' },
    { name: 'Bug City', src: partnerBadge20, type: 'HALLOWEEN-BUGCITY' },
    { name: 'Starboys', src: partnerBadge21, type: 'HALLOWEEN-STARBOYS' },
    { name: 'Ghosts Bar', src: partnerBadge22, type: 'HALLOWEEN-GHOSTSBAR' },
    { name: 'Cyber Galz', src: partnerBadge23, type: 'HALLOWEEN-CYBERGALZ' },
  ]
  const partnerList = [
    { name: 'WeCryptoTogether', type: 'HALLOWEEN-WECRYPTOTOGETHER', simg: partner7 },
    { name: 'KooKooKoob', type: 'HALLOWEEN-KOOKOOKOOB', simg: partner1 },
    { name: 'CryptoNote', type: 'HALLOWEEN-CRYPTONOTE', simg: partner8 },
    { name: 'Chiko in the Box', type: 'HALLOWEEN-CHIKOINTHEBOX', simg: partner5 },
    { name: 'Dolgom Magic', type: 'HALLOWEEN-DOLGOMMAGIC', simg: partner4 },
    { name: 'Rice Been', type: 'HALLOWEEN-RICEBEEN', simg: partner2 },
    { name: 'Sandbank', type: 'HALLOWEEN-SANDBANK', simg: partner3 },
    { name: 'MoneyGrid', type: 'HALLOWEEN-MONEYGRID', simg: partner6 },
    { name: 'iZUMi', type: 'HALLOWEEN-IZUMI', simg: partner9 },
    { name: 'repit!', type: 'HALLOWEEN-REPIT', simg: partner10 },
    { name: 'ZKSpace', type: 'HALLOWEEN-ZKSPACE', simg: partner11 },
    { name: 'Oases', type: 'HALLOWEEN-OASES', simg: partner12 },
    { name: 'Web3.com', type: 'HALLOWEEN-WEB3', simg: partner13 },
    { name: 'ZetaChain', type: 'HALLOWEEN-ZETACHAIN', simg: partner14 },
    { name: 'DWG Guild', type: 'HALLOWEEN-DWGGUILD', simg: partner15 },
    { name: 'TUNA.GG', type: 'HALLOWEEN-TUNAGG', simg: partner16 },
    { name: 'Dynasty', type: 'HALLOWEEN-DYNASTY', simg: partner17 },
    { name: 'EnjoyMyHobby', type: 'HALLOWEEN-ENJOYMYHOBBY', simg: partner18 },
    { name: 'Fire Ant Crypto', type: 'HALLOWEEN-FIREANTCRYPTO', simg: partner19 },
    { name: 'Bug City', type: 'HALLOWEEN-BUGCITY', simg: partner20 },
    { name: 'Starboys', type: 'HALLOWEEN-STARBOYS', simg: partner21 },
    { name: 'Ghosts Bar', type: 'HALLOWEEN-GHOSTSBAR', simg: partner22 },
    { name: 'Cyber Galz', type: 'HALLOWEEN-CYBERGALZ', simg: partner23 },

  ]
  const [sliderViews, setSliderViews] = useState(5)

  const initData = () => {
    if (document.body.clientWidth < 800) {
      setSliderViews(2)
    } else if (document.body.clientWidth > 800 && document.body.clientWidth < 1145) {
      setSliderViews(3)
    } else if (document.body.clientWidth > 1144 && document.body.clientWidth < 1431) {
      setSliderViews(4)
    } else if (document.body.clientWidth > 1430 && document.body.clientWidth < 1717) {
      setSliderViews(5)
    } else if (document.body.clientWidth > 1716 && document.body.clientWidth < 1921) {
      setSliderViews(6)
    }
  }
  const gotoClaim = (type) => {
    if (type) {
      history.push(`/badge/define-halloween/${type}`)
    }
  }

  useEffect(() => {
    initData()
  })
  return (
    <div className={styles.kbw}>
      <div className={`${styles.page_head_img}`} style={{ backgroundImage: `url(${bg})` }}>
        <div className={styles.head_title}>
          <div className={styles.title}><img className={styles.title_h1} src={text} /></div>
          <div className={styles.subtitle}>To celebrate October, DeFine launches DeFine October Carnival with partners! </div>
        </div>
        <img className={styles.bat1} src={bat1}></img>
        <img className={styles.bat2} src={bat2}></img>
      </div>
      <div className={styles.organizer}>
        <img className={styles.bg_cloud} src={bgPartner} />
        <div className={`c_f fw700 tac ${styles.organizer_title}`}>Partner Badges</div>
        <Swiper className={`df mt20 `}
          autoplay={{
            delay: 0,
            stopOnLastSlide: false,
            disableOnInteraction: true,
          }}
          speed={6000}
          loop={true}
          spaceBetween={0}
          slidesPerView={sliderViews}
          onSwiper={(swiper) => {
            swiper.$el[0].addEventListener('mouseover', () => swiper.autoplay.stop());
            swiper.$el[0].addEventListener('mouseleave', () => swiper.autoplay.start());
          }}
        >
          {
            organizerList.map(item => (
              <SwiperSlide><img onClick={() => { gotoClaim(item.type) }} className={`cp ${styles.slide_img}`} src={item.src}></img></SwiperSlide>
            ))
          }
        </Swiper>
      </div>
      <div className={styles.kbw_body}>
        <div className={`pr ${styles.fd1}`}>
          <img width={263} className={styles.ball1} src={pump1}></img>
          {/* <img width={462} className={styles.line1} src={line1}></img> */}
        </div>
        <div className={styles.how}>
          <div>How to get</div>
          <div>DeFine Halloween Badge ??</div>
        </div>
        <div className={styles.intro}>
          <div className={styles.intro_main}>DeFine will run a joint campaign with partners. Users who complete the tasks of each partner will be eligible to claim a partner badge. When the user collects at least 3 partner badges, the user will receive an airdrop of DeFine Halloween Badge through the Polygon network. DeFine Halloween badge holders will be eligible to a share 10,000 DFA reward pool.</div>
          {/* <div className={styles.more_btn}>
                        <Button className={`btn_border`}>
                            <a target='_blank' href='https://gleam.io/ttXhZ/define-join-our-treasure-hunt-and-win-dfa-tokens?__cf_chl_tk=vd.rMRs2I7XPvfUDTveivUMNmF2ean9fw2BtPmXyeVI-1659595003-0-gaNycGzNB70'>More details &gt;</a></Button>
                    </div> */}
        </div>
        <img className={styles.how_img} src={how}></img>
        <div className={styles.more_btn}>
          <Button className={`btn_border`}>
            <a target="_blank" href="https://medium.com/@defineplatform/define-platform-halloween-carnival-ce060945059">More details &gt;</a></Button>
        </div>

      </div>
      <div className={`pr`}>
        <div className={`pr ${styles.fd2}`}>
          {/* <img className={styles.line2} src={line2}></img> */}
          <img width={218} className={styles.ball2} src={pump2}></img>
        </div>
        <div className={styles.kbw_partner}>
          <div className={styles.partner_title}>DeFine October Carnival Partners</div>
          <Row className={`mt40 ${styles.partnerList}`}>
            {
              partnerList.map(item => (
                <Col xs={12} lg={6}>
                  <div className={styles.partner_box} onClick={() => { gotoClaim(item.type) }} >
                    <img className={styles.partner_img} src={item.simg} width={100}></img>
                    <div className={`mt10 fw700`}>{item.name}</div>
                  </div>
                </Col>
              ))
            }
          </Row>
        </div>

      </div>
    </div>
  )
}
