import React, { useEffect, useState } from "react";
import { BackTop, message, Select, Modal, Spin } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import copy from 'copy-to-clipboard';
import pathToRegexp from "path-to-regexp";
import html2canvas from 'html2canvas';

import { useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import {
  getArtistInfo, getAllNfts, getInfoByHandleName,
  addFeatured, deleteFeatured, updateFeatured, getFeaturedList
} from "services/profile";
import NoData from "components/NoData";
import Avatar from "components/Avatar";
import PublishBox from "components/PublishBox";
import nftTag from '../../assets/img/artist/nftTag.svg'
import website from "assets/img/sidebar/website.svg";
import medium from "assets/img/sidebar/medium.svg";
import twitter from "assets/img/sidebar/twitter.svg";
import ins from "assets/img/sidebar/ins.svg";
import FollowButton from "components/FollowButton";
import NftList from "components/NftList";
import NftCardView from "components/NftCardView";
import RecommendBar from "components/RecommendBar";
import ActivityList from "components/ActivityList";
import { dealUrl, isSelf, getQueryString } from "utils/index";
import styles from "./styles.module.scss";
import defaultBg from "../../assets/img/profile/profile_background.jpg"
import { ReactComponent as ShareIcon } from "../../assets/img/profile/share.svg"
import { ReactComponent as EditIcon } from "../../assets/img/profile/edit.svg"
import { ReactComponent as NailIcon } from "../../assets/img/profile/nail.svg"
import { ReactComponent as ArrowLIcon } from "../../assets/img/profile/w_arrow_l.svg"
import { ReactComponent as ArrowRIcon } from "../../assets/img/profile/w_arrow_r.svg"
import { ReactComponent as DeleteIcon } from "../../assets/img/profile/delete.svg"
import arrowright from "assets/img/profile/arrow_right.png"
import env from '../../env';
import { abbrTxHash } from "../../utils/format";
import copyIcon from "assets/img/header/copy.svg";
import wallet from "assets/img/header/wallet.svg";
import { ReactComponent as EmptyIcon } from 'assets/img/empty.svg';
import { ReactComponent as Tag } from "../../assets/img/artist/tag.svg";
import { ReactComponent as WebsiteIcon } from 'assets/img/socialIcon/website.svg'
import { ReactComponent as DiscordIcon } from 'assets/img/socialIcon/discord.svg'
import { ReactComponent as InstagramIcon } from 'assets/img/socialIcon/instagram.svg'
import { ReactComponent as MediumIcon } from 'assets/img/socialIcon/medium.svg'
import { ReactComponent as TelegramIcon } from 'assets/img/socialIcon/telegram.svg'
import { ReactComponent as TwitterIcon } from 'assets/img/socialIcon/twitter.svg'
import { ReactComponent as WebsiteIconG } from 'assets/img/socialIcon/website_g.svg'
import { ReactComponent as DiscordIconG } from 'assets/img/socialIcon/discord_g.svg'
import { ReactComponent as InstagramIconG } from 'assets/img/socialIcon/instagram_g.svg'
import { ReactComponent as MediumIconG } from 'assets/img/socialIcon/medium_g.svg'
import { ReactComponent as TelegramIconG } from 'assets/img/socialIcon/telegram_g.svg'
import { ReactComponent as TwitterIconG } from 'assets/img/socialIcon/twitter_g.svg'
import { G6Box } from "pages/G6";
import { chainTypeImgObj, chains } from '../../utils/networkConnect';


const { Option } = Select;
const types = []


const Profile = () => {
  const { handleName } = useParams();
  const [currentTab, setCurrentTab] = useState(getQueryString('n') ? 'nfts' : 'featured')
  const [currentType, setCurrentType] = useState('posts')
  const [refresh, setRefresh] = useState(0)
  const [nftType, setNftType] = useState('')
  const [isAllNft, setIsAllNft] = useState(false)
  const [mine, setMine] = useState(false)
  const [allNftList, setAllNftList] = useState([])
  const [allNftHashList, setAllNftHashList] = useState({})
  const [nftChain, setNftChain] = useState('')
  const [saleType, setSaleType] = useState('')
  const [address, setAddress] = useState('');
  const [visible, setVisible] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [featuredList, setFeaturedList] = useState([])
  const [isShare, setIsShare] = useState(false)
  const history = useHistory();
  const location = useLocation();
  const [info, setInfo] = useState({})
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { tronAccount } = useActiveTronWeb()

  const handlePublish = () => {
    setRefresh(old => (old + 1) % 100)
  }

  const handleTabChange = (tab) => {
    setCurrentTab(tab)
  }
  const handleTypeChange = (tab) => {
    setCurrentType(tab)
  }
  const handleChainChange = (val) => {
    setNftChain(val)
  }
  const handleNftTypeChange = (val) => {
    setNftType(val)
  }
  const toggleOnSale = (checked) => {
    if (checked) {
      setSaleType('sale')
    } else {
      setSaleType('')
    }
  }
  const handleCopy = () => {
    copy(info.address);
    message.success('copied!')
  }
  const handleShare = () => {
    copy(window.location.href.split('/profile/')[0] + `/p/${info.handleName}`);
    message.success('Share link copied!')
  }

  const openWeb = (val, pre) => {
    if (val) {
      if (/^http[s]?:\/\//i.test(val)) {
        window.open(val, '_blank')
      } else if (pre) {
        window.open(pre + val, '_blank')
      }
    } else {
      history.push('/setting')
    }
  }

  const openNFTs = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    setShareVisible(false)
  }

  const getFeaturedNFTs = (address) => {
    getFeaturedList(address).then(res => {
      if (res.list) {
        setFeaturedList(res.list)
      }
    })
  }

  const featuredD = (item) => {
    deleteFeatured(item).then(res => {
      if (res === 'success') {
        getFeaturedNFTs(address)
      }
    })
  }
  const featuredUpdate = (item, forward) => {
    updateFeatured(item, forward).then(res => {
      if (res === 'success') {
        getFeaturedNFTs(address)
      }
    })
  }

  const selectNFT = (item) => {
    setVisible(false)
    addFeatured(item).then(res => {
      if (res.order) {
        getFeaturedNFTs(address)
      }
    })
  }

  const shared = () => {
    html2canvas(document.getElementById("share_id"), { allowTaint: true }).then(canvas => {
      document.getElementById('show_id').appendChild(canvas)
    }).catch(err => {
      console.log(err)
    });
  }

  useEffect(() => {
    if (handleName > 24) {
      setAddress(handleName);
    } else {
      getInfoByHandleName(handleName).then(res => {
        setAddress(res.address);
      })
    }
    setCurrentTab(getQueryString('n') ? 'nfts' : 'featured');
  }, [handleName])

  useEffect(() => {
    const getAllNftsFun = address => {
      ['mainnet', 'bsc', 'polygon'].forEach(item => {
        getAllNfts(address, item).then(res => {
          if (res) {
            setAllNftList(old => [...old, ...res]);
            setAllNftHashList(old => {
              const obj = { ...old };
              res.forEach(cell => {
                obj[cell.contractAddress + cell.id] = cell
              })
              return obj;
            })
          }
        })
      })
    }

    if (address) {
      getFeaturedNFTs(address)
      getAllNftsFun(address)
    }
  }, [address])

  useEffect(() => {
    const wallet_type = window.localStorage.getItem('wallet_type')

    if (address) {
      if (wallet_type) {
        // if have wallet_type, user logged in
        if (account || tronAccount) {
          const self = isSelf(address, account, tronAccount);
          setMine(self);
          getArtistInfo(address, account || tronAccount).then(res => {
            setInfo(res)
          })
        }
      } else {
        getArtistInfo(address, account || tronAccount).then(res => {
          setInfo(res)
        })
      }
    }
  }, [account, tronAccount, address])

  useEffect(() => {
    setIsShare(pathToRegexp('/p/:handleName').test(location.pathname))
  }, [location.pathname])

  return (
    <div id="share_id" style={{ marginTop: isShare ? '-65px' : 0 }}>
      <BackTop style={{ bottom: 90 }} />
      <div className={`${styles.top_box}`}>

        <div className={`${styles.info_box}`}>
          <div className={`${styles.right_top_box}`}>
            {
              mine
                ?
                <EditIcon onClick={() => { history.push('/setting') }} className="cp" />
                :
                <FollowButton className={styles.h5_hidden} followed={info.followedByCurrentUser} userId={info.address} />
            }
          </div>
          <div className={`${styles.info_top}`}>
            <div className={`${info.avatarFromOwnedNft ? styles.info_header_box_l : styles.info_header_box}`}>
              <div className={`${styles.info_header}`} style={{ backgroundImage: `url(${info.avatar})` }}></div>
              {info.avatarFromOwnedNft && <img src={nftTag} className={styles.nft_tag} />}
            </div>

            <div>
              <div className={`${styles.name_box}`}>
                {
                  info.artist && <Tag></Tag>
                }
                <span className={`${styles.info_name} ell`} title={info.name}>{info.name}</span>
                <span className={`${styles.info_handle} ell`} title={info.handleName}>@{info.handleName}</span>
              </div>
              <div onClick={handleCopy} className={`df_center ${styles.info_addr}`}>
                <div>
                  <img src={wallet} alt="" />
                  <span className={'pl5'}>{abbrTxHash(info.address)}</span>
                </div>
                <img width={12} src={copyIcon} alt="" />
              </div>
              {
                !mine &&
                <FollowButton className={styles.pc_hidden} followed={info.followedByCurrentUser} userId={info.address} />
              }
              <div className={`df_align_center fs14`}>
                <div><span>{t('Collected')}</span><span className={styles.num}>{info.tokenCount || 0}</span></div>
                <div className={`${styles.column}`}></div>
                <div><span>{t('following')}</span><span className={styles.num}>{info.followingCount}</span></div>
                <div className={`${styles.column}`}></div>
                <div><span>{t('follower')}</span><span className={styles.num}>{info.followerCount}</span></div>
              </div>
            </div>
          </div>

          <div className={`${styles.info_bottom} ells _2 `}>{(!info.bio || info.bio === 'undefined') ? '' : info.bio}</div>
        </div>

        <div className={`${styles.link_box} fs14`}>
          <div className={`${styles.web_box}`}>
            <div className={`df_align_center`}>
              <WebsiteIcon className={styles.web_img} />
              <div onClick={() => { openWeb(info.website || `${window.location.href.split('/profile/')[0]}/p/${info.handleName}`) }} className={`cp ell`}>{info.website || `${window.location.href.split('/profile/')[0]}/p/${info.handleName}`}</div>
            </div>
            {
              (mine || info.instagram) &&
              <div className={`df_align_center`}>
                {
                  info.instagram
                    ?
                    <InstagramIcon className={styles.web_img} />
                    :
                    <InstagramIconG className={styles.web_img} />
                }
                <div onClick={() => { openWeb(info.instagram, 'https://www.instagram.com/') }} className={`${!info.instagram ? styles.add_text : ''} cp ell`}>{info.instagram ? info.instagram : mine ? '+ Add Now' : '--'}</div>
              </div>
            }
            {
              (mine || info.twitter) &&
              <div className={`df_align_center`}>
                {
                  info.twitter
                    ?
                    <TwitterIcon className={styles.web_img} />
                    :
                    <TwitterIconG className={styles.web_img} />
                }
                <div onClick={() => { openWeb(info.twitter, 'https://twitter.com/') }} className={`${!info.twitter ? styles.add_text : ''} cp ell`}>{info.twitter ? info.twitter : mine ? '+ Add Now' : '--'}</div>
              </div>
            }
            {
              (mine || info.discord) &&
              <div className={`df_align_center`}>
                {
                  info.discord
                    ?
                    <DiscordIcon className={styles.web_img} />
                    :
                    <DiscordIconG className={styles.web_img} />
                }
                <div onClick={() => { openWeb(info.discord) }} className={`${!info.discord ? styles.add_text : ''} cp ell`}>{info.discord ? info.discord : mine ? '+ Add Now' : '--'}</div>
              </div>
            }
            {
              (mine || info.telegram) &&
              <div className={`df_align_center`}>
                {
                  info.telegram
                    ?
                    <TelegramIcon className={styles.web_img} />
                    :
                    <TelegramIconG className={styles.web_img} />
                }
                <div onClick={() => { openWeb(info.telegram, 'https://t.me/') }} className={`${!info.telegram ? styles.add_text : ''} cp ell`}>{info.telegram ? info.telegram : mine ? '+ Add Now' : '--'}</div>
              </div>
            }
            {
              (mine || info.medium) &&
              <div className={`df_align_center`}>
                {
                  info.medium
                    ?
                    <MediumIcon className={styles.web_img} />
                    :
                    <MediumIconG className={styles.web_img} />
                }
                <div onClick={() => { openWeb(info.medium) }} style={{ maxWidth: '250px' }} className={`${!info.medium ? styles.add_text : ''} cp ell`}>{info.medium ? info.medium : mine ? '+ Add Now' : '--'}</div>
              </div>
            }
          </div>
          {
            !isShare &&
            <div onClick={handleShare} className={styles.share_box}>
              <ShareIcon />
              <span className="ml5">Share</span>
            </div>
          }
        </div>
      </div>

      <div className={styles.board}>
        <div className={styles.tab_box}>
          <div onClick={() => { handleTabChange('featured') }} className={`${styles.tab_btn} ${currentTab === 'featured' ? styles.tab_active : ''}`}>Featured NFTs</div>
          <div onClick={() => { handleTabChange('badge') }} className={`${styles.tab_btn} ${currentTab === 'badge' ? styles.tab_active : ''}`}>Badge Identities</div>
          {
            mine &&
            <>
              <div onClick={() => { handleTabChange('nfts') }} className={`${styles.tab_btn} ${currentTab === 'nfts' ? styles.tab_active : ''}`}>All NFTs</div>
              <div onClick={() => { handleTabChange('wow') }} className={`${styles.tab_btn} ${currentTab === 'wow' ? styles.tab_active : ''}`}>Wow</div>
              <div onClick={() => { handleTabChange('like') }} className={`${styles.tab_btn} ${currentTab === 'like' ? styles.tab_active : ''}`}>Like</div>
            </>
          }
        </div>
        <div>
          {
            currentTab === "nfts" &&
            <div className={`${styles.nft_tab_box}`}>
              <div className={`${styles.tab_item}`}>
                <div onClick={() => { setIsAllNft(false) }} className={`${styles.type_btn_h5} ${!isAllNft ? styles.type_active : ''}`}>On DeFine</div>
                <div className={styles.fence}></div>
                <div onClick={() => { setIsAllNft(true) }} className={`${styles.type_btn_h5} ${isAllNft ? styles.type_active : ''}`}>In wallet</div>
              </div>
              {
                !isAllNft &&
                <div className={`${styles.tab_item}`}>
                  <Select className={`mr10 ${styles.select_w180}`} value={nftChain} onChange={handleChainChange}>
                    <Option key="all" value="">
                      {'All chains'}
                    </Option>
                    {
                      chains.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))
                    }
                  </Select>
                  <Select className={`${styles.select_w180}`} value={nftType} onChange={handleNftTypeChange}>
                    <Option key="all" value="">
                      {'All types'}
                    </Option>
                    <Option key="token" value='token'>
                      Common
                    </Option>
                    <Option key="badge" value='badge'>
                      Badge
                    </Option>
                    <Option key="warrant" value='warrant'>
                      NFT+
                    </Option>
                  </Select>
                  {/* <Switch checked={!!saleType} onChange={toggleOnSale} />
                  <span className={"fs14 c_l ml16 fw600"}>{t('on_sale')}</span> */}
                </div>
              }
            </div>
          }
        </div>
        <div className={styles.row_box}>
          {
            currentTab === "featured" && info.address &&
            <div className={styles.featured_box}>
              {
                !mine && featuredList.length === 0 &&
                <div className={styles.featured_empty}>
                  <EmptyIcon />
                </div>
              }
              {
                featuredList.map(item => {
                  const obj = allNftHashList[item.contractAddress + item.nftId];
                  if (obj) {
                    return (
                      <div
                        key={item.contractAddress + item.nftId}
                        className={`${styles.featured_item}`}
                      >
                        <div className={styles.featured_content_item}>
                          <img className={styles.item_img} src={obj.image} />
                        </div>
                        {
                          mine &&
                          <div className={styles.featured_handle}>
                            <ArrowLIcon onClick={() => { featuredUpdate(item, 1) }} className="cp" />
                            <span className={styles.featured_fence}></span>
                            <DeleteIcon onClick={() => { featuredD(item) }} className="cp" />
                            <span className={styles.featured_fence}></span>
                            <ArrowRIcon onClick={() => { featuredUpdate(item, 0) }} className="cp" />
                          </div>
                        }
                        <div className={styles.featured_info}>
                          <span className={`${styles.featured_name} ell`}>{obj.name}</span>
                          <img className={styles.card_chain} src={chainTypeImgObj[item.chainType]} />
                        </div>
                      </div>
                    )
                  }
                })
              }
              {
                mine &&
                <div onClick={openNFTs} className={`${styles.featured_item} ${styles.nail_block}`}>
                  <div className={styles.featured_content_item}>
                    <div className={styles.nail_box}>
                      <NailIcon />
                      <div>Pin Your Favorite</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
          {
            currentTab === "badge" && info.address &&
            <div className={styles.g6_box}>
              <G6Box address={info.address} />
              <Link to={`/g6/${info.address}`} className={styles.view_all}>
                <span className="mr4">View All Badge Identities</span>
                <img width={28} src={arrowright} alt="" />
              </Link>
            </div>
          }
          {
            (currentTab === "wow" || currentTab === "like") &&
            <div className={styles.wow_box}>
              {
                info.address &&
                <ActivityList
                  creator={currentTab === "wow" ? info.address : undefined}
                  liker={currentTab === "like" ? info.address : undefined}
                />
              }
            </div>
          }
          {
            currentTab === "nfts" && info.address && (
              !isAllNft
                ?
                <div className={`${styles.nft_box}`} style={{ padding: '12px' }}>
                  <NftList nftType={nftType} nftChain={nftChain} saleType={saleType} owner={info.address} />
                </div>
                :
                <div className={`${styles.view_box} ${styles.nft_box}`}>
                  {
                    allNftList.length === 0 && <div className={`wp100 df_center mt60`}><NoData></NoData></div>
                  }
                  {
                    allNftList.map(item => (
                      item && <NftCardView key={item.contractAddress + item.id + item.chainType} tokenInfo={item} />
                    ))
                  }
                </div>
            )
          }
        </div>
      </div>

      <Modal
        className={styles.nft_modal}
        visible={visible}
        onCancel={handleCancel}
        title={`Select a NFT`}
        footer={null}
      >
        {
          loading
            ?
            <div style={{ width: 216, textAlign: 'center', margin: ' 50px auto' }}>
              <Spin size={`large`} />
            </div>
            :
            <>
              <div className={styles.list_nft_box}>
                {
                  allNftList.length > 0 && allNftList.map(item => (
                    <div key={item.contractAddress + item.id} className={styles.item_nft} onClick={() => { selectNFT(item) }}>
                      <img className={styles.item_img} src={item.image} />
                    </div>
                  ))
                }
              </div>
              {
                allNftList.length === 0 &&
                <div className={`df_column_center hp100 mt-20`}>
                  <EmptyIcon />
                  <span className={`pt20`}>You don’t have any NFT yet.</span>
                  <span>check our markrtplace!</span>
                </div>
              }
            </>
        }
      </Modal>

      <Modal
        className={styles.nft_modal}
        visible={shareVisible}
        onCancel={handleCancel}
        title={`Share`}
        footer={null}
      >
        <div id="show_id"></div>
        <a onClick={shared}>create</a>
      </Modal>
    </div>
  )
}
export default Profile;
