export const celebrityList = [
  // {
  //   id: '295218901',
  //   address: '0x3ddfa8ec3052539b6c9549f12cea2c295cff5296',
  //   name: 'H.E. Justin Sun🌞🇬🇩',
  //   uniqueName: 'justinsuntron',
  //   image: 'https://pbs.twimg.com/profile_images/1490173066357342208/MZyfamFE_400x400.jpg'
  // },
  // {
  //   id: '138372303',
  //   address: '',
  //   name: 'FIFA World Cup',
  //   uniqueName: 'FIFAWorldCup',
  //   image: 'https://pbs.twimg.com/profile_images/1592901266669191169/ADlj4YWv_400x400.jpg'
  // },
  {
    id: '295218901',
    address: '',
    name: 'vitalik.eth',
    uniqueName: 'VitalikButerin',
    image: 'https://pbs.twimg.com/profile_images/977496875887558661/L86xyLF4_400x400.jpg'
  },
  {
    id: '44196397',
    address: '',
    name: 'Elon Musk',
    uniqueName: 'elonmusk',
    image: 'https://pbs.twimg.com/profile_images/1572573363255525377/Xz3fufYY_400x400.jpg'
  },
]

export const otherList = []
