import React from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.scss";
import s1 from "../../assets/img/nft+/sypool1.jpeg";
import s2 from "../../assets/img/nft+/sypoolChrisstmas.jpg";
import season from "../../assets/img/nft+/seasons.jpg";
import icon from "../../assets/img/nft+/icon_num.png";
import eth from "../../assets/img/chain/corner_eth.svg"
import bnb from "../../assets/img/chain/corner_bnb.svg"


export default function Nftplus() {

    const history = useHistory();
    const { t } = useTranslation()
    const list = [
        { type: "sypool-xmas", src: s2, chainType: bnb, title:'Popo at Christmas and New Year',total: 300},
        { type: "sypool", src: s1, chainType: bnb, title:'sypool_blind_boxes',total: 492},
        { type: "season", src: season, chainType: eth, title:'DeFine Seasons Collection',total: 500},
    ]

    const goToNext = (type) => {
        console.log(type)
        history.push(`/${type}`)
    }
    return (
        <div className={`page_content_h5`}>
            <div className={`${styles.financial} ${styles.main_area}`}>
                <div className={`page_content_title mt10`}>NFT+</div>
                <div className={`mt6 mb30`}>Assign special features to each NFT.</div>
                <div className={"space-between-center-h5 wp100"}>
                    {
                        list.map(item => (
                            <div key={item.type} className={`mr40 ${styles.card_main_t}`}>
                                <div className={` pointer ${styles.card}`} onClick={() => { goToNext(item.type) }}>
                                    <div style={{ backgroundImage: `url(${item.src})` }} className={styles.top_img}>
                                        {/* <div className={`fw600 ${styles.chainType}`}> */}
                                            <img width={40} className={`fw600 ${styles.chainType}`} src={item.chainType} />
                                        {/* </div> */}
                                    </div>
                                    <div className={styles.info_box}>
                                        <div className={`tac ${styles.info_title}`}>
                                            {t(item.title)}
                                        </div>
                                    </div>
                                </div>
                                <div className={`tac ${styles.flex_center}`}> <img className={`mr10`} width={27} height={14} src={icon} /> <span>{item.total}</span></div>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.descrption}>
                    <h2>{t('description')}</h2>
                    <p>Non-fungible token (NFT) refers to a unique type of digital asset. The ownership of these assets is circulated on various chains such as on the Ethereum blockchain.</p>
                    <p>From digital goods that exist in the virtual world to physical assets such as clothing or real estate, these items can all be expressed as an NFT. Because of their combination with other financial instruments on the blockchain, anyone can issue, own, and trade NFT’s. Therefore, the efficiency to interact between users and NFT is significantly higher than that of traditional platforms similar to how the payment efficiency of cryptocurrency is higher than traditional finance.</p>
                    <p>NFT PAD directly solves the subjective problem of assets behind an NFT by linking the NFT to the assets. This ultimately provides measurable value for the NFT since it can be redeemed for actual assets.</p>
                </div>
            </div>
        </div>
    )
}
