import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form, Row, Col, Steps, Upload, message, Button, Input, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useActiveWeb3React } from "../../../web3";
import { addBadge, checkBadgeType, getEventByProject, getBadgeDetail } from "../../../services/badgeManage"
import { dataURLtoBlob } from '../../../utils/dataURLtoBlob';
import { chainTypeComImgObj,chainTxtObj } from '../../../utils/networkConnect';
import styles from '../style.module.scss'

const { TextArea } = Input;

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export default function CreateBlindBadge() {
  const { account } = useActiveWeb3React()
  const [formRef] = Form.useForm()
  const history = useHistory()
  const { eventId } = useParams()
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [createBadgeInfo, setCreateBadgeInfo] = useState();
  const [onlyBadgeType, setOnlyBadgeType] = useState();
  const [isEdit, setIsEdit] = useState(false)
  const [eventInfo, setEventInfo] = useState({})
  const location = useLocation();

  const goBack = () => {
    history.goBack()
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
    }
  }
  const blurName = (e) => {
    let str = e.target.value
    if(str){
      let defaultType = (str.replace(/\s/g, '-') + '-' + generateMixed(4)).substring(0, 20)
      formRef.setFieldsValue({ badgeType: defaultType })
      setOnlyBadgeType(true)
    }
  }
  const generateMixed = (n) => {
    let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'a', 'B', 'b', 'C', 'c', 'D', 'd', 'E', 'e', 'F', 'f', 'G', 'g', 'H', 'h', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'O', 'o', 'P', 'p', 'Q', 'q', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'V', 'v', 'W', 'w', 'X', 'x', 'Y', 'y', 'Z', 'z'];
    let res = "";
    for (var i = 0; i < n; i++) {
      var id = Math.ceil(Math.random() * 61);
      res += chars[id];
    }
    return res;
  }
  const checkOnlyBadgeType = async (e) => {
    let val = e.target.value
    if (val) {
      let reg = /^[a-zA-Z0-9-]{6,20}$/
      if (!reg.test(val)) {
        setOnlyBadgeType(false)
        return message.error('The badge url name must be longer than 6 characters and shorter than 24 characters, and can only contain letters, numbers, and ‘-’')
      }
      let res = await checkBadgeType(val)
      if (res === 'invalid') {
        setOnlyBadgeType(false)
        return message.error('That badge url name has been taken. Please choose another.')
      }
      setOnlyBadgeType(true)
    }
  }
  const onFinish = async (values) => {
    if(confirmLoading) {return false}
    let regType = /^[a-zA-Z0-9-]{6,20}$/
    if (!regType.test(values.badgeType)) {
      setOnlyBadgeType(false)
      return message.error('The badge url name must be longer than 6 characters and shorter than 24 characters, and can only contain letters, numbers, and ‘-’')
    }else {
      setOnlyBadgeType(true)
    }
    // if (!onlyBadgeType && !isEdit) {
    //   return message.error("Please check badge url name")
    // }
    if(!imageUrl) {
      return message.error("Badge image is required")
    }
    if (!values.name) {
      return message.error("Badge name is required")
    }
    if (!values.maxCount) {
      return message.error("Number of badge introduction is required")
    }
    if (!values.utility) {
      return message.error("Badge utility is required")
    }
    if (!values.description) {
      return message.error("Badge introduction is required")
    }
    setConfirmLoading(true);
    let formData = new FormData();
    let reg = /https:\/\//
    if (imageUrl && !reg.test(imageUrl)) {
      formData.append('image', dataURLtoBlob(imageUrl))
    }
    values.badgeType && formData.append('badgeType', values.badgeType)
    values.name && formData.append('name', values.name)
    values.maxCount && formData.append('maxCount', values.maxCount)
    values.description && formData.append('description', values.description)
    values.utility && formData.append('utility', values.utility)
    formData.append('group', eventInfo.groups[0].group)

    if (isEdit) {
      formData.append('originalBadgeType', createBadgeInfo.originalBadgeType)
    }

    addBadge(eventId, formData).then((res) => {
      message.success("Success")
      if (res) {
        history.push(`/badgeManage/eventBlindDetail/${eventId}`)
      } else {
        message.error("edit error");
      }
    }).finally(() => {
      setConfirmLoading(false);
    })
  };
  const initData = () => {
    let content = location.search
    if (content.indexOf("=") > 0) {
      let badgeType = decodeURIComponent(content.substring(11))
      setIsEdit(true)
      getBadgeInfo(badgeType)
    } else {
      setCreateBadgeInfo({})
    }
  }
  const getEventInfo = async () => {
    let res = await getEventByProject(eventId)
    if(res.creator != account) {
      history.push('/badgeManage')
      return false
    }
    setEventInfo(res)
  }
  const getBadgeInfo = async (badgeType) => {
    let res = await getBadgeDetail(badgeType)
    setImageUrl(res.image)
    let obj = {
      originalBadgeType: res.type,
      badgeType: res.type,
      startTime: moment(res.claimStartTime),
      endTime: moment(res.claimEndTime),
    }
    setCreateBadgeInfo({ ...res, ...obj })
  }

  useEffect(() => {
    initData()
    getEventInfo()
  }, [])

  return (
    <div className={styles.createBadge}>
      <div className='space-between-center'>
        <div className={`fs36 fw600`}>{isEdit ? 'Edit' : 'Create'} badge</div>
      </div>
      {
        createBadgeInfo ?
          <Form form={formRef} layout={'vertical'} onFinish={onFinish}>
            <div className={`${styles.step_box} ${styles.step_box_show}`}>
              <div className={styles.box_header}>Step1 Imformation</div>
              <div className={styles.box_content}>
                <div className={styles.eventInfo_list}>
                  <div className={styles.eventInfo_item}>
                    <div>Current event</div>
                    <div className={`fw600 w200 ${styles.item_content}`}>{eventInfo.name}</div>
                  </div>
                  <div className={`${styles.eventInfo_item} ${styles.line_left}`}>
                    <div>Event type</div>
                    <div className={`fw600 ${styles.item_content}`}>{eventInfo.type === 'general' ? 'General' : 'Mystery Box'}</div>
                  </div>
                  <div className={`${styles.eventInfo_item} ${styles.line_left}`}>
                    <div>Blockchain</div>
                    <div className={`fw600 ${styles.item_content}`}>
                      <img className='pr6' src={chainTypeComImgObj[eventInfo.chainType]} />
                      {chainTxtObj[eventInfo.chainType]}</div>
                  </div>
                  <div className={`${styles.eventInfo_item} ${styles.line_left}`}>
                    <div>Event time</div>
                    <div className={`fw600 ${styles.item_content}`}>{moment(eventInfo.eventStartTime).format("YYYY-MM-DD")} ~ {moment(eventInfo.eventEndTime).format("YYYY-MM-DD")}</div>
                  </div>
                </div>
                <hr style={{ border: '1px dashed #DEDEDE', margin: '20px 0' }} />
                <Row gutter={36}>
                  <Col span={11}>
                    <div className='space-between'>
                      <div>
                        <div>Upload badge image</div>
                        <div className={styles.text_tip}>PNG, GIF, WEBP. Max 2M</div>
                      </div>
                      <Upload className='mt10' customRequest={() => { }} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}><Button className={`btn_border w120 h40`}>Upload Image</Button></Upload>
                    </div>
                    <div className={styles.cover_preview}>
                      <div className='c_9a'>Preview</div>
                      <div className={styles.cover}>
                        <img src={imageUrl}></img>
                      </div>
                    </div>

                  </Col>
                  <Col span={13}>
                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item label={'Badge name'} name={"name"} initialValue={createBadgeInfo.name}>
                          <Input onBlur={blurName}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={'Number of badge'} name={"maxCount"} initialValue={createBadgeInfo.maxCount}>
                          <Input type='number'></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label={'Badge description'} name={"description"} initialValue={createBadgeInfo.description}>
                      <TextArea></TextArea>
                    </Form.Item>
                    <Form.Item label={'Badge utility'} name={"utility"} initialValue={createBadgeInfo.utility}>
                      <TextArea></TextArea>
                    </Form.Item>
                    <Form.Item label={'Badge URL name'}>
                      <div className={styles.text_tip}>The badge type must be longer than 6 characters and shorter than 20 characters, and can only contain letters, numbers, and ‘-’</div>
                      <Form.Item name={"badgeType"} initialValue={createBadgeInfo.badgeType}>
                        <Input onBlur={(e) => { checkOnlyBadgeType(e) }}></Input>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <hr style={{ border: '1px dashed #DEDEDE', width: '1200px', margin: 'auto' }}></hr>
              <div className='space-between-center p30'>
                <Button className={`w200 h50 btn_border`} onClick={goBack}>Back</Button>
                <Button type="primary" className={`w200 h50 ${styles.create_btn}`} htmlType="submit">Create {confirmLoading && <LoadingOutlined />}</Button>
              </div>
            </div>
          </Form> :
          <div className={`tac mt50`}>
            <Spin size={`large`}></Spin>
          </div>
      }
    </div>
  )
}
