import React, { useEffect, useState, useContext, useRef } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom';

import Graphin, { IG6GraphEvent, Utils, Behaviors, GraphinContext, Components } from '@antv/graphin';
import IconLoader from '@antv/graphin-icons';
import { TooltipValue } from '@antv/graphin';
import '@antv/graphin-icons/dist/index.css';
import { Input, message, Button, Spin } from 'antd';
import { useNftList } from 'services/nftData';
import { getOwnerList } from "services/badgeDetail";
import { queryOwnerNfts } from "services/nftData"
import { getArtistInfo } from "services/profile";
import { getBadgeInfo } from "services/badgeDetail";
import BackButton from "components/BackButton";
import { abbrTxHash } from "../../utils/format";
import copy from 'copy-to-clipboard';
import styles from "./styles.module.scss";

import bg from "assets/img/graph/graph-bg.png"
import triangle from "assets/img/graph/triangle.svg"
import logoFull from "assets/img/graph/logo.png";
import { ReactComponent as IconCopy } from "assets/img/header/copy.svg";
import { ReactComponent as IconBack } from "assets/img/graph/back.svg";
import { ReactComponent as IconExit } from "assets/img/graph/exit.svg";
import { chainTypeComImgObj, handleHolders } from '../../utils/networkConnect';

const icons = Graphin.registerFontFamily(IconLoader);
const { Tooltip } = Components;
const { Search } = Input;

export function G6Box({ address, initFlag }) {
  const [loading, setLoading] = useState(false)
  const [graphData, setGraphDate] = useState({})
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [badgeInfo, setBadgeInfo] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [holders, setHolders] = useState([])
  const [holdersNum, setHoldersNum] = useState(0)
  const [isUser, setIsUser] = useState(true)
  const layout = {
    type: 'graphin-force',
    animation: false,
    // center: [document.body.clientWidth /2, document.body.clientHeight/2]
  };


  const onSearch = (value) => {
    // console.log(value);
    let reg = /^0x/
    if (reg.test(value)) {
      dealOwnerCharts(value)
    }
    let reg2 = /-/
    if (reg2.test(value)) {
      dealChartsData(value)
    }
  }
  const dealOwnerCharts = (ownerAdress) => {
    let obj = {
      "nodes": [],
      "edges": []
    }
    setLoading(true)
    getArtistInfo(ownerAdress).then(res => {
      setUserInfo(res)
      setIsUser(true)
      let owner = {
        id: 'user' + '/' + ownerAdress,
        address: ownerAdress,
        style: {
          keyshape: {
            size: 60,
            fill: "#fff",
            stroke: "#B83BD7",
            lineWidth: 2,
            shadowColor: '#7f1a83',
            shadowBlur: 20,
            shadowOffsetY: 20
          },
          icon: {
            type: "image",
            value: res.avatar + '?t=' + Date.now(),
            size: [60, 60],
            clip: {
              r: 30,
            },
          },
          halo: {
            visible: true,
            stroke: '#ca5784',
            fill: '#1f062a',
            fillOpacity: 1,
          }
        }

      }
      obj.nodes.push(owner)

      const ownerNfts = async () => {
        const res = await queryOwnerNfts(1, 200, { owner: ownerAdress })
        res.length > 0 && res.forEach((e, idx) => {
          let nft = {
            id: 'nft' + idx,
            name: e.name,
            chainType: e.chainType,
            nftType: e.type,
            image: e.image,
            style: {
              label: {
                value: ''
              },
              keyshape: {
                size: 30,
                fill: '#e47638',
                fillOpacity: 1,
                opactity: 1,
                lineWidth: 0,

              },
              icon: {
                type: "image",
                value: e.image + '?t=' + Date.now(),
                size: [60, 60],

              }

            }
          }
          obj.nodes.push(nft)
          let relation = {
            source: 'user' + '/' + ownerAdress,
            target: 'nft' + idx,
            style: {
              keyshape: {
                stroke: '#B83BD7',
              }
            }
          }
          obj.edges.push(relation)
        })
        setTimeout(() => {
          setLoading(false)
          setGraphDate(obj)
        }, 1500)
      }
      ownerNfts()
      // message.success('success')

    }).catch(error => {
      if(error === 'user.not.exist') {
        message.error('Address not found')
      }else{
        // message.error(error)
      }
      setLoading(false)
    })
  }

  const dealChartsData = (nftType) => {
    let obj = {
      "nodes": [],
      "edges": []
    }
    setLoading(true)
    getBadgeInfo(nftType).then(res => {
      setBadgeInfo(res)
      setIsUser(false)
      let nft = {
        id: 'nft' + 0 + res.type,
        name: `${res.name}(${res.chainType})`,
        chainType: res.chainType,
        nftType: res.type,
        image: res.image,
        style: {
          keyshape: {
            lineWidth: 0,
            size: 60,
            fill: "#1f1f1f",
          },
          icon: {
            type: "image",
            value: res.image + '?t=' + Date.now(),
            size: [60, 60]
          }
        }
      }
      obj.nodes.push(nft)
      const dealOwner = async (index, type) => {
        const res = await getOwnerList({ current: 1, pageSize: 100 }, type)
        setHolders(res.list)
        setHoldersNum(res.totalCount)
        res.list.length > 0 && res.list.forEach((e, idx) => {
          let owner = {
            id: 'user' + type + idx + '/' + e.address,
            address: e.address,
            style: {
              keyshape: {
                size: 20,
                stroke: '#B83BD7',
                fill: '#e47638',
                fillOpacity: 0.3,
                lineWidth: 1,
              },
              icon: {
                type: 'font',
                fontFamily: 'graphin',
                value: icons.user,
                fill: 'gary',
                size: 20,
              },
            }
          }
          obj.nodes.push(owner)
          let relation = {
            source: 'nft' + index + type,
            target: 'user' + type + idx + '/' + e.address,
            style: {
              keyshape: {
                stroke: '#f9f9f92e',
              }
            }
          }
          obj.edges.push(relation)
        })
        setTimeout(() => {
          setLoading(false)
          setGraphDate(obj)
        }, 1500)
      }
      dealOwner(0, res.type)
      // message.success('success')
    }).catch(error => {
      // console.log(error);
      message.error(error)
    })

  }

  const SampleBehavior = () => {
    const { graph, apis } = useContext(GraphinContext);
    useEffect(() => {
      const handleClick = (evt) => {
        const node = evt.item;
        const { model } = evt.item._cfg;
        // console.log(model);
        let reg = /^nft/
        if (isUser) {
          if (reg.test(model.id)) {
            let nftItem = {
              name: model.name,
              nftType: model.nftType,
              image: model.image,
              chainType: model.chainType,
            }
            dealChartsData(model.nftType)
            return null
          }
        } else {
          let reg2 = /^user/
          if (reg2.test(model.id)) {
            dealOwnerCharts(model.address)
            return null
          }
        }
      };
      // every click on the node
      graph.on('node:click', handleClick);
      graph.on('node:touchstart', handleClick);
      return () => {
        graph.off('node:click', handleClick);
        graph.off('node:touchstart', handleClick);

      };
    }, []);
    return null;
  };
  const filterAddress = (model) => {
    let reg = /^nft/
    if (reg.test(model.id)) {
      return `${model.name}(${model.chainType})`
    }
    let reg2 = /^user/
    if (reg2.test(model.id)) {
      return model.address
    }

  }
  const openDrawer = () => {
    setOpen(true);
    setClose(false)
  }
  const closeDrawer = () => {
    setOpen(false);
    setClose(true)

  }
  const handleCopy = () => {
    copy(userInfo.address);
    message.success('copied!')
  }

  useEffect(() => {
    dealOwnerCharts(address)
  }, [address, initFlag])

  return (
    <>
      {
        loading && <div className={styles.spin}><Spin size='large'></Spin></div>
      }
      <Graphin fitCenter={true} width={document.body.clientWidth} height={document.body.clientHeight - 80} data={graphData} theme={{ mode: 'light' }} layout={layout} >
        <Tooltip bindType="node" style={{ width: "auto" }}>
          {(value) => {
            // console.log(value);
            if (value && value.model) {
              const { model } = value;
              return (
                <div className={`${styles.badgeTip} w320 fs12 c_fff p6 df_align_center`}>
                  <img src={chainTypeComImgObj[model.chainType]}></img> <div>{filterAddress(model)}</div>
                </div>
              );
            }
            return null;
          }}
        </Tooltip>
        <SampleBehavior></SampleBehavior>
      </Graphin>
      <div className={`${styles.info_module} ${open ? styles.info_open : null} ${close ? styles.info_close : null}`}>
        <div className={styles.info_box}>
          <Search className={styles.graph_search} size='large' placeholder="Search wallet address" onSearch={onSearch} />
          {
            isUser ?
              <div className={styles.userInfo_main}>
                <div className='tar p16'><IconCopy onClick={handleCopy} className='cp' fill='#9A9A9A'></IconCopy></div>
                <div className={styles.info}>
                  <img className={styles.avatar} src={userInfo.avatar}></img>
                  <div className={styles.user_name}>{userInfo.name}</div>
                  <div className={styles.user_handleName}>{userInfo.handleName && userInfo.handleName.length > 24 ? abbrTxHash(userInfo.handleName) : `@${userInfo.handleName}`}</div>
                  <div className={styles.user_item}>Collected <span className='fw700 pl8'>{userInfo.tokenCount}</span></div>
                  <div className={styles.user_item}>Following <span className='fw700 pl8'>{userInfo.followingCount}</span></div>
                  <div className={styles.user_item}>Follower <span className='fw700 pl8'>{userInfo.followerCount}</span></div>
                  <div className={styles.line} />
                  <div className={styles.user_des}>{userInfo.bio}</div>
                </div>
              </div> :
              <div className={styles.info_main}>
                <div className={styles.info_badge}>
                  <img src={badgeInfo.image}></img>
                </div>
                <div className={styles.info_title}>{badgeInfo.name}</div>
                <p className='fs14 mt10 mb20'>{badgeInfo.description}</p>
                <div>
                  <div className='fw700'>Holders({holdersNum})</div>
                  {
                    holders.slice(0, 8).map((item) => (
                      <div className='mt10 df'>
                        <div className={styles.holder_avatar}>
                          <img src={item.avatar}></img>
                        </div>
                        <div className={`fs14 pl10 ${styles.holder_address}`} >{item.address}</div>
                      </div>
                    ))
                  }
                </div>
                <div className='mt10'>
                  <a className={styles.checkAll} target="_blank" href={handleHolders(badgeInfo.chainType, badgeInfo.contractAddress)} >Check all holders</a>
                </div>
              </div>
          }

        </div>
        {
          !open ?
            <div className={styles.control_drawer} onClick={openDrawer}>
              <img className={styles.triangle} src={triangle}></img>
            </div> :
            <div className={styles.control_drawer} onClick={closeDrawer}>
              <img src={triangle} ></img>
            </div>
        }

      </div>
    </>
  )
}

export default function G6() {
  const { address } = useParams()
  const history = useHistory()
  const [flag, setFlag] = useState(0)

  const gotoBack = () => {
    setFlag(old => ++old)
  }
  const gotoExit = () => {
    history.push(`/profile/${address}`)
    // history.goBack()
  }

  return (
    <div className={`pr ${styles.badge_graph}`} style={{ backgroundImage: `url(${bg})` }}>
      <div className={`${styles.logo}`}>
        <Link to="/"><img className={styles.logo_img} src={logoFull} /></Link>
      </div>
      <G6Box address={address} initFlag={flag} />
      <div className={styles.btn_footer}>
        <Button className={styles.btn} onClick={gotoBack}><IconBack className='mr8'></IconBack> Back</Button>
        <Button className={styles.btn} onClick={gotoExit}>Exit <IconExit className='ml8'></IconExit></Button>
      </div>
    </div>
  )
}
