import React, { useState, useEffect,useRef } from "react";
import { Input, Select, Button, Popover } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import Picker from 'emoji-picker-react';
import { useLocation, useHistory } from "react-router-dom";

import { getContract, useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import styles from "./styles.module.scss";
import { ReactComponent as ImgIcon } from 'assets/img/home/img_icon.svg'
import { ReactComponent as EmojiIcon } from 'assets/img/home/emoji.svg'
import { ReactComponent as CrossIcon } from 'assets/img/home/cross.svg'
import { postActivity } from 'services/home'
import { useNeedSign } from "hooks/account";

const { TextArea } = Input;
const initImgFile = { previewUrl: '', file: null }

const PublishBox = ({ onPublished }) => {
  const { t } = useTranslation()
  const { library, account } = useActiveWeb3React()
  const { tronLibrary, tronAccount } = useActiveTronWeb()
  const [uploading, setUploading] = useState(false)
  const [textType, setTextType] = useState('public')
  const [imgFile, setImgFile] = useState(initImgFile)
  const [activityText, setActivityText] = useState('')
  const [focused, setFocused] = useState(false)
  const { needSign } = useNeedSign();
  const location = useLocation();
  const history = useHistory();
  const publishInput = useRef()

  const onEmojiClick = (event, emojiObject) => {
    setActivityText(old => old + emojiObject.emoji)
  };

  const handleTextChange = event => {
    setActivityText(event.target.value)
  }

  const addImg = () => {
    document.getElementById("dfa_uploader").click();
  }

  const handleImageChange = e => {
    const file = e.target?.files[0]
    if (!file) {
      return
    }
    let reader = new FileReader()
    reader.onload = () => {
      setImgFile({
        previewUrl: reader.result,
        file
      })
    }
    reader.readAsDataURL(file)
  }

  const clickToDelete = () => {
    setImgFile(initImgFile)
  }

  const handleChangeType = (val) => {
    setTextType(val)
  }

  const handlePublish = () => {
    needSign(() => {
      setUploading(true)
      postActivity(library || tronLibrary, account || tronAccount, activityText, textType, imgFile.file)
        .then(res => {
          setImgFile(initImgFile)
          setActivityText('')
          onPublished && onPublished();
          history.push('/wow')
        }).finally(() => {
          setUploading(false)
        })
    })
  }
  const initData = () => {
    let content = location.search
    if (content.indexOf("=")>0){
      let topic = decodeURIComponent(content.substring(7))+' '
      setActivityText(topic)
      publishInput.current.focus()
    }
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <div className={styles.main}>
      <div className={styles.text_box}>
        <TextArea ref={publishInput}
          autoSize={false}
          bordered={false}
          showCount
          placeholder={t('share_anything')}
          maxLength="280"
          value={activityText}
          className={focused ? styles.on_focus : ''}
          onChange={e => { handleTextChange(e) }}
          onFocus={() => { setFocused(true) }}
          onBlur={() => { setFocused(false) }}
        />
      </div>
      {
        imgFile.previewUrl && <div className={styles.preview_box}>
          <img src={imgFile.previewUrl} className={styles.preview_img} />
          <div className={styles.delete_box} onClick={clickToDelete}>
            <CrossIcon />
          </div>
        </div>
      }
      <div className={styles.handle_box}>
        <div className="df_align_center pos_r">
          <Popover overlayClassName={styles.pop_emoji} trigger="click" content={<Picker onEmojiClick={onEmojiClick} />}>
            <EmojiIcon className="cp" />
          </Popover>
          <span className={styles.fence}></span>
          <ImgIcon onClick={addImg} className="cp" />
          <input id="dfa_uploader"
            type="file"
            accept="image/png, image/jpg, image/jpeg, video/mp4, video/quicktime, image/gif"
            className="hide"
            onChange={handleImageChange}
          />
        </div>

        <div className="df_align_center">
          <Select
            suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none', color: '#000' }} />}
            defaultValue={textType}
            className={styles.permission_select}
            style={{ width: 125 }}
            onChange={handleChangeType}
          >
            <Select.Option value="public">{t('Public')}</Select.Option>
            <Select.Option value="private">{t('Only followers')}</Select.Option>
          </Select>
          <Button
            loading={uploading}
            onClick={handlePublish}
            type="primary"
            style={{ minWidth: 100 }}
            disabled={!activityText}
          >{t('publish')}</Button>
        </div>
      </div>
    </div>
  )
}

export default PublishBox;
