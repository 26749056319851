import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import moment from 'moment';

import { HANDLE_NOTICE_NUM } from "../../../../const";
import { mainContext } from "../../../../reducer";
import PublishCommentBox from "components/PublishCommentBox";
import NoData from "components/NoData";
import { useList, queryNoticeStatus } from "../../../../services/message"
import common from "assets/img/message/common.svg"
import reply from "assets/img/message/reply.svg"
import replyBlack from "assets/img/message/reply_black.svg"
import styles from "../../styles.module.scss";
import Modal from 'antd/lib/modal/Modal';

export default function Activity() {
    const history = useHistory();
    const { state, dispatch } = useContext(mainContext);
    const [refresh, setRefresh] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false)
    const [replyItem, setReplyItem] = useState('')
    const [replyUser, setReplyUser] = useState('')
    const [replayContent, setReplayContent] = useState('')
    const [loading, setLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const { list, total } = useList('activity', pageNo, pageSize, setLoading)

    const openReplay = (item) => {
        setReplyItem(item)
        setReplyUser(item.operator.name)
        setReplayContent(item.commentInfo.content)
        setVisibleModal(true)
    }

    const handleCancel = () => {
        setVisibleModal(false)
    }
    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const handlePublish = () => {
        setRefresh(old => (old + 1) % 100)
        setVisibleModal(false)
    }
    const goToComment = (item) => {
        history.push(`/marketplace/${item.nftInfo.contractAddress}/${item.nftInfo.chainType}/${item.nftInfo.id}?origin=notice`)
    }
    const initNotice = async () => {
        let res = await queryNoticeStatus()
        dispatch({
          type: HANDLE_NOTICE_NUM,
          noticeNum: {
            nftactivitynum: res.sale,
            commentnum: 0,
            likemenum: res.like
          }
        });
    }
    useEffect(() => {
        initNotice()
    }, [])
    return (
        <>
            {/* <div className={styles.notice_title}>Comment</div> */}
            <div className={`${styles.commonList} ${styles.notice_list}`}>
                {
                    loading ? <Spin className={`wp100 mt40`} size="large"></Spin> :
                        <InfiniteScroll
                            dataLength={list.length}
                            next={getList}
                            hasMore={list.length < total}
                            loader={
                                <div style={{ width: 216, textAlign: 'center', margin: '10px auto' }}>
                                    <Spin size={`large`} />
                                </div>
                            }
                        >
                            {
                                list.map(item => (
                                    <div key={item.notificationTime} className={styles.common_item}>
                                        <div className={styles.item_time}>{moment(item.notificationTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        <div className={`${styles.common_main}`}>
                                            <img className={`${styles.common_icon} ${styles.notice_icon}`} width={30} src={common}></img>
                                            <div className={`f1 ${styles.common_content}`}>
                                                <div className={`text_hidden_1 ${styles.common_des}`}>
                                                    <span className={styles.item_name}>{item.operator.name}</span>
                                                    {
                                                        item.type === 'reply_activity' &&
                                                        <>
                                                            <span className={styles.item_info}>relied to your WOW &nbsp;</span>
                                                            "
                                                            <span className={`c_blue`}>{item.activityInfo.content}</span>
                                                            "
                                                        </>
                                                    }
                                                    {
                                                        item.type === 'reply_activity_comment' &&
                                                        <>
                                                            <span className={styles.item_info}>relied to your Comment &nbsp;</span>
                                                            "
                                                            <span className={`c_blue`}>{item.originalCommentInfo.content}</span>
                                                            "
                                                        </>
                                                    }
                                                    {
                                                        item.type === 'comment_nft' &&
                                                        <>
                                                            <span className={styles.item_info}>commented on your &nbsp;</span>
                                                            <span className={`c_blue`}>{item.nftInfo.name}</span>
                                                        </>
                                                    }
                                                </div>
                                                {
                                                    item.type === 'reply_activity' &&
                                                    <div className={styles.common_reply}>
                                                        <div className={`fs14 lh18 ${styles.common_content}`}>{item.commentInfo.content}</div>
                                                        <div className={`fs12 tar c_9a cp ${styles.reply_btn}`} onClick={() => { openReplay(item) }}>
                                                            <img className='pr2' src={reply}></img>
                                                            Reply
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item.type === 'reply_activity_comment' &&
                                                    <>
                                                        <div className={styles.common_reply}>
                                                            <div className={`fs14 lh18 ${styles.common_content}`}>{item.commentInfo.content}</div>
                                                            <div className={`fs12 c_9a cp ${styles.reply_btn}`} onClick={() => { openReplay(item) }}>
                                                                <img className='pr2' src={reply}></img>
                                                                Reply
                                                            </div>
                                                        </div>
                                                        <div className={`fs12 c_9a cp ${styles.reply_btn_h5}`} onClick={() => { openReplay(item) }}>
                                                            <img className='pr2' src={replyBlack}></img>
                                                            Reply
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    item.type === 'comment_nft' &&
                                                    <div className={`cp ${styles.common_reply}`} onClick={() => { goToComment(item) }}>
                                                        <div className={`fs14 lh18 ${styles.common_content}`}>{item.commentInfo.content}</div>
                                                        {/* <div className={`fs12 tar c_9a cp ${styles.reply_btn}`} >
                                                            view all
                                                        </div> */}
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                list.length === 0 && <div className={styles.empty}><NoData></NoData></div>
                            }
                        </InfiniteScroll>
                }

            </div>
            <Modal visible={visibleModal}
                onCancel={handleCancel}
                title={`Reply to ${replyUser}`}
                footer={null} >
                <div className={`mb20`}>{replayContent}</div>

                <PublishCommentBox id={replyItem.activityInfo ? replyItem.activityInfo.id : ''} replyTo={replyItem.commentInfo ? replyItem.commentInfo.id : ''} onPublished={handlePublish} ></PublishCommentBox>

            </Modal>
        </>
    )
}
