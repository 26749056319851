import React, { useState, useEffect } from 'react';
import { Switch, Spin, Modal, Form, Select, Button } from 'antd';
import { useParams } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from 'react-i18next';

import { fetchTokensList, getCollectionInfo } from '../../services/NftPlusBuyPage';
import NftBuyCard from 'components/NftBuyModule';
import NftCard from 'components/NftCard';
import NoData from "components/NoData";
import styles from './styles.module.scss'
import filter from '../../assets/img/Vector.svg'
import { filterGraffiti, filterHands, filterEmissionFuels, filterBody, filterSkiColor, filterBackground, filterEyes, filterStickers, filterTopheads, filterPoseOrAccessories } from "./constant/constant";
// import { filterBackground, filterClothes, filterEarring, filterEyes, filterFur, filterHat, filterMouth } from "./constant/punk";
import * as ape from "./constant/punk";



const { Option } = Select;

export default function Detail() {
  const { type } = useParams()
  const { t } = useTranslation()
  const [pageNo, setPageNo] = useState(1)
  const [saleType, setSaleType] = useState(false)
  const [info, setInfo] = useState({})
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [visibleApe, setVisibleApe] = useState(false)
  const [otherParams, setOtherParams] = useState({})
  const [form] = Form.useForm();
  // console.log(C);
  const getList = () => {
    setPageNo(pageNo + 1)
    getBuyList(info, pageNo + 1, saleType, otherParams)
  }
  const toggleOnSale = (checked) => {
    setPageNo(1)
    if (checked) {
      setSaleType('sale')
      getBuyList(info, 1, 'sale', otherParams)
    } else {
      setSaleType('')
      getBuyList(info, 1, '', otherParams)
    }
  }
  const getInfo = async () => {
    let res = await getCollectionInfo(type)
    res = res || {};
    setInfo(res)
    getBuyList(res, pageNo)
  }
  const getBuyList = async (item, pageNo, saleType, otherParams) => {
    // let res = await buyListApi[collectionType](pageNo, 30, saleType, type, item.pieceNftContractAddress, otherParams)
    let res = await fetchTokensList(pageNo, 30, saleType, type, item.pieceNftContractAddress, otherParams)
    if (res.tokens) {
      if (pageNo === 1) {
        setList(res.tokens)
      } else {
        setList(oldTokens => [...oldTokens, ...res.tokens])
      }
      setTotal(res.totalCount)
    } else {
      if (pageNo === 1) {
        setList(res.list)
      } else {
        setList(oldList => [...oldList, ...res.list])
      }
      setTotal(res.totalCount)
    }
  }

  const onCallback = () => {
    getInfo()
    setPageNo(1)
  }
  const showFilter = () => {
    if (type === 'ape') {
      setVisibleApe(true)
    } else {
      setVisibleFilter(true)
    }
  }
  const handleCancelFilter = () => {
    setVisibleFilter(false);
    setVisibleApe(false);
  }
  const onFinishFilter = (values) => {
    let res = {}
    Object.keys(values).filter(key => values[key] != undefined).forEach(key => {
      res[key] = values[key]
    })
    // console.log(res);
    setPageNo(1);
    setOtherParams(res)
    setVisibleFilter(false)
    getBuyList(info, 1, saleType, res)
  }
  const onFinishFilterApe = (values) => {
    let res = {}
    Object.keys(values).filter(key => values[key] != undefined).forEach(key => {
      res[key] = values[key]
    })
    // console.log(res);
    setPageNo(1);
    setOtherParams(res)
    setVisibleApe(false)
    getBuyList(info, 1, saleType, res)
  }
  const onClearFilter = () => {
    form.resetFields();
  }

  useEffect(() => {
    getInfo()
  }, [])
  return (
    <>
      <div>
        <div className={'page_content_h5_1280'}>
          <NftBuyCard onCallback={onCallback} info={info}></NftBuyCard>
        </div>
        <div className={`wp100`}>
          <div className={`space-between-center ${styles.collection}`}>
            <div className={`fs24 fw700`}>Collection</div>
            <div className={`df`}>
              <div className={`dib ml20`}>
                <Switch onChange={toggleOnSale} />
                <span className={"fs14 c_l ml16 fw600"}>{t('on_sale')}</span>
              </div>
              {
                info.filterProperty &&
                <div className={`pl20 ml20 cp ${styles.filter}`} onClick={showFilter} >
                  <img width={18} src={filter}></img>
                </div>
              }
            </div>
          </div>
          <div className={`page_collection_list`} style={{paddingTop: 12}}>
            <InfiniteScroll
              dataLength={list.length}
              next={getList}
              hasMore={list.length < total}
              loader={
                <div style={{ width: 216, textAlign: 'center', margin: 'auto' }}>
                  <Spin size={`large`} />
                </div>
              }
            >
              {
                list.length > 0 && list.map(item => (
                  <NftCard key={item.contractAddress + item.id} tokenInfo={item} />
                ))
              }
              {
                list.length === 0 && <div className={`wp100 tac df_center ${styles.collection_no_data}`}><NoData></NoData></div>
              }
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <Modal
        title="Filter"
        visible={visibleFilter}
        width={600}
        onCancel={handleCancelFilter}
        footer={null}
      >
        <Form
          form={form}
          name="event-filter"
          onFinish={onFinishFilter}
          requiredMark={false}
          className={styles.activityMint}
        >
          <div className={`${styles.modal_df}`}>
            <Form.Item className={`${styles.modal_select}`} name="graffiti">
              <Select
                showSearch
                mode="multiple"
                placeholder="Graffiti"
                showArrow
              >
                {
                  filterGraffiti.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label} <span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="hands">
              <Select
                showSearch
                mode="multiple"
                placeholder="Hands"
                showArrow
              >
                {
                  filterHands.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.modal_df}`}>
            <Form.Item className={`${styles.modal_select}`} name="emission fuels">
              <Select
                showSearch
                mode="multiple"
                placeholder="Emission Fuels"
                showArrow
              >
                {
                  filterEmissionFuels.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="body">
              <Select
                showSearch
                mode="multiple"
                placeholder="Body"
                showArrow
              >
                {
                  filterBody.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.modal_df}`}>
            <Form.Item className={`${styles.modal_select}`} name="ski color">
              <Select
                showSearch
                mode="multiple"
                placeholder="Ski Color"
                showArrow
              >
                {
                  filterSkiColor.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="background">
              <Select
                showSearch
                mode="multiple"
                placeholder="Background"
                showArrow
              >
                {
                  filterBackground.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.modal_df}`}>
            <Form.Item className={`${styles.modal_select}`} name="eyes">
              <Select
                showSearch
                mode="multiple"
                placeholder="Eyes"
                showArrow
              >
                {
                  filterEyes.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="stickers">
              <Select
                showSearch
                mode="multiple"
                placeholder="Stickers"
                showArrow
              >
                {
                  filterStickers.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.modal_df}`}>
            <Form.Item name="topheads" className={`${styles.modal_select}`}>
              <Select
                showSearch
                mode="multiple"
                placeholder="Topheads"
                showArrow
              >
                {
                  filterTopheads.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="pose or accessories">
              <Select
                showSearch
                mode="multiple"
                placeholder="Pose or Accessories"
                showArrow
              >
                {
                  filterPoseOrAccessories.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`space-between`}>
            <Button className={`wp100 h40 mr10 btn_border`} htmlType="button" onClick={onClearFilter}>
              Reset
            </Button>
            <Button className={`wp100 mr10`} type="primary" htmlType="submit">
              Filter
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Filter"
        visible={visibleApe}
        width={600}
        onCancel={handleCancelFilter}
        footer={null}
      >
        <Form
          form={form}
          name="event-filter"
          onFinish={onFinishFilterApe}
          requiredMark={false}
          className={styles.activityMint}
        >
          <div className={`${styles.modal_df}`}>
            <Form.Item className={`${styles.modal_select}`} name="background">
              <Select
                showSearch
                mode="multiple"
                placeholder="Background"
                showArrow
              >
                {
                  ape.filterBackground.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label} <span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="clothes">
              <Select
                showSearch
                mode="multiple"
                placeholder="Clothes"
                showArrow
              >
                {
                  ape.filterClothes.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.modal_df}`}>
            <Form.Item className={`${styles.modal_select}`} name="earring">
              <Select
                showSearch
                mode="multiple"
                placeholder="Earring"
                showArrow
              >
                {
                  ape.filterEarring.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="eyes">
              <Select
                showSearch
                mode="multiple"
                placeholder="Eyes"
                showArrow
              >
                {
                  ape.filterEyes.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.modal_df}`}>
            <Form.Item className={`${styles.modal_select}`} name="fur">
              <Select
                showSearch
                mode="multiple"
                placeholder="Fur"
                showArrow
              >
                {
                  ape.filterFur.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item className={`${styles.modal_select}`} name="hat">
              <Select
                showSearch
                mode="multiple"
                placeholder="Hat"
                showArrow
              >
                {
                  ape.filterHat.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.modal_df}`}>
            <Form.Item name="mouth" className={`wp100 mr10`}>
              <Select
                showSearch
                mode="multiple"
                placeholder="Mouth"
                showArrow
              >
                {
                  ape.filterMouth.map(item => (
                    <Option key={item.value + Math.random(0, 1000)} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          <div className={`space-between`}>
            <Button className={'btn_border h40 wp50 mr10'} htmlType="button" onClick={onClearFilter}>
              Reset
            </Button>
            <Button className={`wp50 mr10 h40`} type="primary" htmlType="submit">
              Filter
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}
