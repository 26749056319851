import React, { useContext,useState } from 'react';
import { BackTop } from 'antd';
import { useParams, useHistory } from "react-router-dom";

import { mainContext } from "../../reducer";
import RecommendBar from "components/RecommendBar";
import Activity from "./compontents/activety";
import Likeme from "./compontents/likeme";
import Common from "./compontents/common";
import styles from "./styles.module.scss";
import activity from "assets/img/message/activity.svg"
import likeme from "assets/img/message/likeme.svg"
import common from "assets/img/message/common.svg"

export default function Message() {
  const { type } = useParams();
  const history = useHistory();
  const { dispatch, state } = useContext(mainContext);

  const gotoNoticeNav = (nav) => {
    history.push(`/message/${nav}`)
  }
  const gotoWOW = () => {
    history.push(`/wow`)
  }
  const gotoTopic = () => {
    history.push('/topic')
  }

  return (
    <div className="df">
      <BackTop style={{ bottom: 110, right: 40 }} />
      <div className={styles.content_box}>
        <div className={`df ${styles.nav_h5}`}>
          <div className={`f1 tac pr16 border_right`} onClick={gotoWOW}>WoW</div>
          <div className={`f1 tac pl16 border_right`} onClick={gotoTopic}>Topic</div>
          <div className={`f1 tac fw700 pl16`} >Notification</div>
        </div>
        <div className={`df wp100 ${styles.notice_nav_list}`}>
            <div className={`df_align_center mr8 ${styles.notice_nav_h5} ${styles.notice_nav_likeme} ${type==='likeme'?`${styles.activity_nav}` : null}` } onClick={() => { gotoNoticeNav("likeme") }}>
              <div><img src={likeme}></img></div><span>Like me</span>
            </div>
            <div className={`df_align_center mr8 ${styles.notice_nav_h5} ${styles.notice_nav_comment} ${type==='comment'?`${styles.activity_nav}` : null}`} onClick={() => { gotoNoticeNav("comment") }}>
              <div className={`pr`}><img src={common}></img>{state.noticeNum.commentnum > 0 && <span className={styles.red_dot}></span>}</div><span>Comment</span>
            </div>
            <div className={`df_align_center ${styles.notice_nav_h5} ${styles.notice_nav_activity} ${type==='activity'?`${styles.activity_nav}` : null}`} onClick={() => { gotoNoticeNav("activity") }}>
              <div className={`pr`}><img src={activity}></img>{state.noticeNum.nftactivitynum > 0 && <span className={styles.red_dot}></span>}</div><span>NFT Activity</span>
            </div>
          </div>
        {
          type === 'activity' &&
          <Activity></Activity>
        }
        {
          type === 'likeme' &&
          <Likeme></Likeme>
        }
        {
          type === 'comment' &&
          <Common></Common>
        }
      </div>
      {/* <div className={styles.content_box_h5}>
        <div className='wp100'>
          <div className={`fs24 fw700 p20 `}>Notifications</div>
          <div className='df wp100'>
            <div className={`f1 ${styles.line_right} ${styles.notice_nav_h5}`} onClick={() => { gotoNoticeNav("likeme") }}>
              <div><img src={likeme}></img></div><span className={type === 'likeme' ? 'fw700' : null}>Like me</span>
            </div>
            <div className={`f1 ${styles.line_right} ${styles.notice_nav_h5}`} onClick={() => { gotoNoticeNav("comment") }}>
              <div className={`pr`}><img src={common}></img>{state.noticeNum.commentnum > 0 && <span className={styles.red_dot}></span>}</div><span className={type === 'comment' ? 'fw700' : null}>Comment</span>
            </div>
            <div className={`f1  ${styles.notice_nav_h5}`} onClick={() => { gotoNoticeNav("activity") }}>
              <div className={`pr`}><img src={activity}></img>{state.noticeNum.nftactivitynum > 0 && <span className={styles.red_dot}></span>}</div><span className={type === 'activity' ? 'fw700' : null}>Activity</span>
            </div>
          </div>
          <hr className={styles.line_hr}></hr>
        </div>
        <div className={'pt4'}>
          {
            type === 'activity' &&
            <Activity></Activity>
          }
          {
            type === 'likeme' &&
            <Likeme></Likeme>
          }
          {
            type === 'comment' &&
            <Common></Common>
          }
        </div>
      </div> */}

      <RecommendBar recommendType={'message'} />
    </div>
  )
}
