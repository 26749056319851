import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Modal, Button, message, Spin, Pagination, Table} from 'antd';
import moment from 'moment';

import { getSpliceList, fetchTokenInfo, fetchTokensList } from "../../services/puzzleBoard";
import styles from "./styles.module.scss";
import { useActiveWeb3React, getContract } from "../../web3";
import { compoundPuzzle } from "../../utils/handleContract"
import { setupEthMainnet } from '../../utils/networkConnect';
import fluteboy from "../../assets/img/sandbox/fluteboy.gif"
import pearl from "../../assets/img/sandbox/pearl.gif"
import starlight from "../../assets/img/sandbox/starlight.gif"
import puzzle from "../../assets/img/sandbox/puzzle.png"
import puzzleIcon from "../../assets/img/sandbox/puzzle_gary.png"
import skipIcon from "../../assets/img/sandbox/skip_icon.png"
import { mainContext } from "../../reducer";
import DFA1155NFT from "../../web3/abi/DFA1155NFT.json";
import { getChainType } from "../../web3/address";
import {
    HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
  } from "../../const";
let timer;
export default function ShardSplice() {
    const { t } = useTranslation();
    const [ list, setList ] = useState([])
    const [ showNFT, setShowNFT ] = useState(false)
    const [ blindboxInfo, setBlindboxInfo ] = useState({})
    const [ viewUrl, setViewUrl ] = useState('')
    const { library, account, chainId } = useActiveWeb3React()
    const history = useHistory();
    const { dispatch } = useContext(mainContext);
    const chainType = getChainType(chainId);
    const [ levelLoading, setLevelLoading ] = useState(false)
    const [ showSpliced, setShowSpliced ] = useState(false)
    const [ tableData, setTableData ] = useState([])
    const [ tableTotal, setTableTotal ] = useState(0)
    const [ pageNo, setPageNo ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ nftContractAddress, setNftContractAddress ] = useState('');
    const [ nftType, setNftType ] = useState('');
    const tableColumns = [
        { title: "Items", align:"center", key: "name", width:200, render: record => <a onClick={ () => {history.push(`/marketplace/${record.tokenAddress}/${record.chainType}/${record.tokenId}`)}}>{record.name}</a>},
        { title: "Users", align:"center", key: "ownerName" , dataIndex: "ownerName",width:110, render: text => <span>{formatAddress(text)}</span> },
        { title: "Expiration", align:"center", key: "dateCreated", dataIndex: "dateCreated",width:160, render: text => <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span> },
    ]
    const formatAddress = (address)=>{
        if(address.length>20){
            return  address.slice(0, 6) + '...' + address.slice(-3)
        }else {
            return address
        }
    }

    const imgObj = {
        fluteboy: fluteboy,
        pearl: pearl,
        starlight: starlight,
    }

    const goToBuy = () => {
        history.push('/sandbox/list')
    }
    const goToBuyPuzzle = (item) => {
        history.push(`/marketplace/token/${item.nftContractAddress}/${item.chainType}/${item.nftId}`)
    }
    const handleView = () => {
        history.push(viewUrl)
    }
    const handleCancel = () => {
        setShowNFT(false)
      }
    const getList = () => {
        getSpliceList(library,account).then(res => {
            console.log(res);
            res.list.forEach((item) => {
                item.piecesCopy = []
                item.pieces.forEach(ele => {
                    if(ele.countOwnedByCurrentUser > 0){
                        item.piecesCopy.push(ele) 
                    }
                })
                item.typeCopy = splitImg(item.type)
            })
            setList(res.list)
        })
    }
    const splitImg = (type) => {
        let arr = type.split('-')
        return arr[arr.length-1]
    }
    const onMint = async (item) => {
        if (chainId !== 1 && chainId !== 4) {
          message.error("please switch to ETH")
          setupEthMainnet()
          return;
        }
    
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForConfirm
        });
    
        toApproved(item)
    }
    const toApproved = async (item) => {
        try {
          const dfa1155Contract = await getContract(library, DFA1155NFT.abi, item.pieceNftContractAddress);
          let isApproved = await dfa1155Contract.methods.isApprovedForAll(account, item.contractAddress).call()
          if (!isApproved) {
            await dfa1155Contract.methods.setApprovalForAll(item.contractAddress, true).send({ from: account });
          }
          toCompound(item)
          
        } catch (err) {
          console.log(err)
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
          });
        }
      }
    const toCompound = async (item) => {
        try {
            await compoundPuzzle(library, item.contractAddress, account, {
                boxName:  item.type,
                _onTranscationHash: (hash) => {
                },
                _onReceipt: async (receipt) => {
                    console.log(receipt);
                    const { events: { Compound: { returnValues } } } = receipt;
                    dispatch({
                        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                        showWaitingWalletConfirmModal: { show: false }
                    });
                    setShowNFT(true)
                    setLevelLoading(true)
                    queryTokenInfo(item.nftContractAddress, returnValues.tokenId, chainType, item.typeCopy)
                },
                _onError: (err) => {
                    dispatch({
                      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                      showWaitingWalletConfirmModal: { show: false }
                    });
                    dispatch({
                      type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                      showFailedTransactionModal: true
                    });
                }
            })
        } catch (error) {
            console.log(error);
            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { show: false }
              });
        }
    }
    const queryTokenInfo = (contractAddress, tokenId, chainType, typeCopy) => {
        timer = setTimeout(() => {
            fetchTokenInfo(contractAddress, tokenId, chainType).then(res => {
                if (res.image) {
                    clearTimeout(timer)
                    setLevelLoading(false)
                    // setPopImg(res.image)
                    setBlindboxInfo({popImg: res.image, popName: res.name})
                    setViewUrl(`marketplace/${contractAddress}/${chainType}/${tokenId}`)
                     getList()

                } else {
                    queryTokenInfo(contractAddress, tokenId, chainType,typeCopy)
                }
            }).catch(err => {
                console.log(err);
            })
        }, 2000)
    }

    const cancelSpliced = () => {
        setShowSpliced(false)
    }
    const getTableList = async (pageNo,pageSize,tokenAddress,collection) => {
        let res = await fetchTokensList( pageNo,pageSize,tokenAddress, collection)
        res.tokens.forEach(item => {
            item.ownerName = item.owner.name?item.owner.name: item.owner.address
        })
        setTableTotal(res.totalCount)
        setTableData(res.tokens)
    }
    const openSpliced = (item) => {
        if (item.nftCount < 1){return false}
        setPageNo(1)
        setNftContractAddress(item.nftContractAddress)
        setNftType(item.type)
        setShowSpliced(true)
        getTableList(pageNo, pageSize,item.nftContractAddress,item.type)
    }
    const onShowSizeChange = ( current ) => {
        setPageNo(current)
        getTableList(current, pageSize, nftContractAddress, nftType)
    }
    const handelRowClassName = (record,index)=> {
        if (index%2>0){
            return 'rowBgGray'
        }else {
            return 'rowBgWhite'
        }
    }

    useEffect(()=> {
        getList()
    },[library,account])
    return (
        <>
        <Modal
        className={styles.blind_box}
        visible={showNFT}
        onCancel={handleCancel}
        width={750}
        footer={[
          <Button key="submit" type="primary" disabled={levelLoading} onClick={handleView}>
            View now
          </Button>
        ]}
      >
          {
           !levelLoading && <h1 className={styles.blind_box_h1}>{t('pop_congratulations')}!</h1>

        }
        {
           !levelLoading && <h1>you got "{blindboxInfo.popName}" </h1>

        }
        {
          levelLoading
            ? (
              <div className={`mt40 mb20`}>
                <Spin size="large" tip="The NFT information is being obtained, please wait"></Spin>
              </div>
            )
            : 
             <div className={`${styles.pop_img}`}>
            <img width={200} src={blindboxInfo.popImg}></img>
          </div>
        }
        
      </Modal>
      <Modal className={styles.totalSplicedModal}  title={`${t('spliced_list')} (${tableData.length})`} centered={true} visible={showSpliced} onCancel={cancelSpliced}  width={550} footer={[
          <Pagination simple current={pageNo} total={tableTotal} onChange={onShowSizeChange}></Pagination>
      ]}>
          <div>
              <Table scroll={{x: '100%'}} width={470} columns={tableColumns} dataSource={tableData} pagination={false} rowClassName={ handelRowClassName }  />
          </div>
      </Modal>
        <div className={`page_content_h5 ${styles.content}`}>
            <div className={`${styles.main_area}`}>
                <div className={styles.header_title}>
                    <div className={styles.title}>{t('shards_splice')}</div>
                    <div className={`${styles.df_center} pointer`} onClick={goToBuy}><img width={18} height={20} src={skipIcon}></img>  <div className={`${styles.skip} fw700`}>{t('buy_mlind_box')} &gt;</div></div>
                </div>
                <div>
                   {
                       list.length>0 &&list.map(item => (

                           item.layout === 'horizontal' ?<div className={`${styles.card_item}`}>
                               <div className={`space-between fw700 mb10`}>
                                   <span className={`fs24`}>{item.name}</span>
                                   <span  className={`pointer`} onClick={ ()=>{ openSpliced(item) } }>{t('total_spliced')}: {item.nftCount}</span>
                               </div>
                               <div className={styles.card_main}>
                                   <div>
                                       <div className={styles.complete_img}>
                                            <img className={`${styles.img_gary} ${styles.bg_img}`} src={imgObj[item.typeCopy]}></img>
                                            {
                                                item.piecesCopy.map(val => (
                                                    <img className={`${styles.puzzle_img} ${styles.bg_img}`} src={val.image}></img>
                                                ))
                                            }
                                       </div>
                                       <div className={`space-between mt10 mb10`}>
                                            <div className={styles.df_center}><img className={`mr10`} width={24} height={24} src={puzzle}></img><div className={'fw600'}>{item.piecesCopy.length}/10</div></div>
                                            <Button className={styles.mint_btn_primary} type="primary" onClick={ ()=> {onMint(item)} } disabled={item.piecesCopy.length != 10}>Mint</Button>
                                       </div>
                                   </div>
                                    <div className={`df ${styles.pieces_list}`}>
                                        {
                                            item.pieces.map(ele => (
                                                <div className={`${styles.pieces_item} ${styles.item_horizontal} pointer`} onClick={ ()=> { goToBuyPuzzle(ele) } }>
                                                    <img className={`${ele.countOwnedByCurrentUser>0?null:styles.img_gary}`} src={ele.image}></img>
                                                    <p className={'pt10 fs12'}>{ele.name}</p>
                                                    <div className={styles.pieces_count}><img width={12} src={puzzleIcon}></img><span>{ele.countOwnedByCurrentUser}</span></div>
                                                </div>
                                            ))
                                        }
                                    </div>
                               </div>
                           </div>:
                           <div className={`${styles.card_item}`}>
                               <div className={`space-between fw700 mb10`}>
                                   <span className={`fs24`}>{item.name}</span>
                                   <span className={`pointer`} onClick={ ()=>{ openSpliced(item) } }>{t('total_spliced')}: {item.nftCount}</span>
                               </div>
                               <div className={styles.card_main_vertical}>
                                   <div>
                                   <div className={styles.complete_img}>
                                            <img className={`${styles.img_gary} ${styles.bg_img_vertical}`} src={imgObj[item.typeCopy]}></img>
                                            {
                                                item.piecesCopy.map(val => (
                                                    <img className={styles.puzzle_img} width={280} src={val.image}></img>
                                                ))
                                            }
                                       </div>
                                       <div className={`space-between mt10 mb10`}>
                                            <div className={styles.df_center}><img className={`mr10`} width={24} height={24} src={puzzle}></img><div className={'fw600'}>{item.piecesCopy.length}/10</div></div>
                                            <Button className={styles.mint_btn_primary} type="primary" onClick={ ()=> {onMint(item)} } disabled={item.piecesCopy.length != 10}>Mint</Button>
                                       </div>
                                   </div>
                                    <div className={`df ${styles.pieces_list_vertical}`}>
                                        {
                                            item.pieces.map(ele => (
                                                <div className={`${styles.pieces_item} ${styles.item_vertical} pointer`}  onClick={ ()=> { goToBuyPuzzle(ele) } }>
                                                    <img className={`${ele.countOwnedByCurrentUser>0?null:styles.img_gary}`} width={180} src={ele.image}></img>
                                                    <p className={'pt10 fs12'}>{ele.name}</p>
                                                    <div className={styles.pieces_count}><img width={12} src={puzzleIcon}></img><span>{ele.countOwnedByCurrentUser}</span></div>
                                                </div>
                                            ))
                                        }
                                    </div>
                               </div>
                           </div>
                           
                       ) )
                   } 
                </div>
            </div>
        </div>
        </>
    )
}
