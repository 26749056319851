import React from 'react'
import { Table } from 'antd';
import styles from './styles.module.scss'

export default function BlackTable({tableColumns, tableList, rowKey}) {
    
    const handelRowClassName = (record, index) => {
        if (index % 2 > 0) {
            return 'rowBgWhite'
        } else {
            return 'rowBgGray'
        }
    }
    return (
        <div className={styles.black_table}>
            <Table rowKey={rowKey} scroll={{ x: 350 }}  columns={tableColumns} dataSource={tableList} pagination={false} rowClassName={handelRowClassName} />
        </div>
    )
}
