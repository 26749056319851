import React, { useState, useEffect, useContext } from 'react';
import { Button, message, Spin, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import BigNumber from 'bignumber.js'

import CountDown from 'components/CountDown'
import { formatAmountWithDecimal, formatAmount } from '../../utils/format'
import { useActiveWeb3React } from '../../web3'
import { getChainType } from '../../web3/address'
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { getERC20Contract, sypoolMint, mintBlindBox } from '../../utils/handleContract'
import { mainContext } from '../../reducer';
// import { getSypoolTypeInfo } from '../../services/sypool'
import { getNftDetail } from '../../services/nftDetail';
import { useNeedSign } from "hooks/account"
import styles from './styles.module.scss';
import { chainTypeImgObj, chainTxtObj, chainFun } from '../../utils/networkConnect';
import {
    HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    HANDLE_SHOW_CONNECT_MODAL,
    waitingForConfirm,
} from "../../const";



let timer = null;
export default function NftBuyCard({ onCallback, info }) {
    const { t } = useTranslation()
    const history = useHistory();
    const [time, setTime] = useState()
    const [showRow, setShowRow] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [isSoldOut, setIsSoldOut] = useState(false)
    const { library, account, chainId } = useActiveWeb3React()
    const { tronLibrary, tronAccount, tronChainId, tronActive } = useActiveTronWeb();
    const { dispatch } = useContext(mainContext);
    const [showNFT, setShowNFT] = useState(false);
    const [blindBoxInfo, setBlindBoxInfo] = useState({})
    const [levelLoading, setLevelLoading] = useState(false)
    const [viewUrl, setViewUrl] = useState('')
    const [chainType, setChainType] = useState('')
    const { needSign } = useNeedSign();

    const initData = () => {
        let chainType = getChainType(chainId)
        setChainType(chainType)
        if (info.count - info.soldCount === 0) {
            setBtnDisabled(true)
            setIsSoldOut(true)
        }
        dealTime()
    }
    const dealNumber = (amount, decimal) => {
        let price = new BigNumber(new BigNumber(new BigNumber(amount).div(new BigNumber(10).pow(decimal))))
        return price.toString()
    }
    const dealTime = () => {
        // info.startTime = 1640242800000
        // info.endTime = 1640329200000
        if (Date.now() < info.startTime) {
            setTime(info.startTime / 1000)
            setBtnDisabled(true)
            setShowRow(true)
            
        } if (Date.now() > info.startTime && Date.now() < info.endTime) {
            setTime(info.endTime / 1000)
            setBtnDisabled(false)
            setShowRow(true)
        } if (Date.now() > info.endTime) {
            setShowRow(false)
        }
    }
    const goToBuy = () => {
      needSign(() => {
        const chainType = getChainType(chainId)
        setChainType(chainType)
        if (info.chainType !== chainType) {
          const chainTxt = chainTxtObj[info.chainType];
          if (tronChainId && !chainId && chainTxt !== 'Tron') {
            dispatch({
              type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
            });
            return message.success('Please connect wallet.', 1)
          }
      
          if (chainFun[chainTxt]) {
            chainFun[chainTxt]();
          } else if (chainTxt === 'Tron') {
            dispatch({
              type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
            });
            return message.success('Please connect TronLink.', 1)
          }
          return;
        }

        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: waitingForConfirm
        });
        toApprove()
      })
    }
    const toApprove = async () => {
        try {
            const erc20Contract = await getERC20Contract(library, info.priceToken.address);
            let approved = await erc20Contract.methods.allowance(account, info.contractAddress).call()
            if (approved > 0) {
                toMint('approve')
            } else {
                await erc20Contract.methods.approve(info.contractAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                    .send({ from: account })
                    .on('receipt', (_, receipt) => {
                        toMint('approve')
                    })
            }
        } catch (err) {
            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { show: false }
            });
        }
    }
    const toMint = async (mintType) => {
        await mintBlindBox(library, info.contractAddress, account, {
          type: info.type,
          mintType: mintType,
          price: info.price,
          _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess(receipt) },
            _onError: (err) => { callbackError() }
        })
      }
    const callbackSucess = (receipt) => {
        const { events: { Mint: { returnValues } } } = receipt;
        setLevelLoading(true)
        setShowNFT(true)
        queryTokenInfo(returnValues.nftAddress, chainType, returnValues.tokenId)
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
    }
    const callbackError = () => {
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
        dispatch({
            type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
            showFailedTransactionModal: true
        });
    }
    const queryTokenInfo = (contractAddress, chainType, tokenId) => {
        timer = setTimeout(() => {
            getNftDetail(contractAddress, chainType, tokenId).then(res => {
                if (res) {
                    clearTimeout(timer)
                    setLevelLoading(false)
                    setBlindBoxInfo({ popImg: res.image, popName: res.name })
                    setViewUrl(`/marketplace/${contractAddress}/${chainType}/${tokenId}`)
                    onCallback && onCallback()

                } else {
                    queryTokenInfo(contractAddress, chainType, tokenId)
                }
            }).catch(err => {
                console.log(err);
            })
        }, 2000)
    }
    const handleCancel = () => {
        setShowNFT(false)
    }
    const handleView = () => {
        history.push(viewUrl)
    }

    useEffect(() => {
        initData()
    }, [info])
    return (
        <>
            <Modal
                className={styles.box_modal}
                visible={showNFT}
                onCancel={handleCancel}
                title={t('pop_congratulations') + '!'}
                width={750}
                footer={[
                    <Button key="submit" type="primary" disabled={levelLoading} onClick={handleView}>
                        View now
                    </Button>
                ]}
            >
                {
                    !levelLoading && <div className={`fs26 fw700`}>you got "{blindBoxInfo.popName}" </div>

                }
                {
                    levelLoading
                        ? (
                            <div className={`mt40 mb20`}>
                                <Spin size="large" tip="The NFT information is being obtained, please wait"></Spin>
                            </div>
                        )
                        :
                        <div className={`${styles.pop_img} mt20`}>
                            <img width={200} src={blindBoxInfo.popImg}></img>
                        </div>
                }

            </Modal>
            <div className={'df_h5 pt20 pb20 pl10 pr10'}>
                <div className={styles.card_cover} style={{backgroundImage:`url(${info.image})`}}>
                    {/* <img src={info.image}></img> */}
                </div>
                <div className={styles.card_info}>
                    <div className={'page_content_title  pb10'}>{info.name}</div>
                    <div className={`df mt10`}>
                        <div className={`${styles.card_base_box} ${styles.card_chain}`}>
                            <div className={`${styles.txt_tag}`}>Blockchain</div>
                            <div className={`df_center ${styles.txt_content} fw600`}>
                                <div><img className={`mr5 ${styles.chainType_img}`} width={14} height={14} src={chainTypeImgObj[info.chainType]} /><span>{chainTxtObj[info.chainType]}</span></div>
                            </div>
                        </div>
                        <div className={styles.card_base_box}>
                            <div className={`fs14`}>Creator</div>
                            {info.creator && <div className={`df_center ${styles.txt_content} fw600`}><img className={`mr5`} width={14} height={14} src={info.creator.avatar} /> <span className={`text_hidden_1`}> {info.creator.name}</span></div>
                            }
                        </div>
                        <div className={styles.card_base_box_center}>
                            <div className={`fs14`}>Items</div>
                            <div className={`${styles.txt_content} fw600`}>{info.count}</div>
                        </div>
                    </div>
                    {
                        showRow &&
                        <div className={`space-between-center-h5 mt15`}>
                            <div className={`df`}>
                                <div className={`${styles.card_base_item}  ${styles.card_chain}`}>
                                    <div className={`fs14`}>Quantity</div>
                                    <div className={`df ${styles.txt_content}  fw700`}>{info.maxCount-info.count}</div>
                                </div>
                                <div className={styles.card_base_item}>
                                    <div className={`fs14`}>Price</div>
                                    <div className={`df ${styles.txt_content}  fw700`}>{info.priceToken ? dealNumber(info.price, info.priceToken.decimals) : null} {info.priceToken ? info.priceToken.symbol : null}</div>
                                </div>
                                <div >
                                    <div className={`fs14`}>{btnDisabled ? 'Start at' : 'End at'}</div>
                                    <div className={styles.countDown}>
                                        <CountDown onEnd={dealTime} time={time}></CountDown>
                                    </div>
                                </div>
                            </div>
                            <Button onClick={goToBuy} disabled={btnDisabled} className={`w200_h5 h40 ${styles.btn_buy}`} type='primary'>{isSoldOut ? 'Sold Out' : 'Buy'}</Button>
                        </div>
                    }

                    <div className={`mt50`}>
                        <div className={`fs24 fw700`}>Description</div>
                        <div style={{whiteSpace: 'pre-wrap'}} className={'fs14'} dangerouslySetInnerHTML={{ __html: t(`${info.description || ''}`) }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
