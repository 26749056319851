import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';

import { useList } from "../../services/sypool";
import styles from "./styles.module.scss";
import BackButton from "components/BackButton";
import NftPlusCard from "components/NftPlusCard";

export default function Season() {
    const history = useHistory();
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const list = useList('define-seasons',setLoading)
    const goToItem = (item) => {
        console.log(item);
        history.push(`/collection/${item.name}`)
    }

    return (
        <div className={`page_content_h5`}>
            <div className={``}>
                <div className={styles.title}>
                    <BackButton></BackButton>
                </div>
                <h1 className={'page_content_title mt20'}> Season</h1>
                {
                    loading?<Spin className={`wp100 tac mt20`} size="large"></Spin>:
                    <div className={styles.card_list}>
                        <Row gutter={20} className={`wp100`}>
                            {
                                list.map((item, index) => {
                                    return (
                                        <Col xs={12} md={8} lg={6}>
                                            <NftPlusCard onClick={goToItem} key={index} item={item}></NftPlusCard>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                }
                <div>
                    <h1 className={`page_content_title mt50`}>About Seasons</h1>
                    <div className={`${styles.des}`}>
                        <div className={`mt20 mb20`}>
                            <p >{t('nft_plus_text1')}</p>
                            <p >{t('nft_plus_text2')}</p>
                            {/* <h3>{t('sypool_rule')}</h3> */}
                            <div className={`p20 ${styles.des_content}`}>
                                <ul>
                                    <li className={'fs14'}>{t('nft_plus_text3')}</li>
                                    <li className={'fs14'}>{t('nft_plus_text4')}</li>
                                    <li className={'fs14'}>{t('nft_plus_text5')} </li>
                                    <li className={'fs14'}>{t('nft_plus_text6')}</li>
                                </ul>
                            </div>
                            <p className={`mt10`}>{t('nft_plus_text7')}</p>
                            <p className={`mt20`}>{t('nft_plus_text8')}</p>
                            <div className={`p20 ${styles.des_content}`}>
                            <p >{t('nft_plus_text9')}</p>
                                <ul>
                                    <li className={'fs14'}>{t('nft_plus_text10')}</li>
                                    <li className={'fs14'}>{t('nft_plus_text11')}</li>
                                </ul>
                            </div>

                            <p className={`mt20`}>{t('nft_plus_text12')}</p>
                            <div className={`p20 ${styles.des_content}`}>
                            <p >{t('nft_plus_text13')}</p>
                                <ul>
                                    <li className={'fs14'}>{t('nft_plus_text14')}</li>
                                    <li className={'fs14'}>{t('nft_plus_text15')}</li>
                                </ul>
                            </div>

                            <p className={`mt20`}>{t('nft_plus_text16')}</p>
                            <div className={`p20 ${styles.des_content}`}>
                                <p >{t('nft_plus_text17')}</p>
                                <ul>
                                    <li className={'fs14'}>{t('nft_plus_text18')}</li>
                                    <li className={'fs14'}>{t('nft_plus_text19')}</li>
                                </ul>
                            </div>
                            <p className={`mt20`}>{t('nft_plus_text20')}</p>
                            <div className={`p20 ${styles.des_content}`}>
                                <p >{t('nft_plus_text21')}</p>
                                <ul>
                                    <li className={'fs14'}>{t('nft_plus_text22')}</li>
                                    <li className={'fs14'}>{t('nft_plus_text23')}</li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
