import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Spin } from 'antd'
import InfiniteScroll from "react-infinite-scroll-component";

import { useCollectionList, queryList } from "../../services/collectionList"
import { useActiveWeb3React } from "../../web3";
import NoData from "components/NoData";
import styles from './styles.module.scss';

export default function CollectionManage() {
  const history = useHistory()
  const { library, account, active, chainId } = useActiveWeb3React()
  const [pageNo, setPageNo] = useState(1)
  const [loading, setLoading] = useState(false)
  const { collectionList, total } = useCollectionList(1, 9999, '', '', setLoading, account)

  const getList = () => {
    setPageNo(pageNo + 1)
  }
  const gotoCreate = () => {
    history.push('/collectionManage/createCollection')
  }
  const gotoCreateNFT = () => {
    history.push('/collectionManage/createNFT')
  }
  const goToCollection = (item) => {
    history.push(`/collectionManage/collectionDetail/${item.type}`)
  }
  return (
    <div className={`${styles.collectionManage}`}>
      <div className='space-between'>
        <div>
          <div className={styles.collection_title}>My Collection</div>
          <div>Create,manage and sell unique NFTs,try generating NFTs with AI</div>
        </div>
        {
          collectionList.length > 0 &&
          <div className='mt40'>
            <Button className={`w200 h50 fw600 mr20 ${styles.create_btn}`} onClick={gotoCreateNFT}>+ Create a NFT</Button>
            <Button type='primary' className='w200 h50 fw600' onClick={gotoCreate}>+ Create a collection</Button>
          </div>
        }
      </div>
      <div>
        {
          loading ? <Spin className={`wp100 mt40`} size="large" /> :

            <InfiniteScroll
              dataLength={collectionList.length}
              next={getList}
              hasMore={collectionList.length < total}
              loader={
                <div style={{ width: 216, textAlign: 'center', margin: 'auto' }}>
                  <Spin size={`large`} />
                </div>
              }
            >
              <div className={styles.main_list}>
                {
                  collectionList.map(item => (
                    <div key={item.name} className={styles.collection_item} onClick={() => { goToCollection(item) }}>
                      <div className={styles.item_cover}>
                        <img className={styles.cover} src={item.image}></img>
                        {/* <img className={styles.chainType} src={chainTypeImgObj[item.chainType]}></img> */}
                      </div>
                      <div className={styles.collection_creator}>
                        <img className={styles.avatar} src={item.creator && item.creator.avatar}></img>
                      </div>
                      <div className={`text_hidden_1 ${styles.name}`}>{item.name}</div>
                      <div className={`text_hidden_1 ${styles.des}`}>{item.creator ? item.creator.name : ''}</div>
                      <div className={styles.collection_des} dangerouslySetInnerHTML={{ __html: `${item.description}` }}></div>
                    </div>
                  ))
                }
                {
                  collectionList.length === 0 && <div className={`wp100 tac mt40 ${styles.noCollection_tip}`}>
                    <div className='c_purple'>You don't have an <span className='fw600'>Collection</span> yet.<br/>
                      Please create your first collection and NFT！</div>
                    <div className='mt20'>
                      {/* <Button type='primary' className={`w200 h50 fw600 mr20 ${styles.create_btn}`} onClick={gotoCreateNFT}>+ Create a NFT</Button> */}
                      <Button type='primary' className={`w200 h50 fw600 mr20 ${styles.create_btn}`} onClick={gotoCreate}>+ Create a collection</Button>
                    </div>
                  </div>
                }
              </div>
            </InfiniteScroll>
        }
      </div>
    </div>
  )
}
