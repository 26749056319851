import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Progress, Radio, Modal, message, Pagination, Table, Spin, Steps,Space } from 'antd';
import { ArrowRightOutlined, LinkOutlined, CheckCircleOutlined, MinusCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import BigNumber from 'bignumber.js'
import moment from 'moment';
import { defaultAbiCoder } from '@ethersproject/abi';

import { fetchproposalInfo, fetchpClaimInfo, fetchpVotedList, fetchPerposalHistory } from "../../../services/vote"
import { getContract, useActiveWeb3React } from "../../../web3";
import { getVoteAddress } from '../../../web3/address';
import DeFineDAO from "../../../web3/abi/DeFineDAO.json";
import DeFineProposal from "../../../web3/abi/DeFineVotingPortal.json"
import DeFineCommonProposal from "../../../web3/abi/DeFinePortalV3.json";
import DeFineVotingProposal from "../../../web3/abi/DeFineVotingPortalV2.json";
import DeFineVotingProposalNew from "../../../web3/abi/DeFineVotingPortalV2New.json";
import DeFineContractProposal from "../../../web3/abi/DeFineVotingPortalV3.json";
import BackButton from "components/BackButton";
import { useNeedSign } from "hooks/account"
import CountDown from 'components/CountDown';

import styles from "../styles.module.scss";
import clockIcon from "../../../assets/img/dao/clock.png";
import { formatAmountWithDecimal } from "../../../utils/format";
import { mainContext } from "../../../reducer";
import SimpleMdeReact from "react-simplemde-editor";
import { setupEthMainnet } from '../../../utils/networkConnect';
import EnglishAuctionNFT from "../../../web3/abi/EnglishAuctionNFT.json"
import FixedSwapNFT from "../../../web3/abi/FixedSwapNFT.json"

import {
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
} from "../../../const";

const { Step } = Steps;

export default function VoteInfo() {
    const { t } = useTranslation()
    const history = useHistory();
    const { dispatch } = useContext(mainContext);
    const { chainType, contractAddress, id } = useParams();
    const { library, account, chainId } = useActiveWeb3React()
    const [voteDeatil, setVoteDeatil] = useState({})
    const [voteInfo, setVoteInfo] = useState({})
    const [ownerVoteInfo, setOwnerVoteInfo] = useState({})
    const [voteStatus, setVoteStatus] = useState('')
    const [progressPercent, setProgressPercent] = useState(0)
    const [progressPercentTxt, setProgressPercentTxt] = useState(0)
    const [progressBg, setProgressBg] = useState("#dedede")
    // const [myVotedCount, setMyVotedCount] = useState(0)
    const [visibleVoteTip, setVisibleVoteTip] = useState(false)
    const [valueRadio, setValueRadio] = useState('')
    const [isCreator, setIsCreator] = useState(false)
    const [isEndTime, setIsEndTime] = useState(false)
    const [showVotedList, setShowVotedList] = useState(false)
    const [votedList, setVotedList] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tableTotal, setTableTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [mdeShow, setMdeShow] = useState(false)
    const [voteDes, setVoteDes] = useState('')
    const [isWallet, setIsWallet] = useState(true)
    const { needSign } = useNeedSign();
    const [forList, setForList] = useState([])
    const [againstList, setAgainstList] = useState([])
    const [historyList, setHistoryList] = useState([])
    const [ currentFeeMax, setCurrentFeeMax] = useState(10000)
    const [ targetFee, setTargetFee] = useState()
    const [ refresh, setRefresh ] = useState(1)
    const [ voteHistoryType, setVoteHistoryType ] = useState()
    const [ quorumVoteCount, setQuorumVoteCount ] = useState()
    const proposalAbi = {
        '0xd971c877bd66f0438511fe771c8eaa5ea273576d':DeFineDAO, //sip old
        '0x506a5dcf47a27f6a48a23512afa6b32b0f612ce7':DeFineDAO, //sip old
        '0x09e5b8821869dd8ddd8d704f4271ec4c8edc9abe':DeFineProposal,//new dev
        '0xab8e8d9bb650a0c3f91bac1357f90d5efab0d5f7':DeFineProposal,//new pro
        '0x1e2a9456e5f6b63b6b55232010b9ae9dcf22caaf':DeFineCommonProposal,//new pro 2
        '0xfdabe319f98db4a45c8c56e8cf66e4a41673ee8a':DeFineVotingProposal,//dev
        '0x9b1ddf053136bc9710c81a2a89162b9fa0a2640d':DeFineVotingProposalNew,
        '0x63124ff062881888b6fbc348e5932f74b7cf3aa0':DeFineContractProposal,
        '0x8a1bc81d1ea6e6072f565b825dad9dd993e68ebc':DeFineVotingProposalNew,//new dev
        '0xb298128923edc08f5542e9db66e2515192c56a4b':DeFineCommonProposal,//sip new new dev
        '0x0cc1cbe4cde1ebbe7789e95a50ef297beb2da413':DeFineContractProposal,//dip new new dev

    }
    const targetContractAbi = {
        "0x8204830081443a28254ab9e4f3c1c848bfd1d6d6":{abi:FixedSwapNFT,name: 'fixedswap'},//dev
        "0x53ec32583177ac5b2331ea7d1c11685317c03cdc":{abi:FixedSwapNFT,name: 'fixedswap'},
        "0xe4f9783cc2e4fef6ef3353826c03aed9b68e796f":{abi:EnglishAuctionNFT,name: 'auction'},//dev
        "0xae2c96d0643180aed6dc16192b6e1a0113b156cf":{abi:EnglishAuctionNFT,name: 'auction'},
    }
    const proposalSip = {
        '0xd971c877bd66f0438511fe771c8eaa5ea273576d':'SIP',
        '0x506a5dcf47a27f6a48a23512afa6b32b0f612ce7':'SIP',
        '0x09e5b8821869dd8ddd8d704f4271ec4c8edc9abe':'SIP',//new dev
        '0xab8e8d9bb650a0c3f91bac1357f90d5efab0d5f7':'SIP',//new pro
        '0x1e2a9456e5f6b63b6b55232010b9ae9dcf22caaf':'SIP',//new pro 2
        '0xfdabe319f98db4a45c8c56e8cf66e4a41673ee8a':'DIP',
        '0x9b1ddf053136bc9710c81a2a89162b9fa0a2640d':'DIP',
        '0x63124ff062881888b6fbc348e5932f74b7cf3aa0':'DIP',
        '0x8a1bc81d1ea6e6072f565b825dad9dd993e68ebc':'DIP',//new dev
        '0xb298128923edc08f5542e9db66e2515192c56a4b':'SIP',//sip new new dev
        '0x0cc1cbe4cde1ebbe7789e95a50ef297beb2da413':'DIP',//dip new new dev
      }
      const proposalSipBase = {
        '0xd971c877bd66f0438511fe771c8eaa5ea273576d':0,//old
        '0x506a5dcf47a27f6a48a23512afa6b32b0f612ce7':0,//old
        '0x09e5b8821869dd8ddd8d704f4271ec4c8edc9abe':37,//new dev
        '0xab8e8d9bb650a0c3f91bac1357f90d5efab0d5f7':18,//new pro
        '0x1e2a9456e5f6b63b6b55232010b9ae9dcf22caaf':34,//new pro 2
        '0xfdabe319f98db4a45c8c56e8cf66e4a41673ee8a':0,//dip dev
        '0x8a1bc81d1ea6e6072f565b825dad9dd993e68ebc':27,//dip new dev
        '0x9b1ddf053136bc9710c81a2a89162b9fa0a2640d':0,//dip pro
        '0x63124ff062881888b6fbc348e5932f74b7cf3aa0':3,//dip pro
        '0xb298128923edc08f5542e9db66e2515192c56a4b':46,//sip new new dev
        '0x0cc1cbe4cde1ebbe7789e95a50ef297beb2da413':40,//dip new new dev
      }
      const proposalQuorumCount = {
        '0xd971c877bd66f0438511fe771c8eaa5ea273576d':false,//old
        '0x506a5dcf47a27f6a48a23512afa6b32b0f612ce7':false,//old
        '0x09e5b8821869dd8ddd8d704f4271ec4c8edc9abe':true,//new dev
        '0xab8e8d9bb650a0c3f91bac1357f90d5efab0d5f7':true,//new pro
        '0x1e2a9456e5f6b63b6b55232010b9ae9dcf22caaf':true,//new pro 2
        '0xfdabe319f98db4a45c8c56e8cf66e4a41673ee8a':false,//dip dev
        '0x8a1bc81d1ea6e6072f565b825dad9dd993e68ebc':true,//dip new dev
        '0x9b1ddf053136bc9710c81a2a89162b9fa0a2640d':true,//dip pro
        '0x63124ff062881888b6fbc348e5932f74b7cf3aa0':true,//dip pro
        '0xb298128923edc08f5542e9db66e2515192c56a4b':true,//sip new new dev
        '0x0cc1cbe4cde1ebbe7789e95a50ef297beb2da413':true,//dip new new dev
      }

    const tableColumns = [
        { title: "Users", align: "center", key: "name", width: 200, render: record => <a onClick={() => { history.push(`/profile/${record.address}`) }}>{formatAddress(record.name)}</a> },
        { title: "Choose", align: "center", key: "option", dataIndex: "option", width: 110, render: text => <span className={`${text === "for" ? "vote_list_yes" : "vote_list_no"}`} >{text === "for" ? "For" : "Against"}</span> },
        { title: "Votes", align: "center", key: "count", dataIndex: "count", width: 160, render: count => <span>{formatAmountWithDecimal(count, 18, 0)}</span> },
    ]
    const formatAddress = (address) => {
        if (address.length > 20) {
            return address.slice(0, 6) + '...' + address.slice(-3)
        } else {
            return address
        }
    }

    const initData = () => {
        getInfo()
        getVotedListSimple('3', 'for')
        getVotedListSimple('3', 'against')
        queryPerposalHistory()
        
    }
    const queryCurrentFeeMax = async (info) => {
        let feeMax = await getContract(library, targetContractAbi[info.targets[0]].abi.abi, contractAddress).methods.feeMax().call()
        setCurrentFeeMax(feeMax)
    }

    const getVotedListSimple = async (pageSize, option) => {
        let res = await fetchpVotedList({ chainType, contractAddress, id, pageNo, pageSize, option })
        res.list.length > 0 && res.list.forEach(item => {
            Object.assign(item, item.voter)
        })
        if (res.list.length === 1) {
            [1, 2].forEach(item => {
                let obj = { name: '', count: '' }
                res.list.push(obj)
            })
        }
        if (res.list.length === 2) {
            [1].forEach(item => {
                let obj = { name: '', count: '' }
                res.list.push(obj)
            })
        }
        if (option === 'for') {
            setForList(res.list)
        }
        if (option === 'against') {
            setAgainstList(res.list)
        }
    }
    const base64tobytes = (base64) => {
        const buffer = Buffer.from(base64, 'base64');
        const bufString = buffer.toString('hex');
        return '0x'+bufString
    }
    const addSip = (id,contractAddress) => {
        let sip = proposalSip[contractAddress]
        let num = id + proposalSipBase[contractAddress]
        if(num<10){
          sip += ('00'+num)
        }
        if(num>9&&num<100){
          sip += ('0'+num)
        }
        return sip
      }
    const getInfo = async () => {
        setLoading(true)

        let res = await fetchproposalInfo({ chainType, contractAddress, id, library, account })
        if(res.calldatas){
            setTargetFee(defaultAbiCoder.decode(['uint256'],base64tobytes(res.calldatas[0])).toString())
        }
        if(res.target){
            queryCurrentFeeMax(res)
        }
        res.userVotedCount = res.currentUserCount ? Math.floor(new BigNumber(res.currentUserCount).div(new BigNumber(10).pow(18)).toString()) : 0
        res.sip = addSip(res.id,res.contractAddress)
        setVoteDeatil(res)
        if (Date.now() > res.endTime * 1000) {
            setIsEndTime(true)
        }
        if (account) {
            if ((res.creator.address && account) && res.creator.address.toLowerCase() === account.toLowerCase()) {
                setIsCreator(true)
            }
        } else {
            setIsCreator(false)
        }
        setLoading(false)
        setMdeShow(true)
        getContractInfo(res.chainType)
    }
    const getContractInfo = async (chainType) => {
        setLoading(true)
        const proposalsState = [
            { s: 'soon', t: 'Review' },
            { s: 'voting', t: 'Active' },
            { s: 'canceled', t: 'Canceled' },
            { s: 'canceled', t: 'Failed' },
            { s: 'executed', t: 'Passed' },
            { s: 'queued', t: 'Queued' },
            { s: 'expired', t: 'Expired' },
            { s: 'executed', t: 'Executed' }
        ]
        
        let state = await getContract('', proposalAbi[contractAddress].abi, contractAddress, chainType).methods.state(id).call()
        let voteInfo = await getContract('', proposalAbi[contractAddress].abi, contractAddress, chainType).methods.proposals(id).call()
        let quorumVoteCount
        if(proposalQuorumCount[contractAddress]){
            quorumVoteCount = await getContract('', proposalAbi[contractAddress].abi, contractAddress, chainType).methods.getProposalQuorumCount(id).call()
        }else {
            quorumVoteCount = await getContract('', proposalAbi[contractAddress].abi, contractAddress, chainType).methods.quorumVoteCount().call()
        }
        
        setQuorumVoteCount(quorumVoteCount)
        if (voteInfo.executed) {
            setVoteStatus(proposalsState[7])
        } else {
            setVoteStatus(proposalsState[state])
        }
        accountQueryContractInfo()
        dealProgress(voteInfo.forVotes, voteInfo.againstVotes)
        setVoteInfo(voteInfo)
        setLoading(false)
    }
    const accountQueryContractInfo = async () => {
        if (account) {
            let ownerVoteInfo = await getContract('', proposalAbi[contractAddress].abi, contractAddress, chainType).methods.receipts(id, account).call()
            if (ownerVoteInfo.hasVoted) {
                setValueRadio(ownerVoteInfo.support)
            }
            setOwnerVoteInfo(ownerVoteInfo)
            setIsWallet(true)
        } else {
            setIsWallet(false)
        }
    }

    const dealProgress = (forVotes, againstVotes) => {
        let voteCount = new BigNumber(forVotes).plus(new BigNumber(againstVotes))
        let voteMinus = new BigNumber(forVotes).minus(new BigNumber(againstVotes))
        if (voteCount > 0) {
            let res = 0
            let txt = 0
            if (voteMinus > 0) {
                res = (new BigNumber(forVotes).div(new BigNumber(voteCount)) * 100).toFixed(2)
                txt = (new BigNumber(forVotes).div(new BigNumber(voteCount)) * 100).toFixed(2)
            } else {
                res = (100 - new BigNumber(againstVotes).div(new BigNumber(voteCount)) * 100).toFixed(2)
                txt = (new BigNumber(againstVotes).div(new BigNumber(voteCount)).toFixed(4) * 100).toFixed(2)
            }
            setProgressBg('#de5757')
            setProgressPercent(res)
            if(txt>99&&txt<100){
                setProgressPercentTxt('> 99')
            }else{
                setProgressPercentTxt(txt)
            }
        } else {
            setProgressPercent(0)
            setProgressPercentTxt(0)
        }

    }
    const openVoteModal = () => {
        setVisibleVoteTip(true)
    }
    const handleCancel = () => {
        setVisibleVoteTip(false)
    }
    const onChangeRadio = (e) => {
        setValueRadio(e.target.value)
    }
    const handleVote = () => {
        if (chainId !== 1 && chainId !== 4) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        let voteToken = voteDeatil.voteToken.address
        let blockNumber = voteInfo.blockHeight
        let count = new BigNumber(voteDeatil.userVotedCount).times(new BigNumber(10).pow(18))
        setVisibleVoteTip(false)
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        voteSign({ chainType, voteToken, blockNumber, count, library, account })
    }
    const voteSign = async ({ chainType, voteToken, blockNumber, count, library, account }) => {
        let res = await fetchpClaimInfo({ chainType, voteToken, blockNumber, count, library, account })
        toVote(res)
    }
    const toVote = async (params) => {
        let sendPar = { from: account }
        let support = valueRadio === 'For' ? true : false
        return await getContract(library, proposalAbi[contractAddress].abi, voteDeatil.contractAddress).methods.castVoteBySig(voteDeatil.id, support, params.count, params.signature)
            .send(sendPar)
            .on('transcationHash', () => { })
            .on('receipt', () => { callbackSucess("vote") })
            .on('error', callbackError)
    }
    const callbackSucess = (type) => {
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
        message.success("Success")
        if (type === 'vote') {
            setVoteInfo(ownerVoteInfo.hasVoted = true)
        } else if (type === 'claim') {
            setVoteInfo(voteInfo.withdrawn = true)
        }
        getContractInfo(chainType)
    }
    const callbackError = () => {
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
        message.success("Error")
    }
    const undo = async () => {
        if (chainId !== 1 && chainId !== 4) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        let sendPar = { from: account }
        return await getContract(library, proposalAbi[contractAddress].abi, voteDeatil.contractAddress).methods.cancel(voteDeatil.id)
            .send(sendPar)
            .on('transcationHash', () => { })
            .on('receipt', () => { callbackSucess("claim") })
            .on('error', callbackError)
    }
    const claim = async () => {
        if (chainId !== 1 && chainId !== 4) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        let sendPar = { from: account }
        return await getContract(library, proposalAbi[contractAddress].abi, voteDeatil.contractAddress).methods.claim(voteDeatil.id)
            .send(sendPar)
            .on('transcationHash', () => { })
            .on('receipt', () => { callbackSucess("claim") })
            .on('error', callbackError)
    }
    const handelRowClassName = (record, index) => {
        if (index % 2 > 0) {
            return 'rowBgGray'
        } else {
            return 'rowBgWhite'
        }
    }
    const openList = (option) => {
        setShowVotedList(true)
        setVoteHistoryType(option)
        setPageNo(1)
        getVotedList(1,option)
    }
    const cancelListModal = () => [
        setShowVotedList(false)

    ]
    const getVotedList = async (pageNo,option) => {
        setVotedList([])
        let res = await fetchpVotedList({ chainType, contractAddress, id, library, account, pageNo, pageSize, option })
        res.list.forEach(item => {
            Object.assign(item, item.voter)
        })
        setVotedList(res.list)
        setTableTotal(res.totalCount)

    }
    const onShowSizeChange = (current) => {
        setPageNo(current)
        getVotedList(current,voteHistoryType)
    }
    const dealVoteContent = (editor) => {
        let des = editor.markdown(voteDeatil.description)
        setVoteDes(des)
        setTimeout(() => {
            setMdeShow(false)
        }, 100)
    }
    const connectWallet = () => {
        needSign()
    }
    const queryPerposalHistory = async () => {
        let res = await fetchPerposalHistory({ chainType, contractAddress, id })
        setHistoryList(res)
    }
    const goToSnapshot = (snapshot) => {
        if (chainType === "rinkeby") {
            window.open(`https://rinkeby.etherscan.io/block/${snapshot}`)
        } else if (chainType === "goerli") {
            window.open(`https://goerli.etherscan.io/block/${snapshot}`)
        } else {
            window.open(`https://etherscan.io/block/${snapshot}`)
        }
    }
    const goToTX = (e) => {
        if (e.chainType === "rinkeby") {
            window.open(`https://rinkeby.etherscan.io/tx/${e.transactionHash}`)
        } else if (e.chainType === "goerli") {
            window.open(`https://goerli.etherscan.io/tx/${e.transactionHash}`)
        } else {
            window.open(`https://etherscan.io/tx/${e.transactionHash}`)
        }
    }
    const queue = async () => {
        if (chainId !== 1 && chainId !== 4) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        let sendPar = { from: account }
        return await getContract(library, proposalAbi[contractAddress].abi , voteDeatil.contractAddress).methods.queue(voteDeatil.id)
            .send(sendPar)
            .on('transcationHash', () => { })
            .on('receipt', () => { callbackSucess("queue") })
            .on('error', callbackError)
    }
    const execute = async () => {
        if (chainId !== 1 && chainId !== 4) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        let sendPar = { from: account }
        return await getContract(library, proposalAbi[contractAddress].abi , voteDeatil.contractAddress).methods.execute(voteDeatil.id)
            .send(sendPar)
            .on('transcationHash', () => { })
            .on('receipt', () => { callbackSucess("executed") })
            .on('error', callbackError)
    }

    const onEnd = () => {
        setRefresh(refresh+1)
    }
    const allProposal = () => {
        history.push('/vote')
    }

    useEffect(() => {
        initData()
    }, [library, account, chainId,refresh])
    return (
        <div className={`page_content_h5`}>
            <div className={styles.title}>
                <BackButton onClick={allProposal} text={t('all_proposal')} />
            </div>
            <div className={`mt20`}>
                {
                    loading ? <Spin className={`wp100 mt40`} size="large" /> :
                        <Row gutter={16} className={styles.vode_detail}>
                            <Col xs={24} md={24} lg={16}>
                                <div className={`${styles.vote_info} mb20`}>
                                    <div className={styles.sip}>{voteDeatil.sip}</div>
                                    {/* <div className={`fs14 c_9a mb4`}>Created at {moment(voteDeatil.createTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</div> */}
                                    <div className={`fs22 fw700 mt10 lh28`}>{voteDeatil.title}</div>
                                    <div className={`space-between fs14  mt10 ${styles.m_f_10}`}>
                                        <div className={`df_center`}>
                                            <img width={14} src={clockIcon}></img>
                                            <div className={`pl5 status_voting_time`}>{voteStatus.s === "soon" ? "Start at" : "Ended at"} {voteStatus.s === "soon" ? moment(voteDeatil.startTime * 1000).format("YYYY-MM-DD HH:mm:ss") : moment(voteDeatil.endTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        </div>
                                        {
                                            <div className={`${styles.vote_status_bg} status_${voteStatus.s}`}>
                                                <div className={`status_${voteStatus.s}_txt`}>{voteStatus.t}</div>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className={styles.vote_progress}>
                                        <div className={`space-between fs14`}>
                                            <div>{t('vote_progress_for')} <span className={styles.vote_yes}>{formatAmountWithDecimal(voteInfo.forVotes, 18, 0)} </span></div>
                                            <div>{t('vote_progress_against')} <span className={styles.vote_no}>{formatAmountWithDecimal(voteInfo.againstVotes, 18, 0)} </span></div>
                                        </div>
                                        <div className={`pos_r`}>
                                            <Progress percent={progressPercent} strokeLinecap={"square"} showInfo={false} strokeWidth={30} strokeColor={"#37ab2a"} trailColor={progressBg}>
                                            </Progress>
                                            <span className={styles.progress_percent}>{progressPercentTxt}%</span>
                                        </div>
                                        <Row gutter={16}>
                                            <Col xs={24} md={12} lg={12}>
                                                <div className={`${styles.simple_voted_box} mr10`}>
                                                    <div className={`space-between ${styles.simple_voted_head} ${styles.bb_hr}`}><span className={styles.c_yes}>For</span><span className={`c_m`}>Votes</span></div>
                                                    {
                                                        forList.length > 0 ? forList.map((item,index) => (
                                                            <div key={item.name+index} className={`space-between ${styles.simple_voted_item} ${styles.bb_hr_f4}`}><span>{item.name}</span><span className={`fw700`}>{item.count ? formatAmountWithDecimal(item.count, 18, 0) : ''}</span></div>
                                                        ))
                                                            : <div className={styles.simple_no_data}>
                                                                No Data
                                                            </div>
                                                    }
                                                    <div className={`cp tac fz12 p10 ${styles.bt_hr}`} onClick={() => { openList('for') }}>View all <ArrowRightOutlined /></div>
                                                </div>
                                            </Col>
                                            <Col xs={24} md={12} lg={12}>
                                            <div className={styles.simple_voted_box}>
                                                <div className={`space-between ${styles.simple_voted_head} ${styles.bb_hr}`}><span className={styles.c_no}>Against</span><span className={`c_m`}>Votes</span></div>
                                                {
                                                    againstList.length > 0 ? againstList.map((item,index) => (
                                                        <div key={item.name+index}  className={`space-between ${styles.bb_hr_f4} ${styles.simple_voted_item}`}><span>{item.name}</span><span className={`fw700`}>{item.count ? formatAmountWithDecimal(item.count, 18, 0) : ''}</span></div>
                                                    ))
                                                        : <div className={styles.simple_no_data}>
                                                            No Data
                                                        </div>
                                                }
                                                <div className={`cp tac fz12 p10 ${styles.bt_hr}`} onClick={() => { openList('against') }}>View all <ArrowRightOutlined /></div>

                                            </div>
                                            </Col>
                                        </Row>
                                        <div className={styles.valid_votes_tip}>Valid votes : For votes + Against votes ≥ {formatAmountWithDecimal(quorumVoteCount,18,0)} votes</div>
                                    </div>
                                    <div className={`mt10 ${styles.vote_content}`} >
                                        {
                                            (proposalAbi[contractAddress]===DeFineVotingProposal||proposalAbi[contractAddress]===DeFineVotingProposalNew || proposalAbi[contractAddress]===DeFineContractProposal)&&voteDeatil.targets&&
                                            <ul className={styles.targetFeeTip}>
                                                <li>Set <a target="_blank" className={`c_blue`} href={`https://etherscan.io/address/${voteDeatil.targets[0]}`}>{targetContractAbi[voteDeatil.targets[0]].name}</a>  fees to <span className={'fw700'}>{targetFee/currentFeeMax*100}</span>%</li>
                                            </ul>
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: voteDes }} style={{ whiteSpace: 'pre-wrap' }}></div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} md={24} lg={8} className={`fs14`}>
                                <div className={styles.owner_vote}>
                                    <div className={styles.box_header}>{t('vote')}</div>
                                    {
                                        !isWallet ? <div className={`mt20 mb20 tac`}><Button onClick={connectWallet} className={`btn_border `}>Connect wallet</Button></div> :
                                            voteDeatil.userVotedCount === 0 ? <div className={'df_center mt20 mb20'}>{t('no_avaliable_votes')}</div> :
                                                <div>
                                                    {
                                                        ownerVoteInfo.hasVoted ?
                                                            <>
                                                                {
                                                                    voteStatus.s === "voting" ? <div className={styles.owner_vote_main}>
                                                                        <div className={`space-between`}>
                                                                            <span>{t('vote_your_choice')}</span>
                                                                            <span className={`${ownerVoteInfo.support ? "vote_list_yes" : "vote_list_no"}`}>{ownerVoteInfo.support ? "For" : "Against"}</span>
                                                                        </div>
                                                                        <div className={`space-between mt10`}>
                                                                            <span>{t('vote_your_votes')}</span>
                                                                            <span>{formatAmountWithDecimal(ownerVoteInfo.votes, 18, 0)}</span>
                                                                        </div>
                                                                    </div>
                                                                        :
                                                                        <div className={styles.owner_vote_main}>
                                                                            <div className={`space-between`}>
                                                                                <span>{t('vote_your_choice')}</span>
                                                                                <span className={`${ownerVoteInfo.support ? "vote_list_yes" : "vote_list_no"}`}>{ownerVoteInfo.support ? "For" : "Against"}</span>
                                                                            </div>
                                                                            <div className={`space-between mt10`}>
                                                                                <span>{t('vote_your_votes')}</span>
                                                                                <span>{formatAmountWithDecimal(ownerVoteInfo.votes, 18, 0)}</span>
                                                                            </div>
                                                                            {/* <div className={`mt10 br6 ${styles.owner_status} status_${voteStatus.s} `}>
                                                                                <span className={`status_${voteStatus.s}_txt`}>{voteStatus.t}</span>
                                                                            </div> */}
                                                                        </div>
                                                                }
                                                            </> :
                                                            <>
                                                                {
                                                                    <div className={styles.owner_vote_main}>
                                                                        <div>
                                                                            <div className={`mb10`}>{t('vote_your_choice')}</div>
                                                                            <div className={styles.vote_choose}>
                                                                                <Radio.Group onChange={onChangeRadio} value={valueRadio} buttonStyle="solid">
                                                                                    <Space direction="vertical">
                                                                                        <Radio value="For">For</Radio>
                                                                                        <Radio value="Against">Against</Radio>
                                                                                    </Space>
                                                                                </Radio.Group>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`mt20 mb20`}>
                                                                            <div className={'space-between'}>
                                                                                <span>Available votes</span>
                                                                                <span>{voteDeatil.userVotedCount ? formatAmountWithDecimal(voteDeatil.userVotedCount, 0, 0) : '--'}</span>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            voteStatus.s === "voting" ? <Button disabled={!valueRadio} onClick={openVoteModal} className={`wp100`} type={'primary'}>{t('btn_vote_now')} </Button> :
                                                                                voteStatus.s === "soon" ? <Button disabled={true} className={`wp100 fs14`} type={'primary'}>Start at {moment(voteDeatil.startTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</Button> :
                                                                                    // <Button disabled={true} className={`wp100`} type={'primary'}>{voteStatus.t} </Button>
                                                                                    <Button disabled={true} onClick={openVoteModal} className={`wp100`} type={'primary'}>{t('btn_vote_now')} </Button>
                                                                        }

                                                                    </div>
                                                                }
                                                            </>
                                                    }
                                                </div>

                                    }
                                </div>
                                <div className={`${styles.owner_vote} mt20`}>
                                    <div className={styles.box_header}>{t('vote_details')}</div>
                                    <div className={styles.owner_vote_main}>
                                        <div className={`space-between`}>
                                            <span>{t('initiator')}</span>
                                            <a className={`${styles.c_blue}`} onClick={() => { history.push(`/profile/${voteDeatil.creator.address}`) }}>{voteDeatil.creator ? voteDeatil.creator.name : ''}</a>
                                        </div>
                                        <div className={`space-between mt10`}>
                                            <span>{t('snapshot')}</span>
                                            <span className={`${styles.c_blue} cp`} onClick={()=>{goToSnapshot(voteInfo.blockHeight)}}>{voteInfo.blockHeight}</span>
                                        </div>
                                        <div className={`space-between mt10`}>
                                            <span>{t('vote_s_time')}</span>
                                            <span>{moment(voteDeatil.startTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</span>
                                        </div>
                                        <div className={`space-between mt10`}>
                                            <span>{t('vote_e_time')}</span>
                                            <span>{moment(voteDeatil.endTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</span>
                                        </div>
                                        {/* {
                                            isCreator && !voteInfo.withdrawn && !isEndTime && <div className={`mt10`}>
                                                <Button className={`fs14 wp100 btn_border h40`} onClick={undo} >{t('cancel_proposals_and_claim')}</Button>
                                            </div>
                                        } */}
                                        {/* {
                                            isCreator && !voteInfo.withdrawn && isEndTime && <div className={`mt10`}>
                                                <Button className={`fs14 wp100 btn_border h40`} onClick={claim} > Claim DFA</Button>
                                            </div>
                                        } */}

                                    </div>
                                </div>
                                <div className={`${styles.owner_vote} mt20`}>
                                    <div className={styles.box_header}>Proposal History</div>
                                    <div className={`p10`}>

                                        <Steps direction="vertical" current={historyList.length - 1}>
                                            {
                                                historyList.map((item, index) => (
                                                    <Step key={item.operation + index} icon={(item.operation === 'Canceled' || item.operation === 'Failed') ? <MinusCircleOutlined style={{ color: "#dedede" }} /> : (index === historyList.length - 1) ? <CheckCircleFilled style={{ color: "#229C1F" }} /> : <CheckCircleOutlined style={{ color: "#229C1F" }} />} title={item.operation} subTitle={item.transactionHash ? <LinkOutlined onClick={() => { goToTX(item) }} /> : ''} description={moment(item.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")} />
                                                ))
                                            }
                                        </Steps>
                                        {
                                            voteDeatil.targets && <>
                                            {
                                                voteStatus.t === 'Passed'&&
                                               <Button onClick={queue} className={'wp100 mt10'} type="primary">Queue</Button> 
                                            }
                                            {
                                                voteStatus.t === 'Queued'&&
                                                <>
                                                    <Button disabled={moment(voteInfo.eta*1000)>Date.now() } onClick={execute} className={'wp100 mt10'} type="primary">Execute</Button> 
                                                    <div>
                                                        {
                                                            
                                                            moment(voteInfo.eta*1000)>Date.now() &&
                                                            <>
                                                                <div className={`${styles.count_down} c_9a mt4 tac df_center`}><img className={'mr4'} width={14} src={clockIcon}></img><CountDown onEnd={onEnd} type="vote" time={voteInfo.eta}></CountDown> left</div>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            </>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                }
            </div>
            <div style={{ opacity: 0 }}>
                {
                    mdeShow && <SimpleMdeReact getMdeInstance={dealVoteContent} />
                }
            </div>
            <Modal
                title={'Confirm Votes'}
                visible={visibleVoteTip}
                footer={null}
                onCancel={handleCancel}
            >
                <div>
                    <div className={`space-between`}>
                        <span>Voting for</span>
                        <span className={`fw600 ${valueRadio === "For" ? "vote_list_yes" : "vote_list_no"}`}>{valueRadio}</span>
                    </div>
                    <div className={`space-between mt10`}>
                        <span>Your Votes</span>
                        <span className={'fw600'}>{formatAmountWithDecimal(voteDeatil.userVotedCount, 0, 0)}</span>
                    </div>
                    <div className={`bg_f4 p10 br6 mt10`}>
                        Are you sure you want to vote for the above choice? This action cannot be undone.
                    </div>
                    <div className={`space-between mt20`}>
                        <Button className={`wp50 mr20 btn_border h40 fs18`} onClick={handleCancel}>Cancel</Button>
                        <Button className={`wp50`} type={'primary'} onClick={handleVote}>Votes</Button>
                    </div>
                </div>
            </Modal>
            <Modal className={styles.totalSplicedModal} title={`All Votes`} centered={true} visible={showVotedList} onCancel={cancelListModal} width={550} footer={[
                <Pagination simple current={pageNo} total={tableTotal} onChange={onShowSizeChange}></Pagination>
            ]}>
                <div>
                    <Table loading={votedList.length<1} scroll={{ x: '100%' }} width={470} columns={tableColumns} dataSource={votedList} pagination={false} rowClassName={handelRowClassName} />
                </div>
            </Modal>

        </div>
    )
}
