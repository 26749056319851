import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin, Button } from "antd";
import moment from 'moment';

import { topicDetail } from "../../../services/topic"
import RecommendBar from "components/RecommendBar";
import TopicHot from "components/TopicHot";
import ActivityList from "components/ActivityList";
import { formatAmountWithDecimal } from "utils/format"
import NoData from "components/NoData";
import hot from "assets/img/topic/hot.svg"
import back from "assets/img/back.svg";
import styles from "../styles.module.scss";


export default function TopicDetail() {
    const { t } = useTranslation();
    const history = useHistory();
    const { topicContent } = useParams();
    const [loading, setLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const [refresh, setRefresh] = useState(1);
    const [topicInfo, setTopicInfo] = useState({});
    const [isNoData, setIsNoData] = useState(false);
    // const { list, total } = useList(pageNo, pageSize, setLoading, refresh)

    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const handleBack = () => {
        history.push('/topic')
    }
    const queryDetail = async () => {
        let res = await topicDetail(topicContent)
        if(res === 'topic.not.exist') {
            setIsNoData(true)
        }else {
            setIsNoData(false)
            setTopicInfo(res)
        }
    }
    const dealNftName = (name) => {
        let str = ''
        if(!name) {
            return '--'
        }else if(name.length>26){
            str = name.substring(0,26)+'...'
            return str
        }else {
            return name
        }
    }
    const gotoPost = () => {
        var scrollW = document.documentElement.scrollWidth;
        if (scrollW <768) {
          history.push(`/publish?topic=${encodeURIComponent(topicInfo.topic)}`)
          
        } else {
          history.push(`/wow?topic=${encodeURIComponent(topicInfo.topic)}`)
        }
    }
    useEffect(() => {
        queryDetail()
        setRefresh(refresh + 1)
    }, [topicContent])
    return (
        <div className="df">
            <div className={styles.content_box}>
                <div className={styles.content_header} >
                    <div onClick={handleBack}>
                        <img src={back} alt="" />
                        {
                            isNoData?
                            <span className={`pl10`}>{t('back')}</span>:
                            <span className={`pl10 fw700`}>{dealNftName(topicInfo.topic)}</span>
                        }
                    </div>
                    {
                        !isNoData &&
                        <div>
                            <span className={`pr20`}>{formatAmountWithDecimal(topicInfo.activityCount,0,0)} Wows</span>
                            <Button className={styles.btn_post} type="primary" onClick={gotoPost}>Post now</Button>
                        </div>
                    }
                </div>
                <div className={styles.activity_box}>
                    {
                        isNoData?
                        <NoData></NoData>:
                     <ActivityList topic={decodeURIComponent(topicContent) } />
                    }
                </div>
            </div>
            <TopicHot />
        </div>
    )
}
