import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Popover, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { useAccountIsSign } from "hooks/account"
import styles from "./styles.module.scss";
import BadgeItem from 'components/BadgeItem'
import BackButton from "components/BackButton";
import { useActiveWeb3React } from "../../web3"
import { useBadgeList, useUnclaimedBadgeList, useBadgeTotal, getBadgeProjectInfo } from "../../services/badge";
import { chainTypeComImgObj, chainTxtObj } from '../../utils/networkConnect';

import moment from "moment";

const BadgePage = () => {
  const { project } = useParams()
  const { library, account, chainId, active } = useActiveWeb3React()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const { badgeList } = useBadgeList(project, setLoading)
  const { badgeTotal } = useBadgeTotal()
  const { unclaimedBadgeList } = useUnclaimedBadgeList(project, library, account)
  const history = useHistory()
  const isSign = useAccountIsSign()
  const [projectInfo, setProjectInfo] = useState({})

  const unclaimedBadges = (
    <div className={styles.unclaimed_popover}>
      {
        (!unclaimedBadgeList || unclaimedBadgeList.length === 0) &&
        <span>No Badges is able to be claimed at this moment</span>
      }
      {
        unclaimedBadgeList && unclaimedBadgeList.length > 0 && unclaimedBadgeList.map(item => (
          <img key={item.type} className={styles.unclaimed_img} src={item.image} onClick={() => {
            history.push(`${item.project}/${urlBadgeTypeList[item.type] ? urlBadgeTypeList[item.type] : item.type}`)
          }} />))
      }
    </div>
  )
  const urlBadgeTypeList = {
    "FIRST-ANNIVERSARY-DEFINE": "define",
    "FIRST-ANNIVERSARY-AOZ": "aoz",
    // "FIRST-ANNIVERSARY-CERE": "cere",
    "FIRST-ANNIVERSARY-CYBERGALZ": "cybergalz",
    "FIRST-ANNIVERSARY-IMTOKEN": "imtoken",
    "FIRST-ANNIVERSARY-MATHWALLET": "mathwallet",
    "FIRST-ANNIVERSARY-OIN": "oin",
    // "FIRST-ANNIVERSARY-POLYGON": "polygon",
    "FIRST-ANNIVERSARY-SYPOOL": "sypool",
    "FIRST-ANNIVERSARY-NAOS": "naos",
    "FIRST-ANNIVERSARY-PERPETUAL": "perpetual",
    "FIRST-ANNIVERSARY-SUNMIYA": "sunmiya",
    "FIRST-ANNIVERSARY-MTDZ": "mtdz",
    "FIRST-ANNIVERSARY-KLAYSWAP": "klayswap",
    "FIRST-ANNIVERSARY-DVISION": "dvision",
    "FIRST-ANNIVERSARY-WITH": "with",
  }

  const queryProjectInfo = async () => {
    let res = await getBadgeProjectInfo(project)
    setProjectInfo(res)
  }
  useEffect(() => {
    queryProjectInfo()
  }, [project])

  return (
    <div className={`${styles.page_main}`}>
      <div className={`${styles.page_header}`}>
        {/* <div className={`fw600 fs18`}>
          <BackButton></BackButton>
        </div> */}
        <div className={`space-between-h5 pr`}>
          <div className={`${styles.df_align_center} mb10`}>
            <img className={styles.title_logo} src={projectInfo.logo}></img>
            <div className="ml20">
              <div className={`${styles.page_content_title}`}>{projectInfo.title}</div>
              <div className={`mt4 ${project === 'one-year-anniversary' ? 'wp65_h5' : null} ${styles.des}`} dangerouslySetInnerHTML={{ __html: projectInfo.description }} style={{ whiteSpace: 'pre-wrap' }}></div>
            </div>
          </div>
          {
            isSign &&
            <div className={`mb10 ${styles.pop_claimable}`} >
              <Popover placement="bottom" content={unclaimedBadges} trigger="click">
                <a className={`c_purple fs14`}>{t('unclaimed_badges')} ({unclaimedBadgeList.length})</a>
              </Popover>
            </div>
          }
        </div>
        <div className="space-between-h5">
          <div className={`${styles.info_item} ${styles.w320}`}>
            <div className='tac'>Start Time</div>
            <div className={'fw600 tac'}>{moment(projectInfo.eventStartTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
          <div className={`${styles.info_item} ${styles.w320}`}>
            <div className='tac'>End Time</div>
            <div className={'fw600 tac'}>{moment(projectInfo.eventEndTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
          <div className={`${styles.info_item} ${styles.w220}`}>
            <div className='tac'>{chainTxtObj[projectInfo.chainType]}</div>
            <div className={'fw600 tac'}>
              <img src={chainTypeComImgObj[projectInfo.chainType]}></img>
            </div>
          </div>
          <div className={`${styles.info_item} ${styles.w220}`}>
            <div className='tac'>Claimed</div>
            <div className={'fw600 tac'}>{projectInfo.claimedCount}/{projectInfo.totalCount}</div>
          </div>
        </div>
      </div>
      {
        loading ? <Spin className={`wp100 mt40`} size="large" /> :
          <section className={styles.badge_section}>
            {/* <div className={`mb40 ${project==='one-year-anniversary'?'wp65_h5':null} ${styles.des}`} dangerouslySetInnerHTML={{ __html: projectInfo.description }} style={{ whiteSpace: 'pre-wrap' }}></div> */}
            <div className={styles.badgeItems}>
              {
                (!badgeList || badgeList.length === 0) &&
                <div className={styles.badgeItem}>
                  <span>No Badges Available at this moment</span>
                </div>
              }
              {
                badgeList && badgeList.length > 0 && badgeList.map((arr, index) => (
                  <div key={index} className={styles.badge_serise}>
                    {
                      arr[0].showType != "Badge" &&
                      <>
                        <div className={'space-between-center'}>
                          {
                            project === "ampleforth" &&
                            <>
                              <div className={'fw600 fs24'}>{arr[0].showType} Rebase</div>
                              <div><span className={'fw600'}>{badgeTotal[arr[0].qualification] ? badgeTotal[arr[0].qualification].claimed : ''}</span>/<span>{badgeTotal[arr[0].qualification] ? badgeTotal[arr[0].qualification].totalCount : ''}</span> Claimed</div>
                            </>
                          }
                          {
                            !!projectInfo.groups && project != "ampleforth" &&
                            <div className={'fw600 fs24'}>{projectInfo.groups[index].title} </div>

                          }
                        </div>
                      </>
                    }
                    <div className={`${styles.flex_start}`}>
                      {
                        arr.map(item => (
                          <div key={item.type} className={styles.badgeItem} onClick={() => {
                            history.push(`${project}/${urlBadgeTypeList[item.type] ? urlBadgeTypeList[item.type] : item.type}`)
                          }}>
                            <BadgeItem image={item.image} style={item.style} />
                            <div className={`tac ${styles.badge_item_name}`}>{item.name}</div>
                          </div>
                        )
                        )
                      }</div>
                  </div>
                ))
              }
            </div>
          </section>
      }
    </div>
  );
};

export default BadgePage
