import React, { useContext, useState } from "react";
import { Modal } from "antd"

import PublishCommentBox from "components/PublishCommentBox";
import CommentList from "components/CommentList";
import { HANDLE_SHOW_COMMENT_MODAL1 } from "../../const";
import { mainContext } from "../../reducer";

export const CommentModal1 = ({ visible }) => {
  const [refresh, setRefresh] = useState(0)
  const { dispatch, state } = useContext(mainContext);
  const { activityId, replyTo, replyUser } = state.showCommentModal1;

  const handleCancel = () => {
    dispatch({
      type: HANDLE_SHOW_COMMENT_MODAL1,
      showCommentModal1: {
        show: false,
        activityId: "",
        replyTo: "",
        replyUser: ""
      }
    });
  }

  const handlePublish = () => {
    setRefresh(old => (old + 1) % 100)
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={handleCancel}
      title={`Reply to ${replyUser}`}
      footer={null}
    >
      <div className="mb30">
        <PublishCommentBox id={activityId} replyTo={replyTo} onPublished={handlePublish} />
      </div>
      <CommentList parentCommentId={replyTo} refresh={refresh} id={activityId} />
    </Modal>
  );
};
