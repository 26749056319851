import React, { useEffect, useState } from 'react'
import { Button, Input, message, Modal } from 'antd'
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { getBadgeInfo, badgeApply } from "../../services/badgeDetail";
import { getBadgeProjectInfo } from "../../services/badge";
import { formatAmountWithDecimal } from "../../utils/format";
import bg from 'assets/img/token2049/bg.jpg'
import bgh5 from 'assets/img/token2049/bg-h5.jpg'
import aboutIcon from 'assets/img/token2049/about_icon.svg';
import aboutDefine from 'assets/img/token2049/about_define.jpg';
import styles from './styles.module.scss'
import TextArea from 'antd/lib/input/TextArea'
import { chainTxtObj } from '../../utils/networkConnect';


export default function Token2049() {
    const [info, setInfo] = useState({})
    const [eventInfo, setEventInfo] = useState({})
    const [value, setValue] = useState('')
    const [flag, setFlag] = useState(true)
    const { badgeType } = useParams()
    const history = useHistory()

    const goToBadgeEntry = () => {
        history.push('/badgeEntry')
    }
    const handleInput = (e) => {
        let address = e.target.value
        if (address) {
            let reg = /^0x[A-Za-z0-9]{40}/
            if (!reg.test(address)) {
                setFlag(true)
                return message.error("Invalid address")
            } else {
                setFlag(false)
                setValue(e.target.value)
            }
        } else {
            setFlag(true)
            setValue('')
        }

    }

    const collectAddress = async () => {
        if (flag) {
            message.error("Invalid address")
            return false
        }
        let res = await badgeApply(badgeType, value)
        if (res === 'success') {
            message.success({
                content: <div class='p10'><div style={{ color: '#229C1F', fontSize: '18px' }}>Claimed successfully</div><div style={{ fontSize: '14px' }}>Please check in your wallet later.</div></div>,
                className: 'custom-class',
                duration: 3,
                style: {
                    marginTop: '45vh',
                },
            });
        }
    }

    useEffect(() => {
        getBadgeInfo(badgeType).then(res => {
            setInfo(res);
            getBadgeProjectInfo(res.project).then(data => {
              setEventInfo(data)
            })
        });
    }, [])
    return (
        <div className={styles.token2049} style={{ backgroundImage: `url(${bg})` }}>
            <div className={styles.token_main}>
                <img width={216} src={info.image}></img>
                <div className={`c_fff fs24 fw700`}>{info.name}</div>
                <div className='df_center mt10'>
                    <div className={styles.line_right}>
                        <div className={`c_fff fs24 fw700`}>{info.grantedCount}</div>
                        <div className={styles.text1}>Claimed</div>
                    </div>
                    <div className='pl14'>
                        <div className={`c_fff fs24 fw700 df_center`}>
                            <span className='pl6'>{formatAmountWithDecimal(info.maxCount - info.grantedCount, 0, 0)<0?0:formatAmountWithDecimal(info.maxCount - info.grantedCount, 0, 0)}</span>
                        </div>
                        <div className={styles.text1}>Badge left</div>
                    </div>
                </div>
                <div style={{whiteSpace: 'pre-wrap', textAlign: 'left'}} className='mt10 c_fff lh26'>{eventInfo.description}</div>
                <div className={styles.text2}>Event period: {moment(info.claimStartTime).format("MM/DD")} - {moment(info.claimEndTime).format("MM/DD")} </div>
                <div className={'mt10 c_fff fw700'}>Please enter the  address for <span className={styles.text2}>{chainTxtObj[info.chainType]}</span></div>
                <TextArea onChange={handleInput} vlaue={value} className='mt20' placeholder='0x...' rows={2}></TextArea>
                <div className='mt30'>
                    <Button onClick={collectAddress} disabled={formatAmountWithDecimal(info.maxCount - info.grantedCount, 0, 0)<1} className={`wp100 h50 fs16 fw600 ${styles.claimBtn_border}`} type="primary">Claim</Button>
                </div>
                <div className='mt16'>
                    <Button onClick={goToBadgeEntry} className={styles.more_btn}>Learn more DeFine badges</Button>
                </div>
                <div className='mt16 mb30'>
                    <a href={aboutDefine} className={styles.about_btn}><img className="pr10" src={aboutIcon} alt="" /> About DeFine</a>
                </div>
            </div>
        </div>
    )
}
