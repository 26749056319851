import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { Select, Spin, Input, Skeleton } from "antd";

import { getContract, useActiveWeb3React } from "../../web3"
import NoData from "components/NoData";
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { useActivities } from "services/home"
import ActivityCard from "components/ActivityCard";
import styles from "./styles.module.scss";

const ActivityList = ({ sort, refresh, follower, creator, liker, topic, home }) => {
  const [pageNo, setPageNo] = useState(1)
  const [force, setForce] = useState(0)
  const [others, setOthers] = useState({ follower, creator, liker, sort, topic, home })
  const { t } = useTranslation();
  const history = useHistory();
  const { library, account, chainId, active } = useActiveWeb3React()
  const { tronAccount } = useActiveTronWeb()
  const [loading, setLoading] = useState(false)
  const { list, total } = useActivities(pageNo, 10, force, others, setLoading)

  const getList = () => {
    setPageNo(pageNo + 1)
  }

  useEffect(() => {
    // console.log(topic);
    setPageNo(1)
    setForce(refresh)
    setOthers({ follower, creator, liker, sort, topic, home })
  }, [refresh, follower, creator, liker, sort, topic, home])

  return (
    loading ? <div className="p20">
      <Skeleton avatar paragraph={{ rows: 4 }} />
      <Skeleton avatar paragraph={{ rows: 4 }} />
      <Skeleton avatar paragraph={{ rows: 4 }} />
    </div> :
    <InfiniteScroll
      dataLength={list.length}
      next={getList}
      hasMore={list.length < total}
      loader={
        <div style={{ width: '100%', padding: 20, textAlign: 'center' }}>
          <Spin size={`large`} />
        </div>
      }
    >
      {
        list.length === 0 && <div className={`wp100 df_center mt60`}><NoData></NoData></div>
      }
      {
        list.map(item => (
          <ActivityCard key={item.id} data={item} />
        ))
      }
    </InfiniteScroll>
  )
}

export default ActivityList;