import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Table } from 'antd';

import { badgeApplyList } from "../../services/badgeDetail";


export default function SandboxAddressList() {
    const [pagination, setPagination] = useState({});
    const [pageNo,setPageNo] = useState(1);
    const [pageSize,setPageSize] = useState(100);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { badgeType } = useParams()

    const columns = [
        {
            title: "Address",
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: "Private Key",
            dataIndex: 'privateKey',
            key: 'privateKey'
        }
    ]

    const getList = async () => {
        setLoading(true);
        let res = await badgeApplyList(badgeType, pageNo, pageSize)
        setLoading(false);
        setData(res.list)
        setPagination({
          ...pagination,
          pageSize,
          total:res.totalCount,
          pageSizeOptions: [100,200,500,1000],
          showSizeChanger: true,
          showTotal: (total, range) => `total: ${total}`
        })
    }
    const handleTableChange = (pagination) => {
        setPageNo(pagination.current)
        setPageSize(pagination.pageSize)
    }
    useEffect(() => {
        getList()
    },[pageNo,pageSize])
    return (
        <div className='page_content_h5'>
            <Table loading={loading} dataSource={data} columns={columns} pagination={pagination} onChange={handleTableChange}></Table>
        </div>
    )
}
