import React, { useEffect, useState, useContext } from "react";
import { BackTop, Form, Row, Col, Upload, Button, Input, Spin, message, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from '@ant-design/icons';
import InfiniteScroll from "react-infinite-scroll-component";

import { useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import BackButton from "components/BackButton";
import { mainContext } from "../../reducer";
import { getArtistInfo, postProfile, getHandleName } from "../../services/profile";
import { useNftList, queryNFTData } from "services/nftData"
import { dataURLtoBlob } from '../../utils/dataURLtoBlob';
import NoData from "components/NoData";
import { useNeedSign } from "hooks/account"
import { getSign } from 'utils/txSign'
import { twitterUtil } from 'utils/index'
import { codeGetTwitterInfo, deleteTwitterInfo } from "../../services/badgeManage";
import { ReactComponent as EmptyIcon } from 'assets/img/empty.svg';
import { ReactComponent as WebsiteIcon } from 'assets/img/socialIcon/website.svg'
import { ReactComponent as DiscordIcon } from 'assets/img/socialIcon/discord.svg'
import { ReactComponent as InstagramIcon } from 'assets/img/socialIcon/instagram.svg'
import { ReactComponent as MediumIcon } from 'assets/img/socialIcon/medium.svg'
import { ReactComponent as TelegramIcon } from 'assets/img/socialIcon/telegram.svg'
import { ReactComponent as TwitterIcon } from 'assets/img/socialIcon/twitter.svg'
import { ReactComponent as DisconnectIcon } from 'assets/img/socialIcon/disconnect.svg'

import styles from "./styles.module.scss";
import tag from "../../assets/img/artist/tag.png"
import {
  HANDLE_CHANGE_USER_INFO,
} from "../../const";

const { TextArea } = Input;

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const Setting = () => {
  const [userInfo, setUserInfo] = useState()
  const { t } = useTranslation()
  const { library, account } = useActiveWeb3React()
  const [imageUrl, setImageUrl] = useState();
  const [bannerUrl, setBannerUrl] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { tronAccount } = useActiveTronWeb()
  const { dispatch } = useContext(mainContext);
  const [visible, setVisible] = useState(false);
  const [allNftList, setAllNftList] = useState([])
  const [isNft, setIsNft] = useState(false)
  const [nftInfo, setNftInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [formRef] = Form.useForm()

  const getUserInfo = async () => {
    let res = await getArtistInfo(account || tronAccount, account || tronAccount)
    setUserInfo(res)
    setImageUrl(res.avatar)
    setBannerUrl(res.banner)
  }
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        // console.log(url);
        setImageUrl(url);
        setIsNft(false)
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
    }
  }
  const handleChangeBanner = (info) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setBannerUrl(url);
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, url => {
        setBannerUrl(url);
      });
    }
  }
  const onFinish = async (values) => {
    setConfirmLoading(true);
    let formData = new FormData();
    if (values.handleName && (values.handleName != userInfo.handleName)) {
      let reg = /^[a-zA-Z0-9_.]{6,24}$/
      if (!reg.test(values.handleName)) {
        setConfirmLoading(false);
        return message.error('The handle name must be longer than 6 characters and shorter than 24 characters, and can only contain letters, numbers, ‘_’, and ‘.’')
      }
      let res = await getHandleName(values.handleName)
      if (res === 'false') {
        setConfirmLoading(false);
        return message.error('That handle name has been taken. Please choose another.')
      }
      formData.append('handleName', values.handleName)
    }
    let reg = /https:\/\//
    if (imageUrl && !reg.test(imageUrl)) {
      formData.append('avatar', dataURLtoBlob(imageUrl))
    }
    if (bannerUrl && !reg.test(bannerUrl)) {
      formData.append('banner', dataURLtoBlob(bannerUrl))
    }
    if (isNft) {
      formData.append('avatarNftChainType', nftInfo.chainType)
      formData.append('avatarNftAddress', nftInfo.contractAddress)
      formData.append('avatarNftId', nftInfo.id)
    } else {
      formData.avatarNftChainType && delete formData.avatarNftChainType
      formData.avatarNftAddress && delete formData.avatarNftAddress
      formData.avatarNftId && delete formData.avatarNftId
    }
    values.name && formData.append('name', values.name)
    // values.youtube && formData.append('youtube', values.youtube)
    values.instagram && formData.append('instagram', values.instagram)
    values.twitter && formData.append('twitter', values.twitter)
    values.bio && formData.append('bio', values.bio)
    values.website && formData.append('website', values.website)
    // values.email && formData.append('email', values.email)
    values.aboutMe && formData.append('aboutMe', values.aboutMe)
    values.ethAddress && formData.append('ethAddress', values.ethAddress)
    values.discord && formData.append('discord', values.discord)
    values.telegram && formData.append('telegram', values.telegram)
    values.medium && formData.append('medium', values.medium)


    // formData.append('artist', isArtist)
    // if ((account && account.toLowerCase() || tronAccount && tronAccount.toLowerCase()) === artistInfo.address) {
    //   formData.append('aboutMe', values.aboutMe)
    // }
    postProfile(formData).then(res => {
      message.success("success")
      if (res?.address) {
        dispatch({
          type: HANDLE_CHANGE_USER_INFO,
          userInfo: res
        });
      } else {
        message.error("edit error");
      }
    }).finally(() => {
      setConfirmLoading(false);
    })
  };
  const handleCancel = () => {
    setVisible(false)
  }
  const openModal = () => {
    setVisible(true)
    queryList()

  }
  const queryList = async () => {
    setLoading(true)
    let res = await queryNFTData(account)
    setList(res)
    setLoading(false)
  }
  const bindTwitter = (e) => {
    if (e.key === 'twitterCode' && e.newValue) {
      codeGetTwitterInfo(e.newValue, window.location.origin + '/auth').then(res => {
        formRef.setFieldsValue({twitter: '@' + res.username})
        setUserInfo(old => {
          return {
            ...old,
            twitter: '@' + res.username
          }
        })
      })
      window.localStorage.removeItem('twitterCode');
    }
  }
  const connctTwitter = () => {
    twitterUtil('auth')
  }
  const disconnectTwitter = () => {
    deleteTwitterInfo().then(res => {
      setUserInfo(old => {
        return {
          ...old,
          twitter: ''
        }
      })
    })
  }
  const selectNFT = (item) => {
    getSign(library, account, true).then(() => {
      setNftInfo(item)
      setIsNft(true)
      setImageUrl(item.image)
      setVisible(false)
    })

  }

  useEffect(() => {
    if (account || tronAccount) {
      getUserInfo()
    }
  }, [account, tronAccount])

  useEffect(() => {
    window.addEventListener('storage', bindTwitter);
    return () => {
      window.removeEventListener('storage', bindTwitter)
    }
  }, [])

  return (
    <>
      <Modal
        className={styles.nft_modal}
        visible={visible}
        onCancel={handleCancel}
        title={`Select a NFT`}
        footer={null}>
        {
          loading ? <div style={{ width: 216, textAlign: 'center', margin: ' 50px auto' }}>
            <Spin size={`large`} />
          </div> :
            <>
              <div className={styles.list_nftHeader}>
                {
                  list.length > 0 && list.map(item => (
                    <div key={item.createTime + item.id} className={styles.item_nftHeader} onClick={() => { selectNFT(item) }}>
                      <div className={styles.img_box}>
                        <img className={styles.item_img} src={item.image}></img>
                      </div>
                    </div>
                  ))
                }
              </div>
              {
                list.length === 0 && <div className={`df_column_center hp100 mt-20`}>
                  <EmptyIcon />
                  <span className={`pt20`}>You don’t have any NFT yet.</span>
                  <span>check our markrtplace!</span>
                  {/* <NoData text={'You don’t have any NFT yet.check our markrtplace!'}></NoData> */}
                </div>
              }
            </>
        }
      </Modal>
      <div className={`page_content_h5 ${styles.profile}`}>
        <BackButton></BackButton>
        <div className={'page_content_title mt10 mb30'}>Edit Profile</div>
        {
          userInfo ?
            <Form form={formRef} layout={`vertical`} onFinish={onFinish}>
              <Row gutter={32}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item>
                    <div className={styles.profile_avatar}>
                      <div className={styles.avatar_perview}>
                        <div style={{ backgroundImage: `url(${imageUrl})` }}></div>
                      </div>
                      <div className={`pl20 mb20 f1`}>
                        <div className={`mb14 w100`}>File types supported: JPG, PNG, GIF.</div>
                        <Upload customRequest={() => { }} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}><Button className={`btn_border w120 h40`}>Upload</Button></Upload>
                        <Button className={`btn_multicolour mt10 w120 h40`} onClick={openModal}>Select NFT</Button>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item label="Display Name" name="name" initialValue={userInfo.name}>
                    <Input size="large" className={styles.name_input} placeholder="Less than 24 words" maxLength={24} />
                  </Form.Item>
                  <Form.Item label="Handle Name" name="handleName" initialValue={userInfo.handleName}>
                    <Input size="large" className={styles.name_input} placeholder="@" minLength={6} maxLength={24} />
                  </Form.Item>
                  <Form.Item label="Short About" name="bio" initialValue={userInfo.bio}>
                    <Input size="large" className={styles.name_input} placeholder="Brief description" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item label="Website" name="website" initialValue={userInfo.website}>
                    <Input size="large" className={styles.name_input} placeholder="https://" prefix={<WebsiteIcon></WebsiteIcon>} />
                  </Form.Item>
                  <Form.Item label="Instagram" name="instagram" initialValue={userInfo.instagram}>
                    <Input size="large" className={styles.name_input} placeholder="Username" prefix={<InstagramIcon></InstagramIcon>} />
                  </Form.Item>
                  <Form.Item label="Medium" name="medium" initialValue={userInfo.medium}>
                    <Input size="large" className={styles.name_input} placeholder="https://" prefix={<MediumIcon></MediumIcon>} />
                  </Form.Item>
                  {/* <Form.Item label="Youtube" name="youtube" initialValue={userInfo.youtube}>
                  <Input size="large" className={styles.name_input} placeholder="https://" />
                </Form.Item> */}
                  {
                    tronAccount && <Form.Item label="Your Eth address" name="ethAddress" initialValue={userInfo.ethAddress}>
                      <Input size="large" className={styles.name_input} placeholder="0x" />
                    </Form.Item>
                  }
                </Col>
                <Col xs={24} md={12} lg={12}>
                  {/* <Form.Item label="Name" name="name" initialValue={userInfo.name}>
                  <Input size="large" className={styles.name_input} placeholder="name" maxLength={24} />
                </Form.Item>
                <Form.Item label="Email" name="email" initialValue={userInfo.email}>
                  <Input size="large" className={styles.name_input} placeholder="Email" />
                </Form.Item> */}
                  {/* <Form.Item label="Display name" name="name" initialValue={userInfo.name}>
                  <Input size="large" className={styles.name_input} placeholder="Less than 24 words" maxLength={24} />
                </Form.Item>
                <Form.Item label="Handle Name" name="handleName" initialValue={userInfo.handleName}>
                  <Input size="large" className={styles.name_input} placeholder="@" />
                </Form.Item>
                <Form.Item label="Short about" name="bio" initialValue={userInfo.bio}>
                  <Input size="large" className={styles.name_input} placeholder="Brief description" />
                </Form.Item> */}
                  {/* <Form.Item label="About" name="aboutMe" initialValue={userInfo.aboutMe}>
                  <TextArea autoSize={{ minRows: 14, maxRows: 20 }} className={styles.name_input} />
                </Form.Item> */}
                  <Form.Item label="Discord" name="discord" initialValue={userInfo.discord}>
                    <Input size="large" className={styles.name_input} placeholder="Discord server invite link" prefix={<DiscordIcon></DiscordIcon>} />
                  </Form.Item>
                  <Form.Item label="Telegram" name="telegram" initialValue={userInfo.telegram}>
                    <Input size="large" className={styles.name_input} placeholder="@Username" prefix={<TelegramIcon></TelegramIcon>} />
                  </Form.Item>
                  {
                    userInfo.twitter ?
                      <Form.Item label="Twitter" name="twitter" initialValue={userInfo.twitter}>
                        <Input readOnly size="large" className={styles.name_input} placeholder="@username" prefix={<TwitterIcon></TwitterIcon>} suffix={<DisconnectIcon className="cp" onClick={disconnectTwitter} />} />
                      </Form.Item> :
                      <Form.Item label="Twitter">
                        <Button onClick={connctTwitter} size="large" className="btn_multicolour" style={{ border: 'none', height: 43 }}>Connect Twiiter</Button>
                      </Form.Item>
                  }
                </Col>
              </Row>
              {/* {
              userInfo.artist &&
              <Form.Item label="">
                <div className={`mb10`}>Profile Banner <img width={24} src={tag}></img></div>
                <div className={styles.profile_banner_module}>
                  <div className={styles.profile_banner}>
                    <img src={bannerUrl}></img>
                    <Upload customRequest={()=>{}}  className={styles.uploadBtn} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChangeBanner}><Button className={`btn_border w120 h40`}>Upload</Button></Upload>
                  </div>
                  <div className={styles.profile_banner_tip}>
                    <div>Add your own personalized banner as the background.</div>
                    <div className={`fw700 mt10`}>Size: 1440*300</div>
                  </div>
                </div>
              </Form.Item>
            } */}
              <div className={`tar`}>
                <Button type="primary" className={`w200 h40 ${styles.set_btn}`} htmlType="submit">
                  Save
                  {confirmLoading && <LoadingOutlined />}
                </Button>
              </div>
            </Form>
            :
            <div className={`tac mt50`}>
              <Spin size={`large`}></Spin>
            </div>
        }
      </div>
    </>
  )
}
export default Setting;
