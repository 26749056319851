import React from 'react';
import { Button } from 'antd';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from './styles.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

import sandbox from '../../assets/img/events/sandbox.jpg'
import punkCover from '../../assets/img/events/punkCover.png'
import boxCover from '../../assets/img/events/mysteryBoxCover.png'
import aozCover from '../../assets/img/events/aoz.jpg'
import xmasCover from '../../assets/img/events/christmas.jpg'
import deesseCover from '../../assets/img/events/deesse_cover.jpg'
import cybergalzCover from '../../assets/img/events/cyber.jpg'
import anniversary from '../../assets/img/events/anniversary.jpg'
import kbw from '../../assets/img/events/kbw2022.jpg'
import pizzaday from '../../assets/img/events/pizzaday.jpg'
import kucoin from '../../assets/img/events/kucoin.jpg'
import token2049 from '../../assets/img/events/token2049.jpg'
import ethmerge from '../../assets/img/events/eth.jpg'
import halloween from '../../assets/img/events/halloween.jpg'
import footballCampaign from '../../assets/img/events/footballCampaign.jpg'
import quantum from '../../assets/img/events/quantum.jpg'

AOS.init();
export default function EventsEntry() {
  const history = useHistory()
  const { t } = useTranslation()
  const newList = [
    { name: 'Collection', link: '/event/quantum', title: "Meta2150s NFT Public Sale", intro: 'meta2150s', boxImg: quantum },
    { name: 'Badge', link: '/events/DeFine-FootballCampaign2022', title: "Football Campaign 2022", intro: 'In the spirit of celebrating the upcoming mega Football event, we are hosting the DeFine - Football Campaign 2022 with a 50,000 DFA prize pool!', boxImg: footballCampaign },
    { name: 'Badge', link: '/events/halloween', title: "DeFine October Carnival", intro: 'halloween_text', boxImg: halloween },
    { name: 'Badge', link: '/badge/token2049/DEFINE-TOKEN2049', title: "DeFine @Token2049", intro: 'token2049_text', boxImg: token2049 },
    { name: 'Badge', link: '/badge/define-eth-merge/DEFINE-ETHEREUM-MERGE', title: "2022 The Merge Ethereum", intro: 'ethmerge_text', boxImg: ethmerge },
    { name: 'Badge', link: '/events/KBW2022', title: "Korea Blockchain Week 2022", intro: 'kbw_text', boxImg: kbw },
    { name: 'Badge', link: '/badge/define-kucoin/DEFINE-KUCOIN', title: "DeFine x kuCoin Badge", intro: 'kucoin_text', boxImg: kucoin },
    { name: 'Badge', link: '/badge/define-2022-pizza-day-badge/DEFINE-PIZZA', title: "DeFine 2022 Pizza Day Badge", intro: 'pizzaday_text', boxImg: pizzaday },
    { name: 'Badge', link: '/events/one-year-anniversary', title: "DeFine Platform One Year Anniversary Carnival", intro: 'anniversary_text', boxImg: anniversary },
    { name: 'Badge', link: '/badge/CyberGalz/CyberGalz', title: "cybergalz_title", intro: 'cybergalz_text', boxImg: cybergalzCover },
    { name: 'Collection', link: '/collection/galz', title: "galz_title", intro: 'galz_text', boxImg: 'https://arweave.net/rv98TYtK4tUfxoxOiIBKxOhhWfnd4wp5xFmNtBN36O0' },
    { name: 'Collection', link: '/event/deesse', title: "deesse_title", intro: 'deesse_text', boxImg: deesseCover },
    { name: 'Collection', link: '/sypool-xmas', title: "Popo at Christmas and New Year", intro: 'xmas_text', boxImg: xmasCover },
    { name: 'Collection', link: '/event/aoz', title: "AOZ NFT Presale & Auction", intro: 'aoz_text', boxImg: aozCover },
    { name: 'Collection', link: '/sandbox', title: "Sandbox Blind Boxes", intro: 'sandbox_des1', boxImg: sandbox },
    { name: 'Collection', link: '/mysteryBox', title: "Snowmen's Transport Robots Enter ETH", intro: 'mysteryBox_des_1', boxImg: boxCover },
    { name: 'Collection', link: '/punk', title: 'APENFT Drops 10,000“Ape Party Club” NFTs on DeFine', intro: 'event_text_1', boxImg: punkCover },
  ]
  const goToNext = (link) => {
    history.push(link)
  }
  return (
    <div className={`${styles.page_main}`}>
      <div className={`page_content_title mt10 mb20`}>{t('events')}</div>
      <div className={styles.events_box}>
        {
          newList.map((item, index) => (
            <div key={item.title} className={`${styles.card_box}`} data-aos="fade-up" data-aos-duration={1000}>
              <div className={`${styles.box_left} f1`}>
                {/* <span className={`${styles.box_name}`}>{item.name}</span> */}
                <div className={`${styles.box_title}`}>{t(item.title)}</div>
                <div className={`${styles.box_intro}`}>{t(item.intro)}</div>
                <div className={`mt20`}><Button className={styles.events_btn} type="primary" onClick={() => { goToNext(item.link) }}>&nbsp;Enter&nbsp;</Button></div>
              </div>
              <div className={`${styles.box_img}`}><img src={item.boxImg}></img></div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
