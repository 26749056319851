import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { Select, Spin, Input, BackTop, Skeleton, Row, Col, Affix } from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';

import NoData from "components/NoData";
import NftCard from "components/NftCard";
import { useNftList } from "../../services/nftData"
import { useNeedSign } from "hooks/account"
import { useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { useCollectionList } from "../../services/collectionList"
import { ReactComponent as SearchIcon } from "assets/img/search.svg";
import { ReactComponent as FilterIcon } from "assets/img/filter.svg";
import styles from "./styles.module.scss";
import env from '../../env';
import hot from "assets/img/hot.png"
import defaultImg from "assets/img/defaultImg.jpg";
import hotWhite from "assets/img/collectionList/hot_white.svg"
import gameWhite from "assets/img/collectionList/game_white.svg"
import { chainTypeComImgObj, chains } from '../../utils/networkConnect';

const { Option } = Select;
const types = []

const Marketplace = () => {
  const history = useHistory();
  const { needSign } = useNeedSign();
  const [pageNo, setPageNo] = useState(1)
  const [nftType, setNftType] = useState('')
  const [nftChain, setNftChain] = useState('')
  const [saleType, setSaleType] = useState('')
  const [search, setSearch] = useState('')
  const [fixed, setFixed] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const { list, total } = useNftList(pageNo, 30, { nftType, nftChain, saleType, search }, setLoading)
  let timer = useRef()
  const loadingList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
  const loadingCollectionList = [1, 2, 3, 4, 5, 6, 7, 8]
  const loadingCollectionListH5 = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const { collectionList } = useCollectionList(1, 9, '', '')
  const { collectionList: gameList } = useCollectionList(1, 6, '', 'game')
  const [isPc, setIsPc] = useState(true)
  const { account, chainId } = useActiveWeb3React()
  const { tronAccount } = useActiveTronWeb()

  const handleSaleChange = (val) => {
    setPageNo(1)
    setSaleType(val)
  }
  const handleChainChange = (val) => {
    setPageNo(1)
    setNftChain(val)
  }
  const handleTypeChange = (val) => {
    setPageNo(1)
    setNftType(val)
  }
  const handleSearch = (e) => {
    const val = e.target.value
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setPageNo(1)
      setSearch(val)
    }, 600);
  }
  const showFilter = () => {
    setFilterVisible(val => !val)
  }

  const getList = () => {
    setPageNo(pageNo + 1)
  }

  const onScroll = () => {
    setFilterVisible(false)
  }
  const onAffix = val => {
    setFixed(val)
  }

  const goToCollection = (item) => {
    history.push(`collection/${item.type}`)
  }
  const goToAll = () => {
    history.push(`/collections`)
  }

  const goSell = () => {
    needSign(() => {
      history.push(`/profile/${account || tronAccount}?n=1`)
    })
  }

  useEffect(() => {
    if (document.body.clientWidth < 800) {
      setIsPc(false)
    }
    // window.addEventListener('scroll', onScroll)
    // return () => {
    //   window.removeEventListener('scroll', onScroll)
    // }
  }, [])

  return (
    <div>
      <BackTop style={{ bottom: 90 }} />
      <div className={`${styles.bg_box}`}>
        <div className={`${styles.top_box}`}>
          <div className={`${styles.top_h1}`}>Explore and collect NFT</div>
          <div className={`${styles.top_p}`}>DeFine is a multi-chain marketplace that aggregates multiple NFTs into one platform.</div>
          <a className={`${styles.btn_buy}`} href="#buy">Buy</a>
          <a className={`${styles.btn_sell}`} onClick={goSell}>Sell</a>
        </div>
      </div>
      <div id="buy" style={{ visibility: 'hidden', position: 'relative', top: '1px' }}></div>
      <Affix offsetTop={0} onChange={onAffix}>
        <div id="search_box" className={`${styles.search_box}`} style={{ background: fixed ? '#fff' : '' }}>
          <div className={`space-between-center ${styles.search_content}`}>
            <div className="df_align_center">
              <div
                onClick={() => { handleSaleChange('') }}
                className={`${styles.type_label} ${saleType === '' ? styles.type_active : ''}`}
              >{t('all')}</div>
              <div
                onClick={() => { handleSaleChange('fixedswap') }}
                className={`${styles.type_label} ${saleType === 'fixedswap' ? styles.type_active : ''}`}
              >{t('fixswap')}</div>
              <div
                onClick={() => { handleSaleChange('auction') }}
                className={`${styles.type_label} ${saleType === 'auction' ? styles.type_active : ''}`}
              >{t('auction')}</div>
            </div>
            <div className="df_align_center">
              <Input onChange={handleSearch} style={{ width: 280 }} placeholder="Search name" prefix={<SearchIcon style={{ fontSize: 16, marginRight: 6 }} />} />
              {/* <SearchIcon style={{ width: 20 }} /> */}
              <Select className={`${styles.select} ml10 mr10`} value={nftChain} onChange={handleChainChange}>
                <Option key="all" value="">
                  {'All chains'}
                </Option>
                {
                  chains.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
              <Select className={`${styles.select}`} value={nftType} onChange={handleTypeChange}>
                <Option key="all" value="">
                  {'All types'}
                </Option>
                <Option key="token" value='token'>
                  Common
                </Option>
                <Option key="badge" value='badge'>
                  Badge
                </Option>
                <Option key="warrant" value='warrant'>
                  NFT+
                </Option>
              </Select>
            </div>
          </div>
          <div className={`space-between-center ${styles.search_content_h5}`}>
            <div className={styles.title_box}>
              <span className={styles.page_title}>Market</span>
              <FilterIcon onClick={showFilter} />
            </div>
            <div className={filterVisible ? '' : 'hide'}>
              <div className="df_align_center mt10">
                <Select className={`${styles.select} mr10`} value={nftChain} onChange={handleChainChange}>
                  <Option key="all" value="">
                    {'All chains'}
                  </Option>
                  {
                    chains.map(item => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))
                  }
                </Select>
                <Select className={`${styles.select}`} value={nftType} onChange={handleTypeChange}>
                  <Option key="all" value="">
                    {'All types'}
                  </Option>
                  <Option key="token" value='token'>
                    Common
                  </Option>
                  <Option key="badge" value='badge'>
                    Badge
                  </Option>
                  <Option key="warrant" value='warrant'>
                    NFT+
                  </Option>
                </Select>
              </div>
              <Input onChange={handleSearch} style={{ width: '100%', margin: '14px 0' }} placeholder="search name" prefix={<SearchIcon style={{ fontSize: 16, marginRight: 6 }} />} />

              <div className="df_align_center pb6">
                <div
                  onClick={() => { handleSaleChange('') }}
                  className={`${styles.type_label} ${saleType === '' ? styles.type_active : ''}`}
                >{t('all')}</div>
                <div
                  onClick={() => { handleSaleChange('fixedswap') }}
                  className={`${styles.type_label} ${saleType === 'fixedswap' ? styles.type_active : ''}`}
                >{t('fixswap')}</div>
                <div
                  onClick={() => { handleSaleChange('auction') }}
                  className={`${styles.type_label} ${saleType === 'auction' ? styles.type_active : ''}`}
                >{t('auction')}</div>
              </div>

            </div>
          </div>
        </div>
      </Affix>


      <div>
        <div className={styles.main}>
          {
            loading ?
              <div className={styles.list_box} >
                {
                  loadingList.map(v => (
                    <div className={styles.loading_skeleton}>
                      <div className={styles.chainType}>chainType </div>
                      <div className={styles.loading_img}>
                        <Skeleton.Image />
                      </div>
                      <Skeleton size={'small'} active paragraph={{ rows: 1 }}>
                      </Skeleton>
                    </div>
                  ))
                }
              </div>
              :
              <InfiniteScroll
                dataLength={list.length}
                next={getList}
                hasMore={list.length < total}
                loader={
                  <div className={styles.list_box} >
                    {
                      loadingList.map(v => (
                        <div className={styles.loading_skeleton}>
                          <div className={styles.chainType}>chainType</div>
                          <div className={styles.loading_img}>
                            <Skeleton.Image />
                          </div>
                          <Skeleton active paragraph={{ rows: 1 }}>
                          </Skeleton>
                        </div>
                      ))
                    }
                  </div>
                }
                className={styles.list_box}
              >
                {
                  list.length === 0 && <div className={`wp100 df_center ${styles.marketplace_no_data}`}><NoData></NoData></div>

                }
                {
                  list.length > 0 && list.map(item => (
                    item && <NftCard key={item.contractAddress + item.id + item.chainType} tokenInfo={item} />
                  ))
                }

              </InfiniteScroll>
          }
        </div>
      </div>
    </div>
  )
}
export default Marketplace;
