import React from 'react'

export default function BlockProperty({item}) {
    console.log(item);
    return (
        <div className={`bg_f4 br4 p10 mr10 mb10`}>
            <div className={`fs12`}>{item.name}</div>
            <div className={`fw700`}>{item.value}</div>
            {
                item.rarity&&
                <div className={`fs12`}>{item.rarity} have this trait</div>
            }
        </div>
    )
}
