
export const filterGraffiti = [ 
    {label: '666 SINAE', value: '666 SINAE', percent: '3.35%' },
    {label: 'DANK DAMN', value: 'DANK DAMN', percent: '3.38%' },
    {label: 'DAPP n NFT community', value: 'DAPP n NFT community', percent: '3.34%' },
    {label: "Don't say PWNED", value: 'Blue', percent: '3.25%'},
    {label: 'ENTER', value: 'ENTER', percent: '3.30%'},
    {label: 'ETH GAZUA', value: 'ETH GAZUA', percent: '3.29%'},
    {label: 'FIAT', value: 'FIAT', percent: '3.33%'},
    {label: 'FLEX n CHILL', value: 'FLEX n CHILL', percent: '3.18%'},
    {label: 'FOMO', value: 'FOMO', percent: '3.32%'},
    {label: 'Fun Cool Sexy', value: 'Fun Cool Sexy', percent: '3.14%'},
    {label: 'Geek luv MATH so I luv it', value: 'Geek luv MATH so I luv it', percent: '3.33%'},
    {label: 'God has abandoned me', value: 'God has abandoned me', percent: '3.54%'},
    {label: 'GRAB IT', value: 'GRAB IT', percent: '3.30%'},
    {label: 'HODL Crypto', value: 'HODL Crypto', percent: '3.29%'},
    {label: "I'm NERD but HOT", value: "I'm NERD but HOT", percent: '3.20%'},
    {label: 'KITSCH ART', value: 'KITSCH ART', percent: '3.27%'},
    {label: 'NEW WAVE', value: 'NEW WAVE', percent: '3.35%'},
    {label: 'NFT and the Mars', value: 'NFT and the Mars', percent: '3.26%'},
    {label: 'Niftorev', value: 'Niftorev', percent: '3.26%'},
    {label: 'none', value: 'none', percent: '1.08%'},
    {label: 'OG', value: 'OG', percent: '3.35%'},
    {label: 'REAL SHIT', value: 'REAL SHIT', percent: '3.41%'},
    {label: 'RESPECT CRYPTO ART', value: 'RESPECT CRYPTO ART', percent: '3.26%'},
    {label: 'Save Snowmen', value: 'Save Snowmen', percent: '3.29%'},
    {label: 'SEE BAL', value: 'SEE BAL', percent: '3.36%'},
    {label: 'SNS', value: 'SNS', percent: '3.35%'},
    {label: 'STORICH', value: 'STORICH', percent: '3.31%'},
    {label: 'THUG', value: 'THUG', percent: '3.15%'},
    {label: 'To the Mars', value: 'To the Mars', percent: '3.29%'},
    {label: 'Viva La Vida', value: 'Viva La Vida', percent: '3.27%'},
    {label: 'What is my identity', value: 'What is my identity', percent: '3.09%'},
]
export const filterHands = [ 
    {label: 'Green', value: 'Green', percent: '8.85%' },
    {label: 'Pink', value: 'Pink', percent: '8.98%' },
    {label: 'Black', value: 'Black', percent: '8.94%' },
    {label: 'Blue', value: 'Blue', percent: '8.87%' },
    {label: 'Yellow', value: 'Yellow', percent: '9.14%' },
    {label: 'Grey', value: 'Grey', percent: '17.91%' },
    {label: 'Orange', value: 'Orange', percent: '9.24%' },
    {label: 'Red', value: 'Red', percent: '9.03%' },
    {label: 'Purple', value: 'Purple', percent: '8.99%' },
    {label: 'Turquoise', value: 'Turquoise', percent: '9.21%' },
    {label: 'Ancient', value: 'Ancient', percent: '0.01%' },
    {label: 'Hologram', value: 'Hologram', percent: '0.01%' },
    {label: 'Red w Blue', value: 'Red w Blue', percent: '0.09%' },
    {label: 'Yellow w Red', value: 'Yellow w Red', percent: '0.09%' },
    {label: 'Red w White', value: 'Red w White', percent: '0.18%' },
    {label: 'White', value: 'White', percent: '0.18%' },
    {label: 'Green w Yellow', value: 'Green w Yellow', percent: '0.09%' },
    {label: 'Blue w Red', value: 'Blue w Red', percent: '0.09%' },
    {label: 'White w Red', value: 'White w Red', percent: '0.09%' },
]
export const filterEmissionFuels = [ 
    {label: 'Melon', value: 'Melon', percent: '14.21%' },
    {label: 'Yellow', value: 'Yellow', percent: '14.12%' },
    {label: 'Sky blue', value: 'Sky blue', percent: '14.19%' },
    {label: 'Pink', value: 'Pink', percent: '13.99%' },
    {label: 'Light Purple', value: 'Light Purple', percent: '14.28%' },
    {label: 'Blue', value: 'Blue', percent: '14.00%' },
    {label: 'Orange', value: 'Orange', percent: '14.17%' },
    {label: 'Ancient', value: 'Ancient', percent: '0.01%' },
    {label: 'Medium Pink', value: 'Medium Pink', percent: '0.02%' },
    {label: 'Gold Yellow', value: 'Gold Yellow', percent: '0.01%' },
    {label: 'Hologram w White', value: 'Hologram w White', percent: '0.01%' },
    {label: 'White', value: 'White', percent: '0.19%' },
    {label: 'Medium Blue', value: 'Medium Blue', percent: '0.18%' },
    {label: 'Scarlet', value: 'Scarlet', percent: '0.36%' },
    {label: 'Light Blue', value: 'Light Blue', percent: '0.09%' },
    {label: 'Green', value: 'Green', percent: '0.09%' },
    {label: 'Scarlet w Yellow', value: 'Scarlet w Yellow', percent: '0.09%' },
   
]
export const filterBody = [ 
    {label: 'Aloe w Pink', value: 'Aloe w Pink', percent: '3.20%' },
    {label: 'Grey w Medium Grapes', value: 'Grey w Medium Grapes', percent: '3.26%' },
    {label: 'Red w Purple Potion', value: 'Red w Purple Potion', percent: '3.11%' },
    {label: 'Medium White w Purple mix Blue', value: 'Medium White w Purple mix Blue', percent: '3.18%' },
    {label: 'Dark Blue w Blue', value: 'Dark Blue w Blue', percent: '3.06%' },
    {label: 'Skin color w Grey', value: 'Skin color w Grey', percent: '3.35%' },
    {label: 'Bright Pink w Toxicated Grapes', value: 'Bright Pink w Toxicated Grapes', percent: '3.15%' },
    {label: 'Ocean w Medium Pink', value: 'Ocean w Medium Pink', percent: '3.18%' },
    {label: 'Blue w Turquoise', value: 'Blue w Turquoise', percent: '6.46%' },
    {label: 'Flamingo w Grapes', value: 'Flamingo w Grapes', percent: '3.17%' },
    {label: 'Blue mix Purple w Pink mix Red', value: 'Blue mix Purple w Pink mix Red', percent: '3.23%' },
    {label: 'Orange Lemonade w Ambiguous zombie', value: 'Orange Lemonade w Ambiguous zombie', percent: '3.24%' },
    {label: 'Pink w Light Pink', value: 'Pink w Light Pink', percent: '3.24%' },
    {label: 'Melon w Pink', value: 'Melon w Pink', percent: '3.11%' },
    {label: 'Dark Pink w Pink', value: 'Dark Pink w Pink', percent: '3.26%' },
    {label: 'Black w Purple', value: 'Black w Purple', percent: '3.15%' },
    {label: 'Poison Green w Gold Yellow', value: 'Poison Green w Gold Yellow', percent: '3.16%' },
    {label: 'Pink w Medium Sky Blue', value: 'Pink w Medium Sky Blue', percent: '3.15%' },
    {label: 'melon w Light Sky Blue', value: 'melon w Light Sky Blue', percent: '3.21%' },
    {label: 'Monster w Bacchus', value: 'Monster w Bacchus', percent: '3.17%' },
    {label: 'AmbiguousPurple w Ambiguous Orange', value: 'AmbiguousPurple w Ambiguous Orange', percent: '3.24%' },
    {label: 'Dark Mint w Light Green', value: 'Dark Mint w Light Green', percent: '3.09%' },
    {label: 'Turquoise w Ambiguous Blue', value: 'Turquoise w Ambiguous Blue', percent: '3.22%' },
    {label: 'Blck w Green', value: 'Blck w Green', percent: '3.08%' },
    {label: 'White w Light Grey', value: 'White w Light Grey', percent: '3.30%' },
    {label: 'White w Black', value: 'White w Black', percent: '3.21%' },
    {label: 'Black w Red', value: 'Black w Red', percent: '3.14%' },
    {label: 'Medium Jeans w Jeans', value: 'Medium Jeans w Jeans', percent: '3.14%' },
    {label: 'White w Light Pink', value: 'White w Light Pink', percent: '3.29%' },
    {label: 'Orange w Yellow', value: 'Orange w Yellow', percent: '3.17%' },
    {label: 'Mystery Ancient', value: 'Mystery Ancient', percent: '0.01%' },
    {label: 'White w Red Check Pattern', value: 'White w Red Check Pattern', percent: '0.01%' },
    {label: 'Dynasty Gold', value: 'Dynasty Gold', percent: '0.01%' },
    {label: 'Hologram', value: 'Hologram', percent: '0.01%' },
    {label: 'Wrapped Won', value: 'Wrapped Won', percent: '0.09%' },
    {label: 'Wrapped Dollar', value: 'Wrapped Dollar', percent: '0.09%' },
    {label: 'Wrapped Yuan', value: 'Wrapped Yuan', percent: '0.09%' },
    {label: 'Wrapped Euro', value: 'Wrapped Euro', percent: '0.09%' },
    {label: 'Wrapped Ruble', value: 'Wrapped Ruble', percent: '0.09%' },
    {label: 'Wrapped Mexican peso', value: 'Wrapped Mexican peso', percent: '0.09%' },
    {label: 'Lightning', value: 'Lightning', percent: '0.01%' },
    {label: 'Old Picnic Mat', value: 'Old Picnic Mat', percent: '0.01%' },
    {label: 'Swimming Pool', value: 'Swimming Pool', percent: '0.01%' },
    {label: 'Wrapped Canadian dollar', value: 'Wrapped Canadian dollar', percent: '0.09%' },
    {label: 'Wrapped Rupee', value: 'Wrapped Rupee', percent: '0.09%' },
    {label: 'Wrapped Pound sterling', value: 'Wrapped Pound sterling', percent: '0.09%' },
    {label: 'Wrapped Yen', value: 'Wrapped Yen', percent: '0.09%' },
    {label: 'Wrapped Baht', value: 'Wrapped Baht', percent: '0.09%' },
    {label: 'White w Blood', value: 'White w Blood', percent: '0.01%' },
]
export const filterSkiColor = [ 
    {label: 'Pink', value: 'Pink', percent: '10.95%' },
    {label: 'Turquoise', value: 'Turquoise', percent: '11.16%' },
    {label: 'Orange', value: 'Orange', percent: '10.91%' },
    {label: 'Purple', value: 'Purple', percent: '10.90%' },
    {label: 'Red', value: 'Red', percent: '11.15%' },
    {label: 'Green', value: 'Green', percent: '11.18%' },
    {label: 'Yellow', value: 'Yellow', percent: '11.28%' },
    {label: 'White', value: 'White', percent: '11.22%' },
    {label: 'Blue', value: 'Blue', percent: '11.04%' },
    {label: 'Ancient', value: 'Ancient', percent: '0.01%' },
    {label: 'White w Red Check Pattern', value: 'White w Red Check Pattern', percent: '0.01%' },
    {label: 'Dynasty Gold', value: 'Dynasty Gold', percent: '0.01%' },
    {label: 'Hologram', value: 'Hologram', percent: '0.01%' },
    {label: 'White ', value: 'White ', percent: '0.01%' },
    {label: 'Medium Blue', value: 'Medium Blue', percent: '0.12%' },
    {label: 'Black', value: 'Black', percent: '0.01%' },
    {label: 'Old Picnic Mat', value: 'Old Picnic Mat', percent: '0.01%' },
    
]
export const filterBackground = [ 
    {label: 'Yellow', value: 'Yellow', percent: '5.32%' },
    {label: 'Green', value: 'Green', percent: '5.31%' },
    {label: 'Light Lemonade', value: 'Light Lemonade', percent: '5.11%' },
    {label: 'Light blue sky', value: 'Light blue sky', percent: '5.27%' },
    {label: 'Blue', value: 'Blue', percent: '5.27%' },
    {label: 'Light Aloe', value: 'Light Aloe', percent: '4.88%' },
    {label: 'Salmon', value: 'Salmon', percent: '5.33%' },
    {label: 'Black', value: 'Black', percent: '5.20%' },
    {label: 'Pink', value: 'Pink', percent: '5.25%' },
    {label: 'Light Green', value: 'Light Green', percent: '5.25%' },
    {label: 'Light Purple', value: 'Light Purple', percent: '5.14%' },
    {label: 'Light Pink', value: 'Light Pink', percent: '5.31%' },
    {label: 'Light Grey', value: 'Light Grey', percent: '5.07%' },
    {label: 'CryptoPunk OG', value: 'CryptoPunk OG', percent: '5.14%' },
    {label: 'Medium Grey', value: 'Medium Grey', percent: '5.16%' },
    {label: 'Light Yellow', value: 'Light Yellow', percent: '5.32%' },
    {label: 'Medium Orange', value: 'Medium Orange', percent: '5.16%' },
    {label: 'Cherry', value: 'Cherry', percent: '5.27%' },
    {label: 'Brown', value: 'Brown', percent: '5.18%' },
    {label: 'Red', value: 'Red', percent: '0.01%' },
    {label: 'South Korea', value: 'South Korea', percent: '0.09%' },
    {label: 'USA', value: 'USA', percent: '0.09%' },
    {label: 'China', value: 'China', percent: '0.09%' },
    {label: 'Euro', value: 'Euro', percent: '0.09%' },
    {label: 'Russia', value: 'Russia', percent: '0.09%' },
    {label: 'Mexico', value: 'Mexico', percent: '0.09%' },
    {label: 'Space', value: 'Space', percent: '0.01%' },
    {label: 'Light Navy', value: 'Light Navy', percent: '0.01%' },
    {label: 'Water', value: 'Water', percent: '0.01%' },
    {label: 'Canada', value: 'Canada', percent: '0.09%' },
    {label: 'India', value: 'India', percent: '0.09%' },
    {label: 'UK', value: 'UK', percent: '0.09%' },
    {label: 'Japan', value: 'Japan', percent: '0.09%' },
    {label: 'Thailand', value: 'Thailand', percent: '0.09%' },
    {label: 'Medium White', value: 'SaMedium Whitefari', percent: '0.01%' },
 
]
export const filterEyes = [ 
    {label: 'Twitter', value: 'Twitter', percent: '4.29%' },
    {label: 'Red Blood', value: 'Red Blood', percent: '4.12%' },
    {label: 'BoPortrait of an noble hand of the independence herored', value: 'Portrait of an noble hand of the independence hero', percent: '4.42%' },
    {label: 'Blue w Red', value: 'Blue w Red', percent: '4.39%' },
    {label: 'Screen Adjustment', value: 'Screen Adjustment', percent: '4.40%' },
    {label: 'TV Error', value: 'TV Error', percent: '4.23%' },
    {label: 'Opensea', value: 'Opensea', percent: '4.29%' },
    {label: "No, it's Crypto Art", value: "No, it's Crypto Art", percent: '4.40%' },
    {label: 'Blue Blood', value: 'Blue Blood', percent: '4.37%' },
    {label: 'XX', value: 'XX', percent: '4.23%' },
    {label: '!?', value: '!?', percent: '4.42%' },
    {label: 'Yellow X', value: 'Yellow X', percent: '4.44%' },
    {label: 'White w Blue', value: 'White w Blue', percent: '4.34%' },
    {label: '$$', value: '$$', percent: '4.37%' },
    {label: 'Meebits', value: 'Meebits', percent: '4.39%' },
    {label: 'Computer Error', value: 'Computer Error', percent: '4.30%' },
    {label: 'Dizzy', value: 'Dizzy', percent: '4.55%' },
    {label: 'BART', value: 'BART', percent: '4.46%' },
    {label: 'White X', value: 'White X', percent: '4.28%' },
    {label: 'Normal', value: 'Normal', percent: '8.49%' },
    {label: 'QR', value: 'QR', percent: '4.38%' },
    {label: 'Red w White', value: 'Red w White', percent: '4.39%' },
    {label: 'Normal w Ancient', value: 'Normal w Ancient', percent: '0.01%' },
    {label: 'White w Red', value: 'White w Red', percent: '0.01%' },
    {label: 'Yellow w Red', value: 'Yellow w Red', percent: '0.01%' },
    {label: 'White w Turquoise', value: 'White w Turquoise', percent: '0.01%' },
    {label: 'White w Navy', value: 'White w Navy', percent: '0.01%' },
    {label: 'Double blue', value: 'Double blue', percent: '0.01%' },
    {label: 'Bloody n normal', value: 'Bloody n normal', percent: '0.01%' },
]

export const filterStickers = [
    {label: 'PHONE', value: 'PHONE', percent: '3.44%' },
    {label: 'NBA1', value: 'NBA1', percent: '3.30%' },
    {label: 'YELLOW BUMBUCK', value: 'YELLOW BUMBUCK', percent: '3.31%' },
    {label: 'HEALTH', value: 'HEALTH', percent: '3.45%' },
    {label: 'ARMY2', value: 'ARMY2', percent: '3.34%' },
    {label: 'SNS', value: 'SNS', percent: '3.47%' },
    {label: 'DANGER', value: 'DANGER', percent: '3.35%' },
    {label: 'NFL1', value: 'NFL1', percent: '3.47%' },
    {label: 'NFT', value: 'NFT', percent: '3.54%' },
    {label: 'COMPANIES', value: 'COMPANIES', percent: '3.33%' },
    {label: 'NFL2', value: 'NFL2', percent: '3.31%' },
    {label: 'PIZZA', value: 'PIZZA', percent: '3.44%' },
    {label: 'BURGERS', value: 'BURGERS', percent: '3.39%' },
    {label: 'EPL', value: 'EPL', percent: '3.43%' },
    {label: 'EXIT', value: 'EXIT', percent: '3.48%' },
    {label: 'BEVERAGES', value: 'BEVERAGES', percent: '3.44%' },
    {label: 'KBO', value: 'KBO', percent: '3.44%' },
    {label: 'ALIENS', value: 'ALIENS', percent: '3.38%' },
    {label: 'MLB2', value: 'MLB2', percent: '3.29%' },
    {label: 'ARMY1', value: 'ARMY1', percent: '3.39%' },
    {label: 'ARMY4', value: 'ARMY4', percent: '3.43%' },
    {label: 'NBA2', value: 'NBA2', percent: '3.32%' },
    {label: 'PAC12', value: 'PAC12', percent: '3.43%' },
    {label: 'SANTA', value: 'aSANTA', percent: '3.53%' },
    {label: 'ARMY3', value: 'ARMY3', percent: '3.35%' },
    {label: 'MLB1', value: 'MLB1', percent: '3.45%' },
    {label: 'LCK', value: 'LCK', percent: '3.52%' },
    {label: 'BEERS', value: 'BEERS', percent: '3.50%' },
    {label: 'aEMOJI', value: 'EMOJI', percent: '3.40%' },
    {label: 'none', value: 'none', percent: '1.07%' },
    {label: 'Blood', value: 'Blood', percent: '0.01%' },
]

export const filterTopheads = [
    {label: '!', value: '!', percent: '2.67%' },
    {label: '?', value: '?', percent: '2.67%' },
    {label: '@', value: '@', percent: '2.67%' },
    {label: '#', value: '#', percent: '2.84%' },
    {label: 'Angel ring', value: 'Angel ring', percent: '2.50%' },
    {label: 'Bitcoin', value: 'Bitcoin', percent: '5.23%' },
    {label: 'Canada Flag', value: 'Canada Flag', percent: '0.09%' },
    {label: 'Candy', value: 'Candy', percent: '2.58%' },
    {label: 'Candy Cane', value: 'Candy Cane', percent: '2.68%' },
    {label: 'China Flag', value: 'China Flag', percent: '0.09%' },
    {label: 'Cuttie Snowman', value: 'Cuttie Snowman', percent: '2.64%' },
    {label: 'Discord', value: 'Discord', percent: '2.72%' },
    {label: 'Dollar', value: 'Dollar', percent: '2.73%' },
    {label: 'Earth', value: 'Earth', percent: '2.73%' },
    {label: 'Emergency', value: 'Emergency', percent: '2.67%' },
    {label: 'ETH', value: 'ETH', percent: '2.73%' },
    {label: 'Euro', value: 'Euro', percent: '2.78%' },
    {label: 'Euro Flag', value: 'Euro Flag', percent: '0.09%' },
    {label: 'Facebook', value: 'Facebook', percent: '5.42%' },
    {label: 'Google', value: 'Google', percent: '2.78%' },
    {label: 'Heart', value: 'Heart', percent: '2.59%' },
    {label: 'India Flag', value: 'India Flag', percent: '0.09%' },
    {label: 'Instagram', value: 'Instagram', percent: '2.71%' },
    {label: 'Japan Flag', value: 'Japan Flag', percent: '0.09%' },
    {label: 'Mars', value: 'Mars', percent: '2.70%' },
    {label: 'Mexico Flag', value: 'Mexico Flag', percent: '0.09%' },
    {label: 'Moon', value: 'Moon', percent: '2.46%' },
    {label: 'none', value: 'none', percent: '0.09%' },
    {label: 'Parking Cone', value: 'Parking Cone', percent: '2.75%' },
    {label: 'Radioactive', value: 'Radioactive', percent: '2.66%' },
    {label: 'Russia Flag', value: 'Russia Flag', percent: '0.09%' },
    {label: 'Snapchat', value: 'Snapchat', percent: '2.60%' },
    {label: 'Snowman', value: 'Snowman', percent: '2.76%' },
    {label: 'Soilder Snowman', value: 'Soilder Snowman', percent: '2.53%' },
    {label: 'SOS Snowman', value: 'SOS Snowman', percent: '2.78%' },
    {label: 'South Korea Flag', value: 'South Korea Flag', percent: '0.09%' },
    {label: 'Sprout', value: 'Sprout', percent: '2.71%' },
    {label: 'Thailand Flag', value: 'Thailand Flag', percent: '0.09%' },
    {label: 'Twitch', value: 'Twitch', percent: '2.61%' },
    {label: 'Twitter', value: 'Twitter', percent: '2.72%' },
    {label: 'UK Flag', value: 'UK Flag', percent: '0.09%' },
    {label: 'USA Flag', value: 'USA Flag', percent: '0.09%' },
    {label: 'Wifi', value: 'Wifi', percent: '2.62%' },
    {label: 'Won', value: 'Won', percent: '2.63%' },
    {label: 'Yen', value: 'Yen', percent: '2.62%' },
    {label: 'Youtube', value: 'Youtube', percent: '5.43%' },

]

export const filterPoseOrAccessories = [
    {label: 'Angel metamasks', value: 'Angel metamasks', percent: '0.83%' },
    {label: 'Buckets with snowmen', value: 'Buckets with snowmen', percent: '0.83%' },
    {label: "Children's playground", value: "Children's playground", percent: '0.83%' },
    {label: 'Gamble dices', value: 'Gamble dices', percent: '0.83%' },
    {label: 'Game pad', value: 'Game pad', percent: '0.83%' },
    {label: 'Hands Up', value: 'Hands Up', percent: '0.99%' },
    {label: 'Inner peace circle', value: 'Inner peace circle', percent: '0.83%' },
    {label: 'Love bumbucks', value: 'Love bumbucks', percent: '0.83%' },
    {label: 'none', value: 'none', percent: '89.02%' },
    {label: 'Random boxes', value: 'Random boxes', percent: '0.83%' },
    {label: 'Sk8er snowmen', value: 'Sk8er snowmen', percent: '0.83%' },
    {label: 'Travel carrier', value: 'Travel carrier', percent: '0.83%' },
    {label: 'Under construction', value: 'Under construction', percent: '0.83%' },
    {label: 'Workout', value: 'Workout', percent: '0.83%' },

]