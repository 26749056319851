import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Upload, message, Spin } from 'antd'
import { LoadingOutlined, InfoCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { mainContext } from "../../../reducer";
import { chainFun, chains, chainTxtObj, chainTypeComImgObj } from '../../../utils/networkConnect';
import { dataURLtoBlob } from '../../../utils/dataURLtoBlob';
import { useActiveWeb3React } from "../../../web3";
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { useNeedSign } from "hooks/account"
import { getChainType } from "../../../web3/address";
import { deployNFT721 } from '../../../utils/handleContract'
import { saveCollection } from 'services/create'
import { getCollectionInfo } from '../../../services/NftPlusBuyPage';
import { addContractAddress } from '../../../services/badgeManage'

import arrowIcon from 'assets/img/draft/arrow_l.png';
import styles from '../styles.module.scss';
import {
  HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
  HANDLE_SHOW_TRANSACTION_MODAL,
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  HANDLE_SHOW_CONNECT_MODAL,
  waitingForConfirm,
  waitingForInit,
  waitingPending
} from "../../../const";

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default function CreateCollection() {
  const { dispatch } = useContext(mainContext);
  const location = useLocation();
  const history = useHistory()
  const { library, account, active, chainId } = useActiveWeb3React()
  const { tronLibrary, tronAccount, tronChainId, tronActive } = useActiveTronWeb();
  const [formRef] = Form.useForm()
  const [preview, setPreview] = useState();
  const [image, setImage] = useState();
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const chainArr = chains.filter(item => { return item.value != 'klaytn' && item.value != 'baobab' && item.value != 'tron' && item.value != 'shasta' && item.value != 'rinkeby' && item.value != 'ropsten' })
  const [creatForm, setCreatForm] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { needSign } = useNeedSign();
  const [collectionId, setCollectionId] = useState()
  const [isEdit, setIsEdit] = useState(false)

  const beforeCrop = (file) => {
    const isVideo = file.type === 'video/mp4' || file.type === 'video/quicktime' || file.tyoe === 'image/gif'
    getBase64(file, url => {
      setImage(url);
      formRef.setFieldsValue({ image: url });
    })
    document.getElementById("videoBox").innerHTML = '';
    // setImage(URL.createObjectURL(file))
    if (isVideo) {
      setShowVideo(true);
      setShowImage(false);
      setPreview(undefined)
      if (file.type === 'image/gif') {
        if (window.FileReader) {
          const fr = new FileReader();
          fr.onloadend = function (e) {
            const img = document.createElement("img");
            img.src = e.target.result;
            img.width = 305;
            img.height = 305;
            document.getElementById("videoBox").appendChild(img);
            setPreview(e.target.result)
          };
          fr.readAsDataURL(file);
        }
      }
      if (file.type === 'video/mp4' || file.type === "video/quicktime") {
        if (window.FileReader) {
          const fr = new FileReader();
          fr.onloadend = function (e) {
            const video = document.createElement("video");
            video.controls = "controls";
            video.src = e.target.result;
            video.width = 305;
            video.height = 305;
            document.getElementById("videoBox").appendChild(video);
            setPreview(e.target.result)
          };
          fr.readAsDataURL(file);
        }
      }
      return false;
    }
    setShowVideo(false);
    setShowImage(true);
    return true
  }
  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      getBase64(file.url, url => {
        setImage(url);
      })
    }
  };
  const handleUploadChange = info => {
    if (info.file.status === 'uploading') {
      // window.localStorage.setItem('collectionImage', JSON.stringify(info.file.originFileObj))
      getBase64(info.file.originFileObj, url => {
        setPreview(url);
        setImage(url);
      });
      return;
    }
  };

  const transformString = (input) => {
    const uppercaseString = input.toUpperCase();
    const transformedString = uppercaseString.replace(/\s+/g, '-');
    return transformedString;
  }
  const blurName = (e) => {
    // if (hasContractAddress) { return false }
    let str = e.target.value
    if (str) {
      let tokenSymbol = transformString(str)
      formRef.setFieldsValue({ symbol: tokenSymbol })
    }
  }
  const handleChangeChain = (chain) => {
    let chainType = getChainType(chainId)
    if (chain === chainType) return false
    // console.log(formRef.getFieldsValue());
    needSign(() => {
      const chainTxt = chainTxtObj[chain];
      if (tronChainId && !chainId && chainTxt !== 'Tron') {
        dispatch({
          type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
        });
        return message.success('Please connect wallet.', 1)
      }

      if (chainFun[chainTxt]) {
        window.localStorage.setItem('collectionInfo', JSON.stringify(formRef.getFieldsValue()))
        chainFun[chainTxt]();
      } else if (chainTxt === 'Tron') {
        dispatch({
          type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
        });
        return message.success('Please connect TronLink.', 1)
      }
    })
  }
  const onFinish = async (values) => {
    if (!image) {
      return message.error("Collection image is required")
    }
    if (!values.name) {
      return message.error("Collection name is required")
    }
    if (!values.chainType) {
      return message.error("Blockchain is required")
    }
    if (!values.description) {
      return message.error("Collection description is required")
    }
    if (!values.symbol) {
      return message.error("Token symbol is required")
    }
    setConfirmLoading(true);
    let formData = new FormData();
    let reg = /https:\/\//
    if (image && !reg.test(image)) {
      formData.append('image', dataURLtoBlob(image))
    }
    values.name && formData.append('name', values.name)
    values.description && formData.append('description', values.description)
    values.chainType && formData.append('chainType', values.chainType)
    if (!isEdit) {
      let res = await deployContract(values.name, values.symbol, values.chainType)
      res.address && formData.append('contract', res.address)
    } else {
      formData.append('contract', creatForm.contractAddress)
      formData.append('collection', creatForm.collection)
    }
    saveCollection(formData).then((res) => {
      window.localStorage.removeItem('collectionInfo')
      message.success("Success")
      history.push(`/collectionManage/collectionDetail/${res.type}`)

    }).finally(() => {
      setConfirmLoading(false);
    })
  }
  const beforeDeploy = (targetChainType) => {
    let chainType = getChainType(chainId)
    if (targetChainType !== chainType) {
      message.error(`please switch to ${chainTxtObj[targetChainType]}`)
      chainFun[chainTxtObj[targetChainType]] && chainFun[chainTxtObj[targetChainType]]()
      return false
    }
    return true
  }
  const deployContract = async (name, symbol, chainType) => {
    if (!beforeDeploy(chainType)) return false
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    let res = await deployNFT721(library, account, name, symbol, chainType)
    // console.log(res);
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { show: false }
    });
    message.success("success")
    if (res) {
      saveContractAddress(res, chainType, symbol)
    }
    return res
  }
  const saveContractAddress = async (contractInfo, chainType, symbol) => {
    let obj = {
      address: contractInfo.address,
      chainType: chainType,
      type: 'erc721',
      transactionHash: contractInfo.transactionHash,
      blockNumber: contractInfo.blockNumber,
      symbol: symbol
    }

    let res = await addContractAddress(obj)
  }
  const initData = () => {
    let content = location.search
    if (content.indexOf("=") > 0) {
      let collectionId = decodeURIComponent(content.substring(14))
      setCollectionId(collectionId)
      setIsEdit(true)
      queryCollectionInfo(collectionId)
    } else {
      let info = JSON.parse(window.localStorage.getItem('collectionInfo'))
      if(info){
        setCreatForm(info)
      }else {
        setCreatForm({})
      }
    }
  }
  const queryCollectionInfo = async (collectionId) => {
    let res = await getCollectionInfo(collectionId)
    res = res || {};
    setImage(res.image)
    setShowImage(true)
    let obj = {
      collection: res.type,
      name: res.name,
      chainType: res.chainType,
      description: res.description,
      contractAddress: res.contractAddress,
      symbol: res.nftSymbol
    }
    setCreatForm(obj)
  }
  useEffect(() => {
    initData()
  }, [])
  return (
    <div className={styles.createCollection}>
      <div className={styles.collection_title}>{isEdit?'Edit Collection':'Create Collection'}</div>
      {
        creatForm ?
          <Form className='mt10' form={formRef} layout={'vertical'} onFinish={onFinish}>
            <div className={styles.formBox}>
              <Row>
                <Col span={12}>
                  <p>Cover Image</p>
                  <div className={styles.uploadBox}>
                    <div className={styles.upload_common} >
                      <ImgCrop rotate beforeCrop={beforeCrop} modalTitle={'Set Collection Cover'} modalOk={'apply'} modalCancel={'cancel'}>
                        <Dragger
                          name="image"
                          className={`${styles.imgUpload} ${image ? styles.hasImage : ''}`}
                          showUploadList={false}
                          // beforeUpload={() => false}
                          onPreview={onPreview}
                          onChange={handleUploadChange}
                          listType="picture"
                          accept="image/png, image/jpg, image/jpeg, video/mp4, video/quicktime, image/gif"
                        >
                          {showImage &&
                            <div className={styles.previewBox}><img src={image} alt="preview" style={{ width: '100%' }} /></div>
                          }
                          <div id="videoBox" className={styles.videoBox}>
                          </div>
                          {!showImage && !showVideo &&
                            <>
                              <div><img src={arrowIcon} height="60px" className="mb20" /></div>
                              <Button className="btn_border">{'Upload Cover Image'}</Button>
                            </>
                          }
                        </Dragger>
                      </ImgCrop>
                    </div>
                    <div className={`df_align_center ${styles.aiBox}`}>
                      <div className='mr40'>Support: JPG, JPEG2000, PNG, GIF, MOV, MP4</div>
                      {/* <Button>Generate image with AI</Button> */}
                    </div>
                  </div>
                </Col>
                <Col span={12} className={styles.form_right}>
                  <div>
                    <Form.Item label="Collection Name" name={"name"} initialValue={creatForm.name}>
                      <Input disabled={isEdit} onBlur={blurName} size='large' placeholder='Enter collection name'></Input>
                    </Form.Item>
                    <Form.Item label="Description" name={"description"} initialValue={creatForm.description}>
                      <TextArea autoSize={{ minRows: 8, maxRows: 10 }} maxLength={2000} placeholder='Enter collection description'></TextArea>
                    </Form.Item>
                    <Form.Item className={styles.level} label="Blockchain" name={"chainType"} initialValue={creatForm.chainType}>
                      <Select disabled={isEdit} size='large' onChange={handleChangeChain}>
                        {
                          chainArr.map(item => (
                            <Option key={item.label + new Date().getTime()} value={item.value}>
                              <img className={styles.option_img} src={chainTypeComImgObj[item.value]} alt={item.label} />
                              <span className={'pl8'}>{item.label}</span>
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item className={styles.level} label="Token symbol" name={"symbol"} initialValue={creatForm.symbol} tooltip={{ title: "The token symbol is shown on the block explorer when others view your smart contract.", icon: <InfoCircleFilled /> }}>
                      <Input disabled={isEdit} size='large' placeholder='Enter token symbol'></Input>
                    </Form.Item>
                    {
                      isEdit &&
                      <div className='space-between'>
                        <span>Contract Address</span>
                        <span className='c_purple'>{creatForm.contractAddress}</span>
                      </div>
                    }
                  </div>
                  {
                    isEdit ?
                      <Button type='primary' className='wp100' htmlType="submit">Save {confirmLoading && <LoadingOutlined />}</Button> :
                      <Button type='primary' className='wp100' htmlType="submit">Deploy your own contract and create a collection {confirmLoading && <LoadingOutlined />}</Button>
                  }
                </Col>
              </Row>
            </div>
          </Form> :
          <div className={`tac mt50`}>
            <Spin size={`large`}></Spin>
          </div>
      }
    </div>
  )
}
