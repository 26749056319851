import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import moment from 'moment';

import { abbrTxHash } from "../../utils/format";
// import { chainTypeImgObj } from '../../utils/networkConnect';
import arrowLeft from "assets/img/home/arrow_left.png"
import arrowright from "assets/img/home/arrow_right.png"
import { ReactComponent as IconAirdrop } from "assets/img/creatBadge/airdropIcon.svg"
import { chainTypeImgObj, chainTxtObj, chainArr } from '../../utils/networkConnect';
import hot from "assets/img/badge/hot.svg"
import styles from './styles.module.scss'


export default function EventItem({ item }) {
  const history = useHistory()

  const goToBadgeDetail = (item) => {
    if (item.claimType === 'blindbox') {
      history.push(`/blindbadge/${item.project}`)
    } else if (item.claimType === 'nftPlus') {
      history.push(`/${item.project}`)
    } else {
      if (item.types.length > 1) {
        history.push(`/badge/${item.project}`)
      } else {
        history.push(`/badge/${item.project}/${item.types[0]}`)
      }
    }
  }
  useEffect(() => {
  },[])

  return (
    <div className={styles.event_item} onClick={()=>{goToBadgeDetail(item)}}>
      <div className={`f2 df_align_center`}>
        <img className={styles.item_logo} width={80} src={item.image} />
        <span className='pl20 fw600'>{item.title}</span>
      </div>
      <div className={`f1`}>
        <img  className={styles.item_chainType_logo}  width={24} src={chainTypeImgObj[item.chainType]} />
        <span className='pl8 fw600'>{chainTxtObj[item.chainType]}</span>
      </div>
      <div className={`f2 tac`}>
        <span className='fw600'>{moment(item.eventStartTime).format('DD/MM/YYYY')} - {moment(item.eventEndTime).format('DD/MM/YYYY')}</span>
      </div>
      <div className={`f1 df_align_center`}>
        <img width={24} className={styles.item_creator} src={item.logo} />
        <span className='pl8 fw600'>{abbrTxHash(item.creator)}</span>
      </div>
      <div className={`f1 df_center`}>
        {
          item.status === "active" &&
          <div className={`fw600 br6 ${styles.btn_active}`}><img width={12} src={hot} /> <span className='pl4'>Active</span> </div>
        }
        {
          item.status === 'soon' &&
          <div className={`fw600 br6 ${styles.btn_soon}`}>Soon</div>
        }
        {
          item.status === 'ended' &&
          <div className={`fw600 br6 ${styles.btn_end}`}>Ended</div>
        }
      </div>
    </div>
  )
}