import React from 'react';

import Avatar from 'components/Avatar';
import { chainTxtObj, chainTypeImgObj } from 'utils/networkConnect';
import tag from 'assets/img/artist/tag.png';


export default function BlockInfo({ blockType, chainType, url, name, address, isArtist, isOwnedNft }) {

  return (
    <div className={`df_center`}>
      <div className={`mr10`}>
        {
          blockType === 'Blockchain' ?
            <img className={`brp50`} width={36} height={36} src={chainTypeImgObj[chainType]}></img> :
            <Avatar isOwnedNft={isOwnedNft} address={address} size={36} url={url} tagSize={30} leftP={3}></Avatar>
          // <img className={`brp50`} width={36} height={36} src={url}></img>
        }
      </div>
      <div className={`mr16`}>
        <div>{blockType}</div>
        {
          blockType === 'Blockchain' ?
            <div className={`fw700`}>{chainTxtObj[chainType]}</div>
            :
            <div className={`fw700 txt_nowrap df_center`}>{name}
            {
              isArtist && <img src={tag} width={16}></img>
            }
            </div>
            

        }
      </div>
    </div>
  )
}
