import React, { useState, useRef, useEffect } from 'react';
import { Spin, Input, Divider, Button, Affix } from 'antd';
import { DownOutlined, UpOutlined, DoubleRightOutlined } from '@ant-design/icons';
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useCollectionList, queryList } from "../../services/collectionList"
import NoData from "components/NoData";
import Avatar from "components/Avatar";
import { ReactComponent as SearchIcon } from "assets/img/search.svg";
import styles from './styles.module.scss';
import gaming from "assets/img/collectionList/gaming.svg"
import other from "assets/img/collectionList/other.svg"
import { chainTypeImgObj } from '../../utils/networkConnect';


export default function CollectionList() {
  const history = useHistory();
  const { t } = useTranslation();

  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('other')
  const [loading, setLoading] = useState(false)
  const [gameLoading, setGameLoading] = useState(false)
  const [gameList, setGameList] = useState([])
  const { collectionList, total } = useCollectionList(pageNo, 20, search, category, setLoading)
  const timer = useRef()
  const [isMore, setIsMore] = useState(false)
  const [isView, setIsView] = useState('auto')
  const [isPc, setIsPc] = useState(true)
  const [isSearch, setIsSearch] = useState(false)
  const [fixed, setFixed] = useState(false)

  const getList = () => {
    setPageNo(pageNo + 1)
  }
  const queryGameList = async () => {
    setGameLoading(true)
    let res = await queryList(pageNo, 99, search, 'game')
    setGameList(res.list)
    if (res.list.length > 8) {
      setIsMore(true)
      setIsView('550px')
    } else {
      setIsMore(false)
      setIsView('auto')
    }
    setGameLoading(false)
  }
  const handleSearch = (e) => {
    let val = e.target.value
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setPageNo(1)
      setSearch(val)
      if(val){
        setIsSearch(true)
        setCategory('')
      }else {
        setIsSearch(false)
        setCategory('other')
      }
    }, 600);
  }
  const viewAll = () => {
    setIsView('auto')
  }
  const packUp = () => {
    setIsView('550px')
  }
  const goToCollection = (item) => {
    history.push(`collection/${item.type}`)
  }
  const onAffix = val => {
    setFixed(val)
  }
  useEffect(() => {
    queryGameList()
    if (document.body.clientWidth < 800) {
      setIsPc(false)
    }
  }, [])
  return (
    <div className={styles.page_content_collections}>
      <Affix offsetTop={0} onChange={onAffix}>
      <div className={`${styles.collection_header}`} style={{ background: fixed ? '#fff' : '' }}>
        <div className={`space-between-center ${styles.header_main}`}>
          <div className={`page_content_title`}>Collections</div>
          <div >
            <Input allowClear onChange={handleSearch} className={styles.collection_search} placeholder="search name" prefix={<SearchIcon style={{ fontSize: 16, marginRight: 6 }} />} />
          </div>
        </div>
      </div>
      </Affix>
      {
        !isSearch &&
        <>
          <div className={styles.collection_gaming}>
            <div className={`${styles.list_title}`}> <img width={33} src={gaming}></img><span className={`pl10`}>Gaming NFT</span></div>
            {/* <div className='tac mt10'>Boost your P2E with unique collectibles</div> */}
            {
              gameLoading?
              <div style={{ width: 216, textAlign: 'center', margin: '20px auto' }}>
                <Spin size={`large`} />
              </div>:
              <>
                <div className={styles.collection_list} style={{ height: isView }}>
                  {
                    gameList.map(item => (
                      <div key={item.name} className={styles.collection_item} onClick={()=> {goToCollection(item)}}>
                        <div className={styles.item_cover}>
                          <img className={styles.cover} src={item.image}></img>
                          {/* <img className={styles.chainType} src={chainTypeImgObj[item.chainType]}></img> */}
                        </div>
                        <div className={styles.collection_creator}>
                          <img className={styles.avatar} src={item.creator && item.creator.avatar}></img>
                        </div>
                        <div className={styles.name}>{item.name}</div>
                        <div className={`text_hidden_1 ${styles.des}`}>{item.creator.name}</div>
                        <div className={styles.collection_des}>{t(`${item.description}`)}</div>
                      </div>
                    ))
                  }
                </div>
                {
                  isMore &&
                  <>
                    {
                      isView === 'auto' ?
                        <div className={`${styles.viewPack} cp`} onClick={packUp}>Pack Up <UpOutlined /></div> :
                        <div className={`${styles.viewPack} cp`} onClick={viewAll}>View All <DownOutlined /></div>
                    }
                  </>
                }
                {
                  isMore && isView === 'auto' &&
                  <Button className={`btn_border w120 ${styles.packUp}`} onClick={packUp}><DoubleRightOutlined className={styles.tranlate90} />Pack up</Button>
                }
                {
                  gameList.length === 0 && <div className={`wp100 df_center`}><NoData></NoData></div>
                }
              </>
            }
          </div>
          {/* <Divider className={`ml10 mr10`}></Divider> */}
        </>
      }
      <div className={styles.collection_main}>
        {
          !isSearch?
          <div className={`${styles.list_title}`}> <img width={33} src={other}></img><span className={`pl10`}>Other collections</span></div>:
          <div className={`${styles.list_title}`}>Collection results</div>
        }
        {/* <div className='tac mt10'>Description gaming nft XXXXXXXXXXXXXXXXXx</div> */}
        {
          loading ? <Spin className={`wp100 mt40`} size="large" /> :

            <InfiniteScroll
              dataLength={collectionList.length}
              next={getList}
              hasMore={collectionList.length < total}
              loader={
                <div style={{ width: 216, textAlign: 'center', margin: 'auto' }}>
                  <Spin size={`large`} />
                </div>
              }
            >
              <div className={styles.main_list}>
                {
                  collectionList.map(item => (
                    <div key={item.name} className={styles.collection_item} onClick={()=> {goToCollection(item)}}>
                      <div className={styles.item_cover}>
                        <img className={styles.cover} src={item.image}></img>
                        {/* <img className={styles.chainType} src={chainTypeImgObj[item.chainType]}></img> */}
                      </div>
                      <div className={styles.collection_creator}>
                        <img className={styles.avatar} src={item.creator && item.creator.avatar}></img>
                      </div>
                      <div className={`text_hidden_1 ${styles.name}`}>{item.name}</div>
                      <div className={`text_hidden_1 ${styles.des}`}>{item.creator?item.creator.name:''}</div>
                      <div className={styles.collection_des} dangerouslySetInnerHTML={{ __html: t(`${item.description}`) }}></div>
                    </div>
                  ))
                }
                {
                  collectionList.length === 0 && <div className={`wp100 df_center mt40`}><NoData></NoData></div>
                }
              </div>
            </InfiniteScroll>
        }
      </div>
    </div>
  )
}
