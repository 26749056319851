import React, { useState, useEffect } from "react";
import {
  Tabs, Spin, Button, message, Select,
  Modal, Row, Col, Input, Form, Radio,
  DatePicker
} from 'antd';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import { useActiveWeb3React, getContract } from "../../web3";
import { getEnglishAuctionNFTAddress, getFixswapAddress } from '../../web3/address'
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { getSymbol } from "../../utils/symbol";
import { formatAmountWithDecimal } from 'utils/format';
import {
  getTokenAddress, erc20TokenList as erc20TokenListOriginal,
  getDecimalByName, trc20TokenList
} from '../../utils/tokenList'
import EnglishAuctionNFT from '../../web3/abi/EnglishAuctionNFT.json'
import FixedSwapNFT from '../../web3/abi/FixedSwapNFT.json'
import styles from "./styles.module.scss";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'left',
  preserve: false,
  colon: false
};
const { Option } = Select;

export const NftSellModal = ({
  visible, dataType, detailInfo, onCancel, onSellFinish
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { library, account, active, chainId } = useActiveWeb3React()
  const { tronLibrary, tronAccount, tronChainId, tronActive } = useActiveTronWeb();
  const symbol = getSymbol(chainId || tronChainId)
  const [currency, setCurrency] = useState(symbol)
  const [fixswapStartTime, setFixswapStartTime] = useState(0);
  const [auctionStartTime, setAuctionStartTime] = useState(0);
  const [auctionEndTime, setAuctionEndTime] = useState(0);
  const [type, setType] = useState('swap');
  const [feeObj, setFeeObj] = useState({ swap: '', bid: '' });
  const erc20TokenList = erc20TokenListOriginal.filter(item => item.chainIds.includes(chainId))

  const handleTypeChange = e => {
    setType(e.target.value);
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const disabledDate = current => {
    return current < moment().startOf('day') || current > moment().add(6, 'day')
  };

  const disabledDateTime = (dates) => {
    let hours = moment().hours();
    let minutes = moment().minutes();

    if (!dates) {
      return {
        disabledHours: () => range(0, 24).splice(0, hours),
        disabledMinutes: () => range(0, 60).splice(0, minutes + 5)
      };
    }

    if (dates && dates.format('YYYY-MM-DD').valueOf() === moment().format('YYYY-MM-DD').valueOf()) { //today
      if (dates.format('HH').valueOf() !== moment().format('HH').valueOf()) {
        return {
          disabledHours: () => range(0, 24).splice(0, hours)
        };
      } else {
        return {
          disabledHours: () => range(0, 24).splice(0, hours),
          disabledMinutes: () => range(0, 60).splice(0, minutes + 10)
        };
      }
    }

    if (dates && dates.format('YYYY-MM-DD').valueOf() > moment().add(6, 'day').format('YYYY-MM-DD').valueOf()) {
      return {
        disabledHours: () => range(0, 24).splice(hours)
      };
    }
  }

  const toDecimals = (value, decimal) => {
    let result, power
    power = new BigNumber(10).pow(decimal)
    result = new BigNumber(value).times(power)
    return result
  }

  const getToken1 = () => {
    if (tronAccount) {
      return getTokenAddress(currency, trc20TokenList) ? getTokenAddress(currency, trc20TokenList) : 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb'
    }
    if (account) {
      return getTokenAddress(currency, erc20TokenList) ? getTokenAddress(currency, erc20TokenList) : '0x0000000000000000000000000000000000000000'
    }
  }

  const onFinishSell = (values) => {
    let decimal;
    if (tronAccount) {
      decimal = getDecimalByName(currency, trc20TokenList)
    }
    if (account) {
      decimal = getDecimalByName(currency, erc20TokenList)
    }
    onSellFinish(values, {
      weiPrice: toDecimals(values.price, decimal),
      weiIncr: toDecimals(values.incr, decimal),
      token1: getToken1(),
    })
  }

  const queryAuctionFees = async () => {
    let auctionContract;
    let auctionFee;
    let auctionFeeMax
    if (account) {
      auctionContract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId));
      auctionFee = await auctionContract.methods.fee().call()
      auctionFeeMax = await auctionContract.methods.feeMax().call()
    } else {
      auctionContract = window.tronWeb.contract(EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(tronChainId))
      auctionFee = await auctionContract.fee().call()
      auctionFee = Number(auctionFee._hex)
      auctionFeeMax = await auctionContract.feeMax().call()
      auctionFeeMax = Number(auctionFeeMax._hex)
    }
    auctionFeeMax = auctionFeeMax <= 0 ? 10000 : auctionFeeMax

    setFeeObj(old => ({
      ...old,
      bid: auctionFee / auctionFeeMax * 100
    }))
  }
  const queryFixswapFees = async () => {
    let fixswapContract;
    let fixswapFee;
    let fixswapFeeMax
    if (account) {
      fixswapContract = getContract(library, FixedSwapNFT.abi, getFixswapAddress(chainId));
      fixswapFee = await fixswapContract.methods.fee().call()
      fixswapFeeMax = await fixswapContract.methods.feeMax().call()
    } else {
      fixswapContract = window.tronWeb.contract(FixedSwapNFT.abi, getFixswapAddress(tronChainId))
      fixswapFee = await fixswapContract.fee().call()
      fixswapFee = Number(fixswapFee._hex)
      fixswapFeeMax = await fixswapContract.feeMax().call()
      fixswapFeeMax = Number(fixswapFeeMax._hex)
    }
    fixswapFeeMax = fixswapFeeMax <= 0 ? 10000 : fixswapFeeMax

    setFeeObj(old => ({
      ...old,
      swap: fixswapFee / fixswapFeeMax * 100
    }))
  }

  useEffect(() => {
    if (account || tronAccount) {
      queryAuctionFees()
      queryFixswapFees()
    }
  }, [account, tronAccount])

  useEffect(() => {
    setCurrency(symbol)
  }, [symbol])

  return (
    <Modal
      title={'List item for sale'}
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Row gutter={16}>
        <Col xs={0} sm={12}>
          <img className={styles.modal_img} src={detailInfo.preview} alt="" />
        </Col>
        <Col xs={24} sm={12}>
          <Form
            className={styles.dfa_sell_form}
            name="sell-nft"
            onFinish={onFinishSell}
            requiredMark={false}
            form={form}
            {...layout}
          >
            <Form.Item
              label={t('nft_name')}
            >
              <div className="ell fwb">{detailInfo.name}</div>
            </Form.Item>
            <Form.Item
              label={t('quantity_to_sell')}
              name="quantity"
              initialValue='1'
              rules={[
                { required: true, message: t('please_enter_number_greater_than_0') },
                { pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/, message: t('please_enter_number_greater_than_0') },
                {
                  validator: (_, value) => {
                    if (dataType) {
                      return value <= detailInfo.ownedCountByCurrentUser ? Promise.resolve() : Promise.reject(new Error('Out of maximum'));
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              ]}
            >
              <Input addonAfter={dataType ? `/${detailInfo.ownedCountByCurrentUser}` : `/1`} disabled={!dataType} />
            </Form.Item>

            <Form.Item
              hidden={dataType}
              label={t('Sell method')}
              name="type"
              initialValue={type}
              rules={[{ required: true, message: t('please_enter_number_greater_than_0') }]}
            >
              <Radio.Group className={styles.radio_black} buttonStyle="solid" onChange={handleTypeChange}>
                <Radio.Button value="swap">{t('fixswap')}</Radio.Button>
                <Radio.Button value="bid">{t('auction')}</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {type === 'swap' &&
              <>
                <Form.Item label={dataType ? t('unit_price') : t('price')}>
                  <Form.Item
                    noStyle
                    name="price"
                    rules={[
                      { required: true, message: t('please_enter_number_greater_than_0') },
                      { pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/, message: t('please_enter_number_greater_than_0') }
                    ]}>
                    <Input style={{ width: '60%' }} />
                  </Form.Item>
                  <Select className={styles.unit} value={currency} onChange={setCurrency}>
                    {account && erc20TokenList.map(item => (
                      item.chainIds.includes(chainId) && <Option key={item.name} value={item.name}>{item.name}</Option>
                    ))}
                    {tronAccount && trc20TokenList.map(item => (
                      item.chainIds.includes(tronChainId) && <Option key={item.name} value={item.name}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('start_time')}
                  name="fixswapStartTime"
                  rules={[{ required: true, message: 'Start Time is required' }]}
                >
                  <DatePicker
                    className={styles.dfa_data_input}
                    allowClear={false}
                    format={'YYYY-MM-DD HH:mm:ss'}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    showTime={{
                      format: 'HH:mm:ss',
                      defaultValue: moment('00', 'ss'),
                      hideDisabledOptions: true
                    }}
                    showNow={true}
                    inputReadOnly
                  />
                </Form.Item>
              </>
            }

            {type === 'bid' &&
              <>
                <Form.Item label={t('starting_price')}>
                  <Form.Item
                    noStyle
                    name="price"
                    rules={[{ required: true, message: t('please_enter_number_greater_than_0') },
                    { pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/, message: t('please_enter_number_greater_than_0') }]}
                  >
                    <Input style={{ width: '60%' }} />
                  </Form.Item>
                  <Select className={styles.unit} value={currency} onChange={setCurrency}>
                    {account && erc20TokenList.map(item => (
                      item.chainIds.includes(chainId) && <Option key={item.name} value={item.name}>{item.name}</Option>
                    ))}
                    {tronAccount && trc20TokenList.map(item => (
                      item.chainIds.includes(tronChainId) && <Option key={item.name} value={item.name}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t('Min bid')}>
                  <Form.Item
                    noStyle
                    name="incr"
                    rules={[{ required: true, message: t('please_enter_number_greater_than_0') },
                    { pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/, message: t('please_enter_number_greater_than_0') }
                    ]}
                  >
                    <Input style={{ width: '60%' }} />
                  </Form.Item>
                  <Select className={styles.unit} value={currency} onChange={setCurrency}>
                    {account && erc20TokenList.map(item => (
                      item.chainIds.includes(chainId) && <Option value={item.name}>{item.name}</Option>
                    ))}
                    {tronAccount && trc20TokenList.map(item => (
                      item.chainIds.includes(tronChainId) && <Option value={item.name}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('start_time')}
                  name="auctionStartTime"
                  rules={[{ required: true, message: 'Start Time is required' }]}
                >
                  <DatePicker
                    className={styles.dfa_data_input}
                    allowClear={false}
                    format={'YYYY-MM-DD HH:mm:ss'}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    showTime={{
                      format: 'HH:mm:ss',
                      defaultValue: moment('00', 'ss'),
                      hideDisabledOptions: true
                    }}
                    showNow={true}
                    inputReadOnly
                  />
                </Form.Item>
                <Form.Item
                  label={t('expiration_date')}
                  name="auctionEndTime"
                  rules={[
                    { required: true, message: 'Expiration Time is required' },
                    {
                      validator: (_, value) => {
                        if (value < auctionStartTime) {
                          return Promise.reject(new Error('Expiration Time should greater than Start Time'))
                        }
                        return Promise.resolve();
                      }
                    },
                  ]}
                >
                  <DatePicker
                    className={styles.dfa_data_input}
                    allowClear={false}
                    format={'YYYY-MM-DD HH:mm:ss'}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    showTime={{
                      format: 'HH:mm:ss',
                      defaultValue: moment('00', 'ss'),
                      hideDisabledOptions: true
                    }}
                    showNow={false}
                    inputReadOnly
                  />
                </Form.Item>
              </>
            }

            <Form.Item label={t('fee')}>
              <div>{feeObj[type]}%</div>
            </Form.Item>
            <p>{t('handling_charge_deduction')}</p>
            <div style={{ textAlign: 'right', marginTop: '30px' }}>
              <Button type="primary" htmlType="submit">{t('confirm_to_sell')}</Button>
            </div>
          </Form>

        </Col>
      </Row>
    </Modal>
  );
};
