import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Select } from "antd";
import BigNumber from 'bignumber.js';
import { useTranslation } from "react-i18next";
import Web3 from "web3";

import { queryDfaPrice } from "../../services/vote"
import { abbrTxHash } from "../../utils/format";
import { getERC20Contract } from '../../utils/handleContract';
import { formatAmountWithDecimal } from "../../utils/format";
import styles from './styles.module.scss'
import treasuryBG from 'assets/img/Treasury/treasury_bg.png'
import dfa from 'assets/img/Treasury/dfa.png'
import comingsoon from 'assets/img/Treasury/comingsoon.jpg'


const { Option } = Select;

export default function Treasury() {
    const { t, i18n } = useTranslation();
    const [totalPrice, setTotalPrice ] = useState()
    const [list,setList] = useState([
        { name: 'DFA', src: dfa, count: 0, price: 0 },
        // { name: 'ETH', src: dfa, count: 0, price: 0 },
    ])
    const list2 = [
        { name: 'DFA', src: dfa, address: '0x047835a98Acf0F6FA018Cc0d2eb01c418C8dc8dD' },
        { name: 'DFA', src: dfa, address: '0x047835a98Acf0F6FA018Cc0d2eb01c418C8dc8dD' },
        { name: 'DFA', src: dfa, address: '0x047835a98Acf0F6FA018Cc0d2eb01c418C8dc8dD' },
    ]
    const queryDFA = async () => {
        let res = await queryDfaPrice('dfa')
        let price = res.data.DFA.quote.USD.price
        let count = await getERC20Contract('', '0x62959c699a52ec647622c91e79ce73344e4099f5','mainnet').methods.balanceOf('0x047835a98Acf0F6FA018Cc0d2eb01c418C8dc8dD').call()
        count = new BigNumber(count).div(new BigNumber(10).pow(18))
        let totalPrice = 0
        list.map((item,index) => {
            if(index === 0 ) {
                item.count = count.toString()
                item.price = price
                setList(prevState => {
                    const arr = [...prevState];
                    arr[0] = item
                    return arr
                });
            }
            totalPrice = new BigNumber(totalPrice).plus(new BigNumber(item.count * item.price)) 
        })
        setTotalPrice(totalPrice.toString())
    }
    const queryETH = async () => {
        let res = await queryDfaPrice('eth')
        let price = res.data.ETH.quote.USD.price
        const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/efcd618d59564cf4a3d52938d7987ec9"))
        web3.eth.getBalance("0x047835a98Acf0F6FA018Cc0d2eb01c418C8dc8dD", function(err, result) {
            if (err) {
                console.log(err)
            } else {
                console.log(web3.utils.fromWei(result, "ether") + " ETH")
                let count = web3.utils.fromWei(result, "ether")
                let totalPrice = 0
                list.map((item,index) => {
                    if(index === 1 ) {
                        item.count = count
                        item.price = price
                        setList(prevState => {
                            const arr = [...prevState];
                            arr[1] = item
                            return arr
                        });
                    }
                    totalPrice = new BigNumber(totalPrice).plus(new BigNumber(item.count * item.price)) 
                })
                setTotalPrice(totalPrice.toString())
            }
        })
    }
    const goToBlockchian = (address) => {
        window.open(`https://etherscan.io/address/${address}`)
    }

    useEffect(() => {
        queryDFA()
        // queryETH()
    },[])
    return (
        <div style={{backgroundImage:`url(${treasuryBG})`}} className={styles.treasury}>
            <div className='page_content_h5' style={{background: 'transparent', boxShadow: 'none'}}>
                <div className='df_center_h5 mt10'>
                    <div className={`${styles.treasury_title}`}>
                        <div>The world's leading </div>
                        <div>art encryption community.</div>
                    </div>
                    <div className={`${styles.nft_show_list}`}>
                        <div className={`${styles.nftBox_no1}`}>
                            <div style={{backgroundImage: `url('https://define-art-static-prod.s3-ap-northeast-1.amazonaws.com/token/image/mainnet/0x70a76282752b5d2f09f81fe86d49d80ed8b53dc7/154.jpeg')`}} className={styles.nft_img} >
                            </div>
                            <div className={styles.nft_info}>
                                <div className={`fs12 lh16 ${styles.nft_name}`}>Sealed Smile 3</div>
                                <div className={`fw600 fs18 ${styles.nft_price}`}>21.257 ETH</div>
                            </div>
                        </div>
                        <div className={`df`}>
                            <div className={`${styles.nftBox}`}>
                                <div className={styles.nft_show}>
                                    <video muted autoPlay loop src='https://define-art-static-prod.s3-ap-northeast-1.amazonaws.com/token/video/mainnet/ERC721/129.mp4'></video>
                                </div>
                                <div className={styles.nft_info}>
                                    <div className={`fs12 lh16 ${styles.nft_name}`}>SBXG PARK IS Legendary Token 03</div>
                                    <div className={`fw600 fs18 ${styles.nft_price}`}>18.000 ETH</div>
                                </div>
                            </div>
                            <div className={styles.nftBox}>
                                <div className={styles.nft_show}>
                                    <img src='https://define-art-static-prod.s3-ap-northeast-1.amazonaws.com/token/image/mainnet/ERC721/127.gif' />
                                </div>
                                <div className={styles.nft_info}>
                                    <div className={`fs12 lh16 ${styles.nft_name}`}>SBXG PARK IS Legendary Token 01</div>
                                    <div className={`fw600 fs18 ${styles.nft_price}`}>11.000 ETH</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.treasury_admin}>
                    <div className={styles.treasury_total}>
                        <div className={`fs24 fw600`}>Build a robust ecosystem for DeFine</div>
                        <div className={`fs14`}>DAO Treasury balance</div>
                        <div className={styles.total}>${totalPrice?formatAmountWithDecimal(totalPrice,0,2):'--'}</div>
                    </div>
                    <div className={`${styles.treasury_list} space-between-h5`}>
                        <div className={styles.li}>
                            <div className={`c_9a pb16 tac`}>Treasury Assets</div>
                            <div className='f1'>
                                {
                                    list.map(item => (
                                        <div className={`space-between mb10`}>
                                            <div className={`df_center `}>
                                                <img width={28} src={item.src}></img>
                                                <div className={`pl10`}>{item.name}</div>
                                            </div>
                                            <div>{formatAmountWithDecimal(item.count,0,0)}</div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div onClick={()=>{goToBlockchian('0x047835a98Acf0F6FA018Cc0d2eb01c418C8dc8dD')}} className={`c_blue tac cp`}>View on blockchain &gt;</div>
                        </div>
                        {/* <div className={styles.li}>
                            <div className={`c_9a pb16`}>Multi-Sign Addresses</div>
                            <div>
                                {
                                    list2.map(item => (
                                        <div className={`space-between mb10`}>
                                            <div className={`df_center `}>
                                                <img width={28} src={item.src}></img>
                                                <div className={`pl10`}>{item.name}</div>
                                            </div>
                                            <div onClick={()=>{goToBlockchian(item.address)}} className={`c_blue cp`}>{abbrTxHash(item.address)}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={`mt40 ${styles.usage}`}>
                    <div className={`fs24 fw600 tac mb10`}>Record of fund usage</div>
                    <img src={comingsoon}></img>
                </div>
            </div>
        </div>
    )
}
