import React, { useEffect, useState, useContext } from 'react';
import { Button, message, Modal, Table } from 'antd';
import { Progress } from 'antd';
import { Link } from "react-router-dom";

import { useActiveWeb3React } from "../../web3";
import { getClaimContract } from '../../utils/handleContract';
import { getContractClaimDfaAddress, getChainType } from '../../web3/address';
import { formatAmountWithDecimal } from "../../utils/format";
import { getClaimDFAInfo } from "../../services/badge";
import BigNumber from 'bignumber.js';
import styles from "./styles.module.scss";
import dfa from "../../assets/img/dfa.png";
import ball1 from "../../assets/img/halloween/pump1.png";
import ball2 from "../../assets/img/halloween/pump2.png";
import env from '../../env';
import moment from 'moment';
import {
    HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
} from "../../const";
import { setupEthMainnet } from 'utils/networkConnect';
import { mainContext } from 'reducer';
import { useHistory } from 'react-router-dom';

export default function ClaimDFA() {

    const { library, account, chainId } = useActiveWeb3React()
    const { dispatch } = useContext(mainContext);
    const history = useHistory();
    const [ claimInfo, setClaimInfo ] = useState({})
    const [ visibleDetail, setVisibleDetail] = useState(false)
    const [ recordInfo, setRecordInfo] = useState({})
    const [ activityName, setActivityName] = useState('')
    const [ activityLink, setActivityLink] = useState('')
    const [ activityBg, setActivityBg] = useState('')
    const contractChainIdOnEth = {
        production: 1,
        development: 4,
    }
    
    const columns = [
        {
            align: "left",
            title: "Event",
            dataIndex: "name",
            key: 'name',
            width: 170,
            render: (name,record) => {
                return <span className='c_blue cp text_hidden_1' onClick={()=> {openModal(record)}}>{name}</span>
            }
        },
        {
            align: "center",
            title: "Allocated",
            dataIndex: "rewardAmount",
            key: 'rewardAmount',
            render: rewardAmount => {
                return formatAmountWithDecimal(rewardAmount,18,2)
            }
        },
        {
            align: "right",
            title: "Released",
            dataIndex: "released",
            key: 'released',
            render: released => {
                return formatAmountWithDecimal(released,18,2)
            }
        },
    ]
    const openModal = (record) => {
        setRecordInfo(record)
        setVisibleDetail(true)
    }

    const gotokbw = () => {
        window.open(activityLink);
    }
    const handelRowClassName = (record, index) => {
        if (index % 2 > 0) {
            return 'rowBgGray'
        } else {
            return 'rowBgWhite'
        }
    }

    const queryClaminInfo = async () => {
        let info = await getClaimContract(library,getContractClaimDfaAddress(contractChainIdOnEth[env.ENV])).methods.getRewardHistory( account ).call();
        info.tobeReleased = new BigNumber(info.totalReward).minus(new BigNumber(info.totalClaimed)).minus(new BigNumber(info.totalCanClaim)).toString()
        info.unlocked = new BigNumber(info.totalCanClaim).plus(new BigNumber(info.totalClaimed)).toString()
        info.progressPercent = (new BigNumber(info.unlocked).div(new BigNumber(info.totalReward))*100).toFixed(1)
        info.arr = []
        info.userRewardHistorys.length>0 && info.userRewardHistorys.forEach(item => {
            if (item.name) {
                let duration = 'once'
                let endTime = ''
                if(Number(item.unlockSteps)>0){
                    if(moment.duration(item.unlockPeriod*1000).days()>0){
                     duration = moment.duration(item.unlockPeriod*1000).days() + ' days'
                    }else if (moment.duration(item.unlockPeriod*1000).hours()>0) {
                        duration = moment.duration(item.unlockPeriod*1000).hours() + ' hours'
                    }else{
                        duration = moment.duration(item.unlockPeriod*1000).minutes() + ' minutes'
                    }
                    endTime = moment(item.paymentDate*1000).add(Number(item.unlockPeriod)*Number(item.unlockSteps),'s').valueOf()
                }
                let obj = {
                    name:item.name,
                    paymentDate:item.paymentDate,
                    endTime:endTime,
                    duration:duration,
                    rewardAmount:item.rewardAmount,
                    perReleased: new BigNumber(item.rewardAmount).div(new BigNumber(item.unlockSteps)),
                    released: new BigNumber(item.canClaimedAmount).plus(new BigNumber(item.claimedAmount)).toString(),
                }
                info.arr.push(obj)
            }
        })
        setClaimInfo(info)
    }
    const claimDfa = async () => {
        let chainType = getChainType(chainId)
        if (chainType != 'mainnet' && chainType != 'rinkeby' && chainType != 'goerli') {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        } 
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        await getClaimContract(library,getContractClaimDfaAddress(contractChainIdOnEth[env.ENV])).methods.claim()
        .send({ from: account })
        .on('transcationHash', () => {})
        .on('receipt', (receipt) => {
            setTimeout(()=> {
                dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: { show: false }
                });
                message.success("success!")
                queryClaminInfo()
            },3000)
            
        })
        .on('error', (error) => {
            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { show: false }
            });
            dispatch({
                type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                showFailedTransactionModal: true
            });
        })
    }
    const handleCancel = () => {
        setVisibleDetail(false)
    }

    useEffect(() => {
        if(chainId) {
            let chainType = getChainType(chainId)
            if (chainType != 'mainnet' && chainType != 'rinkeby' && chainType != 'goerli') {
                message.error("please switch to Ether")
                setupEthMainnet()
                return;
            } 
        }
        account && queryClaminInfo()
    },[library,account,chainId])

    useEffect(() => {
      getClaimDFAInfo().then(res => {
        setActivityName(res.claim_dfa_activity_name);
        setActivityLink(res.claim_dfa_activity_link);
        setActivityBg(res.claim_dfa_activity_bg);
      });
    },[])

  return (
    <>
    <Modal 
        title={'Event info'}
        visible={visibleDetail}
        footer={null}
        onCancel={handleCancel}>
            <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Event name</span><span className='f1 fs14 fw700 lh18 pb10'>{recordInfo.name}</span></div>
            <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Start time</span><span className='f1 fs14 pb10'>{moment(recordInfo.paymentDate*1000).format("YYYY-MM-DD HH:mm:ss")}</span></div>
           {
               recordInfo.duration != 'once' &&
                <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>End time</span><span className='f1 fs14 pb10'>{moment(recordInfo.endTime).format("YYYY-MM-DD HH:mm:ss")}</span></div>
           }
            <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Duration</span><span className='f1 fs14 pb10'>{recordInfo.duration}</span></div>
            <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Total allocated</span><span className='f1 fs14 pb10'>{formatAmountWithDecimal(recordInfo.rewardAmount,18,0)}</span></div>
            {
               recordInfo.duration != 'once' &&
                <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Amount per released</span><span className='f1 fs14 pb10'>{formatAmountWithDecimal(recordInfo.perReleased,18,0)}</span></div>
            }
            <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Released</span><span className='f1 fs14 pb10'>{formatAmountWithDecimal(recordInfo.released,18,0)}</span></div>

    </Modal>
    <div className={styles.claimDfa_module} style={{backgroundImage: activityBg && `url(${activityBg})`}}>
        <div className={styles.claimDfa_main}>
            <div className={`${styles.title} fw700`}>Claim DFA</div>
            <div className={styles.claimDfa_box}>
                <div className={styles.box_left}>
                    <div className={`fs14 mb10`}>Available for claim</div>
                    <div className={styles.balance}>
                        <img src={dfa} width={28} height={28}></img>
                        <span className={`${styles.avaibleTxt} fw700 pl4`}>{claimInfo.totalCanClaim?formatAmountWithDecimal(claimInfo.totalCanClaim,18,2):"--" } DFA</span>
                    </div>
                    <div className='mt10'>
                        <Button type='primary' disabled={claimInfo.totalCanClaim==='0'} className={`w200 h50 fs16 ${styles.claim_btn}`} onClick={claimDfa}>Claim</Button>
                    </div>
                    <div className={`wp100 ${styles.box_mt}`}>
                        <div className='fs14'>Claimed</div>
                        <div className={styles.gary_box}>{ claimInfo.totalClaimed?formatAmountWithDecimal(claimInfo.totalClaimed,18,2):"--" } DFA</div>
                    </div>
                    <div className='mt10 wp100'>
                        <div className='fs14'>To Be Released</div>
                        <div className={styles.gary_box}>{ claimInfo.tobeReleased?formatAmountWithDecimal(claimInfo.tobeReleased,18,2):"--" }  DFA</div>
                    </div>
                    <div className='mt10 wp100'>
                        <div className='fs14'>Progress</div>
                        <div>
                            <Progress strokeWidth={14} percent={claimInfo.progressPercent} showInfo={false} />
                            <div className='fs12 c_9a pr lh12'>
                                <span style={{left:claimInfo.progressPercent>90?`calc(${claimInfo.progressPercent}% - 26px)`:claimInfo.progressPercent<10?`calc(${claimInfo.progressPercent}% - 0px)`:`calc(${claimInfo.progressPercent}% - 19px)`}} className={`${styles.progress_percent}`}>{claimInfo.progressPercent>0?claimInfo.progressPercent:'--'}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.box_right}>
                    <div className={`space-between ${styles.tab_top}`}>
                        <span className={`fs14 fw700 lh16`}>Airdrop activity</span>
                        <Link className={`${styles.new_v}`} to="/claimDfa">New version &gt;</Link>
                    </div>
                    <Table pagination={false} bordered={false} scroll={{y:400}}
                    columns={columns} dataSource={claimInfo.arr} rowClassName={handelRowClassName}></Table>
                </div>
            </div>
            {/* <div className='pr'> */}
                {/* <img onClick={gotokbw} width={200} className={`${styles.ball1} cp`} src={ball1}></img>
                <img onClick={gotokbw} width={200} className={`${styles.ball2} cp`} src={ball2}></img> */}
            {/* </div> */}
        </div>
    </div>
    </>
  )
}
