import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { Select, Spin, Input } from "antd";

import { getContract, useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { useComments } from "services/home"
import CommentCard from "components/CommentCard";
import styles from "./styles.module.scss";

const CommentList = ({ id, parentCommentId, refresh }) => {
  const [pageNo, setPageNo] = useState(1)
  const [force, setForce] = useState(0)
  const [myFollow, setMyFollow] = useState(false)
  const { t } = useTranslation();
  const history = useHistory();
  const { library, account, chainId, active } = useActiveWeb3React()
  const { tronAccount } = useActiveTronWeb()
  const { list, total } = useComments(id, pageNo, 10, parentCommentId, force)

  const getList = () => {
    setPageNo(pageNo + 1)
  }

  useEffect(() => {
    setPageNo(1)
    setForce(refresh)
  }, [id, parentCommentId, refresh])

  return (
    <InfiniteScroll
      dataLength={list.length}
      next={getList}
      hasMore={list.length < total}
      loader={
        <div style={{ width: '100%', padding: 20, textAlign: 'center' }}>
          <Spin size={`large`} />
        </div>
      }
    >
      {
        list.map(item => (
          <CommentCard cardType={parentCommentId ? 'second' : ''} key={item.id} data={item} />
        ))
      }
      {
        !!parentCommentId && list.length < total &&
        <div className={styles.load_more}>
          <span onClick={getList} className="cp">{t('load_more')}</span>
        </div>
      }
    </InfiniteScroll>
  )
}

export default CommentList;