import React, { useEffect, useState, useContext } from 'react';
import { Button, message, Modal, Table } from 'antd';
import { Progress } from 'antd';
import { Link } from "react-router-dom";

import { useActiveWeb3React } from "../../web3";
import { getVaultRewardContract } from '../../utils/handleContract';
import { getChainType } from '../../web3/address';
import { formatAmountWithDecimal } from "../../utils/format";
import { getClaimDFAInfo, getUserRewardsTotal, getUserRewardsRecords, getUserRewardsClaimInfo } from "../../services/badge";
import BigNumber from 'bignumber.js';
import styles from "./styles.module.scss";
import dfa from "../../assets/img/dfa_claim.png";
import usdt from "../../assets/img/usdt.svg";
import usdc from "../../assets/img/usdc.svg";
import env from '../../env';
import moment from 'moment';
import {
  HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  waitingForConfirm,
} from "../../const";
import { setupEthMainnet, setupMumbai } from 'utils/networkConnect';
import { mainContext } from 'reducer';
import { useHistory } from 'react-router-dom';
import NoData from 'components/NoData';

export default function ClaimDFA() {

  const { library, account, chainId } = useActiveWeb3React()
  const { dispatch } = useContext(mainContext);
  const history = useHistory();
  const [claimInfo, setClaimInfo] = useState({})
  const [visibleDetail, setVisibleDetail] = useState(false)
  const [recordInfo, setRecordInfo] = useState({})
  const [activityName, setActivityName] = useState('')
  const [activityLink, setActivityLink] = useState('')
  const [activityBg, setActivityBg] = useState('')
  const [claimInfos, setClaimInfos] = useState([])
  const [claimDisable, setClaimDisable] = useState(true)
  const contractChainIdOnEth = {
    production: 1,
    development: 4,
  }
  const symbolObj = {
    "DFA": dfa,
    "USDT": usdt,
    "USDC": usdc,
  }

  const columns = [
    {
      align: "left",
      title: "Event",
      dataIndex: "name",
      key: 'name',
      width: 170,
      render: (name, record) => {
        return <span className='c_blue cp text_hidden_1' onClick={() => { openModal(record) }}>{name}</span>
      }
    },
    {
      align: "center",
      title: "Allocated",
      dataIndex: "rewardAmount",
      key: 'rewardAmount',
      render: (rewardAmount, record) => {
        return <span>{formatAmountWithDecimal(rewardAmount, record.token.decimals, 2)} {record.token.symbol}</span>
      }
    },
    {
      align: "right",
      title: "Released",
      dataIndex: "released",
      key: 'released',
      render: (released, record) => {
        return <span>{formatAmountWithDecimal(released, record.token.decimals, 2)} {record.token.symbol}</span>
      }
    },
  ]
  const openModal = (record) => {
    setRecordInfo(record)
    setVisibleDetail(true)
  }

  const gotokbw = () => {
    window.open(activityLink);
  }
  const handelRowClassName = (record, index) => {
    if (index % 2 > 0) {
      return 'rowBgGray'
    } else {
      return 'rowBgWhite'
    }
  }

  const claimDfa = async () => {
    let chainType = getChainType(chainId)

    if (env.ENV === 'production' && chainType != 'mainnet') {
      message.error("please switch to Ether")
      setupEthMainnet()
      return;
    }
    if (env.ENV !== 'production' && chainType != 'mumbai') {
      message.error("please switch to mumbai")
      setupMumbai()
      return;
    }
   
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });

    const info = await getUserRewardsClaimInfo(claimInfo.chainType, claimInfo.contract);
    await getVaultRewardContract(library, claimInfo.contract).methods.claim(
      info.nonce,
      info.signature,
      info.tokens,
      info.totalRewards
    ).send({ from: account })
      .on('transcationHash', () => { })
      .on('receipt', (receipt) => {
        setTimeout(() => {
          dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
          });
          message.success("success!")
          initFun()
        }, 3000)
      })
      .on('error', (error) => {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        dispatch({
          type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
          showFailedTransactionModal: true
        });
      })
  }
  const handleCancel = () => {
    setVisibleDetail(false)
  }

  const initFun = () => {
    getUserRewardsTotal().then(res => {
      if (res && res.length > 0) {
        setClaimInfo(info => ({
          ...info,
          chainType: res[0].chainType,
          contract: res[0].contract,
          totalReward: res[0].amount,
          tobeReleased: '0',
          unlocked: res[0].amount,
          progressPercent: '100'
        }));
        setClaimDisable(true)
        res.forEach(item => {
          getVaultRewardContract(library, item.contract).methods.getUserClaimed(account, item.token.address).call().then(res => {
            item.totalClaimed = res
            item.totalCanClaim = new BigNumber(item.amount).minus(new BigNumber(res)).toString()
            if(item.totalCanClaim != '0'){
              setClaimDisable(false)
            }
            setClaimInfo(info => ({
              ...info,
              totalClaimed: res,
              totalCanClaim: new BigNumber(info.totalReward).minus(new BigNumber(res)).toString()
            }));
          });
        })
        setClaimInfos(res)
      }
    });
    getUserRewardsRecords().then(res => {
      if (res && res.list && res.list.length > 0) {
        const arr = [];
        res.list.forEach(item => {
          let obj = {
            name: item.eventTitle,
            paymentDate: item.time / 1000,
            duration: 'once',
            rewardAmount: item.amount,
            released: item.amount,
            token: item.token
          }
          arr.push(obj)
        })

        setClaimInfo(info => ({
          ...info,
          arr
        }));
      }
    });
  }

  useEffect(() => {
    if (chainId) {
      let chainType = getChainType(chainId)
      if (env.ENV === 'production' && chainType != 'mainnet') {
        message.error("please switch to Ether")
        setupEthMainnet()
        return;
      }
      if (env.ENV !== 'production' && chainType != 'mumbai') {
        message.error("please switch to mumbai")
        setupMumbai()
        return;
      }
    }
    account && initFun()
  }, [library, account, chainId])

  useEffect(() => {
    getClaimDFAInfo().then(res => {
      setActivityName(res.claim_dfa_activity_name);
      setActivityLink(res.claim_dfa_activity_link);
      setActivityBg(res.claim_dfa_activity_bg);
    });
  }, [])

  return (
    <>
      <Modal
        title={'Event info'}
        visible={visibleDetail}
        footer={null}
        onCancel={handleCancel}>
        <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Event name</span><span className='f1 fs14 fw700 lh18 pb10'>{recordInfo.name}</span></div>
        <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Start time</span><span className='f1 fs14 pb10'>{moment(recordInfo.paymentDate * 1000).format("YYYY-MM-DD HH:mm:ss")}</span></div>
        {
          recordInfo.duration != 'once' &&
          <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>End time</span><span className='f1 fs14 pb10'>{moment(recordInfo.endTime).format("YYYY-MM-DD HH:mm:ss")}</span></div>
        }
        <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Duration</span><span className='f1 fs14 pb10'>{recordInfo.duration}</span></div>
        <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Total allocated</span><span className='f1 fs14 pb10'>{formatAmountWithDecimal(recordInfo.rewardAmount, 18, 0)}</span></div>
        {
          recordInfo.duration != 'once' &&
          <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Amount per released</span><span className='f1 fs14 pb10'>{formatAmountWithDecimal(recordInfo.perReleased, 18, 0)}</span></div>
        }
        <div className='space-between'><span className='f1 fs14 c_9a lh18 pb10'>Released</span><span className='f1 fs14 pb10'>{formatAmountWithDecimal(recordInfo.released, 18, 0)}</span></div>

      </Modal>
      <div className={styles.claimDfa_module} style={{ backgroundImage: activityBg && `url(${activityBg})` }}>
        <div className={styles.claimDfa_main}>
          <div className={`${styles.title} fw700`}>Badge Rewards</div>
          <div className={styles.claimDfa_box}>
            <div className={`${styles.box_left} ${claimInfos.length>0?styles.pb_h5:''}`}>
              <div className={styles.coin_header}>
                <div className='f1'>Token</div>
                <div className='f1 tac'>Claimed</div>
                <div className='f1 tac'>Availble for Claim</div>
              </div>
              {
                claimInfos.length === 0 &&
                <div className={styles.coin_nodata}>
                  <NoData className='mt100' text={' '}></NoData>
                </div>
              }
              {
                claimInfos.length > 0 && claimInfos.map(item => (
                  <div className={styles.coin_item}>
                    <div className='f1 fs16 fw600'>
                      <img className={styles.coin_symbol} width={40} src={symbolObj[item.token.symbol]}></img>
                      {item.token.symbol}
                    </div>
                    <div className='f1 tac fs18'>{item.totalClaimed ? formatAmountWithDecimal(item.totalClaimed, item.token.decimals, 2) : "--"}</div>
                    <div className='f1 tac c_purple fs18'>{item.totalCanClaim ? formatAmountWithDecimal(item.totalCanClaim, item.token.decimals, 2) : "--"}</div>
                  </div>
                ))
              }
              <div className={styles.claim_all_btn}>
                {
                  claimInfos.length > 0 &&
                  <div className='f1'><Button disabled={claimDisable} type='primary' className='w200 h50 btn_multicolour_dark' onClick={claimDfa}>Claim</Button></div>
                }
              </div>
            </div>
            <div className={styles.box_right}>
              <div className={`space-between ${styles.tab_top}`}>
                <span className={`fs14 fw700 lh16`}>Airdrop activity</span>
                <Link className={`${styles.pre_v}`} to="/claimDfa-old">Previous version &gt;</Link>
              </div>
              <Table pagination={false} bordered={false} scroll={{ y: 400 }}
                columns={columns} dataSource={claimInfo.arr} rowClassName={handelRowClassName}></Table>
            </div>
          </div>
          {/* <div className='pr'> */}
          {/* <img onClick={gotokbw} width={200} className={`${styles.ball1} cp`} src={ball1}></img>
                <img onClick={gotokbw} width={200} className={`${styles.ball2} cp`} src={ball2}></img> */}
          {/* </div> */}
        </div>
      </div>
    </>
  )
}
