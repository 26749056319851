import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import { Select, Input, Button, Table, Popover, message } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { mainContext } from "../../reducer";
import { getRedeemList, getRedeemHistoryList } from "../../services/redeem";
import { useActiveWeb3React } from "../../web3";
import { formatAmountWithDecimal } from '../../utils/format';
import { setupBscMainnet, setupEthMainnet } from '../../utils/networkConnect';
import { getSypoolRedeemContract,getSeasonRedeemContract, getERC721Contract, getERC20Contract } from '../../utils/handleContract'
// import { getSypoolUsdtAddress, getSypoolRedeemAddress } from "../../web3/address";
import {
    HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
} from "../../const";
import styles from "./styles.module.scss";
import empty from '../../assets/img/empty.svg'

const { Option } = Select;
let btnFlag = ''
export default function Redeem() {
    const { dispatch } = useContext(mainContext);
    const [selectItem, setSelectItem] = useState({})
    const [pagination, setPagination] = useState({});
    const [userAddress, setUserAddress] = useState('');
    const [historyList, setHistoryList] = useState([]);
    const { library, account, chainId } = useActiveWeb3React()
    const { t } = useTranslation()
    const [list, setList] = useState([])
    const [itemValue, setItemValue] = useState()
    const [redeemAccount, setRedeemAccount] = useState()
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [project, setProject] = useState()
    //   const { list } = useRedeemList('sypool');

    const columns = [
        {
            align: "center",
            title: t('redeem_history'),
            key: 'redeem',
            render: item => (
                <div className={styles.history_redeem}>
                    <div
                        style={{ backgroundImage: `url(${item.image})` }}
                        className={styles.history_img}
                    ></div>
                    <div className={styles.history_info}>
                        <div className={styles.history_info_title}>{item.name}</div>
                        <div>#{item.id}</div>
                    </div>
                </div>
            ),
        },
        {
            align: "center",
            title: t('address'),
            key: 'address',
            dataIndex: "redeemAddress",
            render: val => (
                <Popover content={val}>
                    <span>{`${val.substr(0, 6)}...${val.substr(-6, 6)}`}</span>
                </Popover>
            ),
        },
        {
            align: "center",
            title: t('redeem_history_amount'),
            key: 'amount',
            // dataIndex: "count",
            render: item => <span>{formatAmountWithDecimal(parseFloat(item.count), item.warrantToken.decimals, 0)} {item.warrantToken.symbol}</span>,
        },
        {
            align: "center",
            title: t('paymount'),
            key: 'payment',
            dataIndex: "exerciseAmount",
            render: val => <span>{formatAmountWithDecimal(parseFloat(val), 6, 0)} USDT</span>,
        },
        {
            align: "center",
            title: t('date'),
            key: 'date',
            dataIndex: "redeemTime",
            render: val => <span>{moment(val * 1000).format('YYYY/MM/DD HH:mm:ss')}</span>,
        },
    ];

    const handleChangeProject = async (val) => {
        let res = await getRedeemList(val);
        setList(res.list)
        setProject(val)
        setSelectItem('')
        setItemValue('')
        if (val === 'define-seasons') {
            setRedeemAccount(account)
        } else {
            setRedeemAccount('')
        }
        handleTableChange({
            current: 1,
            pageSize: 10,
            project: val
        })

    }

    const handleChange = (val) => {
        let item = list.find(item => item.transactionHash === val)
        setSelectItem(item)
        setItemValue(val)
        if (item.exerciseTime < Date.now() && item.exerciseTimeEnd > Date.now()) {
            setBtnDisabled(false)
        } else {
            setBtnDisabled(true)
        }
    }

    const handleInput = (e) => {
        if (e) {
            setUserAddress(e.target.value)
            setRedeemAccount(e.target.value)
        }
    }

    const handleRedeem = async () => {
        if (chainId !== 56 && chainId !== 97 && project === 'sypool') {
            message.error("please switch to BSC")
            setupBscMainnet()

            return;
        }
        if (chainId !== 1 && chainId !== 4 && project === 'define-seasons') {
            message.error("please switch to ETH")
            setupEthMainnet()
            return;
        }
        if (!selectItem.contractAddress) {
            message.error(t('redeem_select_nft'))
            return;
        }
        if (!userAddress) {
            message.error(t('error_address_tip'))
            return;
        }
        if (userAddress.length != 44 && project === 'sypool') {
            message.error(t('error_address_tip'))
            return;
        }
        if (btnFlag) {
            return false
        } else {
            btnFlag = true
        }
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });

        try {
            const redeemAddress = selectItem.contractAddress;

            const erc20Contract = await getERC20Contract(library, selectItem.priceToken.address);
            let approved = await erc20Contract.methods.allowance(account, redeemAddress).call()
            if (!approved || approved <= 0) {
                await erc20Contract.methods.approve(redeemAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                    .send({ from: account })
            }

            const erc721Contract = await getERC721Contract(library, selectItem.contractAddress)
            const isApproved = await erc721Contract.methods.isApprovedForAll(account, redeemAddress).call()
            if (!isApproved) {
                await erc721Contract.methods.setApprovalForAll(redeemAddress, true).send({ from: account });
            }
            project==='sypool'&&sypoolRedeem()
            project==='define-seasons'&&seasonRedeem()

        } catch (err) {
            console.log(err)
        }
    }
    const sypoolRedeem = async () => {
        const sypoolContract = await getSypoolRedeemContract(library, selectItem.contractAddress);
        await sypoolContract.methods.redeem(selectItem.contractAddress, selectItem.id, userAddress)
            .send({ from: account })
            .on('receipt', (receipt) => {
                selectItem.hadRedeem = true
                dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: { show: false }
                });
                message.success(t("stake_success"))
                btnFlag = false
            })
            .on('error', (err) => {
                console.log(err)
                dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: { show: false }
                });
                dispatch({
                    type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                    showFailedTransactionModal: true
                });
                btnFlag = false
            })
    }
    const seasonRedeem = async () => {
        const seasonContract = await getSeasonRedeemContract(library, selectItem.contractAddress);
        await seasonContract.methods.redeembyERC20(selectItem.id, selectItem.priceToken.address)
            .send({ from: account })
            .on('receipt', (receipt) => {
                selectItem.hadRedeem = true
                dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: { show: false }
                });
                message.success(t("stake_success"))
                btnFlag = false
            })
            .on('error', (err) => {
                console.log(err)
                dispatch({
                    type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                    showWaitingWalletConfirmModal: { show: false }
                });
                dispatch({
                    type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                    showFailedTransactionModal: true
                });
                btnFlag = false
            })
    }

    const handleTableChange = (pagination) => {
        console.log(project);
        getRedeemHistoryList(pagination.current, pagination.pageSize, pagination.project).then(res => {
            setHistoryList(res.list)
            setPagination({
                hideOnSinglePage: true,
                current: res.pageNo,
                pageSize: res.pageSize,
                total: res.totalCount
            })
        })
    }

    return (
        <div className={`page_content_h5`}>
            <div className={`page_content_title mt10 mb30`}>{t('redeem')}</div>
            <div className={`space-between-center-h5`}>
                <Select size={"large"} className={styles.select_project}
                    placeholder={"Select Project"}
                    onChange={handleChangeProject}>
                    <Option key={`sypool`} value={`sypool`}><span>SYPool</span></Option>
                    <Option key={`define-seasons`} value={`define-seasons`}><span>Season</span></Option>
                </Select>
                <Select size={"large"} className={styles.select_item}
                    placeholder={t('redeem_select_nft')}
                    onChange={handleChange}
                    notFoundContent={t('no_nft_tip')}
                    value={itemValue}
                >
                    {
                        list.length > 0 && list.map(item => (
                            <Option disabled={item.hadRedeem} key={item.transactionHash} value={item.transactionHash}>
                                <img className={styles.option_img} src={item.image} alt={item.name} />
                                <span className={styles.option_name}>{item.name}</span>
                                <span className={styles.option_id}>#{item.id}</span>
                            </Option>
                        ))
                    }
                </Select>
            </div>
            <div className={styles.card}>
                {
                    selectItem.image
                        ? <div style={{ backgroundImage: `url(${selectItem.image})` }} className={styles.img_box}></div>
                        : <div className={styles.empty}><img src={empty} /></div>
                }
                <div className={styles.info_box}>
                    <div className={styles.fl}>
                        <div className={styles.fl_item}>
                            <div className={styles.fl_title}>{t('redeem_amount')}</div>
                            <div className={styles.fl_value}>{selectItem.count ? formatAmountWithDecimal(parseFloat(selectItem.count), selectItem.warrantToken.decimals, 0) : '--'} {selectItem.warrantToken ? selectItem.warrantToken.symbol : ''}</div>
                        </div>
                        <div className={styles.fl_item}>
                            <div className={styles.fl_title}>{t('redeem_price')}</div>
                            <div className={styles.fl_value}>{selectItem.exercisePrice ? formatAmountWithDecimal(selectItem.exercisePrice, 6, 0) : '--'} USDT</div>
                        </div>
                        <div className={styles.fl_item}>
                            <div className={styles.fl_title}>{t('redeem_paid')}</div>
                            <div className={styles.fl_value}>{selectItem.exerciseAmount ? formatAmountWithDecimal(parseFloat(selectItem.exerciseAmount), 6, 0) : '--'} USDT</div>
                        </div>
                    </div>
                    <div className={styles.fl_title}>{t('redeem_expiration_Date')}</div>
                    <div className={styles.fl_value}>Before {selectItem.exerciseTimeEnd ? moment(selectItem.exerciseTimeEnd * 1000).format('YYYY/MM/DD HH:mm:ss') : '--'}</div>
                    <div className={`${styles.fl_title} mb10`}>{t('redeem_recipient_address', { bits: selectItem.warrantToken ? selectItem.warrantToken.symbol : '--' })}</div>
                    <div>
                        <Input disabled={project === 'define-seasons'} placeholder="enter your address" value={redeemAccount} onChange={handleInput} />
                    </div>
                    {
                        project === 'define-seasons' ? <div className={`c_9a mt10`}>
                            (Current connected wallet address)
                        </div> :
                            <div className={styles.check}>
                                <div className={styles.fl_title}>{t('redeem_recipient_address_check')}</div>
                                <div className={styles.fl_address}>{userAddress}</div>
                            </div>
                    }
                    <div className={styles.btn_box}>
                        <Button className={`w200_h5`} disabled={btnDisabled} type="primary" onClick={handleRedeem}>{t('redeem')}</Button>
                    </div>
                </div>
            </div>
            <div className={styles.history_box}>
                {
                    project === 'sypool' && <>
                        <div className={styles.title}>{t('redeem_rule')}</div>
                        <div>{t('redeem_rule1')}</div>
                        <div className={styles.history_address}>FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ</div>
                        <div>{t('redeem_rule2')}</div>
                        <div>{t('redeem_rule3')}</div>
                        <div>{t('redeem_rule4')}</div>
                        <div>{t('redeem_rule5')} <Link to="/sypool">https://app.define.one/sypool</Link>.</div>
                    </>
                }

                {
                    project === 'define-seasons' && <>
                        <div className={styles.title}>{t('redeem_rule')}</div>
                        <div>1. DeFine (DFA) Contract Address: </div>
                        <div className={`${styles.history_address} ml20`}>0x62959c699a52ec647622c91e79ce73344e4099f5</div>
                        <div>2. The NFT will be permanently destroyed after successful redeem.</div>
                        <div>3. NFT redemption method of BSC:</div>
                        <div className={'pl10'}>a. For Seasons NFTs from Binance marketplace, please withdraw to your own wallet first. Then send the NFT to below address on BSC. The corresponding NFT on Ethereum will be delivered to your Ethereum wallet address in 24 hours.</div>
                        <div className={`pl20 ${styles.history_address}`}>0xC2FAd25DC8B8da696CAdfB30296fcc13C7f61Aae</div>
                        <div className={'pl10'}>b. Switch to the Ethereum network and continue to redeem on this page.</div>
                    </>
                }

                <div className={styles.table_box}>
                    <Table
                        scroll={{ x: '100%' }}
                        columns={columns}
                        dataSource={historyList}
                        pagination={pagination}
                        onChange={handleTableChange}
                        rowKey={record => record.transactionHash}
                    />
                </div>
            </div>
        </div>
    )
}
