import React, { useState, useEffect } from "react";
import {
  Tabs, Spin, Button, message,
  Modal, Row, Col, Input, Form
} from 'antd';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';

import {
  getTokenAddress, erc20TokenList as erc20TokenListOriginal,
  getDecimalByAddress, trc20TokenList
} from '../../utils/tokenList'
import { formatAmountWithDecimal } from 'utils/format';
import { handleIsSignExpired } from "utils/txSign";
import { useActiveWeb3React } from "../../web3";
import { useActiveTronWeb } from "hooks/activeTronWeb";
import styles from "./styles.module.scss";

const toDecimals = (value, decimal) => {
  let result, power
  power = new BigNumber(10).pow(decimal)
  result = new BigNumber(value).times(power)
  return result
}

export const NftBuyModal = ({
  visible, dataType, detailInfo, buyModalData, minBid,
  onCancel, onSwapFinish, onAuctionFinish
}) => {
  const { t } = useTranslation()
  const [num, setNum] = useState(1)
  const [price, setPrice] = useState(0)
  const { library, account, active, chainId } = useActiveWeb3React()
  const erc20TokenList = erc20TokenListOriginal.filter(item => item.chainIds.includes(chainId))


  const handleInput = (e) => {
    setNum(e.target.value)
  }

  const handlePriceInput = (e) => {
    setPrice(e.target.value)
  }

  const onFinish = () => {
    if (dataType !== 'erc1155') {
      if (Number(price) >= Number(minBid.replace(',', ''))) {
        onCancel()
        onAuctionFinish({ amount: toDecimals(price, getDecimalByAddress(buyModalData.token1?.address, erc20TokenList.concat(trc20TokenList))).toString() })
      } else {
        return message.error(`The minimum price is ${minBid} ${buyModalData.token1?.symbol}`)
      }
    } else {
      if (num <= 0) {
        return message.error(t('please_enter_number_greater_than_0'))
      } else if (num > buyModalData.token0Amount - buyModalData.swappedAmount0) {
        return message.error('Out of maximum')
      }
      onCancel()
      onSwapFinish(buyModalData, false, num)
    }
  }

  useEffect(() => {
    if (minBid) {
      setPrice(minBid.replace(',', ''))
    }
  }, [minBid])

  return (
    <Modal
      destroyOnClose
      title={dataType === 'erc1155' ? detailInfo.name : 'Place a bid'}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={500}
    >
      <Row gutter={16}>
        <Col xs={0} sm={9}>
          <img className={styles.modal_img} src={detailInfo.preview} alt="" />
        </Col>
        {
          dataType === 'erc1155' ? (
            <Col xs={24} sm={15}>
              <div className={styles.modal_row}>
                <div className={styles.modal_row_label}>{t('price')} :</div>
                <div className={styles.modal_row_value}>{`${formatAmountWithDecimal(BigNumber(buyModalData.amountTotal1).div(buyModalData.token0Amount), buyModalData.token1?.decimals)} ${buyModalData.token1?.symbol}`}</div>
              </div>
              <div className={styles.modal_row}>
                <div className={styles.modal_row_label}>{t('quantity')} :</div>
                <div className={styles.modal_row_value}><Input value={num} onChange={handleInput} addonAfter={`/ ${buyModalData.token0Amount - buyModalData.swappedAmount0}`} /></div>
              </div>
              <div className={styles.modal_row}>
                <div className={styles.modal_row_label}>Total :</div>
                <div className={styles.modal_row_value}>{`${formatAmountWithDecimal(BigNumber(buyModalData.amountTotal1).div(buyModalData.token0Amount).times(num || 0), buyModalData.token1?.decimals)} ${buyModalData.token1?.symbol}`}</div>
              </div>
            </Col>
          ) : (
            <Col xs={24} sm={15}>
              <div className="df_align_center mb30">
                <div style={{ width: '30%' }}>Name</div>
                <div style={{ width: '70%' }}><div className="ell fwb">{detailInfo.name}</div></div>
              </div>
              <div className="df_align_center">
                <div style={{ width: '30%' }}>{t('price')}</div>
                <div style={{ width: '70%' }} className="df_align_center"><Input onChange={handlePriceInput} className="mr10" value={price} />{buyModalData.token1?.symbol}</div>
              </div>
            </Col>
          )
        }
      </Row>
      <div style={{ textAlign: 'center', marginTop: '30px' }}>
        <Button className={styles.modal_btn} type="primary" onClick={onFinish}>{dataType === 'erc1155' ? 'Check out' : 'Place a bid'}</Button>
      </div>
    </Modal>
  );
};
