import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Popover, Button, Skeleton, message, Table, Modal, Pagination } from 'antd';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import Zmage from 'react-zmage';
import copy from 'copy-to-clipboard';

import { useActiveWeb3React } from "../../web3";
import { claimBadge, claimLoot } from '../../utils/handleContract';
import { mainContext } from "../../reducer";
import { chainFun, chainTypeImgObj, chainTxtObj, handleHistoryAddress } from '../../utils/networkConnect';
import { erc20TokenList, getDecimalByAddress } from "../../utils/tokenList";
import { getBadgeInfo, getClaimInfo, getOwnerList, queryMyBadges } from "../../services/badgeDetail";
import { getBadgeProjectInfo } from "../../services/badge"
import { getArtistInfo } from "../../services/profile"
import { taskVerify, codeGetTwitterInfo } from "../../services/badgeManage";
import { getChainType } from "../../web3/address";
import CountDown from 'components/CountDown';
import BackButton from "components/BackButton";
import ConnectWallet from "components/ConnectWallet";
import { useNeedSign, useAccountIsSign } from "hooks/account"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import styles from "./styles.module.scss";
import { abbrTxHash, formatAmount } from "../../utils/format";
import Avatar from "components/Avatar";
import copyIcon from "assets/img/header/copy.svg";
import SvgTg from "assets/img/sidebar/telegram.svg"
import SvgTw from "assets/img/sidebar/twitter.svg"
import SvgDs from "assets/img/sidebar/discord.svg"
import SvgWallet from "assets/img/sidebar/wallet.svg"
import SvgWWW from "assets/img/sidebar/www.svg"
import SvgRichTxt from "assets/img/sidebar/richText.svg"
import iconOk from "assets/img/badge/ok.svg"
import iconDownArrow from "assets/img/badge/downArrow.svg"
import tipIcon from "assets/img/badge/tip_icon.svg"
import tip from 'assets/img/dao/tip.png'
import { twitterUtil } from "../../utils/index"
import {
  HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  HANDLE_SHOW_CONNECT_MODAL,
  waitingForConfirm,
} from "../../const";
import BigNumber from "bignumber.js";

const CreatorItem = ({ creator }) => {
  return (
    <Link className={`df_align_center ${styles.owerInfo}`} to={`/profile/${creator?.address}`}>
      <Avatar isOwnedNft={creator.avatarFromOwnedNft} url={creator.avatar} size={36} tagSize={28}></Avatar>
      <div className={'pl5'}>{creator && abbrTxHash(creator.address, 4, 4)}</div>
      {/* <div className={'pl5'}>{creator && (creator.name ? creator.name : abbrTxHash(creator.address, 4, 4))}</div> */}
    </Link>
  )
}

let timer = ''
let btnFlag = ''

const chainTypeToken = {
  mainnet: 'Eth',
  ropsten: 'Eth',
  rinkeby: 'Eth',
  goerli: 'Eth',
  bsc: 'BNB',
  bsctestnet: 'BNB',
  polygon: 'Matic',
  mumbai: 'Matic',
}
const chainTypeName = {
  mainnet: 'Ethereum',
  ropsten: 'Ethereum',
  rinkeby: 'Ethereum',
  goerli: 'Ethereum',
  bsc: 'BSC',
  bsctestnet: 'BSC',
  polygon: 'Polygon',
  mumbai: 'Polygon',
}

const BadgeDetail = () => {
  const location = useLocation();
  const { type, project } = useParams();
  const { state, dispatch } = useContext(mainContext);
  const [initType, setInitType] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: 20,
    total: 0
  });
  const [badgeInfo, setBadgeInfo] = useState({});
  const { library, account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const ref = useRef();
  const [timeBtnShow, setTimeBtnShow] = useState(false);
  const [time, setTime] = useState();
  const { needSign } = useNeedSign();
  const isSign = useAccountIsSign();
  const { tronLibrary, tronAccount, tronChainId, tronActive } = useActiveTronWeb();
  const [anniversaryBadgeFlag, setAnniversaryBadgeFlag] = useState(false);
  const [partnerBadges, setPartnerBadges] = useState(0);
  const [showCongratulation, setShowCongratulation] = useState(false)
  const [isEnough, setIsEnough] = useState(false)
  const [isView, setIsView] = useState(false)
  const [taskList, setTaskList] = useState([]);
  const [hasTwitter, setHasTwitter] = useState(false);
  const [eventHasWhitelist, setEventHasWhitelist] = useState(false);
  const [allTaskFinished, setAllTaskFinished] = useState(false);

  const handleClaim = (item) => {
    needSign(() => {
      if (item.chainType !== getChainType(chainId)) {
        const chainTxt = chainTxtObj[item.chainType];
        if (tronChainId && !chainId && chainTxt !== 'Tron') {
          dispatch({
            type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
          });
          return message.success('Please connect wallet.', 1)
        }

        if (chainFun[chainTxt]) {
          chainFun[chainTxt]();
        } else if (chainTxt === 'Tron') {
          dispatch({
            type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
          });
          return message.success('Please connect TronLink.', 1)
        }
        return;
      }

      if (btnFlag) {
        return false
      } else {
        btnFlag = true
      }
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: waitingForConfirm
      });
      let badgeType = item.type
      let contractAddress = item.contractAddress
      getClaimInfo({ badgeType, library, account }).then(async res => {
        if (project === 'ampleforth') {
          toClaimAmpl({ badgeType, library, account, res })
        } else {
          toClaimLoot({ badgeType, library, contractAddress, account, res })
        }
      })
    })
  }
  const toClaimAmpl = async ({ badgeType, library, account, res }) => {
    await claimBadge(library, chainId, account, {
      ...res,
      _onTranscationHash: (hash) => {
      },
      _onReceipt: async (receipt) => {
        badgeInfo.claimStatus = 'claimed'
        badgeInfo.ownerCount += 1
        badgeInfo.grantedCount += 1

        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        message.success("Success!")
        btnFlag = false
      },
      _onError: (err) => {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        dispatch({
          type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
          showFailedTransactionModal: true
        });
        btnFlag = false
      }
    })
  }
  const toClaimLoot = async ({ badgeType, library, contractAddress, account, res }) => {
    await claimLoot(library, contractAddress, account, {
      ...res,
      _onTranscationHash: (hash) => {
      },
      _onReceipt: async (receipt) => {
        badgeInfo.claimStatus = 'claimed'
        badgeInfo.ownerCount += 1
        badgeInfo.grantedCount += 1

        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        btnFlag = false

        if (badgeInfo.project === 'one-year-anniversary' && badgeInfo.group != 'FIRST-ANNIVERSARY-DEFINE') {
          queryDefineAnniversaryBadges()
        } else {
          message.success("Success!")
        }
      },
      _onError: (err) => {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        dispatch({
          type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
          showFailedTransactionModal: true
        });
        btnFlag = false
      }
    })
  }
  const getClaimStatus = ({ claimStatus, claimEndTime, claimStartTime,maxCount, grantedCount}) => {
    if (claimStartTime > Date.now()) {
      if ((claimStatus - 24 * 60 * 60 * 1000) < Date.now() && Date.now() < claimStatus) {//24小时内
        setTimeBtnShow(true)
        setTime(claimStatus / 1000)
        return
      } else {
        return 'Not Started'
      }
    } else {
      if (claimStatus === 'claimed') {
        return 'Owned'
      } else {
        if (claimEndTime < Date.now()) {
          return 'Expired'
        } else {
          if((maxCount-grantedCount)>0){
            return 'Claim'
          }else {
            return 'All Claimed'
          }
        }
      }
    }
  }

  const handleTableChange = (pageInfo, badgeType) => {
    getOwnerList(pageInfo, badgeType || badgeInfo.type).then(res => {
      setTableData(res.list)
      setPagination({
        hideOnSinglePage: true,
        current: res.pageNo,
        pageSize: res.pageSize,
        total: res.totalCount
      })
    })
  }

  const content = (
    <div className={styles.pop}>
      <h1>How to get ?</h1>
      <div>Co-produced by Youngohm and NFT1’s award-winning Creative Director, NINO, this collaboration content is made exclusively for NFT1’s Rarible Store. The NFT content is an animation artwork created by Gins Jirakulsawat. It’s produced as MP4.</div>
    </div>
  )

  const columns = [
    {
      title: `Badge Owners (${badgeInfo.ownerCount})`,
      key: 'user',
      render: item => <CreatorItem creator={item} />,
    },
  ];

  const tableList = (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
      rowKey={record => record.address}
    />
  )
  const dealTime = (info) => {
    let row = info ? info : badgeInfo
    if (Date.now() < row.claimStartTime) {
      setTime(row.claimStartTime / 1000)
      setTimeBtnShow(true)
    } if (Date.now() > row.claimStartTime && Date.now() < row.claimEndTime) {
      setTime(row.claimEndTime / 1000)
      setTimeBtnShow(false)
    } if (Date.now() > row.claimEndTime) {
      setTimeBtnShow(false)
    }
  }
  const ConnectWallet = () => {
    needSign()
  }

  const queryDefineAnniversaryBadges = async () => {
    try {
      let res = await queryMyBadges(badgeInfo.project)
      let flag = false
      let num = 0
      if (res.list.length > 0) {
        flag = res.list.find(item => item.type === "FIRST-ANNIVERSARY-DEFINE")
      }
      if (!flag) {
        let data = await queryMyBadges(badgeInfo.project, badgeInfo.group)
        num = data.list.length
        setShowCongratulation(true)
        if ((num + 1) > 2) {
          setIsEnough(true)
        } else {
          setIsEnough(false)
          // message.success("success!")
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const urlBadgeTypeList = {
    "define": "FIRST-ANNIVERSARY-DEFINE",
    "aoz": "FIRST-ANNIVERSARY-AOZ",
    // "cere": "FIRST-ANNIVERSARY-CERE",
    "cybergalz": "FIRST-ANNIVERSARY-CYBERGALZ",
    "imtoken": "FIRST-ANNIVERSARY-IMTOKEN",
    "mathwallet": "FIRST-ANNIVERSARY-MATHWALLET",
    "oin": "FIRST-ANNIVERSARY-OIN",
    // "polygon": "FIRST-ANNIVERSARY-POLYGON",
    "sypool": "FIRST-ANNIVERSARY-SYPOOL",
    "naos": "FIRST-ANNIVERSARY-NAOS",
    "perpetual": "FIRST-ANNIVERSARY-PERPETUAL",
    "sunmiya": "FIRST-ANNIVERSARY-SUNMIYA",
    "mtdz": "FIRST-ANNIVERSARY-MTDZ",
    "klayswap": "FIRST-ANNIVERSARY-KLAYSWAP",
    "dvision": "FIRST-ANNIVERSARY-DVISION",
    "with": "FIRST-ANNIVERSARY-WITH",
  }
  const handleCopy = () => {
    copy(badgeInfo.contractAddress);
    message.success('Copied!')
  }

  const matchTwitterUrl = (url) => {
    const regex = /^https?:\/\/twitter\.com\/(.+?)\/status\/\d+/;
    const match = url.match(regex);
    if (match && match.length === 2) {
      return match[1];
    }
    return null;
  }
  const getTwitterStatusId = (twitterLink) => {
    const regex = /status\/(\d+)/;
    const match = twitterLink.match(regex);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }
  const verifyItem = async (task) => {
    if (!task.firstBtn) {
      message.error("Verification failed")
      return false
    }
    let res = await taskVerify(badgeInfo.project, task.id)
    if (res === 'completed') {
      queryBadgeInfo()
      // window.location.reload();
      const taskElement = document.getElementById(`task${task.id}`)
      const contentElement = taskElement.querySelector('.content');
      contentElement.style.display = 'none';
    } else {
      message.error('Verification failed')
    }
  }
  const openTwitter = (item, type, option, url) => {
    item.firstBtn = true
    twitterUtil(type, option, url)
  }
  const doTheTask = (item) => {
    item.firstBtn = true
    if (item.type === 'visit_website' || item.type === 'join_telegram' || item.type === 'join_discord') {
      window.open(item.url)
    }
  }
  const goToAuth = (e, item) => {
    if (item.type === 'follow_on_twitter' || item.type === 'retweet' || item.type === 'like_tweet' || item.type === 'tweet' || item.type === 'quote_tweet') {
      if (!hasTwitter) {
        e.stopPropagation()
        twitterUtil('auth')
        return false
      }
    }
  }
  const taskIsCompleted = (e, item) => {
    e.stopPropagation()
    return false
  }
  const initTaskListClickEvent = () => {
    const listItems = document.querySelectorAll('.taskItem');
    listItems.forEach(function (item) {
      const heading = item.querySelector('.taskHeader');
      const content = item.querySelector('.content');

      heading.onclick = () => {
        needSign(() => {
          if (content.style.display === 'none') {
            content.style.display = 'block';
          } else {
            content.style.display = 'none';
          }
        })
      };
    });
  }
  const bindTwitter = async (code) => {
    try {
      let res = await codeGetTwitterInfo(code, window.location.origin + '/auth')
      message.success('Success')
      localStorage.removeItem('twitterCode')
      setHasTwitter(true)
    } catch (error) {
      localStorage.removeItem('twitterCode')
    }
  }

  const initData = () => {
    let content = location.search
    if (content.indexOf("=") > 0) {
      setIsView(true)
    } else {
      setIsView(false)
    }
  }
  const reductionBigNum = (value, decimals) => {
    const val = new BigNumber(value)
    return val.div(new BigNumber(10).pow(decimals)).toString()
  }
  const queryBadgeInfo = async () => {
    let t = urlBadgeTypeList[type] ? urlBadgeTypeList[type] : type
    let res = await getBadgeInfo(t, account)
    setBadgeInfo(res)
    setInitType(true)
    handleTableChange(pagination, res.type)
    if (res.tasks) {
      res.tasks.map(item => {
        if (item.type === 'token_balance' || item.type === 'transaction_count' || item.type === 'total_gas_fee' || item.type === 'course') {
          item.firstBtn = true
        } else {
          item.firstBtn = false
        }
        if (item.type === 'token_balance') {
          item.balance = reductionBigNum(item.balance, getDecimalByAddress(item.token.address, erc20TokenList))
        }
      })
      setTaskList(res.tasks);
      let finished = true;
      res.tasks.forEach(cell => {
        if (!cell.completed) {
          finished = false
        }
      });
      setAllTaskFinished(finished);
    }
  }
  const queryTwitterCode = (event) => {
    if (event.storageArea === localStorage) {
      if (event.key === 'twitterCode') {
        event.newValue && bindTwitter(event.newValue)
      }
    }
  }
  const queryProjectInfo = async () => {
    let res = await getBadgeProjectInfo(project)
    if (res.hasWhitelist) {
      setEventHasWhitelist(true)
    }
  }
  useEffect(() => {
    const addr = window.localStorage.getItem('address');
    if ((addr && account) || !addr) {
      queryProjectInfo()
      queryBadgeInfo().then(_ => {
        initTaskListClickEvent()
      })
    }
    account && getArtistInfo(account, account || tronAccount).then(res => {
      if (res.twitter) {
        setHasTwitter(true)
      }
    })

  }, [account])

  useEffect(() => {
    initData()
    window.addEventListener('storage', queryTwitterCode);
    return () => {
      window.removeEventListener('storage', queryTwitterCode)
    }
  }, [])


  return (
    <>
      <Modal
        className={styles.badge_modal}
        visible={showCongratulation}
        onCancel={() => { setShowCongratulation(false) }}
        title={t('pop_congratulations') + '!'}
        width={750}
        footer={[
          <Button className="w200_h5 fs16 h50" type="primary" onClick={() => { setShowCongratulation(false) }}>
            Close
          </Button>
        ]}
      >
        <div className={`${styles['emoji']} ${styles['emoji--yay']} mt30`}>
          <div className={`${styles['emoji__face']}`}>
            <div className={`${styles['emoji__eyebrows']}`} />
            <div className={`${styles['emoji__mouth']}`} />
          </div>
        </div>
        {
          !isEnough ? <>
            <div style={{ width: "68%", margin: 'auto' }} className={`fs18 lh13`}>You have successfully claimed a partner badge! </div>
            <div className={`fs18 lh13 pl20 pr20 mt6`}>You need to collect at least 3 badges to be eligible for an airdrop of DeFine Anniversary Badge.</div>
          </> :
            <div className={`fs18 lh13 pl20 pr20`}>You have collected 3 partner badges. You will receive an airdrop of DeFine One Year Anniversary Badge! </div>
        }

      </Modal>
      <div className={`page_content_h5 ${styles.badgeDetail}`}>
        {
          !initType ? <div className={'space-between-center-h5'}><Skeleton.Image style={{ marginRight: '30px' }} /> <Skeleton active></Skeleton></div> :
            <>
              <div className={`df_h5`}>
                <div className={`${styles.img_box}`}>
                  <div className={`${styles.badge_cover} br6`}>
                    <Zmage className={styles.img} src={badgeInfo.image} ></Zmage>
                  </div>
                </div>
                <div className={`${styles.info_box}`}>
                  <div className={`f1`}>
                    <div className={`page_content_title`}>{badgeInfo.name}</div>
                    <div className={`fs14 mt20 mb30`}>{badgeInfo.description}</div>
                    <div className={`space-between-h5 mt20`}>
                      <div className={`${styles.mr10_h5} ${styles.time_box} br4`}>
                        <div className={`fs14`}>{t('badge_sTime')}</div>
                        <div className={`fs16 fw700`}>{moment(badgeInfo.claimStartTime).format("YYYY/MM/DD HH:mm:ss")}</div>
                      </div>
                      <div className={`${styles.time_box} br4`}>
                        <div className={`fs14`}>{t('badge_eTime')}</div>
                        <div className={`fs16 fw700`}>{moment(badgeInfo.claimEndTime).format("YYYY/MM/DD HH:mm:ss")}</div>
                      </div>
                    </div>
                    {
                      badgeInfo.contractAddress &&
                      <div className={`${styles.addr_box}`}>
                        <div className={`fs14`}>Contract Address</div>
                        <div className={`df_center cp`}>
                          <div className="pr10">
                            <a className={`c_blue`} target="_blank" href={handleHistoryAddress(badgeInfo.chainType, badgeInfo.contractAddress)} >{abbrTxHash(badgeInfo.contractAddress)}</a>
                          </div>
                          <img
                            width={18}
                            onClick={handleCopy}
                            src={copyIcon}
                            alt=""
                          />
                        </div>
                      </div>
                    }
                    <div className={`space-between`}>
                      <div className={`${styles.info_item}`}>
                        <div className={`fs14`}>Blockchain</div>
                        <Popover content={chainTxtObj[badgeInfo.chainType]}>
                          <div className={`fs16  p5`}>
                            <img className={styles.chain} width={30} src={chainTypeImgObj[badgeInfo.chainType]} />
                          </div>
                        </Popover>
                      </div>
                      <div className={`${styles.info_item}`}>
                        <div className={`fs14`}>Claimed</div>
                        <div className={`fs24 fw700 tac`}>{formatAmount(badgeInfo.grantedCount)}<span className="fs16">/{badgeInfo.maxCount?formatAmount(badgeInfo.maxCount):'--'}</span> </div>
                      </div>
                      <div className={`${styles.info_item}`}>
                        <div className={`fs14 ${styles.item_h2}`}>Owners</div>
                        <div className={`fs24 fw700 tac cp`}>
                          <a href="#badge_owners" className={`link_color`}>{formatAmount(badgeInfo.ownerCount)}</a>
                        </div>
                      </div>
                    </div>
                    {
                      !isView &&

                      <div className={` space-between-center-h5 mt40`}>
                        {
                          !isSign ? <div><Button onClick={ConnectWallet} className={`btn_border w180 h40`}>Claim</Button></div> :
                            badgeInfo.claimType.toLowerCase() != "airDrop".toLowerCase() ?
                            <>
                              {
                                (badgeInfo.claimStatus) && (!timeBtnShow) && <Button
                                  onClick={() => { handleClaim(badgeInfo) }}
                                  type="primary"
                                  disabled={
                                    badgeInfo.claimStatus === 'cannotClaim' ||
                                    badgeInfo.claimStatus === 'claimed' ||
                                    badgeInfo.claimStartTime > Date.now() ||
                                    badgeInfo.claimEndTime < Date.now() ||
                                    (badgeInfo.maxCount - badgeInfo.grantedCount) === 0
                                  }
                                  className={`${styles.item_btn} h50 w200_h5 fs16 btn_multicolour`}
                                >{getClaimStatus(badgeInfo)} </Button>
                              }
                              {
                                timeBtnShow && <div className={`w200_h5`}>
                                  <Button type={'primary'} disabled className={`w200_h5 h50 fs20 fw700 `}><CountDown onEnd={dealTime} time={time}></CountDown></Button>
                                </div>
                              }
                              {
                                (badgeInfo.claimStatus != 'claimed') ? <div className={`ml20 tar f1 fs14`}>{badgeInfo.claimStatus === 'cannotClaim' ? t('badge_eligible_no') : badgeInfo.claimStatus === 'notClaim' ? t('badge_eligible') : ''}</div> : ''
                              }
                            </> :
                            (
                              allTaskFinished && <div className={styles.tip_box}>
                                <img className="mr15" src={tipIcon} />
                                <span>Great! You've complete the tasks and the badge is yours.</span>
                              </div>
                            )
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
              {
                badgeInfo.tasks &&
                <div className={`${styles.description_box}`}>
                  <div className={`page_content_subtitle mb10`}>Badge Task</div>
                  {
                    eventHasWhitelist &&
                    <div className={`df_align_center ${styles.task_tip}`}>
                      <img width={18} src={tip}></img>
                      <span className="pl8">These tasks are only open to whitelist users.</span>
                    </div>
                  }
                  <div>
                    <div expandIconPosition={'right'} className={`taskList`}>
                      {
                        taskList.map(item => (
                          <>
                            {
                              item.type === 'follow_on_twitter' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background: `${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Follow @{item.twitterId} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, item.twitterId) }}>Follow</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }
                            {
                              item.type === 'retweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background: `${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Retweet @{matchTwitterUrl(item.url)} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, getTwitterStatusId(item.url)) }}>Retweet</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }
                            {
                              item.type === 'tweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Post a Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <div>{item.content}</div>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, item.content) }}>Post</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'like_tweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Like a Tweet by @{matchTwitterUrl(item.url)} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, getTwitterStatusId(item.url)) }}>Like</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'quote_tweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Quote tweet @{matchTwitterUrl(item.url)} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <div>{item.content}</div>
                                  <div className="mb10">
                                    <a className="c_blue" target="_blank" href={item.url}>{item.url}</a>
                                  </div>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, item.content, item.url) }}>Quote Tweet</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'join_discord' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgDs}></img><span className="fs16 pl20">Join Discord server</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { doTheTask(item) }}>Join</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'join_telegram' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTg}></img><span className="fs16 pl20">Join on Telegram</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { doTheTask(item) }}>Join</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'visit_website' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWWW}></img><span className="fs16 pl20">Visit this Page</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { doTheTask(item) }}>Go</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'token_balance' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWallet}></img><span className="fs16 pl20">Hold {item.balance} {item.token.symbol} on {chainTypeName[item.chainType]}</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  {/* <div className="mb10">ContractAddress: {item.token.address}</div> */}
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary" className={'w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'total_gas_fee' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWallet}></img><span className="fs16 pl20">Spend {item.amount} {chainTypeToken[item.chainType]} gas fee on {chainTypeName[item.chainType]}</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary" className={'w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'transaction_count' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}` }}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWallet}></img><span className="fs16 pl20">{item.count} transaction on {chainTypeName[item.chainType]}</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary" className={'w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'course' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgRichTxt}></img><span className="fs16 pl20">{item.title}</span>
                                  </div>
                                  {
                                    item.completed ? <img width={20} src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <div className={styles.richTxt} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary"  className={'w200 h50 fw600 mt10'} onClick={() => { verifyItem(item) }}>Done</Button>
                                </div>
                              </div>
                            }
                          </>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              <div className={`${styles.description_box}`}>
                <div className={`page_content_subtitle mb10`}>Badge Utility</div>
                <div>
                  {
                    badgeInfo.utility &&
                    <div dangerouslySetInnerHTML={{ __html: badgeInfo.utility }}></div>
                  }
                  {
                    badgeInfo.utilityUrl &&
                    <a className={`c_blue`} target="_blank" href={badgeInfo.utilityUrl}>{badgeInfo.utilityUrl}</a>
                  }

                </div>
              </div>
              <div className={`${styles.description_box}`}>
                <div className={`page_content_subtitle mb10`}>Task Description</div>
                <div>
                  {
                    badgeInfo.taskDescription &&
                    <div dangerouslySetInnerHTML={{ __html: badgeInfo.taskDescription }}></div>
                  }
                  {
                    badgeInfo.taskDescriptionUrl &&
                    <a className={`c_blue`} target="_blank" href={badgeInfo.taskDescriptionUrl}>{badgeInfo.taskDescriptionUrl}</a>
                  }
                </div>
                {
                  badgeInfo.whitelistUrl &&
                  <div className="mt10">
                    <a className={`c_blue`} target="_blank" href={badgeInfo.whitelistUrl}>{t('click_here')}</a>
                  </div>
                }
              </div>
              <div id="badge_owners" className={`${styles.description_box}`}>
                <div className={`page_content_subtitle mb10`}>{`Badge Owners (${formatAmount(badgeInfo.ownerCount)})`}</div>
                <div className={styles.owners_box}>
                  {
                    tableData.map(item => {
                      return <div className={styles.owners_item}><CreatorItem creator={item} /></div>
                    })
                  }
                </div>
                <div className={styles.owners_pagination}>
                  <Pagination onChange={(page, pageSize) => { handleTableChange({ current: page, pageSize }) }} simple {...pagination} />
                </div>
              </div>
            </>
        }
      </div>
    </>
  );
};

export default BadgeDetail