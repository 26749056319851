import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Spin } from 'antd';

import { useList } from "../../services/puzzles";
import styles from "./styles.module.scss";
import BackButton from "components/BackButton";
import NftPlusCard from "components/NftPlusCard";
import RadioFilter from "components/RadioFilter";
import BlackTable from "components/BlackTable";

export default function Sandbox() {
    const history = useHistory();
    const { t } = useTranslation()
    const [filterType, setFilterType] = useState(0)
    const [loading, setLoading] = useState(false)
    const list = useList('sandbox',setLoading)
    const tableColumns = [
        { title: t('sandbox_tr1'), align: "center", key: "name", dataIndex: "name", width: 100, },
        { title: t('sandbox_tr2'), align: "center", key: "pieces", dataIndex: "pieces", width: 150, },
        { title: t('sandbox_tr3'), align: "center", key: "price", dataIndex: "price", width: 125, },
    ]
    const tableList = [
        { name: "100 boxes", pieces: "10 pieces", price: '0.1 ETH' },
    ]
    const onChange = (index) => {
        setFilterType(index)
    }
    const goToItem = (item) => {
        console.log(item);
        history.push(`/collection/${item.type}`)
    }
    const goShardSplice = () => {
        history.push("/shardsplice")
    }

    return (
        <div className={`page_content_h5`}>
            <div className={``}>
                <div>
                    <BackButton></BackButton>
                </div>
                <div className={`space-between-center-h5 mb10`}>
                    <h1 className={'page_content_title mt20'}>{t('sandbox_sys_name')}</h1>
                    <Button className={`btn_border w200 h40`} onClick={goShardSplice}>{t('shards_splice')}</Button>
                </div>
                {
                    loading?<Spin className={`wp100 tac mt20`} size="large"></Spin>:
                    <Row gutter={20}>
                        {
                            list.map((item, index) => {
                                return (
                                    <Col xs={12} md={8} lg={6}>
                                        <NftPlusCard onClick={goToItem} key={index} item={item}></NftPlusCard>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
                <div>
                    <h1 className={`page_content_title mt30`}>{t('sypool_about_title')}</h1>
                    {list.length > 0 && <RadioFilter onChange={onChange} list={list}></RadioFilter>}
                    <BlackTable tableColumns={tableColumns} tableList={tableList}></BlackTable>
                    <div className={`${styles.des}`}>
                        <div className={`mt20 mb20`}>
                            <h3>{t('sandbox_rule')}</h3>
                            <ul>
                                <li className={'fs14'}>{t('sandbox_rule1')}</li>
                                <li className={'fs14'}>{t('sandbox_rule2')}</li>
                                <li className={'fs14'}>{t('sandbox_rule3')} </li>
                            </ul>
                        </div>
                        <div className={`p20 ${styles.des_content}`}>
                            <h3>{t('sandbox_des')}</h3>
                            <div >
                                <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('sandbox_des1') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                                <p className={'fs14'} dangerouslySetInnerHTML={{ __html: t('sandbox_des2') }} style={{ whiteSpace: 'pre-wrap' }}></p>
                                <p className={'fs14'}><a className={`c_blue`} target="_blank" href="https://medium.com/define-platform/define-launches-masterpiece-pixel-art-the-first-nft-series-by-sandbox-network-ecefe6c9a2f0">https://medium.com/define-platform/define-launches-masterpiece-pixel-art-the-first-nft-series-by-sandbox-network-ecefe6c9a2f0</a></p>
                            </div>
                            <h3>{t('sandbox_disclaimer')}</h3>
                            <p>{t('sandbox_disclaimer_content')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
