import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Zmage from 'react-zmage'
import { Link, NavLink, useLocation, useHistory, useParams, useRouteMatch } from "react-router-dom";

import CountDown from "components/CountDown";
import { formatAmountWithDecimal } from "utils/format";
import styles from "./styles.module.scss";
import place from "assets/img/defaultImg.jpg";
import { chainTypeImgObj } from 'utils/networkConnect';



const NftCardView = ({ tokenInfo, className }) => {
  const [showCountDown, setShowCountDown] = useState(false)
  const [time, setTime] = useState(0)
  const [timeType, setTimeType] = useState('start')
  const [symbol, setSymbol] = useState('ETH')
  const [balance, setBalance] = useState(0)
  const [priceType, setPriceType] = useState('')
  const [imgLoaded, setImgLoaded] = useState(false)
  const { t } = useTranslation();
  const history = useHistory();
  const reg = /.gif/

  const handleClick = () => {
    history.push(`/marketplace/${tokenInfo.contractAddress}/${tokenInfo.chainType}/${tokenInfo.id}`)
  }

  const dealTime = () => {
    const now = Date.now() / 1000;
    const info = tokenInfo.saleInfoList && tokenInfo.saleInfoList[0];
    if (info) {
      let date;
      if (info.type === 'auction') {
        if (info.startAt - now > 0) {
          date = info.startAt;
        } else {
          date = info.closeAt;
          setTimeType('end')
        }
      } else {
        date = info.startAt;
      }

      if (date - now > 0) {
        setTime(date)
        setShowCountDown(true)
      } else {
        setTime(0)
        setShowCountDown(false)
      }
    }
  }

  const dealPrice = () => {
    const info = tokenInfo.saleInfoList && tokenInfo.saleInfoList[0];
    if (info) {
      if (info.type === 'auction') {
        if (info.bidHistories && info.bidHistories.length > 0) {
          setBalance(formatAmountWithDecimal(
            info.bidHistories[info.bidHistories.length - 1].amount1,
            info.token1?.decimals
          ))
        } else {
          setBalance(formatAmountWithDecimal(
            info.amountMin1,
            info.token1?.decimals
          ))
        }
        setPriceType('auction')

      } else {
        setBalance(formatAmountWithDecimal(info.amountTotal1, info.token1?.decimals))
        setPriceType('fixedSwap')
      }
      setSymbol(info.token1?.symbol)

    } else if (tokenInfo.lastPrice) {
      setBalance(formatAmountWithDecimal(
        tokenInfo.lastPrice.price,
        tokenInfo.lastPrice.priceToken?.decimals
      ))
      setSymbol(tokenInfo.lastPrice.priceToken?.symbol)
      setPriceType('last')
    }
  }

  const handleLoad = () => {
    setImgLoaded(true)
  }

  useEffect(() => {
    dealTime()
    dealPrice()
  }, [tokenInfo])

  return (
    <div className={`${styles.card} ${showCountDown ? styles.is_auction : ''} ${className || ''}`}>
      <div className={`${styles.card_box}`}>
        <div>
          <img className={styles.chain_type} src={chainTypeImgObj[tokenInfo.chainType]} alt="" />
        </div>
        <div className={styles.nft_img}>
          {/* {
            showCountDown && (
              <div className={styles.timer_box}>
                {timeType !== 'end' && <span>Start in </span>}
                <CountDown onEnd={dealTime} time={time} type="word" />
                {timeType === 'end' && <span> Left</span>}
              </div>
            )
          } */}
          <Zmage
            style={{display: imgLoaded ? 'block' : 'none'}}
            onLoad={handleLoad}
            className={styles.card_image}
            src={tokenInfo.image}
            set={[{ src: tokenInfo.image }]}
          />
          <img
            style={{display: !imgLoaded ? 'block' : 'none'}}
            className={styles.card_image}
            src={place}
          />
        </div>
        <div className={`${styles.nft_name}`}>{tokenInfo.name}</div>
        {
          priceType === 'auction' && <div className={styles.price_box}>
            <span className={styles.price_bid}>Bid {balance} {symbol}</span>
          </div>
        }
        {
          priceType === 'fixedSwap' && <div className={styles.price_box}>
            <span className={styles.price_bid}>{balance} {symbol}</span>
          </div>
        }
        {
          priceType === 'last' && <div className={styles.price_box}>
            <span className={styles.price_last}>Last : </span><span className={styles.price_last}>{balance} {symbol}</span>
          </div>
        }
      </div>
    </div>
  )
}

export default NftCardView;