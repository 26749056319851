import React, { useState } from "react";
import { Spin, Input, Switch, Modal, Form, Button, Select } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getChainType } from "../../web3/address";
import { useActiveWeb3React } from "../../web3";
import NftCard from 'components/NftCard';
import { usePrunkList } from "../../services/punk";
import { filterBackground, filterClothes, filterEarring, filterEyes, filterFur, filterHat, filterMouth } from "./constant";
import styles from "./styles.module.scss";
import filter from "../../assets/img/Vector.svg";
import punkBg from "../../assets/img/punk/ape.jpg"
import NoData from "components/NoData";


let timer;
const { Option } = Select;

const Punk = () => {
    const [loading, setLoading] = useState(false);
    const { library, chainId, account } = useActiveWeb3React()
    const chainType = getChainType(chainId)
    const [current, setCurrent] = useState(1);
    const [search, setSearch] = useState('');
    const [isOnSale, setIsOnSale] = useState('')
    const [otherParams, setOtherParams] = useState()
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()
    let { address } = useParams();

    if (!address) {
        address = "TRXGss8BRer1jtAiEzSjrY2k5skDtVB7DK"
    }
    const { list, total } = usePrunkList(current, 30, setLoading, search, isOnSale, otherParams)

    const changeSearch = (e) => {
        const value = e.target.value
        clearTimeout(timer)
        timer = setTimeout(() => {
            setCurrent(1);
            setSearch(value)
            setLoading(true)
        }, 500);
    }

    const toggleOnSale = (checked) => {
        setCurrent(1);
        setIsOnSale(checked ? 'sale' : '')
    }

    const showFilter = () => {
        setVisible(true)
    }

    const onClear = () => {
        form.resetFields();
    }

    const handleCancel = () => {
        setVisible(false);
    };
    const getList = () => {
        setCurrent(current + 1)
    }

    const onFinish = (values) => {
        let res = {}
        Object.keys(values).filter(key => values[key] != undefined).forEach(key => {
            res[key] = values[key]
        })
        setCurrent(1);
        setOtherParams(res)
        setVisible(false)
    }

    return (
        <div className={styles.container}>
            <Modal
                title="Filter"
                visible={visible}
                width={600}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    name="event-filter"
                    onFinish={onFinish}
                    requiredMark={false}
                    className={styles.activityMint}
                >
                    <div className={`${styles.modal_df}`}>
                        <Form.Item className={`${styles.modal_select}`} name="background">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Background"
                                showArrow
                            >
                                {
                                    filterBackground.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label} <span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item className={`${styles.modal_select}`} name="clothes">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Clothes"
                                showArrow
                            >
                                {
                                    filterClothes.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={`${styles.modal_df}`}>
                        <Form.Item className={`${styles.modal_select}`} name="earring">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Earring"
                                showArrow
                            >
                                {
                                    filterEarring.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item className={`${styles.modal_select}`} name="eyes">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Eyes"
                                showArrow
                            >
                                {
                                    filterEyes.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={`${styles.modal_df}`}>
                        <Form.Item className={`${styles.modal_select}`} name="fur">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Fur"
                                showArrow
                            >
                                {
                                    filterFur.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item className={`${styles.modal_select}`} name="hat">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Hat"
                                showArrow
                            >
                                {
                                    filterHat.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={`${styles.modal_df}`}>
                        <Form.Item name="mouth" className={`wp100 mr10`}>
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Mouth"
                                showArrow
                            >
                                {
                                    filterMouth.map(item => (
                                        <Option key={item.value} value={item.value}>{item.label}<span className={`fs12 ${styles.text666}`}> &nbsp;&nbsp;{item.percent}</span> </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={styles.btnBox}>
                        <Button className={'btn_border h40 wp50 mr10'} htmlType="button" onClick={onClear}>
                            Reset
                        </Button>
                        <Button className={`wp50 mr10`} type="primary" htmlType="submit">
                            Filter
                        </Button>
                    </div>
                </Form>
            </Modal>
            <div className={`tac auto ${styles.page_head_img}`} style={{backgroundImage:`url(${punkBg})`}}>
                <div className={`page_content_title ${styles.title_mt}`}>{t('event_title1')} {t('event_title2')}</div>
                <div className="subtitle_wrapper">
                    <div className={`lh18 mt30 fs16 lh24 ${styles.subtitle}`}>
                        {t('event_text_1')}
                    </div>
                    
                </div>
            </div>
            <div className={`df_center_h5 mt20`}>
                        <div className={`df_center`}>
                            <div>
                                <Switch onChange={toggleOnSale} />
                                <span className={"fs14 c_l ml10 fw600"}>{t('on_sale')}</span>
                            </div>
                            <div className={`${styles.filter}`} onClick={showFilter} >
                                <div className={styles.space_line}></div>
                                <img height={20} src={filter}></img>
                            </div>
                        </div>
                        <div className={`${styles.search}`}>
                            <div className={styles.space_line_search}></div>
                            <Input className={`${styles.search_input} h40`} prefix={<SearchOutlined />} placeholder="Search ”9999”" onChange={changeSearch} />
                        </div>
                    </div>
            <div className={`page_collection_list`}>
                {
                    loading?<Spin className={`wp100 mt40`} size="large"></Spin>:
                    <InfiniteScroll
                        dataLength={list.length}
                        next={getList}
                        hasMore={list.length < total}
                        loader={
                            <div style={{ width: 216, textAlign: 'center', margin:'auto' }}>
                                <Spin size={`large`} />
                            </div>
                        }
                    >
                        {
                            list.length > 0 && list.map(item => (
                                <NftCard key={item.contractAddress + item.id} tokenInfo={item} />
                            ))
                        }
                        {
                            list.length === 0 &&<div className={`wp100 tac`}><NoData></NoData></div> 
                        }
                    </InfiniteScroll>
                }
            </div>
        </div>
    )
}

export default Punk;
