import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Popover, Modal } from 'antd';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import "swiper/swiper-bundle.min.css";

import styles from "./styles.module.scss";
import bg1 from 'assets/img/worldcup/bg-head.jpg'
import bg2 from 'assets/img/worldcup/bg-p2.jpg'
import bg3 from 'assets/img/worldcup/bg-p3.png'
import bg4 from 'assets/img/worldcup/bg-p4.png'
import cup from 'assets/img/worldcup/cup.png'
import footballH5 from 'assets/img/worldcup/football.png'
import ball1 from 'assets/img/worldcup/ball1.png'
import ball2 from 'assets/img/worldcup/ball2.png'
import ball3 from 'assets/img/worldcup/ball3.png'
import claimNow from 'assets/img/worldcup/claimNow.png'
import claimDfa from 'assets/img/worldcup/claimDfa.png'
import results from 'assets/img/worldcup/results.png'
import resultsTable from 'assets/img/worldcup/resultTable.png'
import resultsTableH5 from 'assets/img/worldcup/resultTableH5.png'
import closeBtn from 'assets/img/worldcup/closeBtn.png'

import groupA from "assets/img/worldcup/groupA.png"
import groupB from "assets/img/worldcup/groupB.png"
import groupC from "assets/img/worldcup/groupC.png"
import groupD from "assets/img/worldcup/groupD.png"
import groupE from "assets/img/worldcup/groupE.png"
import groupF from "assets/img/worldcup/groupF.png"
import groupG from "assets/img/worldcup/groupG.png"
import groupH from "assets/img/worldcup/groupH.png"

import step1 from "assets/img/worldcup/step1.png"
import step2 from "assets/img/worldcup/step2.png"
import step3 from "assets/img/worldcup/step3.png"

import partner1 from "assets/img/worldcup/p_alan.png"
import partner2 from "assets/img/halloween/partner20.png"
import partner3 from "assets/img/worldcup/p_chiko.png"
import partner4 from "assets/img/worldcup/p_chiliz.png"
import partner5 from "assets/img/worldcup/p_cochang.png"
import partner6 from "assets/img/worldcup/p_cryptonote.png"
import partner7 from "assets/img/worldcup/p_dolganmagic.png"
import partner8 from "assets/img/worldcup/p_dwg.png"
import partner9 from "assets/img/worldcup/p_fireant.png"
import partner10 from "assets/img/halloween/partner22.png"
import partner11 from "assets/img/worldcup/p_hanabi.png"
import partner12 from "assets/img/worldcup/p_hyperwallet.png"
import partner13 from "assets/img/worldcup/p_koob.png"
import partner14 from "assets/img/worldcup/p_meta2150s.png"
import partner15 from "assets/img/halloween/partner6.png"
import partner16 from "assets/img/worldcup/p_nftscan.png"
import partner17 from "assets/img/worldcup/p_openBlox.png"
import partner18 from "assets/img/worldcup/p_polygonStudios.png"
import partner19 from "assets/img/kbw/rai_p.png"
import partner20 from "assets/img/worldcup/p_riceBean.png"
import partner21 from "assets/img/worldcup/p_sorare.png"
import partner22 from "assets/img/worldcup/p_thesandbox.png"
import partner23 from "assets/img/worldcup/p_tokenview.png"
import partner24 from "assets/img/worldcup/p_tunagg.png"
import partner25 from "assets/img/worldcup/twitterScan_p.png"
import partner26 from "assets/img/worldcup/p_utopo.png"
import partner27 from "assets/img/halloween/partner13.png"
import partner28 from "assets/img/halloween/partner7.png"
import partner29 from "assets/img/worldcup/p_weSleep.png"
import partner30 from "assets/img/worldcup/p_with.png"
import partner31 from "assets/img/worldcup/p_w3goo.png"
import partner32 from "assets/img/worldcup/oxgen_p.png"
import partner33 from "assets/img/kbw/sypool_p.png"

import missionLink from "assets/img/worldcup/missionLink.png"

import top16Bg from "assets/img/worldcup/top16-bg.png"
// import top16Spain from "assets/img/worldcup/top16-Spain.png"
// import top16Brazil from "assets/img/worldcup/top16-Brazil.png"
import top16Argentina from "assets/img/worldcup/top16Argentina.png"
import top16Australia from "assets/img/worldcup/top16australia.png"
import top16Brazil from "assets/img/worldcup/top16Brazil.png"
import top16Croatia from "assets/img/worldcup/top16Croatia.png"
import top16England from "assets/img/worldcup/top16England.png"
import top16France from "assets/img/worldcup/top16France.png"
import top16Japan from "assets/img/worldcup/top16Japan.png"
import top16Korea from "assets/img/worldcup/top16Korea.png"
import top16Morocco from "assets/img/worldcup/top16Morocco.png"
import top16Netherlands from "assets/img/worldcup/top16Netherlands.png"
import top16Poland from "assets/img/worldcup/top16Poland.png"
import top16Portugal from "assets/img/worldcup/top16Portugal.png"
import top16Senegal from "assets/img/worldcup/top16Senegal.png"
import top16Spain from "assets/img/worldcup/top16Spain.png"
import top16Switzerland from "assets/img/worldcup/top16Switzerland.png"
import top16USA from "assets/img/worldcup/top16USA.png"
import checkallbadges from "assets/img/worldcup/checkallbadges.png"
import top2Bg from "assets/img/worldcup/top2-bg.png"

import env from '../../env';

SwiperCore.use([Autoplay, Pagination])
export default function WorldCup() {

    const history = useHistory();
    const [resultsOpen, setResultsOpen] = useState(false)
    const badgeList = [
        [
            { name: 'GroupA', src: groupA, type: 'KBW2022-BOBANETWORK' },
            { name: 'GroupB', src: groupB, type: 'KBW2022-BOBANETWORK' },
            { name: 'GroupC', src: groupC, type: 'KBW2022-BOBANETWORK' },
            { name: 'GroupD', src: groupD, type: 'KBW2022-BOBANETWORK' },
        ],
        [
            { name: 'GroupE', src: groupE, type: 'KBW2022-BOBANETWORK' },
            { name: 'GroupF', src: groupF, type: 'KBW2022-BOBANETWORK' },
            { name: 'GroupG', src: groupG, type: 'KBW2022-BOBANETWORK' },
            { name: 'GroupH', src: groupH, type: 'KBW2022-BOBANETWORK' },
        ]

    ]
    const badgeList2 = [
        [
            [
                { name: 'GroupA', src: top16Netherlands, type: 'DeFine-FC2022-NETHERLANDS',typeTest:'DeFine-FIFA-20' },
                { name: 'GroupA', src: top16USA, type: 'DeFine-FC2022-USA',typeTest:'31'  },
            ],
            [
                { name: 'GroupA', src: top16Argentina, type: 'DeFine-FC2022-ARGENTINA',typeTest:'DeFine-FIFA-1'  },
                { name: 'GroupA', src: top16Australia, type: 'DeFine-FC2022-AUSTRALIA',typeTest:'DeFine-FIFA-2'  },
            ],
            [
                { name: 'GroupA', src: top16Japan, type: 'DeFine-FC2022-JAPAN',typeTest:'DeFine-FIFA-16'  },
                { name: 'GroupA', src: top16Croatia, type: 'DeFine-FC2022-CROATIA',typeTest:'DeFine-FIFA-8'  },
            ],
            [
                { name: 'GroupA', src: top16Brazil, type: 'DeFine-FC2022-BRAZIL',typeTest:'DeFine-FIFA-4'  },
                { name: 'GroupA', src: top16Korea, type: 'DeFine-FC2022-KOREAREPUBLIC',typeTest:'DeFine-FIFA-17'  },
            ],
        ],
        [
            [
                { name: 'GroupA', src: top16England, type: 'DeFine-FC2022-ENGLAND',typeTest:'DeFine-FIFA-11'  },
                { name: 'GroupA', src: top16Senegal, type: 'DeFine-FC2022-SENEGAL',typeTest:'DeFine-FIFA-25'  },
            ],
            [
                { name: 'GroupA', src: top16France, type: 'DeFine-FC2022-FRANCE',typeTest:'DeFine-FIFA-12'  },
                { name: 'GroupA', src: top16Poland, type: 'DeFine-FC2022-POLAND',typeTest:'DeFine-FIFA-21'  },
            ],
            [
                { name: 'GroupA', src: top16Morocco, type: 'DeFine-FC2022-MOROCCO',typeTest:'DeFine-FIFA-19'  },
                { name: 'GroupA', src: top16Spain, type: 'DeFine-FC2022-SPAIN',typeTest:'DeFine-FIFA-27'  },
            ],
            [
                { name: 'GroupA', src: top16Portugal, type: 'DeFine-FC2022-PORTUGAL',typeTest:'DeFine-FIFA-22'  },
                { name: 'GroupA', src: top16Switzerland, type: 'DeFine-FC2022-SWITZERLAND',typeTest:'DeFine-FIFA-28'  },
            ],
        ],

    ]
    const badgeList3 = [
        [
            [
                { name: 'GroupA', src: top16Netherlands, type: 'DeFine-FC2022-NETHERLANDS',typeTest:'DeFine-FIFA-20' },
                { name: 'GroupA', src: top16Argentina, type: 'DeFine-FC2022-ARGENTINA',typeTest:'DeFine-FIFA-1'  },
            ],
            [
                { name: 'GroupA', src: top16Croatia, type: 'DeFine-FC2022-CROATIA',typeTest:'DeFine-FIFA-8'  },
                { name: 'GroupA', src: top16Brazil, type: 'DeFine-FC2022-BRAZIL',typeTest:'DeFine-FIFA-4'  },
            ],
            [
                { name: 'GroupA', src: top16England, type: 'DeFine-FC2022-ENGLAND',typeTest:'DeFine-FIFA-11'  },
                { name: 'GroupA', src: top16France, type: 'DeFine-FC2022-FRANCE',typeTest:'DeFine-FIFA-12'  },
            ],
            [
                { name: 'GroupA', src: top16Morocco, type: 'DeFine-FC2022-MOROCCO',typeTest:'DeFine-FIFA-19'  },
                { name: 'GroupA', src: top16Portugal, type: 'DeFine-FC2022-PORTUGAL',typeTest:'DeFine-FIFA-22'  },
            ],
        ],
    ]
    const badgeList4 = [
        [
            [
                { name: 'GroupA', src: top16Argentina, type: 'DeFine-FC2022-ARGENTINA',typeTest:'DeFine-FIFA-1'  },
                { name: 'GroupA', src: top16Croatia, type: 'DeFine-FC2022-CROATIA',typeTest:'DeFine-FIFA-8'  },
            ],
            
            [
                { name: 'GroupA', src: top16France, type: 'DeFine-FC2022-FRANCE',typeTest:'DeFine-FIFA-12'  },
                { name: 'GroupA', src: top16Morocco, type: 'DeFine-FC2022-MOROCCO',typeTest:'DeFine-FIFA-19'  },
            ],
        ],
    ]
    const badgeList5 = [
        [
            [
                { name: 'GroupA', src: top16Argentina, type: 'DeFine-FC2022-ARGENTINA',typeTest:'DeFine-FIFA-1'  },
                { name: 'GroupA', src: top16France, type: 'DeFine-FC2022-FRANCE',typeTest:'DeFine-FIFA-12'  },
                
            ],
        ],
    ]
    const partnerList = [
        { name: 'Alan', type: '', simg: partner1,content:'Alan is a KOL who travels the world, meets many market and crypto investors, and decides to invest based on various factors.',href:'https://gleam.io/QO8IK/alan-x-define-football-campaign-2022'},
        { name: 'Bugcity', type: '', simg: partner2,content:'Bug City is a community based on ‘Social Mining’, and interaction of members which started on February 8th, 2022. ‘Social Mining’ and ‘Working Economy’ are some unique factors of Bug City and other features are more than interesting. Bug City proved that ‘Social Mining’ is a method that guarantees sustainability and scalability.',href:'https://gleam.io/Olq9N/bugcity-x-define-football-campaign-2022' },
        { name: 'Chiko', type: '', simg: partner3,content:'Korean cryptocurrency Influencer - Chiko. Chiko seeks profit in a different way than others, and is very strong in overcoming crises. Taking a different path from others and creating profits in their own way.',href:'https://gleam.io/GIWVT/chiko-x-define-football-campaign-2022' },
        { name: 'Chiliz', type: '', simg: partner4,content:"The world's leading blockchain and fintech provider for Sports and Entertainment. Powering ChilizX, Socios & Chiliz Chain.",href:'https://gleam.io/WH7yQ/define-football-campaign-2022-for-the-chiliz-community' },
        { name: 'Cochang', type: '', simg: partner5,content:'Cochang is the number one web3 influencer in Korea.He has various NFTs such as CloneX and Ghosts. He is good precedent and goal to those who are new to web3.',href:'https://gleam.io/21YDP/cochang-x-define-football-campaign-2022' },
        { name: 'Cryptonote', type: '', simg: partner6,content:'A Korean cryptocurrency Influencer who provides quality materials rather than delivering the latest news quickly.',href:'https://gleam.io/ZOVIK/cryptonote-x-define-football-campaign-2022' },
        { name: 'Dolgamagic', type: '', simg: partner7,content:'Korean Crypto Degen l DOLGOMBAE & MAGIC I provide Information for Crypto Beginners! Coin, NFT, DeFi, Whitelist, Airdrop, etc.',href:'https://gleam.io/SxYJ2/dolgamagic-x-define-football-campaign-2022' },
        { name: 'DWGG', type: '', simg: partner8,content:'DWG Guild (DWGG) is a gaming DAO to serve as a main bridge to blockchain gaming & entertainment. The first gaming DAO partnered with an e-sports champion team, DWG KIA, to build the greater blockchain gaming metaverse and to promote better blended entertainment for all.',href:'https://gleam.io/0c8Wh/dwgg-x-define-football-campaign-2022' },
        { name: 'Fireant', type: '', simg: partner9,content:"Fire Ant Crypto is a comprehensive coin information channel and Korea's largest cryptocurrency community.",href:'https://gleam.io/wO9TF/fireant-x-define-football-campaign-2022' },
        { name: 'Ghostbar', type: '', simg: partner10,content:"Ghosts bar is the holder community of 'GhostsProject'. We get together under the theme of GhostsProject and WEB3, but there are various events that may or may not be related to them. If you're curious about the Ghosts bar, why don't you come in?",href:'https://gleam.io/N8CJC/ghostbar-x-define-football-campaign-2022' },
        { name: 'Hanabi', type: '', simg: partner11,content:'Hanabi is a KOL that runs the Butterfly Effect channel.It delivers various information such as spot, futures, testnet, and is a holder of Digidaigaku.',href:'https://gleam.io/4aKQm/hanabi-x-define-football-campaign-2022' },
        { name: 'HyperPay', type: '', simg: partner12,content:`HyperPay is the world's first Web5 wallet, integrating "Offchain Wallet + Web3 Wallet + Hardware Wallet + Co managed Wallet", providing personal and enterprise users with one-stop services including asset custody, wealth management, and consumer payment. It also has the capabilities of traditional banking and digital currency banking, connects CeFi and DeFi application scenarios, and uses one wallet to solve all financial payment related problems.HyperPay was founded in 2017, with 1.2 million users in 200 countries and regions around the world.`,href:'https://medium.com/define-platform/define-world-cup-event-e495fd073147#49f7'  },
        // { name: 'iZUMi', type: '', simg: partner9,content:'test' },
        { name: 'Koob', type: '', simg: partner13,content:"A Korean cryptocurrency Influencer, marketer, investor, node operator. The photo on Nft is of koob's pet, koowoo.",href:'https://gleam.io/rDI98/kookookoob-x-define-football-campaign-2022' },
        { name: 'Meta2150s', type: '', simg: partner14,content:'Meta2150s is a Multi-chain platform for GameFi, NFT, Guilds, and Game enthusiasts.',href:'https://gleam.io/Sqy2r/meta2150s-x-define-football-campaign-2022' },
        { name: 'MoneyGrid', type: '', simg: partner15,content:'Moneygrid has delivered various news, insights, and project information in the cryptocurrency market, starting with other webpages in 2017. Moneygrid has served as an ambassador for several projects and exchanges, and is currently serving as a KOL for various projects, supporting the market and communicating with investors.',href:'https://gleam.io/TlK2A/moneygrid-x-define-football-campaign-2022' },
        { name: 'NFTStar', type: '', simg: partner16,content:'Building The Biggest Sports Stadium In the Web3 Metavers',href:'https://gleam.io/bVIV7/define-football-campaign-2022-for-nftstar-community' },
        { name: 'openblox', type: '', simg: partner17,content:'Welcome to OpenBlox! A digital world full of magical places to explore! Collect, battle, breed and explore!OpenBlox is an EVM-based NFT Gaming Platform, that leverages one set of Blox NFTs and tokens across all games in the OpenBlox universe - BloxVerse. Our first web3 lifestyle app - RunBlox is downloadable on both iOS and Android app stores, and its second product - a roguelike PVE card game - RogueBlox is set to be launched in November.',href:'https://gleam.io/QpJb6/openblox-x-define-football-campaign-2022' },
        { name: 'Polygon Studios', type: '', simg: partner18,content:'Building in the metaverse and supporting the best web3 projects on Polygon',href:'https://gleam.io/Rgc41/define-football-campaign-2022-for-the-polygon-studios-community' },
        { name: 'ProjectWITH', type: '', simg: partner30,content:'Project WITH is a blockchain-based sports career management platform that connects players, clubs, agents, and fans together. Project WITH is currently focusing on the sports IP-based donation NFT, and is expanding the NFT business through the generative art CODE and the recently launched WEDID (WITH DID).',href:'https://gleam.io/myxP4/projectwith-x-define-football-campaign-2022'},
        { name: 'RAI Finance', type: '', simg: partner19,content:'Rai Finance is a project to establish a decentralized finance (DeFi) ecosystem. RAI Finance is established to complement the problems of building DeFi-based digital assets and supplying liquidity to existing distributed financial ecosystems.',href:'https://gleam.io/C2ahm/rai-finance-x-define-football-campaign-2022' },
        { name: 'RiceBean', type: '', simg: partner20,content:'Korean Crypto Influencer - Ricebean! I am a co-founder of Web3stat! I provide information on Play to Earn!',href:'https://gleam.io/Wm49N/ricebean-x-define-football-campaign-2022' },
        { name: 'Sorare', type: '', simg: partner21,content:'Experience fantasy football and baseball like never before! Collect, play, win. #OwnYourGame ⚽⚾',href:'https://gleam.io/BpjyN/define-football-campaign-2022-for-sorare-community' },
        { name: 'Sypool Protocol', type: '', simg: partner33,content:'Sypool is an asset management protocol on Solana, where investors exchange fund shares with their tokens. Like traditional funds, investors are offered professional financial services, while fund managers have a platform to raise tokens.',href:'https://gleam.io/U63tY/sypool-protocol-x-define-football-campaign-2022' },
        { name: 'The Sandbox', type: '', simg: partner22,content:'The Sandbox is a virtual gaming world where players can build, own, and monetize their gaming experiences.',href:'https://gleam.io/J3ThN/define-football-campaign-2022-for-the-sandbox-community' },
        { name: 'Tokenview', type: '', simg: partner23,content:'Token View is a channel created by domestic DEGEN. You can see the next thing in here.',href:'https://gleam.io/MoNPG/tokenview-x-define-football-campaign-2022' },
        { name: 'TUNA.GG', type: '', simg: partner24,content:'TUNA BOT, a blockchain-based SNS chatbot service serviced by TUNA.GG, can easily send coins/tokens through chatting through the Non-Custody Wallet function, and can help revitalize the community through various commands.',href:'https://gleam.io/tDOKz/tunagg-x-define-football-campaign-2022' },
        { name: 'TwitterScan', type: '', simg: partner25,content:'TwitterScan is a one-stop information collection and project launch platform for "what is worth buying" in the world of Crypto and NFTs, and helps ordinary investors in the ever-changing cryptocurrency market.',href:'https://gleam.io/Nd8hB/twitterscan-x-define-football-campaign-2022' },
        { name: 'Utopo', type: '', simg: partner26,content:'Utopo is a metaverse game recommendation platform and a community that integrates SocialFI into GameFI.',href:'https://gleam.io/SPDyZ/utopo-x-define-football-campaign-2022' },
        { name: 'Web3.com', type: '', simg: partner27,content:'Web3.com is an information aggregation platform dedicated to providing more customized services for both WEB2 & WEB3 users and aggregating on-chain and off-chain information for multi-dimensional display.',href:'https://gleam.io/ipifW/web3com-x-define-football-campaign-2022' },
        { name: 'WeCryptoTogether', type: '', simg: partner28,content:'A Korean crypto channel and community that shares important crypto news and information!',href:'https://gleam.io/Z9e2p/wecryptotogether-x-define-football-campaign-2022' },
        { name: 'WeSleep', type: '', simg: partner29,content:'WeSleep is a Web3 app with inbuilt Game-Fi elements rewarding users for their sleep time on a daily basis as well as incentivising them to build a healthier and more active lifestyle.',href:'https://gleam.io/KQuso/wesleep-x-define-football-campaign-2022' },
        { name: 'W3Goo', type: '', simg: partner31,content:'W3Goo is a Web3 search engine that provides all decentralized information that Web3 natives need and is the entrance for web2 users to explore Web3.',href:'https://gleam.io/SXucJ/w3goo-x-define-football-campaign-2022' },
        { name: '0xGen', type: '', simg: partner32,content:'0xGen is the one-stop liquidity aggregator that integrates leading Dex aggregators. We offer access to the deepest liquidity, lowest slippage and best exchange rates by smart routing through all liquidity providers with up to 18 chains.',href:'https://gleam.io/TN2Mx/0xgen-x-define-football-campaign-2022' },
    
      ]

    const gotoClaim = () => {
        history.push(`/blindbadge/DeFine-FootballCampaign2022`)
    }
    const gotoClaimDFAPage = () => {
        history.push(`/claimDfa`)
    }
    const gotoBlindBox = () => {
        history.push(`/blindbadge/DeFine-FootballCampaign2022`)
    }
    const gotoMidum = () => {
        window.open("https://defineplatform.medium.com/define-world-cup-event-e495fd073147")
    }

    const showModal = () => {
        setResultsOpen(true)
    }
    const closeModal = () => {
        setResultsOpen(false)
    }
    
    const scrollToAnchor = (anchorName) => {
        console.log(anchorName);
        if(anchorName){
            let anchorElement = document.getElementById(anchorName)
            console.log(anchorElement);
            if(anchorElement) anchorElement.scrollIntoView({block:'start'})
        }
    }
    const gotoSingleBadge = (type) => {
        history.push(`/badge/DeFine-FootballCampaign2022/${type}?isview=true`)
    }
    const gotoAllBadges = () => {
        history.push('/blindbadge/DeFine-FootballCampaign2022')
    }

    useEffect(() => {
        console.log(env);
    })
    return (
        <>
        <Modal visible={resultsOpen}
            footer={null}
            width={1244}
            wrapClassName={styles.modal_result}
            onCancel={closeModal}
            >
            <div className={`df_h5 ${styles.modal_main}`}>
                <div className={styles.main_1}>
                    <div className='fs24 fw700 mb20'>Results & Reward</div>
                    <div>1. According to the Football event results, Football Team badge holders will share the 50,000 DFA prize pool. The reward distribution methods are as follows:</div>
                    <div className='mt10 mb10'>
                        <img className={styles.tablePc} src={resultsTable}></img>
                        <img className={styles.tableH5} src={resultsTableH5}></img>
                    </div>
                    <ul>
                        <li>If a user owns several of the same team badges, the user will receive more cuts of rewards.</li>
                        <li>If a team reaches a high rank, the team badge holder will receive all rewards from the lower stages.</li>
                    </ul>
                    <div>Example: If you hold three badges of a Semi-Final Qualified Team, you will share the Prize pool of Gold, Siver, and Bronze, and your reward will be three times of the one badge holder.</div>
                </div>
                <div className={styles.main_2}>
                    <div>2. Snapshots will be taken for each round according to the schedule below. To receive the reward, you must hold the badge in your wallet when the snapshot takes place.</div>
                    <div className='mt6'>3. The rewards for each round will be distributed according to the schedule below.</div>
                    <div>
                        <div className='fs16 fw700 mt24'>Round of 16</div>
                        <div>Snapshot time:</div>
                        <div>3 Dec 2022, 18:00 (SGT) Claim opens: 4 Dec 2022, 00:00 (SGT)</div>
                    </div>
                    <div>
                        <div className='fs16 fw700 mt24'>Quarter Finals</div>
                        <div>Snapshot time:</div>
                        <div>7 Dec 2022, 18:00 (SGT) Claim opens: 8 Dec 2022, 00:00 (SGT)</div>
                    </div>
                    <div>
                        <div className='fs16 fw700 mt24'>Semi Finals</div>
                        <div>Snapshot time:</div>
                        <div>11 Dec 2022, 18:00 (SGT) Claim opens: 12 Dec 2022, 00:00 (SGT)</div>
                    </div>
                    <div>
                        <div className='fs16 fw700 mt24'>The Finals</div>
                        <div>Snapshot time:</div>
                        <div>19 Dec 2022, 18:00 (SGT) Claim opens: 20 Dec 2022, 00:00 (SGT)</div>
                    </div>
                    <div className='tar mt20'><img className='cp' src={closeBtn} onClick={closeModal}></img></div>
                </div>
            </div>
        </Modal>
        <div className={styles.kbw} style={{overflowY:'hidden'}}>
            <div className={`${styles.page_head_img}`} style={{ backgroundImage: `url(${bg1})` }}>
                <div className={styles.head_main}>
                    <div>
                        <div className={styles.main_1}>21 Nov 2022 ~ 20 Dec 2022</div>
                        <div className={styles.main_2}>
                            <div className={styles.text}>Football Campaign 2022</div>
                            {/* <img className={styles.reward} src={reward}></img> */}
                            <img width={100} className={styles.footballH5} src={footballH5}></img>
                        </div>
                        <div className={styles.main_3}>In the spirit of celebrating the upcoming mega Football event, we are hosting the DeFine - Football Campaign 2022 with a <span style={{ color: "#FDFF9B", fontWeight: 700 }}>50,000 DFA</span>  prize pool!
                            <div className={styles.claimNow}>
                                {/* <Button className={styles.results}>RESULTS & REWARD</Button> */}
                                <img className={styles.results} src={results} onClick={showModal}></img>
                                <img className={`cp ${styles.claim}`} src={claimDfa} onClick={gotoClaimDFAPage}></img></div>
                         </div>
                    </div>
                    <img className={styles.cup} src={cup}></img>
                </div>
                <img className={styles.ball1} src={ball1}></img>
            </div>
            <div id="footballTeam" className={styles.organizer} style={{ backgroundImage: `url(${bg2})` }}>
                {/* <div className={`c_f fw700 tac ${styles.organizer_title}`}> Football Team Badges</div> */}
                <div className={`c_f fw700 tac ${styles.organizer_title}`}> </div>
                {/* <div className={`c_f fw700 tac ${styles.organizer_title}`}>Round of 16 qualified team badges</div> */}
                {/* <div className={`c_f tac`}>Semi-Final qualified team badges</div> */}
                <div className='df_center_h5'>
                    <div className={styles.topOne_txt}>2022 WORLD CUP CHAMPION TEAM BADGE</div>
                    <img className={styles.topOne_img} src={top16Argentina}></img>
                </div>
                {/* <Swiper className={`df_center tac ${styles.swiperList} `}
                    autoplay={{ autoplay: false }}
                    pagination={{ clickable: true, bulletClass: 'swiper-pagination-bullet' }}
                    spaceBetween={0}
                    slidesPerView={1}
                    onSwiper={(swiper) => {
                        swiper.$el[0].addEventListener('mouseover', () => swiper.autoplay.stop());
                    }}
                >
                    {
                        badgeList5.map(item => (
                            <SwiperSlide>
                                <div className={styles.swiperItem}>
                                    {
                                        item.map(ev => (
                                            <div className={styles.vs_bg} style={{ backgroundImage: `url(${top2Bg})` }}>
                                                {
                                                    ev.map(li => (
                                                        <img onClick={()=>{gotoSingleBadge(env.ENV==='development'?li.typeTest: li.type)}} className={`cp ${styles.slide_img}`} src={li.src}></img>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    
                                    }
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper> */}
                {/* <div style={{marginTop:'-70px',textAlign:'center'}}> */}
                    {/* <div onClick={gotoAllBadges} className={styles.clickallpage} style={{ backgroundImage: `url(${checkallbadges})` }}>&nbsp;</div> */}
                {/* </div> */}
            </div>
            <div className={styles.kbw_body} >
                <div className={styles.intro_main}  style={{ backgroundImage: `url(${bg3})` }}>
                    <div className={styles.intro_content}>
                        <div className={styles.how}>How to get </div>
                        <div className={styles.how}>DeFine Football Team Badge </div>
                        <div className={`df_h5 ${styles.intro}`}>
                            <div className={styles.step}>
                                <div className={styles.step_title}>Step1</div>
                                <div className={styles.step_intro}>Choose a Partner’s mission and complete it to start your Football journey!</div>
                                <div className={styles.step_time}>21 Nov 2022 - 25 Nov 2022</div>
                                <div><img className='cp' onClick={gotoMidum} src={step1}></img></div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.step_title}>Step2</div>
                                <div className={styles.step_intro}>Claim your Football Mystery Box! You can claim as many Mystery Boxes as the number of missions you have completed. </div>
                                <div className={styles.step_time}>28 Nov 2022 - 3 Dec 2022</div>
                                <div><img className='cp' src={step2} onClick={gotoBlindBox}></img></div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.step_title}>Step3</div>
                                <div className={styles.step_intro}>Enjoy the games! And during the game time, you can trade any Football Team badges to collect your supporting team or the team most likely to win. </div>
                                <div className={styles.step_time}>4 Dec 2022 - 20 Dec 2022</div>
                                <div><img className='cp' src={step3} onClick={()=>{scrollToAnchor('footballTeam')}}></img></div>
                            </div>
                            <div className={styles.step}>
                                <div className={styles.step_title}>Step4</div>
                                <div className={styles.step_intro}>Share the rewards! Badge holders will share a 50,000 DFA prize pool based on the Group stage and the Knockout stages results! </div>
                                <div className={styles.step_time}>Start from 4 Dec 2022 ~</div>
                                <div><img className='cp' src={step2} onClick={gotoClaimDFAPage}></img></div>
                            </div>
                        </div>
                    </div>
                    <img className={styles.ball2} src={ball2}></img>
                    <img className={styles.ball3} src={ball3}></img>
                </div>
            </div>
            <div className={styles.partner_footer} style={{ backgroundImage: `url(${bg4})` }}>
                <div className={styles.kbw_partner}>
                    <div className={styles.partner_title}>Campaign Missions</div>
                    <Row className={`mt60 ${styles.partnerList}`}>
                        {
                            partnerList.map(item => (
                                <Col xs={12} lg={6}>
                                    <Popover placement="topLeft" overlayClassName={'worldCup_popover'} 
                                    content={
                                        <div>
                                            <div>{item.content}</div>
                                            <a target='_blank' href={item.href}><img src={missionLink}></img></a>
                                        </div>
                                    } title={item.name} trigger="hover">
                                        <div className={styles.partner_box}  >
                                            <img className={styles.partner_img} src={item.simg} width={100}></img>
                                            <div className={`mt10 fw700`}>{item.name}</div>
                                        </div>
                                    </Popover>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        </div>
        </>
    )
}

