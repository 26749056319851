import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { useNeedSign } from "hooks/account"
import { unfollowArtist, followArtist } from "services/home"
import styles from "./styles.module.scss";

const FollowButton = ({ userId, followed, className, ...others }) => {
  const { t } = useTranslation();
  const [flag, setFlag] = useState(false)
  const { needSign } = useNeedSign();
  const [currentFollowed, setCurrentFollowed] = useState(followed)

  const handleClick = () => {
    needSign(() => {
      if (currentFollowed) {
        unfollowArtist(userId).then(() => {
          setCurrentFollowed(false)
        })
      } else {
        followArtist(userId).then(() => {
          setCurrentFollowed(true)
        })
      }
    })
  }

  const handleMouseEnter = () => {
    setFlag(true)
  }

  const handleMouseLeave = () => {
    setFlag(false)
  }
  useEffect(() => {
    setCurrentFollowed(followed)
  }, [followed])

  return (
    <div
      onClick={handleClick}
      className={`${styles.follow_btn} ${currentFollowed ? styles.followed : ''} ${className}`}
      {...others}
    >
      {currentFollowed ? ('- ' + t('following')) : ('+ ' + t('follow'))}
    </div>
  )
  // return (
  //   <Button
  //     onMouseEnter={handleMouseEnter}
  //     onMouseLeave={handleMouseLeave}
  //     onClick={handleClick}
  //     type={currentFollowed ? 'default' : 'primary'}
  //     className={`${styles.follow_btn} ${currentFollowed ? 'btn_border' : ''}`}
  //     {...others}
  //   >
  //     {currentFollowed ? t(flag ? 'unfollow' : 'following') : ('+ ' + t('follow'))}
  //   </Button>
  // )
}

export default FollowButton;