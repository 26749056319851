import React from "react";
import { Button, Modal, Input, Form,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { useActiveWeb3React } from "../../web3";
import { useActiveTronWeb } from "hooks/activeTronWeb";
import styles from "./styles.module.scss";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'left',
  preserve: false,
  colon: false
};
const { TextArea } = Input;

export const NftTransferModal = ({ visible, dataType, detailInfo, onCancel, onTransferFinish }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { library, account, active, chainId } = useActiveWeb3React()
  const { tronLibrary, tronAccount, tronChainId, tronActive } = useActiveTronWeb();


  const onFinishSend = (values) => {
    onTransferFinish(values)
  }

  return (
    <Modal
      title={'Transfer'}
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={450}
      className={styles.transfer_modal}
    >

      <Form
        className={styles.transfer_form}
        name="transfer-nft"
        onFinish={onFinishSend}
        requiredMark={false}
        form={form}
        {...layout}
      >
        <div className={`space-between`}>
          <div className={styles.transfer_imgBox}>
            {
              detailInfo.image && (!detailInfo.image.includes('.mp4') && !detailInfo.image.includes('.mov')) &&
              <img className={styles.nft_img} src={detailInfo.image}></img>
            }
            {
              detailInfo.image && (detailInfo.image.includes('.mp4') || detailInfo.image.includes('.mov')) &&
              <video  className={styles.nft_img} controls><source src={detailInfo.image} />Sorry, your browser doesn't support embedded videos.</video>
            }
          </div>
          <div className={`f1 pl10 ${detailInfo.nftType==="ERC1155"?styles.infoBox: styles.infoBox_one}`}>
            <Form.Item label={t('Name')} >
              <div className="ell fwb">{detailInfo.name}</div>
            </Form.Item>
            {
              detailInfo.nftType === 'ERC1155' &&
              <Form.Item
                label={t('quantity')}
                name="quantity"
                initialValue='1'
                rules={[
                  { required: true, message: t('please_enter_number_greater_than_0') },
                  { pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/, message: t('please_enter_number_greater_than_0') },
                  {
                    validator: (_, value) => {
                      if (dataType) {
                        return value <= detailInfo.ownedCountByCurrentUser ? Promise.resolve() : Promise.reject(new Error('Out of maximum'));
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input addonAfter={dataType ? `/${detailInfo.ownedCountByCurrentUser}` : `/1`} disabled={!dataType} />
              </Form.Item>
            }
          </div>
        </div>

        <Form.Item
          label={t('Address')}
          name="address"
          labelCol={{ span: 24 }}
          className={styles.transfer_address}
          rules={[
            { required: true, message: t('Please enter address') },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve()
                }
                if (account) {
                  if (value.toLowerCase() === account.toLocaleLowerCase()) {
                    return Promise.reject(new Error("Can't transfer to yourself"));
                  }
                  let reg = /^0x[A-Za-z0-9]{40}/
                  if (!reg.test(value)) {
                    return Promise.reject(new Error('Invalid address'));
                  } else {
                    return Promise.resolve()
                  }
                } else {
                  if (value.toLowerCase() === tronAccount.toLocaleLowerCase()) {
                    return Promise.reject(new Error("Can't transfer to yourself"));
                  }
                  let reg = /[A-Za-z0-9]{34}/
                  if (!reg.test(value)) {
                    return Promise.reject(new Error('Invalid address'));
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            }
          ]}
        >
          <TextArea rows={2} className="wp100" placeholder="0x..." />
        </Form.Item>

        <div className="mt20">
          <Button className="wp100 h50" type="primary" htmlType="submit">{t('Send')}</Button>
        </div>
      </Form>
    </Modal>
  );
};
