import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form, Row, Col, Steps, Upload, message, Button, Input, Select, DatePicker, Tabs, Radio, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import BigNumber from 'bignumber.js';

import { useActiveWeb3React } from "../../../web3";
import { getArtistInfo } from "../../../services/profile"
import { getGroups, addBadge, checkBadgeType, putBadgeTaskList, getBadgeDetail, getEventByProject, delTask, postImage, queryBaseGasFee } from '../../../services/badgeManage'
import { dataURLtoBlob } from '../../../utils/dataURLtoBlob';
import { erc20TokenList, getDecimalByAddress } from "../../../utils/tokenList";
import { getChainId } from '../../../web3/address';
import { chainTypeComImgObj, chainTxtObj } from '../../../utils/networkConnect';
import env from '../../../env';
import matic1 from "assets/img/chain/com_polygon.svg";
import eth1 from "assets/img/chain/com_eth.svg";
import bnb1 from "assets/img/chain/com_bnb.svg";
import iconUpload from "assets/img/creatBadge/icon_upload.svg"
import example from "assets/img/creatBadge/example.jpg"
import whiteListTemplate from "assets/Addresses_template.csv";
import styles from '../style.module.scss'

const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const selectObj = {
  production: [
    { name: 'Ethereum', icon: eth1, value: 'mainnet' },
    { name: 'BSC', icon: bnb1, value: 'bsc' },
    { name: 'Polygon', icon: matic1, value: 'polygon' },
  ],
  development: [
    { name: 'Ethereum', icon: eth1, value: 'goerli' },
    { name: 'BSC', icon: bnb1, value: 'bsctestnet' },
    { name: 'Polygon', icon: matic1, value: 'mumbai' },
  ]
}
const tokenObj = {
  mainnet: 'ETH',
  goerli: 'ETH',
  bsc: 'BNB',
  bsctestnet: 'BNB',
  polygon: 'MATIC',
  mumbai: 'MATIC',
  optimism:'ETH',
  optimisticgoerli:'ETH',
  arbitrum:'ETH',
  nitrogoerli:'ETH',
  zksyncera:'ETH',
  zksynceratest:'ETH',
  base:'ETH',
  basegoerli:'ETH',
  loot:'AGLD',
  loottest:'AGLD',
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export default function CreateBadge() {
  const { account } = useActiveWeb3React()
  const [formRef] = Form.useForm()
  const history = useHistory()
  const { eventId } = useParams()
  const [groups, setGroups] = useState([])
  const [stepBox, setStepBox] = useState(1);
  const [imageUrl, setImageUrl] = useState();
  const [whiteList, setWhiteList] = useState();
  const [whiteListName, setWhiteListName] = useState();
  const [eligibilityType, setEligibilityType] = useState('all');
  const [taskList, setTaskList] = useState([]);
  const [createBadgeInfo, setCreateBadgeInfo] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [taskDescription, setTaskDescription] = useState();
  const [onlyBadgeType, setOnlyBadgeType] = useState();
  const [isEdit, setIsEdit] = useState(false)
  const [eventInfo, setEventInfo] = useState({})
  const [tokenBalanceIcon, setTokenBalanceIcon] = useState()
  const [gasFeeIcon, setGasFeeIcon] = useState()
  const [checkAutoBadgeType, setCheckAutoBadgeType] = useState(false);
  const location = useLocation();

  const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const [offChain, setOffChain] = useState([
    { uniqueId: guid(), sortType: "Off-Chain", type: 'follow_on_twitter', event: 'twitter', desc: 'Follow on Twitter', keyName: 'Username', frequency: 'more', nameColor: 'blue', placeholder: '@username', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'retweet', event: 'twitter', desc: 'Retweet on Twitter', keyName: 'Tweet URL', frequency: 'more', nameColor: 'blue', placeholder: 'Enter a Tweet URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'like_tweet', event: 'twitter', desc: 'Like a Tweet on Twitter', keyName: 'Tweet URL', frequency: 'more', nameColor: 'blue', placeholder: 'Enter a tweet URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'tweet', event: 'twitter', desc: 'Tweet on Twitter', keyName: 'Tweet', frequency: 'more', nameColor: 'blue', placeholder: ' Input template, can contain #tag or URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'quote_tweet', event: 'twitter', desc: 'Quote Tweet on Twitter', frequency: 'more', nameColor: 'blue', placeholder: '', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'join_discord', event: 'discord', desc: 'Join a Discord server', keyName: 'Tweet URL', frequency: 'more', nameColor: 'blue2', placeholder: 'Enter a Discord server URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'join_telegram', event: 'telegram', desc: 'Join a Telegram channel', frequency: 'more', nameColor: 'blue', placeholder: '@username', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'visit_website', event: 'website', desc: 'Visit a website', keyName: 'Website URL', frequency: 'more', nameColor: 'blue', placeholder: 'Enter the URL', disabled: false },
  ])
  const [onChain, setOnChain] = useState([
    { uniqueId: guid(), sortType: "On-Chain", type: 'token_balance', event: 'wallet', desc: 'Verify token balance', frequency: 'more', nameColor: 'blue', disabled: false },
    // { uniqueId: guid(), sortType: "On-Chain", type: 'total_gas_fee', event: 'wallet', desc: 'Total amount of gas fees spent', frequency: 'more', nameColor: 'blue', disabled: false },
    { uniqueId: guid(), sortType: "On-Chain", type: 'transaction_count', event: 'wallet', desc: 'Number of Transactions', frequency: 'more', nameColor: 'blue', disabled: false },
  ])
  const [customMade, setCustomMade] = useState([
    // { uniqueId: guid(), sortType: "Custom-Made", type: 'course', event: 'course', desc: 'Graphic course', frequency: 'more', nameColor: 'blue', disabled: false },
  ])
  const [oneGasFee,setOneGasFee] = useState()
  const quillRef = useRef()
  const handleImageUpload = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async function () {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);
      const response = await postImage(formData);

      const imageUrl = response;
      const quillEditor = quillRef.current.getEditor();
      const range = quillRef.current.getEditor().getSelection();
      // const value = prompt('');
      const value = '';
      quillEditor.insertEmbed(range.index, 'image', `${imageUrl}?alt='${value}'`);
      quillEditor.setSelection(range.index + 1);//
    };
  }
  const modules = useMemo(() => ({
    toolbar: {
      handlers: { image: handleImageUpload, },
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
        ["clean"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
  }), []);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
  ];

  const goBack = () => {
    history.goBack()
  }

  const nextStep = (step) => {
    let reg = /^[a-zA-Z0-9-]{6,20}$/
    if (!reg.test(checkAutoBadgeType)) {
      setOnlyBadgeType(false)
      return message.error('The badge url name must be longer than 6 characters and shorter than 24 characters, and can only contain letters, numbers, and ‘-’')
    }
    setStepBox(step)
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
    }
  }
  const blurName = (e) => {
    let str = e.target.value
    if(str){
      let defaultType = (str.replace(/[^a-zA-Z0-9-\s]+/g, '') + ' ' + generateMixed(4)).replace(/\s+/g, '-').substring(0, 20)
      formRef.setFieldsValue({ badgeType: defaultType })
      setCheckAutoBadgeType(defaultType)
      setOnlyBadgeType(true)
    }
  }
  const generateMixed = (n) => {
    let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'a', 'B', 'b', 'C', 'c', 'D', 'd', 'E', 'e', 'F', 'f', 'G', 'g', 'H', 'h', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'O', 'o', 'P', 'p', 'Q', 'q', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'V', 'v', 'W', 'w', 'X', 'x', 'Y', 'y', 'Z', 'z'];
    let res = "";
    for (var i = 0; i < n; i++) {
      var id = Math.ceil(Math.random() * 61);
      res += chars[id];
    }
    return res;
  }

  const handleChangeAddress = (info) => {
    const isCsv = info.file.type === 'text/csv';
    if (!isCsv) {
      message.error('You can only upload csv file!');
      return isCsv
    }
    setWhiteListName(info.file.name)
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setWhiteList(url);
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setWhiteList(url);
      });
    }
  }
  const handleSelectEligibility = (value) => {
    setEligibilityType(value)
  }
  const addEventList = (item) => {
    let arr = []
    arr.push(item)
    setTaskList(oldList => [...oldList, ...arr])
  }

  const onFinish = async (values) => {
    if (confirmLoading) { return false }
    if (!onlyBadgeType && !isEdit) {
      return message.error("Please check badge url name")
    }
    if (!imageUrl) {
      return message.error("Badge image is required")
    }
    if (!values.name) {
      return message.error("Badge name is required")
    }
    if (!values.group) {
      return message.error("Series is required")
    }
    if (!values.startTime) {
      return message.error("StartTime is required")
    }
    if (!values.endTime) {
      return message.error("EndTime is required")
    }
    if (!values.maxCount) {
      return message.error("Number of badge introduction is required")
    }
    if (!values.utility) {
      return message.error("Badge utility is required")
    }
    if (!values.description) {
      return message.error("Badge introduction is required")
    }
    if (!taskDescription) {
      return message.error("Task Description is required")
    }
    if (values.eligibility === 'whitelist' && !whiteList) {
      return message.error('Please upload address')
    }
    if (values.eligibility === 'task' && taskList.length === 0) {
      return message.error('Task cannot be empty')
    }
    console.log(values);
    if(!values.applyPageDescription && eventInfo.type === 'airdrop' && values.eligibility === 'all') {
      return message.error("Description of the badge receive page is required")
    }
    setConfirmLoading(true);
    let formData = new FormData();
    let reg = /https:\/\//
    if (imageUrl && !reg.test(imageUrl)) {
      formData.append('image', dataURLtoBlob(imageUrl))
    }
    if (whiteList && !reg.test(whiteList)) {
      formData.append('whitelist', dataURLtoBlob(whiteList))
    }
    // console.log(values);
    values.name && formData.append('name', values.name)
    values.badgeType && formData.append('badgeType', values.badgeType)
    values.description && formData.append('description', values.description)
    values.group && formData.append('group', values.group)
    values.startTime && formData.append('startTime', new Date(values.startTime).getTime())
    values.endTime && formData.append('endTime', new Date(values.endTime).getTime())
    values.utility && formData.append('utility', values.utility)
    values.maxCount && formData.append('maxCount', values.maxCount)
    values.eligibility && formData.append('eligibility', values.eligibility)
    values.applyPageDescription && formData.append('applyPageDescription', values.applyPageDescription)
    // taskDescription && formData.append('taskDescription', taskDescription)

    if (isEdit) {
      formData.append('originalBadgeType', createBadgeInfo.originalBadgeType)
    }
    const taskArr = JSON.parse(JSON.stringify(taskList))
    if (values.eligibility === 'task') {
      try {
        taskArr.map(item => {
          if (item.type === 'follow_on_twitter' && !item.twitterId) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'retweet' && !item.url) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'tweet' && !item.content) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'like_tweet' && !item.url) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'quote_tweet' && (!item.url || !item.content)) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'join_discord' && !item.url) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'join_telegram' && !item.url) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'visit_website' && !item.url) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'token_balance' &&(!item.tokenAddress || !item.chainType || !item.balance) ) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'transaction_count' && (!item.chainType || !item.count)) {
            return message.error('Task cannot be empty')
          }
          if (item.type === 'token_balance') {
            if (!item.tokenAddress) {
              item.tokenAddress = item.token.address
            }
            item.balance = dealBigNum(item.balance, getDecimalByAddress(item.tokenAddress, erc20TokenList))
          }
          if (item.type === 'course' && (!item.title || !item.content || item.content==='<p><br></p>') ) {
            message.error('Task cannot be empty')
            throw Error();
          }
        })
      } catch (error) {
        setConfirmLoading(false)
        return false
      }
    }


    addBadge(eventId, formData).then(res => {
      message.success("Success")
      if (res?.project) {

        putBadgeTaskList(eventId, values.badgeType, values.eligibility === 'task' ? taskArr : [], taskDescription).then(r => {
          history.push(`/badgeManage/eventDetail/${res.project}`)
        }).catch(error => {
          setConfirmLoading(false);
          message.error("Failed to submit task list. Please edit and add again");
        })
      } else {
        history.push(`/badgeManage/eventDetail/${res.project}`)
      }
    }).finally(() => {
      setConfirmLoading(false);
    })
  };

  const removeEventList = async (item, index) => {
    if (item.id) {
      let res = await delTask(eventId, item.id)
    }
    let arr = [...taskList]
    arr.splice(index, 1)
    setTaskList(arr)
    if (item.frequency === 'one') {
      if (item.sortType === 'Off-Chain') {
        let arr = offChain
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = false
          }
        })
        setOffChain(arr)
      } else if (item.sortType === 'On-Chain') {
        let arr = onChain
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = false
          }
        })
        setOnChain(arr)
      }
      else if (item.sortType === 'Custom-Made') {
        let arr = customMade
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = false
          }
        })
        setCustomMade(arr)
      }
    }
  }
  const queryGroups = async () => {
    let res = await getGroups(eventId)
    setGroups(res)
  }
  const checkOnlyBadgeType = async (e) => {
    let val = e.target.value
    setCheckAutoBadgeType(val)
    if (val) {
      let reg = /^[a-zA-Z0-9-]{6,20}$/
      if (!reg.test(val)) {
        setOnlyBadgeType(false)
        return message.error('The badge url name must be longer than 6 characters and shorter than 24 characters, and can only contain letters, numbers, and ‘-’')
      }
      let res = await checkBadgeType(val)
      if (res === 'invalid') {
        setOnlyBadgeType(false)
        return message.error('That badge url name has been taken. Please choose another.')
      }
      setOnlyBadgeType(true)
    }
  }
  const dealBigNum = (value, decimals) => {
    const val = new BigNumber(value)
    return val.multipliedBy(new BigNumber(10).pow(decimals)).toString()
  }
  const reductionBigNum = (value, decimals) => {
    const val = new BigNumber(value)
    return val.div(new BigNumber(10).pow(decimals)).toString()
  }
  const taskInputChange = (e, item, index, value) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        if (item.type === 'follow_on_twitter') {
          item.twitterId = e.target.value
        } else if (item.type === 'retweet') {
          item.url = e.target.value
        } else if (item.type === 'tweet') {
          item.content = e.target.value
        } else if (item.type === 'like_tweet') {
          item.url = e.target.value
        } else if (item.type === 'quote_tweet') {
          item.url = e.target.value
        } else if (item.type === 'join_discord') {
          item.url = e.target.value
        } else if (item.type === 'join_telegram') {
          item.url = e.target.value
        } else if (item.type === 'visit_website') {
          item.url = e.target.value
        } else if (item.type === 'token_balance') {
          if (value === "tokenAddress") {
            item.tokenAddress = e.target.value
          } else {
            item.balance = e.target.value
          }
        } else if (item.type === 'total_gas_fee') {
          item.amount = e.target.value
        } else if (item.type === 'transaction_count') {
          let reg = /^[1-9]\d*$/
          if(e.target.value){
            if(reg.test(e.target.value)) {
              item.count = e.target.value
            }else {
              message.error('Please enter a positive integer')
            }
          }
        } else if (item.type === 'course') {
          item.title = e.target.value
        }
      }
    })
    setTaskList(arr)
  }
  const taskTextareaChange = (e, item, index) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        item.content = e.target.value

      }
    })
    setTaskList(arr)
  }
  const taskSelectChange = (e, item, index, value) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        if (value === 'tokenAddress') {
          item.tokenAddress = e
        } else {
          item.chainType = e
        }
        if (item.type === 'token_balance') {
          setTokenBalanceIcon(e)
        }
        if (item.type === 'total_gas_fee') {
          setGasFeeIcon(e)
        }
      }
    })
    setTaskList(arr)
  }
  const taskRediaChange = (e, item, index) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        item.channelType = e.target.value
      }
    })
    setTaskList(arr)
  }
  const changeQuill = (e, index) => {
    // console.log(e);
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        item.content = e
      }
    })
    setTaskList(arr)
    // console.log(arr);
  }
  const onChangeTaskDes = (e) => {
    setTaskDescription(e.target.value)
  }
  const initData = () => {
    let content = location.search
    if (content.indexOf("=") > 0) {
      let badgeType = decodeURIComponent(content.substring(11))
      setIsEdit(true)
      getBadgeInfo(badgeType)
    } else {
      setCreateBadgeInfo({ eligibility: 'all' })
    }
  }
  const getEventInfo = async () => {
    let res = await getEventByProject(eventId)
    if(res.creator != account) {
      history.push('/badgeManage')
      return false
    }
    setEventInfo(res)
    getGasFeeBase(res.chainType)
  }
  const getBadgeInfo = async (badgeType) => {
    let res = await getBadgeDetail(badgeType)
    setImageUrl(res.image)
    let obj = {
      originalBadgeType: res.type,
      badgeType: res.type,
      startTime: moment(res.claimStartTime),
      endTime: moment(res.claimEndTime),
    }
    setCheckAutoBadgeType(res.type)
    setCreateBadgeInfo({ ...res, ...obj })
    setEligibilityType(res.eligibility)
    setTaskDescription(res.taskDescription)
    setWhiteListName(res.whitelistFile)
    setWhiteList(res.whitelistFile)
    if (res.hasTask) {
      res.tasks.map(item => {
        if (item.type === 'token_balance') {
          item.balance = reductionBigNum(item.balance, getDecimalByAddress(item.token.address, erc20TokenList))
        }
      })
      setTaskList(res.tasks)
    }
    getGasFeeBase(res.chainType).then(baseGasFee => {
      countGaeFee(res.maxCount,baseGasFee)
    })
  }
  const getGasFeeBase = async (chainType) => {
    let res = await queryBaseGasFee(chainType)
    setOneGasFee(res.gasFee)
    return res.gasFee
  }
  const getMaxCount = (e) => {
    countGaeFee(e.target.value,oneGasFee)
  }
  const countGaeFee = (maxCount,oneGasFee) => {
    let gasFees = new BigNumber(maxCount * oneGasFee).div(new BigNumber(10).pow(18)).toFixed()
    formRef.setFieldsValue({ gasFee: gasFees })
  }

  const disabledDate = current => {
    return current < moment(eventInfo.eventStartTime) || current > moment(eventInfo.eventEndTime)
  };
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  useEffect(() => {
    queryGroups()
    getEventInfo()
    initData()
  }, [])
  useEffect(() => {
     if(groups && eventInfo){ 
       if(eventInfo.type === 'airdrop'){
        formRef.setFieldsValue({group: groups[0]})
       }
     }
  },[groups,eventInfo])

  useEffect(() => {
    account && getArtistInfo(account, account).then(res => {
      if (res.privileges) {
        if (res.privileges.includes('course')) {
          let arr = []
          let obj = { uniqueId: guid(), sortType: "Custom-Made", type: 'course', event: 'course', desc: 'Graphic course', frequency: 'more', nameColor: 'blue', disabled: false }
          arr.push(obj)
          setCustomMade(arr)
        }
      }
    })
  }, [account])

  return (
    <div className={styles.createBadge}>
      <div className='space-between-center'>
        <div className={`fs36 fw600`}>{`${isEdit ? 'Edit Badge' : 'Create Badge'} `}</div>
        <Steps className={styles.steps} current={stepBox - 1}>
          <Step />
          <Step />
        </Steps>
      </div>
      {
        createBadgeInfo ?
          <Form form={formRef} layout={'vertical'} onFinish={onFinish}>
            <div className={`${styles.step_box} ${stepBox === 1 ? styles.step_box_show : styles.step_box_hidden}`}>
              <div className={styles.box_header}>1.Information</div>
              <div className={styles.box_content}>
                <div className={styles.eventInfo_list}>
                  <div className={styles.eventInfo_item}>
                    <div>Current event</div>
                    <div className={`fw600 w200 ${styles.item_content}`}>{eventInfo.name}</div>
                  </div>
                  <div className={`${styles.eventInfo_item} ${styles.line_left}`}>
                    <div>Event type</div>
                    <div className={`fw600 ${styles.item_content}`}>{eventInfo.type === 'general' ? 'General' : 'Airdrop'}</div>
                  </div>
                  <div className={`${styles.eventInfo_item} ${styles.line_left}`}>
                    <div>Blockchain</div>
                    <div className={`fw600 ${styles.item_content}`}>
                      <img className='pr6' src={chainTypeComImgObj[eventInfo.chainType]} />
                      {chainTxtObj[eventInfo.chainType]}</div>
                  </div>
                  <div className={`${styles.eventInfo_item} ${styles.line_left}`}>
                    <div>Event time</div>
                    <div className={`fw600 ${styles.item_content}`}>{moment(eventInfo.eventStartTime).format("YYYY-MM-DD")} ~ {moment(eventInfo.eventEndTime).format("YYYY-MM-DD")}</div>
                  </div>
                </div>
                <hr style={{ border: '1px dashed #DEDEDE', margin: '20px 0' }} />
                <Row gutter={36}>
                  <Col span={11}>
                    <div className='space-between'>
                      <div>
                        <div>Upload badge image</div>
                        <div className={styles.text_tip}>PNG, GIF, WEBP. Max 2M.</div>
                      </div>
                      <Upload className='mt10' customRequest={() => { }} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}><Button className={`btn_border w120 h40`}>Upload Image</Button></Upload>
                    </div>
                    <div className={styles.cover_preview}>
                      <div className='c_9a'>Preview</div>
                      <div className={styles.cover}>
                        <img src={imageUrl}></img>
                      </div>
                    </div>
                    <div>Claiming Time</div>
                    <div className={`pb6 ${styles.text_tip}`}>The NFT claim time is inconsistent with the event time</div>
                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item label={''} name={"startTime"} initialValue={createBadgeInfo.startTime}>
                          <DatePicker placeholder='Start Time' format={'YYYY-MM-DD HH:mm:ss'}
                          disabledDate={disabledDate}
                          showTime={{
                            format: 'HH:mm:ss',
                            defaultValue: moment('00', 'ss'),
                            hideDisabledOptions: true
                          }}></DatePicker>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={''} name={"endTime"} initialValue={createBadgeInfo.endTime}>
                          <DatePicker placeholder='End Time' format={'YYYY-MM-DD HH:mm:ss'}
                          disabledDate={disabledDate}
                          showTime={{
                            format: 'HH:mm:ss',
                            defaultValue: moment('00', 'ss'),
                            hideDisabledOptions: true
                          }}></DatePicker>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={13}>
                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item label={'Event Series'} name={"group"} initialValue={createBadgeInfo.group}>
                          <Select>
                            {
                              groups.map(item => (
                                <Option key={item} value={item}>{item}</Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={'Badge Name'} name={"name"} initialValue={createBadgeInfo.name}>
                          <Input onBlur={blurName}></Input>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item label={'Number of badge'} name={"maxCount"} initialValue={createBadgeInfo.maxCount}>
                          <Input type='number' onChange={getMaxCount}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={'Estimated Gas Fee'} name={"gasFee"} initialValue={createBadgeInfo.gasFee}>
                          <Input disabled suffix={tokenObj[eventInfo.chainType]}></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label={'Badge Description'} name={"description"} initialValue={createBadgeInfo.description}>
                      <TextArea></TextArea>
                    </Form.Item>
                    <Form.Item label={'Badge Utility'} name={"utility"} initialValue={createBadgeInfo.utility}>
                      <TextArea></TextArea>
                    </Form.Item>
                    <Form.Item label={'Badge URL Name'} >
                      <div className={styles.text_tip}>The badge type must be longer than 6 characters and shorter than 20 characters, and can only contain letters, numbers, and '-'</div>
                      <Form.Item name={"badgeType"} initialValue={createBadgeInfo.badgeType}>
                        <Input onBlur={(e) => { checkOnlyBadgeType(e) }}></Input>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <hr style={{ border: '1px dashed #DEDEDE', width: '1200px', margin: 'auto' }}></hr>
              <div className='space-between-center p30'>
                <Button className={`w200 h50 btn_border`} onClick={goBack}>Back</Button>
                <Button type="primary" className={`w200 h50`} onClick={() => { nextStep(2) }}>Next</Button>
              </div>
            </div>
            <div className={`${styles.step_box} ${stepBox === 2 ? styles.step_box_show : styles.step_box_hidden}`}>
              <div className={styles.box_header}>2.Set event Eligibility</div>
              <div className={`${styles.box_content}`}>
                <Form.Item name="eligibility" initialValue={createBadgeInfo.eligibility} label={'Please set the eligibility to claim'}>
                  <Select defaultValue={'all'} className={styles.select_eligibility} onChange={handleSelectEligibility}>
                    <Option value="all">All Users</Option>
                    {
                      eventInfo.type === 'general' &&
                    <Option value="whitelist">Upload Addresses</Option>
                    }
                    <Option value="task">Choose Tasks</Option>
                  </Select>
                </Form.Item>
                {
                  eligibilityType === 'all' && eventInfo.type === 'general' &&
                  <div className={styles.allUser}>
                    <div className='c_9a fs16'>Anyone can participate in this event.</div>
                  </div>
                }
                {
                  eligibilityType === 'all' && eventInfo.type === 'airdrop' &&
                  <Row gutter={32}>
                    <Col span={12}>
                      <div className={''}>
                        {/* <div className='fs18'>Generate a receive page</div> */}
                        {/* <div className={styles.text_tip}>Enter the address to receive badges, generate a QR code and use your mobile phone to scan the code to receive badges</div> */}
                        <Form.Item label={'Description of the badge receiving page'} name='applyPageDescription' initialValue={createBadgeInfo.applyPageDescription}>
                          <TextArea rows={22}></TextArea>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className='mb10'>Preview of badge receiving page</div>
                      <img width={580} src={example}></img>
                    </Col>
                  </Row>
                }
                {
                  eligibilityType === 'whitelist' &&
                  <div className={styles.allUser}>
                    {
                      whiteListName ?
                        <div className='c_9a'>{whiteListName}</div> :
                        <img src={iconUpload}></img>
                    }
                    <Upload headers={{ authorization: 'authorization-text' }} className='mt10' customRequest={() => { }} showUploadList={false} onChange={handleChangeAddress}><Button className={`btn_border w200 h40`}>Upload address</Button></Upload>
                    <p className='mt10'>Download this <a download className={styles.download_temp} href={whiteListTemplate}>reference template</a>.</p>
                  </div>
                }
                {
                  eligibilityType === 'task' &&
                  <div className={styles.filter_box}>
                    <Tabs defaultActiveKey="2" type='card'>
                      <TabPane key='1' disabled={true} tab="Choose a template"></TabPane>
                      <TabPane key='2' tab="All">
                        <div className={styles.add_list}>
                          {
                            offChain.concat(onChain).concat(customMade).map(item => (
                              <div className={styles.add_item}>
                                <div>{item.desc}</div>
                                <div className={styles.add_btn} onClick={() => { addEventList(item) }}>+</div>
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                      <TabPane key='3' tab="Off-Chain">
                        <div className={styles.add_list}>
                          {
                            offChain.map(item => (
                              <div key={item.uniqueId} className={styles.add_item}>
                                <div>{item.desc}</div>
                                <div className={styles.add_btn} onClick={() => { addEventList(item) }}>+</div>
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                      <TabPane key='4' tab="On-Chain">
                        <div className={styles.add_list}>
                          {
                            onChain.map(item => (
                              <div key={item.uniqueId} className={styles.add_item}>
                                <div>{item.desc}</div>
                                <div className={styles.add_btn} onClick={() => { addEventList(item) }}>+</div>
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                      <TabPane key='5' tab="Custom-Made">
                        <div className={styles.add_list}>
                          {
                            customMade.map(item => (
                              <div key={item.uniqueId} className={styles.add_item}>
                                <div>{item.desc}</div>
                                <div className={styles.add_btn} onClick={() => { addEventList(item) }}>+</div>
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                    </Tabs>
                    <div className='mt10'>
                      {
                        taskList.map((item, index) => (
                          <>
                            {
                              item.type === 'follow_on_twitter' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Follow on Twitter</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Username</div>
                                    <div className='f1 pt10 pb10'><Input prefix={'@'} defaultValue={item.twitterId} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'retweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Retweet a Tweet</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Tweet URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }

                            {
                              item.type === 'like_tweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Like a Tweet on Twitter</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Tweet URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'join_discord' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue2']}`}>Join a Discord server</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Discord URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'tweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Tweet on Twitter</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Tweet</div>
                                    <div className={`f1 pt10 pb10`}><TextArea defaultValue={item.content} onChange={(e) => { taskTextareaChange(e, item, index) }} placeholder='Input template, can contain #tag or URL'></TextArea></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'quote_tweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Quote Tweet on Twitter</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Quote Tweet</div>
                                      <div className={`f1 pt10 pb10`}><TextArea defaultValue={item.content} onChange={(e) => { taskTextareaChange(e, item, index) }} placeholder='Input template, can contain #tag or URL'></TextArea></div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Tweet URL</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter a tweet URL'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'visit_website' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Visit a website</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Website URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'join_telegram' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Join a Telegram channel</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Channel link</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter a channel URL'></Input></div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Channel type</div>
                                      <div className={`f1 pt10 pb10`}><Radio.Group defaultValue={item.channelType ? item.channelType : 'public'} buttonStyle="solid" onChange={(e) => { taskRediaChange(e, item, index) }}>
                                        <Radio.Button value="public">Public</Radio.Button>
                                        <Radio.Button value="private">Private</Radio.Button>
                                      </Radio.Group></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'token_balance' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Verify token balance</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Select a network</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.chainType} onChange={(e) => { taskSelectChange(e, item, index) }}>
                                          {
                                            selectObj[env.ENV].map(item => (
                                              <Option value={item.value}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Token contract address</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.token?.address} onChange={(e) => { taskSelectChange(e, item, index, 'tokenAddress') }}>
                                          {
                                            erc20TokenList.filter(el => el.chainIds.includes(getChainId(item.chainType))).map(item => (
                                              <Option key={item.address} value={item.address}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                      {/* <div className={`f1 pt10 pb10`}><Input defaultValue={item.token?.address} onChange={(e) => { taskInputChange(e, item, index, 'tokenAddress') }} placeholder='Enter the token contract address'></Input></div> */}
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Token balance</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.balance} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter amount'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'total_gas_fee' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Total amount of gas fees spent</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Select a network</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.chainType} onChange={(e) => { taskSelectChange(e, item, index) }}>
                                          {
                                            selectObj[env.ENV].map(item => (
                                              <Option value={item.value}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Gas fees spend value</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.amount} suffix={gasFeeIcon ? tokenObj[gasFeeIcon] : item.chainType ? tokenObj[item.chainType] : ''} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter value [ETH, BNB, MATIC]'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'transaction_count' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Number of Transactions</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Select a network</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.chainType} onChange={(e) => { taskSelectChange(e, item, index) }}>
                                          {
                                            selectObj[env.ENV].map(item => (
                                              <Option value={item.value}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Number of Transactions</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.count} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter value'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'course' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Graphic course</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Title</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Input defaultValue={item.title} onChange={(e) => { taskInputChange(e, item, index) }} />
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Content</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <ReactQuill ref={quillRef} value={item.content} modules={modules}
                                          onChange={(e) => { changeQuill(e, index) }}
                                          formats={formats}
                                        >
                                        </ReactQuill>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                          </>
                        ))
                      }
                    </div>
                  </div>
                }
                <Form.Item className='mt20' label={'Task description'}>
                  <TextArea value={taskDescription} onChange={(e) => { onChangeTaskDes(e) }}></TextArea>
                </Form.Item>
              </div>
              <hr style={{ border: '1px dashed #DEDEDE', width: '1200px', margin: 'auto' }}></hr>
              <div className='space-between-center p30'>
                <Button className={`btn_border w200 h50`} onClick={() => { nextStep(1) }}>Prev</Button>
                <Button type="primary" className={`w200 h50 ${styles.create_btn}`} htmlType="submit">Save {confirmLoading && <LoadingOutlined />}</Button>
              </div>
            </div>
          </Form> :
          <div className={`tac mt50`}>
            <Spin size={`large`}></Spin>
          </div>
      }
    </div>
  )
}
