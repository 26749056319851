import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Col, Form, Input, Row, Select, Upload, message, Spin } from 'antd'
import { InfoCircleFilled,LoadingOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { getChainType } from "../../../web3/address";
import { saveDraft, getDraftList, deleteAllDraft, draftBindNft, deleteDraft, queryCollectionList, unbindNftTokenIds } from 'services/create'
import { getNFT721 } from 'utils/handleContract'
import { chainFun, chainTxtObj, chainTypeComImgObj } from '../../../utils/networkConnect';
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { useActiveWeb3React } from "../../../web3";
import { mainContext } from "../../../reducer";
import { dataURLtoBlob } from '../../../utils/dataURLtoBlob';
import { useNeedSign } from "hooks/account"
import DraftsCard from 'components/DraftCard';
import NoData from "components/NoData";
import arrowIcon from 'assets/img/draft/arrow_l.png';
import delBIcon from 'assets/img/draft/del_b.png';
import styles from '../styles.module.scss';

import {
  HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
  HANDLE_SHOW_TRANSACTION_MODAL,
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  HANDLE_SHOW_CONNECT_MODAL,
  waitingForConfirm,
  waitingForInit,
  waitingPending
} from "../../../const";

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default function CreateNft() {
  const history = useHistory();
  const location = useLocation();
  const { library, account, active, chainId } = useActiveWeb3React()
  const { tronLibrary, tronAccount, tronChainId, tronActive } = useActiveTronWeb();
  const { state, dispatch } = useContext(mainContext);
  const [formRef] = Form.useForm()
  const [preview, setPreview] = useState();
  const [image, setImage] = useState();
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [createForm, setCreateForm] = useState();
  const [draftList, setDraftList] = useState([])
  const [collectionList, setCollectionList] = useState([])
  const [currentCollection, setCurrentCollection] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  // const { list, total } = useUnbindNftList((account || tronAccount), getNFT721Address(chainId || tronChainId))
  const { needSign } = useNeedSign();


  const gotoCreateCollection = () => {
    history.push('/collectionManage/createCollection')
  }
  const onFinish = (values) => {
    needSign(() => {
      setIsPublish(true)
      uploadDraftData('ERC721', true)
    })
  }
  const beforeCrop = (file) => {
    const isVideo = file.type === 'video/mp4' || file.type === 'video/quicktime' || file.tyoe === 'image/gif'
    getBase64(file, url => {
      setImage(url);
      formRef.setFieldsValue({ image: url });
    })
    document.getElementById("videoBox").innerHTML = '';
    // setImage(URL.createObjectURL(file))
    if (isVideo) {
      setShowVideo(true);
      setShowImage(false);
      setPreview(undefined)
      if (file.type === 'image/gif') {
        if (window.FileReader) {
          const fr = new FileReader();
          fr.onloadend = function (e) {
            const img = document.createElement("img");
            img.src = e.target.result;
            img.width = 305;
            img.height = 305;
            document.getElementById("videoBox").appendChild(img);
            setPreview(e.target.result)
          };
          fr.readAsDataURL(file);
        }
      }
      if (file.type === 'video/mp4' || file.type === "video/quicktime") {
        if (window.FileReader) {
          const fr = new FileReader();
          fr.onloadend = function (e) {
            const video = document.createElement("video");
            video.controls = "controls";
            video.src = e.target.result;
            video.width = 305;
            video.height = 305;
            document.getElementById("videoBox").appendChild(video);
            setPreview(e.target.result)
          };
          fr.readAsDataURL(file);
        }
      }
      return false;
    }
    setShowVideo(false);
    setShowImage(true);
    return true
  }

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      getBase64(file.url, url => {
        setImage(url);
      })
    }
  };

  const handleUploadChange = info => {
    console.log(info);
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setPreview(url);
        setImage(url);
        setShowImage(true)
      });
      return;
    }
  };
  const changeCollection = (val) => {
    let item = collectionList.find((item) => item.contractAddress === val)
    setCurrentCollection(item)
  }
  const getDraftListFun = () => {
    getDraftList(account || tronAccount, 1, 100).then(res => {
      setDraftList(res.tokenDrafts)
      initData(res.tokenDrafts)
    })
  }

  const handleDeleteDraft = (id) => {
    deleteDraft(id).then(res => {
      getDraftListFun()
      message.success('delete success')
    })
  }
  const handleDeleteAllDraft = async () => {
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { show: true, title: 'Deleting your Drafts' }
    });

    deleteAllDraft().then(res => {
      if (res === 'success') {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: false
        });
        message.success('Delete Success')
        getDraftListFun();
      } else {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: false
        });
        message.error('Failed in deleting draft')
      }
    })
  }
  const goToEdit = (item) => {
    history.push(`/collectionManage/createNft?draftId=${item.id}`)
    window.location.reload();
  }
  const uploadDraftData = async (tokenType, isDirect) => {
    if (confirmLoading) { return false }
    const values = await formRef.validateFields();
    if (!image) {
      return message.error('Please upload image or video.')
    }
    if (!values.name) {
      return message.error("Item name is required")
    }
    if (!values.description) {
      return message.error("Item description is required")
    }
    if (!values.collection) {
      return message.error("Collection is required")
    }
    showWalletConfirmModal({ show: true, title: 'Saving your Draft' })
    setConfirmLoading(true);
    var formData = new FormData();
    formData.append('chainType', currentCollection.chainType)
    formData.append('tokenType', tokenType)
    formData.append('tokenAddress', currentCollection.contractAddress)
    formData.append('name', values.name)
    formData.append('artist', (account || tronAccount))
    values.description && formData.append('description', values.description)
    let reg = /https:\/\//
    if (image && !reg.test(image)) {
      formData.append('image', dataURLtoBlob(image))
      formData.append('preview', dataURLtoBlob(image))
      values.image = dataURLtoBlob(image)
    }
    if (isEdit) {
      formData.append('draftId', createForm.draftId)
    }
    if (isDirect) {
      values.tokenAddress = currentCollection.contractAddress
      window.localStorage.setItem('nftInfo', JSON.stringify(values))
      if (!beforeDeploy(currentCollection.chainType)) return false
    }

    saveDraft(formData).then(res => {
      setConfirmLoading(false);
      window.localStorage.removeItem('nftInfo')
      closeWalletConfirmModal()
      if (!isDirect) {
        message.success('success')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
        return
      }
      if (res.id) {
        showTxConfirmModal()
        createNftByApi(currentCollection.contractAddress, res.id)
      }
    })
  }
  const beforeDeploy = (targetChainType) => {
    let chainType = getChainType(chainId)
    if (targetChainType !== chainType) {
      message.error(`please switch to ${chainTxtObj[targetChainType]}`)
      chainFun[chainTxtObj[targetChainType]] && chainFun[chainTxtObj[targetChainType]]()
      return false
    }
    return true
  }
  const createNftByApi = async (tokenAddress, draftId) => {
    let item = collectionList.find((item) => item.contractAddress === tokenAddress)
    setCurrentCollection(item)
    showWalletConfirmModal()
    let res = await unbindNftTokenIds(account || tronAccount, tokenAddress)
    const list = res.tokens
    if (list.length > 0 && draftId) {
      queryConfirmDraft(tokenAddress, list[0].tokenId, 'ERC721', '', list[0].count, draftId)
    } else {
      createNftByContract(tokenAddress, draftId, account ? 'eth' : 'trx')
    }
  }
  const queryConfirmDraft = async (tokenAddress, tokenId, tokenType, txhash, count, draftId) => {
    let formData = new FormData();
    formData.append('draftId', draftId)
    formData.append('tokenId', tokenId)
    formData.append('chainType', getChainType(chainId || tronChainId))
    formData.append('tokenAddress', tokenAddress)

    if (txhash) {
      formData.append('txHash', txhash)
    }

    draftBindNft(draftId, formData).then(res => {
      window.localStorage.removeItem('nftInfo')
      closeWalletConfirmModal()
      message.success('Success')
      history.push(`/collectionManage/collectionDetail/${res.collection}`)
    })
  }
  const createNftByContract = async (tokenAddress, draftId, networkType) => {
    if (networkType === 'eth') {
      const myContract = getNFT721(library, tokenAddress);
      await myContract.methods.mint()
        .send({ from: account })
        .on('transcationHash', () => { })
        .on('receipt', async (receipt) => {
          // console.log(receipt);
          if (receipt.events.Mint && receipt.events.Mint.returnValues) {
            let tokenId = receipt.events.Mint.returnValues.tokenId
            let txhash = receipt.events.Mint.transactionHash
            await queryConfirmDraft(tokenAddress, tokenId, 'ERC721', txhash, 1, draftId)
          }
        })
        .on('error', (err) => {
          closeWalletConfirmModal()
        })
    }
  }
  const showWalletConfirmModal = (options) => {
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: options || waitingForConfirm
    });
  }
  const closeWalletConfirmModal = () => {
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { show: false }
    });
  }
  const showTxConfirmModal = (hash) => {
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: { ...waitingPending, hash }
    });
  }
  const queryList = async () => {
    let res = await queryCollectionList(account || tronAccount)
    setCollectionList(res.list)
  }
  const initData = async (list) => {
    let content = location.search
    if (content.indexOf("=") > 0) {
      let draftId = decodeURIComponent(content.substring(9))
      setIsEdit(true)
      let item = list.find((item) => item.id === draftId)
      let res = await queryCollectionList(account || tronAccount)
      let collectionItem = res.list.find((val) => val.contractAddress === item.tokenAddress)
      setCurrentCollection(collectionItem)
      setImage(item.image)
      setShowImage(true)
      let obj = {
        draftId: item.id,
        name: item.name,
        description: item.description,
        collection: item.tokenAddress,
      }
      setCreateForm(obj)
    } else {
      let info = JSON.parse(window.localStorage.getItem('nftInfo'))
      if (info) {
        let res = await queryCollectionList(account || tronAccount)
        let collectionItem = res.list.find((val) => val.contractAddress === info.tokenAddress)
        setCurrentCollection(collectionItem)
        setCreateForm(info)
        // if(info.image){
        //   getBase64(info.image,url=>{
        //     setImage(url)
        //     setShowImage(true)
        //   })
        // }
      } else {
        setCreateForm({})
      }
      // setCreateForm({})
    }
  }
  useEffect(() => {
    if (account || tronAccount) {
      queryList()
      getDraftListFun()
    }
  }, [account, tronAccount])

  return (
    <div className={styles.createCollection}>
      <div className={`mb10 ${styles.collection_title}`}>Create NFT</div>
      <div className={styles.formBox}>
        {
          createForm ?
            <Form className='mt10' form={formRef} layout={'vertical'} onFinish={onFinish}>
              <Row>
                <Col span={12}>
                  <p>Upload File</p>
                  <div className={styles.uploadBox}>
                    <div className={styles.upload_common} >
                      {/* <ImgCrop rotate beforeCrop={beforeCrop} modalTitle={'create_an_artwork_preview'} modalOk={'apply'} modalCancel={'cancel'}> */}
                      <Dragger
                        name="image"
                        className={`${styles.imgUpload} ${image ? styles.hasImage : ''}`}
                        showUploadList={false}
                        // beforeUpload={() => false}
                        onPreview={onPreview}
                        onChange={handleUploadChange}
                        listType="picture"
                        accept="image/png, image/jpg, image/jpeg, video/mp4, video/quicktime, image/gif"
                      >
                        {showImage &&
                          <div className={styles.previewBox}><img src={image} alt="preview" style={{ width: '100%' }} /></div>
                        }
                        <div id="videoBox" className={styles.videoBox}>
                        </div>
                        {!showImage && !showVideo &&
                          <>
                            <div><img src={arrowIcon} height="60px" className="mb20" /></div>
                            <Button className="btn_border">{'Upload Cover Image'}</Button>
                          </>
                        }
                      </Dragger>
                      {/* </ImgCrop> */}
                    </div>
                    <div className={`df_align_center ${styles.aiBox}`}>
                      <div className='mr40'>Support: JPG, JPEG2000, PNG, GIF, MOV, MP4</div>
                      {/* <Button>Generate image with AI</Button> */}
                    </div>
                  </div>
                </Col>
                <Col span={12} className={styles.form_right}>
                  <div>
                    <Form.Item label="Item Name" name={"name"} initialValue={createForm.name}>
                      <Input size='large' placeholder='Enter Item name'></Input>
                    </Form.Item>
                    <Form.Item label="Description" name={"description"} initialValue={createForm.description} tooltip={{ title: "The description will be included on the item's detail page underneath its image.", icon: <InfoCircleFilled /> }}>
                      <TextArea autoSize={{ minRows: 8, maxRows: 10 }} maxLength={2000} placeholder='Enter Item description'></TextArea>
                    </Form.Item>
                    <Form.Item className={styles.level} label="Collection" name={"collection"} initialValue={createForm.collection}>
                      <Select size='large' onChange={changeCollection}>
                        {
                          collectionList.map(item => (
                            <Option key={item.name + new Date().getTime()} value={item.contractAddress}>
                              {/* <img className={styles.option_img} src={chainTypeComImgObj[item.value]} alt={item.label} /> */}
                              <span className={'pl8'}>{item.name}</span>
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                    {
                      collectionList.length === 0 &&
                      <Button type='primary' className='wp100 h40 mb20' onClick={gotoCreateCollection}>Create A Collection</Button>
                    }
                    <div className='df'>
                      <div className={`pr60`}>
                        <div>Blockchain</div>
                        <div className='fw600 mt10'>
                          {
                            currentCollection ? <div>
                              <img src={chainTypeComImgObj[currentCollection.chainType]}></img> {chainTxtObj[currentCollection.chainType] ? chainTxtObj[currentCollection.chainType] : '--'}

                            </div> : <span>--</span>
                          }
                        </div>
                      </div>
                      <div className={`pl60 pr60 ${styles.border_left}`}>
                        <div>Token Standard</div>
                        <div className='fw600 mt10'>ERC-721</div>
                      </div>
                      <div className={`pl60 pr60 ${styles.border_left}`}>
                        <div>Supply</div>
                        <div className='fw600 mt10'>1</div>
                      </div>
                    </div>
                  </div>
                  <div className='tar'>
                    <Button className='btn_border mr40 w200 h40' onClick={() => { uploadDraftData('ERC721', false) }}>Save as draft {confirmLoading && !isPublish && <LoadingOutlined />}</Button>
                    <Button type='primary' className='w200 h40' htmlType="submit">Publish {confirmLoading && isPublish && <LoadingOutlined />}</Button>
                  </div>
                </Col>
              </Row>
            </Form> :
            <div className={`tac mt50`}>
              <Spin size={`large`}></Spin>
            </div>
        }
        <div className={styles.drafts}>
          <div className={`space-between`}>
            <div className={`fs18 fw700`}>Drafts</div>
            <div onClick={handleDeleteAllDraft} className="df_align_center cp"><img style={{ height: 14, marginRight: 5 }} src={delBIcon} />{'Delete All'}</div>
          </div>
          <div>
            {
              draftList.length === 0 ? <div className={`wp100 tac pt20`}><NoData></NoData></div> :
                draftList.map(item => <DraftsCard key={item.id} handleDeleteDraft={handleDeleteDraft} goToEdit={goToEdit} item={item}></DraftsCard>)
            }
          </div>
        </div>
      </div>
    </div>
  )
}
