import React, { useState, useEffect, useContext } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import SimpleMdeReact from "react-simplemde-editor";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Modal, Form, Input, DatePicker, message,Radio,Select, Spin } from 'antd';
import moment from 'moment';
import { defaultAbiCoder } from '@ethersproject/abi';

import { getERC20Contract } from '../../../utils/handleContract';
import { getVoteAddress, getContractVoteAddress, getEnglishAuctionNFTAddress, getFixswapAddress } from '../../../web3/address';
import { getContract, useActiveWeb3React } from "../../../web3";
// import DeFineDAO from "../../../web3/abi/DeFineVotingPortal.json";
import DeFineCommonProposal from "../../../web3/abi/DeFinePortalV3.json";
import DeFineContractProposal from "../../../web3/abi/DeFineVotingPortalV3.json";
// import DeFineDAOContract from "../../../web3/abi/DeFineVotingPortalV2New.json";
import { formatAmountWithDecimal } from "../../../utils/format";
import { mainContext } from "../../../reducer";
import { setupEthMainnet } from '../../../utils/networkConnect';
import BackButton from "components/BackButton";
import EnglishAuctionNFT from "../../../web3/abi/EnglishAuctionNFT.json"
import FixedSwapNFT from "../../../web3/abi/FixedSwapNFT.json"
import {
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
} from "../../../const";
import env from '../../../env';
import styles from "../styles.module.scss";
import BigNumber from 'bignumber.js';

const { Option } = Select;

export default function VoteCreat() {
    const { dispatch } = useContext(mainContext);
    const { t } = useTranslation()
    const history = useHistory();
    const { library, account, chainId } = useActiveWeb3React()
    const [dfaCount, setDfaCount] = useState(0)
    const [needDfa, setNeedDfa] = useState(0)
    const [creatBtn, setCreatBtn] = useState(true)
    const [minDuration, setMinDuration] = useState(259200 * 1000)  //最短
    const [maxDuration, setMaxDuration] = useState(2592000 * 1000) //最长
    const [voteDays, setVoteDays] = useState()
    const [visibleVoteTip, setVisibleVoteTip] = useState(false)
    const [voteInfo, setVoteInfo] = useState({})
    const [MDValue, setMDValue] = useState('')
    const [globalPendingTime, setGlobalPendingTime] = useState('')
    const [startDays, setStartDays] = useState('')
    const [maxEndDays, setMaxEndDays] = useState()
    const [contractFeesShow, setContractFeesShoaw] = useState(false)
    const [currentFees, setCurrentFees] = useState('')
    const [proposalType, setProposalType] = useState('proposal')
    const [ current, setCurrent] = useState(0)
    const [ currentFeeMax, setCurrentFeeMax] = useState(10000)
    const [ feeLoading, setFeeLoading] = useState(false)
    const [form] = Form.useForm();

    const voteAddressFc = {
        proposal: getVoteAddress,
        contract: getContractVoteAddress,
    }
    const voteAbi = {
        proposal: DeFineCommonProposal,
        contract: DeFineContractProposal,
    }
    const contractChainIdOnEth = {
        production: 1,
        development: 4,
    }
    const contractModificatioList = [
        {label: "Auction fees on Ethereum", value:0, currentFees: '5', fcName:'setFee', paramsType: 'uint256', contractVal: 0, abi:EnglishAuctionNFT, contractAddress: getEnglishAuctionNFTAddress(contractChainIdOnEth[env.ENV]) },
        {label: "Fixedswap fees on Ethereum", value:1, currentFees: '8', fcName:'setFee',paramsType: 'uint256', contractVal: 0, abi:FixedSwapNFT, contractAddress:getFixswapAddress(contractChainIdOnEth[env.ENV]) },
    ]

    const initData = () => {
        if (chainId !== contractChainIdOnEth[env.ENV]) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        queryCurrentFees(contractModificatioList[0])
        library && getContractInfo(proposalType)
        library && getDFACount(proposalType)
    }
    const onFinish = (value) => {
        if(proposalType==='proposal'){
            if (value.title && value.content && value.eTime) {
                if (value.eTime < moment(Date.now() + minDuration + globalPendingTime) || value.eTime > moment(Date.now() + maxDuration + globalPendingTime)) {
                    message.error(`Please select an end time again`)
                } else {
                    let params = value
                    params.eTime = moment(value.eTime).format("X")
                    setVoteInfo(params)
                    setVisibleVoteTip(true)
                }
            } else if (!value.eTime) {
                message.error("Please entry EndTime")
            } else {
                message.error("Please entry full content")
            }
        }else if(proposalType==='contract') {
            if (value.title && value.content && value.eTime && value.targetFees) {
                if(Number(value.targetFees)<0 || Number(value.targetFees)>30){
                    message.error(`The limit of fees is 0 - 30`)
                    return false
                }
                if (value.eTime < moment(Date.now() + minDuration + globalPendingTime) || value.eTime > moment(Date.now() + maxDuration + globalPendingTime)) {
                    message.error(`Please select an end time again`)
                } else {
                    let params = value
                    params.eTime = moment(value.eTime).format("X")
                    setVoteInfo(params)
                    setVisibleVoteTip(true)
                }
            } else if (!value.eTime) {
                message.error("Please entry EndTime")
            } else {
                message.error("Please entry full content")
            }
        }
        
    }
    const sureCreat = () => {
        if (chainId !== contractChainIdOnEth[env.ENV]) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        setVisibleVoteTip(false)
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        toApprove(voteInfo)
    }
    const getContractInfo = async (proposalType) => {
        let minDuration = await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.minDuration().call()
        let maxDuration = await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.maxDuration().call()
        let globalPendingTime = await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.globalPendingTime().call()
        let days = moment(Date.now() + globalPendingTime * 1000).diff(moment(), 'days');
        let hours = moment(Date.now() + globalPendingTime * 1000).diff(moment(), 'hours');
        let minutes = moment(Date.now() + globalPendingTime * 1000).diff(moment(), 'minutes');
        let maxEnddays = moment(Date.now() + maxDuration * 1000).diff(moment(), 'days');
       
        if(days>0){
            setStartDays(days+' days')
        }else if(hours>0){
            setStartDays(hours+' hours')
        }else {
            setStartDays(minutes+' minutes')
        }
        setMaxEndDays(maxEnddays)
        
        setGlobalPendingTime(globalPendingTime * 1000)
        setMinDuration(minDuration * 1000)
        setMaxDuration(maxDuration * 1000)
       
    }

    const disabledEnd = (current) => {
        return current && current < moment(Date.now() + minDuration + globalPendingTime).add(1, "seconds") || current > moment(Date.now() + globalPendingTime + maxDuration).add(1, "seconds");
    }

    const changeEnd = (current) => {
        let days = moment(current).diff(moment(Date.now() + globalPendingTime), 'days')
        setVoteDays(days)
    }
    const toApprove = async (params) => {
        try {
            let res = await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.voteToken().call()
            const erc20Contract = await getERC20Contract(library, res);
            let approved = await erc20Contract.methods.allowance(account, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).call()
            if (approved > 0) {
                creatProposal(params)
            } else {
                await erc20Contract.methods.approve(voteAddressFc[proposalType](contractChainIdOnEth[env.ENV]), "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                    .send({ from: account })
                    .on('receipt', (_, receipt) => {
                        creatProposal(params)
                    })
            }
        } catch (err) {
            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { show: false }
            });
        }
    }
    const creatProposal = async (params) => {
        let sendPar = { from: account }
        if(proposalType==='proposal'){
            return await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.propose(params.eTime, params.title, params.content)
                .send(sendPar)
                .on('transcationHash', () => { })
                .on('receipt', callbackSucess)
                .on('error', callbackError)
        }else if(proposalType==='contract'){
            let a1 = [contractModificatioList[current].contractAddress]
            let a2 = [contractModificatioList[current].contractVal]
            let a3 = [`${contractModificatioList[current].fcName}(${contractModificatioList[current].paramsType})`]
            let a4 = [defaultAbiCoder.encode([contractModificatioList[current].paramsType],[params.targetFees*currentFeeMax/100])]
            return await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.propose(a1,a2,a3,a4,params.eTime, params.title, params.content)
                .send(sendPar)
                .on('transcationHash', () => { })
                .on('receipt', callbackSucess)
                .on('error', callbackError)
        }
    }
    const callbackSucess = () => {
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
        message.success("create success").then(_ => {

            history.push('/vote')
        })
    }
    const callbackError = () => {
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
    }
    const getDFACount = async (proposalType) => {
        let res = await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.voteToken().call()
        let needdfa = await getContract(library, voteAbi[proposalType].abi, voteAddressFc[proposalType](contractChainIdOnEth[env.ENV])).methods.proposalThresholdCount().call()
        setNeedDfa(needdfa)
        let count = await getERC20Contract(library, res).methods.balanceOf(account).call()
        setDfaCount(count)
        if ( new BigNumber(count).comparedTo(new BigNumber(needdfa)) < 0 ) {
            setCreatBtn(true)
        } else {
            setCreatBtn(false)
        }
    }
    const handleCancel = () => {
        setVisibleVoteTip(false)
    }

    const chageMDValue = (value) => {
        setMDValue(value)
    }
    const onchangeType = (type) => {
        if(type === 'proposal') {
            setContractFeesShoaw(false)
            setProposalType(type)
            
        }else {
            setContractFeesShoaw(true)
            setProposalType(type)
        }
        form.setFieldsValue({
            eTime: ''
        })
        getContractInfo(type)
        getDFACount(type)
    }
    const onChangeContractFees = (e) => {
        setCurrent(e)
        queryCurrentFees(contractModificatioList[e])
    }
    const queryCurrentFees = async (item) => {
        setFeeLoading(true)
        let fee = await getContract(library, item.abi.abi, item.contractAddress).methods.fee().call()
        let feeMax = await getContract(library, item.abi.abi, item.contractAddress).methods.feeMax().call()
        setCurrentFeeMax(feeMax)
        setCurrentFees(fee/feeMax*100)
        setFeeLoading(false)
    }
    const allProposal = () => {
        history.push('/vote')
    }

    useEffect(() => {
        library && chainId && initData()
    }, [library, chainId])
    return (
        <div className={`page_content_h5`}>
            <div className={styles.title}>
                <BackButton onClick={allProposal} text={t('all_proposal')} />
            </div>
            <div className={`mt20 mb10 fs32 fw700`}>{t('creat_a_proposal')}</div>
            <Form form={form} className={styles.vote_creat} layout={'vertical'} onFinish={onFinish}>
                <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={16}>
                        <Form.Item
                            label=''
                            name="action"
                        >
                            <Select onChange={onchangeType} size={`large`}  defaultValue={"proposal"} className={`wp100`}>
                                <Option value="proposal">General proposal (community proposal)</Option>
                                <Option value="contract">Smart contract proposal</Option>
                            </Select>
                        </Form.Item>
                        {
                            contractFeesShow &&
                            <div className={`space-between-center-h5 wp100 ${styles.select_contract_modification}`}>
                                <Form.Item
                                    label=''
                                    name="contractAddress"
                                >
                                    <Select onChange={onChangeContractFees} size={`large`} defaultValue={0} className={`wp100`}>
                                        {
                                            contractModificatioList.map(item =>(
                                                <Option key={item.label} value={item.value}>{item.label}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label=''
                                    name="targetFees"
                                >
                                    <div className={`df pr wp100 ${styles.fees}`}>
                                        <div className={styles.current_fees}>
                                            <div className={`fs12 mb4`}>Current fees</div>
                                            <div className={`fw700`}>{feeLoading?<Spin size='small'></Spin>:<span>{currentFees}%</span>}</div>
                                        </div>
                                        <div className={styles.to_icon}><ArrowRightOutlined /></div>
                                        <div className={styles.target_fees}>
                                            <div className={`fs12 mb4`}>Target fees</div>
                                            <Input size={`large`} type='number'  className={`w100`} placeholder="0~30" suffix="%" />
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>
                        }
                        <Form.Item
                            label={t('vote_title')}
                            name="title"
                        >
                            <Input size={`large`}  />
                        </Form.Item>
                        <Form.Item
                            label={t('vote_content')}
                            name="content"
                        >
                            <SimpleMdeReact className={styles.markdown_h} value={MDValue} onChange={chageMDValue}></SimpleMdeReact>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>

                        <div className={`space-between  mb20`}>
                            <span>{t('vote_s_time')}</span>
                            <span className={'fw600'}>In { startDays }</span>
                        </div>

                        <Form.Item
                            label={t('vote_e_time')}
                            name="eTime"
                        >
                            <DatePicker size={`large`}  showNow={false} disabledDate={disabledEnd} onChange={changeEnd} showTime />
                        </Form.Item>
                        <div className={`space-between mt10`}>
                            <span>{t('vote_days')}</span>
                            <span className={'fw600'}>{(voteDays || voteDays === 0) ? voteDays : '--'} days</span>
                        </div>
                        <div className={`space-between mt10`}>
                            <span>{t('vote_your_balance')}</span>
                            <span className={`fw600 ${creatBtn ? "status_defeated_txt" : null}`}>{formatAmountWithDecimal(dfaCount, 18, 0)} DFA</span>
                        </div>
                        <div className={`space-between mt10`}>
                            <span>{t('vote_minimun_dfa')}</span>
                            <span className={'fw600'}>{formatAmountWithDecimal(needDfa, 18, 0)} DFA</span>
                        </div>
                        <div className={`${styles.creat_tip} mt20`}>
                            <p>{t('vote_creat_rule_1', { startday: startDays, maxEndDays: maxEndDays })}</p>
                            <p>{t('vote_creat_rule_2')}</p>
                        </div>
                        <div className={'mt20 space-between'}>
                            <span></span>
                            <Button className={`w200`} disabled={creatBtn} type={'primary'} htmlType="submit"> {t('create')} </Button>
                        </div>
                    </Col>
                </Row>
            </Form>

            <Modal
                title={t('confirm_proposal')}
                visible={visibleVoteTip}
                footer={null}
                onCancel={handleCancel}
            >
                <div>
                    <div className={`${styles.space_between}`}>
                        <div className={'w200'}>{t('vote_your_proposal')}</div>
                        <span className={'fw600'}>{voteInfo.title}</span>
                    </div>
                    <div className={`space-between mt10`}>
                        <span>{t('vote_s_time')}</span>
                        <span className={'fw600'}>In {startDays} days</span>
                    </div>
                    <div className={`space-between mt10`}>
                        <span>{t('vote_e_time')}</span>
                        <span className={'fw600'}>{moment(voteInfo.eTime * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </div>
                    <div className={`space-between mt10`}>
                        <span>{t('vote_creat_will_stak')}</span>
                        <span className={'fw600'}>{formatAmountWithDecimal(needDfa, 18, 0)}</span>
                    </div>
                    {/* <div className={`bg_gray p10 br6 mt10`}>
                        Are you sure you want to vote for the above choice? This action cannot be undone.
                    </div> */}
                    <div className={`space-between mt20`}>
                        <Button className={`wp40 mr20 fs14 btn_border h40`} onClick={handleCancel}>{t('cancel')}</Button>
                        <Button className={`wp60 fs14`} type={'primary'} onClick={sureCreat}>{t('vote_stake_DFA_Create')}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
