import React,{useState,useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu, Icon, Modal, Input, message, Row, Col, Switch } from 'antd';
import moment from 'moment';

import { chainTypeComImgObj, chainTxtObj } from '../../../utils/networkConnect';
import { abbrTxHash } from "../../../utils/format";
import { fetchTokensList, getCollectionInfo } from '../../../services/NftPlusBuyPage';
import { deleteCollection } from '../../../services/create'
import NftCard from 'components/NftCard';
import iconManage from "assets/img/creatBadge/icon_manage.svg"
import styles from '../styles.module.scss';

export default function CollectionDetail() {
  const history = useHistory()
  const { collectionId } = useParams()
  const [collectionInfo,setCollectionInfo] = useState({})
  const [delModal, setDelModal] = useState(false);
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)

  const gotoCreateCollection = () => {
    history.push(`/collectionManage/createCollection?collectionId=${collectionId}`)
  }
  const gotoCreateNft = () => {
    history.push(`/collectionManage/createNFT`)
  }
  const removeCollection = async () => {
    let res = await deleteCollection(collectionId)
    if (res) {
      history.push('/collectionManage')
    }
  }
  const getInfo = async () => {
    let res = await getCollectionInfo(collectionId)
    res = res || {};
    setCollectionInfo(res)
    // getBuyList(res, pageNo)
    getBuyList()
  }
  const getBuyList = async (pageNo) => {
    let res = await fetchTokensList(1, 9999, '', collectionId)
    if (res.tokens) {
      if (pageNo === 1) {
        setList(res.tokens)
      } else {
        setList(oldTokens => [...oldTokens, ...res.tokens])
      }
      setTotal(res.totalCount)
    } else {
      if (pageNo === 1) {
        setList(res.list)
      } else {
        setList(oldList => [...oldList, ...res.list])
      }
      setTotal(res.totalCount)
    }
  }
  useEffect(() => {
    getInfo()
  }, [])
  return (
    <>
      <Modal visible={delModal} title={'Delete Collection'} width={500} footer={null} onCancel={() => { setDelModal(false) }}>
        <div>The collection will also be cleared,  Are you sure you want to delete this collection?</div>
        <div className='space-between mt20'>
          <Button className={`w200  h50 btn_border`} onClick={() => { setDelModal(false) }}>Cancel</Button>
          <Button type={'primary'} className={`w200  h50`} onClick={removeCollection}>Delete Collection</Button>
        </div>
      </Modal>
      <div className={styles.collectionDetailPage}>
        <div className={styles.collection_baseInfo}>
          <div className={styles.collection_logo}><img width={300} src={collectionInfo.image}></img></div>
          <div className={`pl30 f1`}>
            <div className={styles.baseInfo_name}>{collectionInfo.name}</div>
            <div className={styles.baseInfo_desc}>{collectionInfo.description}</div>
            <div className={styles.info_list}>
              <div className={`${styles.info_item} ${styles.w270}`}>
                <div className='tac'>Creator</div>
                <div className={'fw600 tac'}>{abbrTxHash(collectionInfo.creator?.address)}</div>
              </div>
              <div className={`${styles.info_item} ${styles.w270}`}>
                <div className='tac'>{chainTxtObj[collectionInfo.chainType]}</div>
                <div className='tac'>
                  <img src={chainTypeComImgObj[collectionInfo.chainType]}></img>
                </div>
              </div>
              <div className={`${styles.info_item} ${styles.w270}`}>
                <div className='tac'>Items</div>
                <div className='fw600 tac'>{total}</div>
              </div>
            </div>
            <div className={styles.func_box}>
              <Dropdown className={`cp`} 
                overlay={
                  <Menu>
                    <Menu.Item key='1' onClick={gotoCreateCollection}>Edit Collection</Menu.Item>
                    <Menu.Item key='2' onClick={gotoCreateNft}>Add Item</Menu.Item>
                    <Menu.Item key='4' disabled={list.length > 0 } onClick={() => { setDelModal(true) }}>Delete Collection</Menu.Item>
                  </Menu>
                }>
                <div className={styles.collection_setting}><img src={iconManage}></img></div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className='mt20'>
          <div className={'fs18 fw600'}>Items({total})</div>
          <div className={styles.nft_list}>
            {
                list.length > 0 && list.map(item => (
                  <NftCard key={item.contractAddress + item.id} tokenInfo={item} />
                ))
            }
            <div className={styles.add_card} onClick={gotoCreateNft}>
              <div className='c_9a fs40'>+</div>
              <div className='c_9a'>Add Item</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
