import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Popover, Button, Skeleton, message, Table, Modal, Row, Col, Spin, Collapse } from 'antd';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import Zmage from 'react-zmage';
import copy from 'copy-to-clipboard';
import BigNumber from 'bignumber.js';
import Web3 from "web3";

import { useActiveWeb3React } from "../../web3";
import { claimBlindBadge } from '../../utils/handleContract';
import { mainContext } from "../../reducer";
import { chainFun, chainTypeImgObj, chainTxtObj, handleHistoryAddress } from '../../utils/networkConnect';
import { erc20TokenList, getDecimalByAddress } from "../../utils/tokenList";
import { getBadgeInfo, getOwnerList, queryMyBadges, getBlindBoxClaimInfo } from "../../services/badgeDetail";
import { getBadgeProjectInfo, useBadgeList } from "../../services/badge";
import { taskVerify, codeGetTwitterInfo } from "../../services/badgeManage";
import { getArtistInfo } from "../../services/profile"
import { getChainType } from "../../web3/address";
import CountDown from 'components/CountDown';
import BackButton from "components/BackButton";
import { useNeedSign, useAccountIsSign } from "hooks/account"
import styles from "./styles.module.scss";
import { abbrTxHash, formatAmountWithDecimal,formatAmount } from "../../utils/format";
import { getERC20Contract } from '../../utils/handleContract'
import copyIcon from "assets/img/header/copy.svg";
import { useActiveTronWeb } from "hooks/activeTronWeb";
import SvgTg from "assets/img/sidebar/telegram.svg"
import SvgTw from "assets/img/sidebar/twitter.svg"
import SvgDs from "assets/img/sidebar/discord.svg"
import SvgWallet from "assets/img/sidebar/wallet.svg"
import SvgWWW from "assets/img/sidebar/www.svg"
import SvgRichTxt from "assets/img/sidebar/richText.svg"
import iconOk from "assets/img/badge/ok.svg"
import iconDownArrow from "assets/img/badge/downArrow.svg"
import { twitterUtil } from "../../utils/index"

import {
  HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  HANDLE_SHOW_CONNECT_MODAL,
  waitingForConfirm,
} from "../../const";

const { Panel } = Collapse;
let timer = ''
let btnFlag = ''

const chainTypeToken = {
  mainnet: 'Eth',
  ropsten: 'Eth',
  rinkeby: 'Eth',
  goerli: 'Eth',
  bsc: 'BNB',
  bsctestnet: 'BNB',
  polygon: 'Matic',
  mumbai: 'Matic',
}
const chainTypeName = {
  mainnet: 'Ethereum',
  ropsten: 'Ethereum',
  rinkeby: 'Ethereum',
  goerli: 'Ethereum',
  bsc: 'BSC',
  bsctestnet: 'BSC',
  polygon: 'Polygon',
  mumbai: 'Polygon',
}

const BadgeDetail = () => {
  const { type, project } = useParams();
  const { state, dispatch } = useContext(mainContext);
  const history = useHistory();
  const [initType, setInitType] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [badgeInfo, setBadgeInfo] = useState({});
  const { library, account, chainId } = useActiveWeb3React()
  const { tronLibrary, tronAccount, tronChainId, tronActive } = useActiveTronWeb();
  const { t } = useTranslation()
  const ref = useRef();
  const [timeBtnShow, setTimeBtnShow] = useState(false);
  const [time, setTime] = useState();
  const { needSign } = useNeedSign();
  const isSign = useAccountIsSign();
  const [showCongratulation, setShowCongratulation] = useState(false)
  const [showTips, setShowTips] = useState(false)
  const [loading, setLoading] = useState(false);
  const { seriesList } = useBadgeList(project, setLoading)
  const [blindBoxInfo, setBlindBoxInfo] = useState({})
  const [enoughBalance, setEnoughBalance] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [hasTwitter, setHasTwitter] = useState(false);

  const handleClaim = (item) => {
    needSign(() => {
      if (item.chainType !== getChainType(chainId)) {
        const chainTxt = chainTxtObj[item.chainType];
        if (tronChainId && !chainId && chainTxt !== 'Tron') {
          dispatch({
            type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
          });
          return message.success('Please connect wallet.', 1)
        }

        if (chainFun[chainTxt]) {
          chainFun[chainTxt]();
        } else if (chainTxt === 'Tron') {
          dispatch({
            type: HANDLE_SHOW_CONNECT_MODAL, showConnectModal: true
          });
          return message.success('Please connect TronLink.', 1)
        }
        return;
      }

      if (btnFlag) {
        return false
      } else {
        btnFlag = true
      }

      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: waitingForConfirm
      });
      let blindBadgeName = item.blindBox.type
      let contractAddress = item.blindBox.contractAddress

      getBlindBoxClaimInfo(blindBadgeName).then(async res => {
        res.name = res.blindBox
        if (Number(item.blindBox.price) > 0) {
          let info = item.blindBox
          toApprove({ info, library, contractAddress, account, res })
        } else {
          toClaimBlindBadge({ library, contractAddress, account, res })
        }
      })
      // if (item.needWhitelist) {
      //   getBlindBoxClaimInfo(blindBadgeName).then(async res => {
      //     res.name = res.blindBox
      //     if (Number(item.blindBox.price) > 0) {
      //       let info = item.blindBox
      //       toApprove({ info, library, contractAddress, account, res })
      //     } else {
      //       toClaimBlindBadge({ library, contractAddress, account, res })
      //     }
      //   })
      // } else if (Number(item.blindBox.price) > 0) {
      //   let res = {
      //     name: blindBadgeName,
      //     nonce: 0,
      //     signature: '0x0',
      //     maxCount: item.userMaxCount
      //   }
      //   let info = item.blindBox
      //   toApprove({ info, library, contractAddress, account, res })
      // } else {
      //   let res = {
      //     name: blindBadgeName,
      //     nonce: 0,
      //     signature: '0x0',
      //     maxCount: item.userMaxCount
      //   }
      //   toClaimBlindBadge({ library, contractAddress, account, res })
      // }
    })
  }
  const toApprove = async ({ info, library, contractAddress, account, res }) => {
    try {
      if (info.priceToken.address === "0x0000000000000000000000000000000000000000") {
        toClaimBlindBadge({ library, contractAddress, account, res, price: info.price })
      } else {
        const erc20Contract = await getERC20Contract(library, info.priceToken.address);
        let approved = await erc20Contract.methods.allowance(account, contractAddress).call()

        if (approved > 0) {
          toClaimBlindBadge({ library, contractAddress, account, res })
        } else {
          await erc20Contract.methods.approve(contractAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
            .send({ from: account })
            .on('receipt', (_, receipt) => {
              toClaimBlindBadge({ library, contractAddress, account, res })
            })
        }
      }
    } catch (err) {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
    }
  }

  const toClaimBlindBadge = async ({ library, contractAddress, account, res, price }) => {
    popAgain()
    await claimBlindBadge(library, contractAddress, account, {
      ...res,
      price,
      _onTranscationHash: (hash) => {
      },
      _onReceipt: async (receipt) => {
        badgeInfo.claimStatus = 'claimed'
        badgeInfo.userClaimedCount += 1
        badgeInfo.blindBox.soldCount += 1
        // console.log(receipt);
        setShowTips(false)
        clearTimeout(timer)
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        const { events: { Mint: { returnValues } } } = receipt;
        queryBadgeTypeInfo(returnValues.badgeType, account)
        btnFlag = false
      },
      _onError: (err) => {
        setShowTips(false)
        clearTimeout(timer)
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        dispatch({
          type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
          showFailedTransactionModal: true
        });
        btnFlag = false
      }
    })
  }
  const queryERC20Balance = async (info) => {
    let res = await getERC20Contract(library, info.priceToken.address).methods.balanceOf(account).call()
    let balanceMinus = new BigNumber(res).minus(new BigNumber(info.price))
    if (balanceMinus >= 0) {
      setEnoughBalance(true)
    } else {
      setEnoughBalance(false)
    }
  }
  const queryMainBalance = (info) => {
    let web3 = new Web3(library.provider)
    web3.eth.getBalance(account).then(res => {
      let balanceMinus = new BigNumber(res).minus(new BigNumber(info.price))
      if (balanceMinus >= 0) {
        setEnoughBalance(true)
      } else {
        setEnoughBalance(false)
      }
    })
  }
  const popAgain = () => {
    timer = setTimeout(() => {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
      setShowTips(true)
    }, 30000)
  }
  const queryBadgeTypeInfo = async (badgeType, account) => {
    let res = await getBadgeInfo(badgeType, account)
    setLoading(true)
    setBlindBoxInfo(res)
    setShowCongratulation(true)
  }

  const getClaimStatus = ({ claimStatus, claimEndTime, claimStartTime }) => {
    if (claimStartTime > Date.now()) {
      if ((claimStatus - 24 * 60 * 60 * 1000) < Date.now() && Date.now() < claimStatus) {//24小时内
        setTimeBtnShow(true)
        setTime(claimStatus / 1000)
        return
      } else {
        return 'Not Started'
      }
    } else {
      if (claimStatus === 'claimed') {
        // return 'Owned'
        return 'Claim'
      } else {
        if (claimEndTime < Date.now()) {
          return 'Expired'
        } else {
          if (enoughBalance) {
            return 'Claim'
          } else {
            return 'Insufficient balance'
          }

        }
      }
    }
  }

  const handleTableChange = (pagination) => {
    getOwnerList(pagination, badgeInfo.type).then(res => {
      setTableData(res.list)
      setPagination({
        hideOnSinglePage: true,
        current: res.pageNo,
        pageSize: res.pageSize,
        total: res.totalCount
      })
    })
  }

  const showList = () => {
    let initPagination = {
      hideOnSinglePage: true,
      current: 1,
      pageSize: 10,
      total: 0
    }
    setTableData([])
    setPagination(initPagination)
    handleTableChange(initPagination)
  }


  const CreatorItem = ({ creator }) => {
    return (
      <Link className={`df_align_center ${styles.owerInfo}`} to={`/profile/${creator?.address}`}>
        <img src={creator?.avatar} width={30} />
        <div className={'pl5'}>{creator?.name}</div>
      </Link>
    )
  }

  const content = (
    <div className={styles.pop}>
      <h1>How to get ?</h1>
      <div>Co-produced by Youngohm and NFT1’s award-winning Creative Director, NINO, this collaboration content is made exclusively for NFT1’s Rarible Store. The NFT content is an animation artwork created by Gins Jirakulsawat. It’s produced as MP4.</div>
    </div>
  )

  const columns = [
    {
      title: `Badge Owners (${badgeInfo.ownerCount})`,
      key: 'user',
      render: item => <CreatorItem creator={item} />,
    },
  ];

  const tableList = (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={pagination}
      onChange={handleTableChange}
      rowKey={record => record.address}
    />
  )
  const dealTime = (info) => {
    let row = info ? info : badgeInfo
    if (Date.now() < row.claimStartTime) {
      setTime(row.claimStartTime / 1000)
      setTimeBtnShow(true)
    } if (Date.now() > row.claimStartTime && Date.now() < row.claimEndTime) {
      setTime(row.claimEndTime / 1000)
      setTimeBtnShow(false)
    } if (Date.now() > row.claimEndTime) {
      setTimeBtnShow(false)
    }
  }
  const ConnectWallet = () => {
    needSign()
  }

  const handleCopy = () => {
    copy(badgeInfo.blindBox.contractAddress);
    message.success('Copied!')
  }

  const reductionBigNum = (value, decimals) => {
    const val = new BigNumber(value)
    return val.div(new BigNumber(10).pow(decimals)).toString()
  }
  const queryProjectInfo = async () => {
    let res = await getBadgeProjectInfo(project)
    // setProjectInfo(res)
    res.claimStatus = res.blindBox.claimStatus
    res.claimStartTime = res.blindBox.claimStartTime
    res.claimEndTime = res.blindBox.claimEndTime
    res.userClaimedCount = res.blindBox.userClaimedCount
    res.userMaxCount = res.blindBox.userMaxCount
    if (library && account && Number(res.blindBox.price) > 0) {
      if (res.blindBox.priceToken.address !== "0x0000000000000000000000000000000000000000") {
        queryERC20Balance(res.blindBox)
      } else {
        queryMainBalance(res.blindBox)
      }
    }
    setBadgeInfo(res)
    setInitType(true)
    if (res.blindBox.tasks) {
      res.blindBox.tasks.map(item => {
        if (item.type === 'token_balance' || item.type === 'transaction_count' || item.type === 'total_gas_fee' || item.type === 'course') {
          item.firstBtn = true
        } else {
          item.firstBtn = false
        }
        if (item.type === 'token_balance') {
          item.balance = reductionBigNum(item.balance, getDecimalByAddress(item.token.address, erc20TokenList))
        }
      })
      setTaskList(res.blindBox.tasks)
    }
  }
  const goToTypeInfo = async (type) => {
    history.push(`/badge/${project}/${type}?isview=true`)
  }
  const keepWaiting = () => {
    setShowTips(false)
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
  }
  const matchTwitterUrl = (url) => {
    const regex = /^https?:\/\/twitter\.com\/(.+?)\/status\/\d+/;
    const match = url.match(regex);
    if (match && match.length === 2) {
      return match[1];
    }
    return null;
  }
  const getTwitterStatusId = (twitterLink) => {
    const regex = /status\/(\d+)/;
    const match = twitterLink.match(regex);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }
  const verifyItem = async (task) => {
    if (!task.firstBtn) {
      message.error("Verification failed")
      return false
    }
    let res = await taskVerify(badgeInfo.project, task.id)
    if (res === 'completed') {
      queryProjectInfo()
      // window.location.reload();
      const taskElement = document.getElementById(`task${task.id}`)
      const contentElement = taskElement.querySelector('.content');
      contentElement.style.display = 'none';
    } else {
      message.error('Verification failed')
    }
  }
  const openTwitter = (item, type, option, url) => {
    item.firstBtn = true
    twitterUtil(type, option, url)
  }
  const doTheTask = (item) => {
    item.firstBtn = true
    if (item.type === 'visit_website' || item.type === 'join_telegram' || item.type === 'join_discord') {
      window.open(item.url)
    }
  }
  const goToAuth = (e, item) => {
    if (item.type === 'follow_on_twitter' || item.type === 'retweet' || item.type === 'like_tweet' || item.type === 'tweet' || item.type === 'quote_tweet') {
      if (!hasTwitter) {
        e.stopPropagation()
        twitterUtil('auth')
        return false
      }
    }
  }
  const taskIsCompleted = (e, item) => {
    e.stopPropagation()
    return false
  }
  const initTaskListClickEvent = () => {
    const listItems = document.querySelectorAll('.taskItem');
    listItems.forEach(function (item) {
      const heading = item.querySelector('.taskHeader');
      const content = item.querySelector('.content');

      heading.onclick = () => {
        needSign(() => {
          if (content.style.display === 'none') {
            content.style.display = 'block';
          } else {
            content.style.display = 'none';
          }
        })
      };
    });
  }
  const bindTwitter = async (code) => {
    try {
      let res = await codeGetTwitterInfo(code, window.location.origin + '/auth')
      message.success('Success')
      localStorage.removeItem('twitterCode')
      setHasTwitter(true)
    } catch (error) {
      localStorage.removeItem('twitterCode')
    }
  }

  useEffect(() => {
    const addr = window.localStorage.getItem('address');
    if ((addr && account) || !addr) {
      queryProjectInfo().then(_ => {
        initTaskListClickEvent()
      })
    }
    account && getArtistInfo(account, account || tronAccount).then(res => {
      if (res.twitter) {
        setHasTwitter(true)
      }
    })
  }, [account, library])
  const queryTwitterCode = (event) => {
    if (event.storageArea === localStorage) {
      // console.log('localStorage', event.key, event.newValue);
      if (event.key === 'twitterCode') {
        event.newValue && bindTwitter(event.newValue)
      }
    }
  }
  useEffect(() => {
    window.addEventListener('storage', queryTwitterCode);
    return () => {
      window.removeEventListener('storage', queryTwitterCode)
    }
  }, [])

  return (
    <>
      <Modal
        className={styles.badge_modal}
        visible={showCongratulation}
        onCancel={() => { setShowCongratulation(false) }}
        title={t('pop_congratulations') + '!'}
        width={750}
        footer={[
          <Button className="w200_h5 fs16 h50" type="primary" onClick={() => { setShowCongratulation(false) }}>
            Close
          </Button>
        ]}
      >
        <div className={`${styles.pop_img} mt20`}>
          <img width={200} src={blindBoxInfo.image}></img>
        </div>
        <div>{blindBoxInfo.name}</div>
      </Modal>
      <Modal
        className={styles.badge_modal}
        visible={showTips}
        onCancel={() => { setShowTips(false) }}
        title={'Tips'}
        width={750}
        footer={[
          <Button className="w200_h5 fs16 h50 btn_border" onClick={() => { setShowTips(false) }}>
            Close
          </Button>,
          <Button className="w200_h5 fs16 h50" type="primary" onClick={() => { keepWaiting(false) }}>
            Keep waiting
          </Button>
        ]}
      >
        <div>The transaction is still being confirmed, do you wish to keep waiting? After success, you can view it in Profile - NFTs</div>
      </Modal>
      <div className={`page_content_h5 ${styles.badgeDetail}`}>
        {
          !initType ? <div className={'space-between-center-h5'}><Skeleton.Image style={{ marginRight: '30px' }} /> <Skeleton active></Skeleton></div> :
            <>
              <div className={`df_h5`}>
                <div className={`${styles.img_box}`}>
                  <div className={`${styles.badge_cover} br6`}>
                    <Zmage className={styles.img} src={badgeInfo.blindBox.cover} ></Zmage>
                    {
                      false &&
                      <Button className={`w120 h30 fs14 btn_border br6 ${styles.btn_setWhiteList}`}>Set whitelist</Button>
                    }
                  </div>
                </div>
                <div className={`${styles.info_box}`}>
                  <div className={`f1`}>
                    <div className={`page_content_title`}>{badgeInfo.name}</div>
                    <div className={`fs14 mt20 mb30`}>{badgeInfo.description}</div>
                    <div className={`space-between-h5 mt20`}>
                      <div className={`${styles.mr10_h5} ${styles.time_box} br4`}>
                        <div className={`fs14`}>{t('badge_sTime')}</div>
                        <div className={`fs16 fw700`}>{moment(badgeInfo.eventStartTime).format("YYYY/MM/DD HH:mm:ss")}</div>
                      </div>
                      <div className={`${styles.time_box} br4`}>
                        <div className={`fs14`}>{t('badge_eTime')}</div>
                        <div className={`fs16 fw700`}>{moment(badgeInfo.eventEndTime).format("YYYY/MM/DD HH:mm:ss")}</div>
                      </div>
                    </div>
                    {
                      badgeInfo.blindBox.contractAddress &&
                      <div className={`${styles.addr_box}`}>
                        <div className={`fs14`}>Contract Address</div>
                        <div className={`df_center cp`}>
                          <div className="pr10">
                            <a className={`c_blue`} target="_blank" href={handleHistoryAddress(badgeInfo.chainType, badgeInfo.blindBox.contractAddress)} >{abbrTxHash(badgeInfo.blindBox.contractAddress)}</a>
                          </div>
                          <img onClick={handleCopy} width={18} src={copyIcon} alt="" />
                        </div>
                      </div>
                    }
                    <div className={`space-between`}>
                      <div className={`${styles.info_item}`}>
                        <div className={`fs14`}>Blockchain</div>
                        <Popover content={chainTxtObj[badgeInfo.chainType]}>
                          <div className={`fs16  p5`}>
                            <img className={styles.chain} width={30} src={chainTypeImgObj[badgeInfo.chainType]} />
                          </div>
                        </Popover>
                      </div>
                      <div className={`${styles.info_item}`}>
                        <div className={`fs14`}>Claimed</div>
                        <div className={`fs24 fw700 tac`}>{formatAmount(badgeInfo.blindBox.soldCount)}<span className="fs16">/{badgeInfo.blindBox.count?formatAmount(badgeInfo.blindBox.count):'--'}</span> </div>
                      </div>
                      {
                        Number(badgeInfo.blindBox.price) > 0 &&
                        <div className={`mr10 ${styles.info_item} tac`}>
                          <div className={`fs14 ${styles.item_h2}`}> Price</div>
                          <div className={`fs20 fw700 tac cp`}>
                            {formatAmountWithDecimal(badgeInfo.blindBox.price, badgeInfo.blindBox.priceToken.decimals)} {badgeInfo.blindBox.priceToken.symbol}
                          </div>
                        </div>
                      }
                    </div>
                    <div className={` space-between-center-h5 mt40`}>
                      {
                        !isSign ? <div><Button onClick={ConnectWallet} className={`btn_border w180 h40`}>Claim</Button></div> :
                          badgeInfo.claimType != "airDrop" &&
                          <>
                            {
                              (badgeInfo.claimStatus) && (!timeBtnShow) && <Button
                                onClick={() => { handleClaim(badgeInfo) }}
                                type="primary"
                                disabled={
                                  badgeInfo.claimStatus === 'cannotClaim' ||
                                  (badgeInfo.claimStatus === 'claimed' && (badgeInfo.userMaxCount - badgeInfo.userClaimedCount) === 0) ||
                                  badgeInfo.claimStartTime > Date.now() ||
                                  badgeInfo.claimEndTime < Date.now() ||
                                  !enoughBalance
                                }
                                className={`${styles.item_btn} h50 w200_h5 fs16 btn_multicolour`}
                              >{getClaimStatus(badgeInfo)} </Button>
                            }
                            {
                              timeBtnShow && <div className={`w200_h5`}>
                                <Button type={'primary'} disabled className={`w200_h5 h50 fs20 fw700 `}><CountDown onEnd={dealTime} time={time}></CountDown></Button>
                              </div>
                            }
                            {/* (badgeInfo.claimStatus != 'claimed') ? <div className={`p10 f1 fs14`}>{badgeInfo.claimStatus === 'cannotClaim' ? t('badge_eligible_no') : badgeInfo.claimStatus === 'notClaim' ? t('badge_eligible') : ''}</div> : '' */}
                            <div className={`ml20 tar f1 fs14`}>{(badgeInfo.claimStatus === 'notClaim' || badgeInfo.claimStatus === 'claimed') ? (badgeInfo.userMaxCount > 0 && (badgeInfo.userMaxCount - badgeInfo.userClaimedCount) > 0) ? `You are eligible to claim ${badgeInfo.userMaxCount - badgeInfo.userClaimedCount} Mystery Boxes.` : 'You have completed your Mystery Box claim' : 'You are not eligible to claim the Mystery Box.'} </div>

                          </>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {
                badgeInfo.blindBox.tasks &&
                <div className={`${styles.description_box}`}>
                  <div className={`page_content_subtitle mb10`}>Badge Task</div>
                  <div>
                    <div expandIconPosition={'right'} className={`taskList`}>
                      {
                        taskList.map(item => (
                          <>
                            {
                              item.type === 'follow_on_twitter' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Follow @{item.twitterId} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter && account &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, item.twitterId) }}>Follow</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }
                            {
                              item.type === 'retweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Retweet @{matchTwitterUrl(item.url)} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter && account &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, getTwitterStatusId(item.url)) }}>Retweet</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }
                            {
                              item.type === 'tweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Post a Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter && account &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <div>{item.content}</div>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, item.content) }}>Post</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'like_tweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Like a Tweet by @{matchTwitterUrl(item.url)} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter && account &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, getTwitterStatusId(item.url)) }}>Like</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'quote_tweet' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTw}></img><span className="fs16 pl20">Quote tweet @{matchTwitterUrl(item.url)} on Twitter</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  !hasTwitter && account &&
                                  <div className="taskAuth" onClick={(e) => { goToAuth(e, item) }}></div>
                                }
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <div>{item.content}</div>
                                  <div className="mb10">
                                    <a className="c_blue" target="_blank" href={item.url}>{item.url}</a>
                                  </div>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { openTwitter(item, item.type, item.content, item.url) }}>Quote Tweet</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'join_discord' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgDs}></img><span className="fs16 pl20">Join Discord server</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { doTheTask(item) }}>Join</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'join_telegram' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgTg}></img><span className="fs16 pl20">Join on Telegram</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { doTheTask(item) }}>Join</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'visit_website' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWWW}></img><span className="fs16 pl20">Visit this Page</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <Button type="primary" className={'w200 h50 btn_pruple fw600'} onClick={() => { doTheTask(item) }}>Go</Button>
                                  <Button type="primary" className={'ml20 w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'token_balance' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWallet}></img><span className="fs16 pl20">Hold {item.balance} {item.token.symbol} on {chainTypeName[item.chainType]}</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  {/* <div className="c_blue mb10">ContractAddress: {item.token.address}</div> */}
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary" className={'w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'total_gas_fee' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWallet}></img><span className="fs16 pl20">Spend {item.amount} {chainTypeToken[item.chainType]} gas fee on {chainTypeName[item.chainType]}</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary" className={'w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'transaction_count' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgWallet}></img><span className="fs16 pl20">{item.count} transaction on {chainTypeName[item.chainType]}</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary" className={'w200 h50 fw600'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }{
                              item.type === 'course' &&
                              <div id={`task${item.id}`} className={`taskItem`} style={{background:`${item.completed?'#F7F3FD':'#fff'}`}}>
                                <div className={`space-between taskHeader`} >
                                  <div>
                                    <img width={28} src={SvgRichTxt}></img><span className="fs16 pl20">{item.title}</span>
                                  </div>
                                  {
                                    item.completed ? <img src={iconOk}></img> : <img src={iconDownArrow}></img>
                                  }
                                </div>
                                {
                                  item.completed &&
                                  <div className="taskAuth" onClick={(e) => { taskIsCompleted(e, item) }}></div>
                                }
                                <div className={`content pt10`} style={{ display: 'none' }}>
                                  <div className={styles.richTxt} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                  {/* <Button type="primary" className={'w200 h50 btn_gradient'} onClick={() => { openTwitter(item.type, item.content, item.url) }}>Go</Button> */}
                                  <Button type="primary" className={'w200 h50 fw600 mt10'} onClick={() => { verifyItem(item) }}>Verify</Button>
                                </div>
                              </div>
                            }
                          </>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              <div className={`${styles.description_box}`}>
                <div className={`page_content_subtitle mb10`}>Series Content</div>
                <Row className={styles.badge_series} gutter={20}>
                  {
                    seriesList.length > 0 && seriesList.map(item => (
                      <Col key={item.type} className={styles.series_item} onClick={() => { goToTypeInfo(item.type) }}>
                        <div className={styles.item_cover} style={{backgroundImage:`url(${item.image})`}}>
                          {/* <img className={styles.item_img} src={item.image}></img> */}
                          </div>
                        <div className={`fw600 fs14 mt10`}>{item.name}</div>
                        {/* <div className={'fs12 lh28'}>{item.grantedCount ? item.grantedCount : 0}/{item.maxCount ? item.maxCount : 0}</div> */}
                      </Col>
                    ))
                  }
                </Row>
              </div>
              {/* <div className={`${styles.description_box}`}>
                <div className={`page_content_subtitle mb10`}>Badge Utility</div>
                <div>
                  {
                    badgeInfo.utility &&
                    <div dangerouslySetInnerHTML={{ __html: badgeInfo.utility }}></div>
                  }
                  {
                    badgeInfo.utilityUrl &&
                    <a className={`c_blue`} target="_blank" href={badgeInfo.utilityUrl}>{badgeInfo.utilityUrl}</a>
                  }
                </div>
              </div> */}
              <div className={`${styles.description_box}`}>
                <div className={`page_content_subtitle mb10 mt20`}>Task Description</div>
                <div>
                  {
                    badgeInfo.taskDescription &&
                    <div dangerouslySetInnerHTML={{ __html: badgeInfo.taskDescription }}></div>
                  }
                  {
                    badgeInfo.taskDescriptionUrl &&
                    <a className={`c_blue`} target="_blank" href={badgeInfo.taskDescriptionUrl}>{badgeInfo.taskDescriptionUrl}</a>
                  }
                </div>
                {
                  badgeInfo.whitelistUrl &&
                  <div className="mt10">
                    <a className={`c_blue`} target="_blank" href={badgeInfo.whitelistUrl}>{t('click_here')}</a>
                  </div>
                }
              </div>
            </>
        }
      </div>
    </>
  );
};

export default BadgeDetail