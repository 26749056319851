import { Modal } from 'antd'
import React from 'react'

import styles from "./styles.module.scss";
import { handleHistoryTx } from 'utils/networkConnect';


export const NftTransferSuccessModal = ({ visible, successInfo, onCancel, detailInfo }) => {


    return (
        <Modal
            title={'Transfer'}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            width={450}>
            <div className={`df_column_center`}>
                <div className={styles.transfer_imgBox}>
                    {
                        detailInfo.image && (!detailInfo.image.includes('.mp4') && !detailInfo.image.includes('.mov')) &&
                        <img className={styles.nft_img} src={detailInfo.image}></img>
                    }
                    {
                        detailInfo.image && (detailInfo.image.includes('.mp4') || detailInfo.image.includes('.mov')) &&
                        <video className={styles.nft_img} controls><source src={detailInfo.image} />Sorry, your browser doesn't support embedded videos.</video>
                    }
                </div>
                <div className={`mt20 fw700`}>Transfer succeed!</div>
                <div className={`bg_f4 p10 br6 mt10 wwbw`}>{successInfo.address}</div>
                <a className={`btn_border p10 mt20 lh18`} href={handleHistoryTx(successInfo.chainType, successInfo.transactionHash)} target="_blank" >View on blockchain</a>
            </div>
        </Modal>
    )
}
