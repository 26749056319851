import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Row, Col, Steps, Upload, message, Button, Input, Select, DatePicker, Tabs, Radio, Popover, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useActiveWeb3React } from "../../../web3";
import { saveEvent, checkEventId, putBlindTaskList, getEventByProject, delTask, postImage } from "../../../services/badgeManage";
import { getArtistInfo } from "../../../services/profile"
import { getBadgeListByProject } from '../../../services/badge'
import { dataURLtoBlob } from '../../../utils/dataURLtoBlob';
import { erc20TokenList, getDecimalByAddress } from "../../../utils/tokenList";
import { getChainId } from '../../../web3/address';
import { chains, chainTypeComImgObj, chainTypeImgObj } from '../../../utils/networkConnect';

import iconUpload from "assets/img/creatBadge/icon_upload.svg"
import matic1 from "assets/img/chain/com_polygon.svg";
import eth1 from "assets/img/chain/com_eth.svg";
import bnb1 from "assets/img/chain/com_bnb.svg";
import badgeType1 from "assets/img/creatBadge/generalNft.jpg"
import badgeType2 from "assets/img/creatBadge/mysterybox.jpg"
import badgeType3 from "assets/img/creatBadge/airdrop.png"
import whiteListTemplate from "assets/Addresses_template.csv";

import styles from '../style.module.scss'
import { mainContext } from "../../../reducer";
import moment from 'moment';
import env from '../../../env';
import BigNumber from 'bignumber.js';
// import { EditorState, ContentState } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import '@wangeditor/editor/dist/css/style.css'

const { Step } = Steps;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const selectObj = {
  production: [
    { name: 'Ethereum', icon: eth1, value: 'mainnet' },
    { name: 'BSC', icon: bnb1, value: 'bsc' },
    { name: 'Polygon', icon: matic1, value: 'polygon' },
  ],
  development: [
    { name: 'Ethereum', icon: eth1, value: 'goerli' },
    { name: 'BSC', icon: bnb1, value: 'bsctestnet' },
    { name: 'Polygon', icon: matic1, value: 'mumbai' },
  ]
}
const tokenObj = {
  mainnet: 'ETH',
  bsc: 'BNB',
  polygon: 'MATIC',
  goerli: 'ETH',
  bsctestnet: 'BNB',
  mumbai: 'MATIC',
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF/WEBP file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
function beforeUploadWhiteList(file) {
  const isCsv = file.type === 'text/csv';
  if (!isCsv) {
    message.error('You can only upload csv file!');
  }
  return isCsv
}

export default function Create() {
  const { account } = useActiveWeb3React()
  const [formRef] = Form.useForm()
  const history = useHistory()
  const { dispatch } = useContext(mainContext);
  const [stepBox, setStepBox] = useState(1);
  const [logoUrl, setLogoUrl] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [selectType, setSelectType] = useState('general');
  const [eligibilityType, setEligibilityType] = useState('all');
  const [whiteList, setWhiteList] = useState();
  const [whiteListName, setWhiteListName] = useState();
  const [createEventForm, setCreateEventForm] = useState();
  const [taskList, setTaskList] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [eventName, setEventName] = useState()
  const [eventChainType, setEventChainType] = useState()
  const [onlyEventId, setOnlyEventId] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [eventId, setEventId] = useState()
  const [tokenBalanceIcon, setTokenBalanceIcon] = useState()
  const [gasFeeIcon, setGasFeeIcon] = useState()
  const [chainId, setChainId] = useState()
  const location = useLocation();
  const [taskDescription, setTaskDescription] = useState();
  const [hasBadges, setHasBadges] = useState(false);
  const [hasContractAddress, setHasContractAddress] = useState(false);
  const [checkAutoEventId, setCheckAutoEventId] = useState(false);
  const chainArr = chains.filter(item => { return item.value != 'klaytn' && item.value != 'baobab' && item.value != 'tron' && item.value != 'shasta' && item.value != 'rinkeby' && item.value != 'ropsten' })

  const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const [offChain, setOffChain] = useState([
    { uniqueId: guid(), sortType: "Off-Chain", type: 'follow_on_twitter', event: 'twitter', desc: 'Follow on Twitter', keyName: 'Username', frequency: 'more', nameColor: 'blue', placeholder: '@username', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'retweet', event: 'twitter', desc: 'Retweet on Twitter', keyName: 'Tweet URL', frequency: 'more', nameColor: 'blue', placeholder: 'Enter a Tweet URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'like_tweet', event: 'twitter', desc: 'Like a Tweet on Twitter', keyName: 'Tweet URL', frequency: 'more', nameColor: 'blue', placeholder: 'Enter a tweet URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'tweet', event: 'twitter', desc: 'Tweet on Twitter', keyName: 'Tweet', frequency: 'more', nameColor: 'blue', placeholder: ' Input template, can contain #tag or URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'quote_tweet', event: 'twitter', desc: 'Quote Tweet on Twitter', frequency: 'more', nameColor: 'blue', placeholder: '', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'join_discord', event: 'discord', desc: 'Join a Discord server', keyName: 'Tweet URL', frequency: 'more', nameColor: 'blue2', placeholder: 'Enter a Discord server URL', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'join_telegram', event: 'telegram', desc: 'Join a Telegram channel', frequency: 'more', nameColor: 'blue', placeholder: '@username', disabled: false },
    { uniqueId: guid(), sortType: "Off-Chain", type: 'visit_website', event: 'website', desc: 'Visit a website', keyName: 'Website URL', frequency: 'more', nameColor: 'blue', placeholder: 'Enter the URL', disabled: false },
  ])
  const [onChain, setOnChain] = useState([
    { uniqueId: guid(), sortType: "On-Chain", type: 'token_balance', event: 'wallet', desc: 'Verify token balance', frequency: 'more', nameColor: 'blue', disabled: false },
    // { uniqueId: guid(), sortType: "On-Chain", type: 'total_gas_fee', event: 'wallet', desc: 'Total amount of gas fees spent', frequency: 'more', nameColor: 'blue', disabled: false },
    { uniqueId: guid(), sortType: "On-Chain", type: 'transaction_count', event: 'wallet', desc: 'Number of Transactions', frequency: 'more', nameColor: 'blue', disabled: false },
  ])
  const [customMade, setCustomMade] = useState([
    // { uniqueId: guid(), sortType: "Custom-Made", type: 'course', event: 'course', desc: 'Graphic course', frequency: 'more', nameColor: 'blue', disabled: false },
  ])
  const quillRef = useRef()
  const handleImageUpload = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async function () {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);
      const response = await postImage(formData);

      const imageUrl = response;
      const quillEditor = quillRef.current.getEditor();
      const range = quillRef.current.getEditor().getSelection();
      // const value = prompt('');
      const value = '';
      quillEditor.insertEmbed(range.index, 'image', `${imageUrl}?alt='${value}'`);
      quillEditor.setSelection(range.index + 1);//
    };
  }
  const modules = useMemo(() => ({
    toolbar: {
      handlers: { image: handleImageUpload, },
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
        ["clean"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
  }), []);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
  ];

  const nextStep = (step) => {
    let reg = /^[a-zA-Z0-9-]{6,20}$/
    if (!reg.test(checkAutoEventId)) {
      setOnlyEventId(false)
      return message.error("The event id must be longer than 6 characters and shorter than 24 characters, and can only contain letters, numbers, and '-'")
    }
    setStepBox(step)
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setLogoUrl(url);
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setLogoUrl(url);
      });
    }
  }
  const handleChangeCover = (info) => {
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
    }
  }
  const slectType = (type) => {
    console.log(type);
    if (hasBadges || hasContractAddress) return false
    setSelectType(type)
  }
  const handleChangeAddress = (info) => {
    const isCsv = info.file.type === 'text/csv';
    if (!isCsv) {
      message.error('You can only upload csv file!');
      return isCsv
    }
    setWhiteListName(info.file.name)
    if (info.file.status === 'uploading') {
      getBase64(info.file.originFileObj, url => {
        setWhiteList(url);
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setWhiteList(url);
      });
    }
  }
  const handleSelectEligibility = (value) => {
    setEligibilityType(value)
  }
  const addEventList = (item, index) => {
    if (item.frequency === 'one') {
      if (item.sortType === 'Off-Chain') {
        let arr = offChain
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = true
          }
        })
        setOffChain(arr)
      } else if (item.sortType === 'On-Chain') {
        let arr = onChain
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = true
          }
        })
        setOnChain(arr)
      }
      else if (item.sortType === 'Custom-Made') {
        let arr = customMade
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = true
          }
        })
        setCustomMade(arr)
      }
    }
    let arr = []
    arr.push(item)
    setTaskList(oldList => [...oldList, ...arr])
  }
  const removeEventList = async (item, index) => {
    if (item.id) {
      let res = await delTask(eventId, item.id)
    }
    let arr = [...taskList]
    arr.splice(index, 1)
    setTaskList(arr)
    if (item.frequency === 'one') {
      if (item.sortType === 'Off-Chain') {
        let arr = offChain
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = false
          }
        })
        setOffChain(arr)
      } else if (item.sortType === 'On-Chain') {
        let arr = onChain
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = false
          }
        })
        setOnChain(arr)
      }
      else if (item.sortType === 'Custom-Made') {
        let arr = customMade
        arr.map((item, idx) => {
          if (index === idx) {
            item.disabled = false
          }
        })
        setCustomMade(arr)
      }
    }
  }
  const onChangeName = (e) => {
    setEventName(e.target.value)
  }
  const blurName = (e) => {
    if (hasContractAddress) { return false }
    let str = e.target.value
    if (str) {
      let defaultId = (str.replace(/[^a-zA-Z0-9-\s]+/g, '') + ' ' + generateMixed(4)).replace(/\s+/g, '-').substring(0, 20)
      formRef.setFieldsValue({ eventId: defaultId })
      setCheckAutoEventId(defaultId)
      setOnlyEventId(true)
    }
  }
  const generateMixed = (n) => {
    let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'a', 'B', 'b', 'C', 'c', 'D', 'd', 'E', 'e', 'F', 'f', 'G', 'g', 'H', 'h', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'O', 'o', 'P', 'p', 'Q', 'q', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'V', 'v', 'W', 'w', 'X', 'x', 'Y', 'y', 'Z', 'z'];
    let res = "";
    for (var i = 0; i < n; i++) {
      var id = Math.ceil(Math.random() * 61);
      res += chars[id];
    }
    return res;
  }
  const onChangeChain = (e) => {
    setEventChainType(e)
    setChainId(getChainId(e))
  }
  const onFinish = async (values) => {
    if (confirmLoading) { return false }
    if (!onlyEventId) {
      return message.error("Please Check Event Id")
    }
    if (!logoUrl) {
      return message.error("Event logo is required")
    }
    if (!imageUrl) {
      return message.error("Event image is required")
    }
    if (!values.name) {
      return message.error("Event name is required")
    }
    if (!values.chainType) {
      return message.error("ChainType is required")
    }
    if (!values.startTime) {
      return message.error("StartTime is required")
    }
    if (!values.endTime) {
      return message.error("EndTime is required")
    }
    if (!values.description) {
      return message.error("Event introduction is required")
    }
    if (selectType === 'blindBox' && !taskDescription) {
      return message.error("Task Description is required")
    }
    if (values.eligibility === 'task' && taskList.length === 0) {
      return message.error('Task cannot be empty')
    }
    if (values.eligibility === 'whitelist' && !whiteList) {
      return message.error('Please upload address')
    }

    setConfirmLoading(true);
    let formData = new FormData();
    let reg = /https:\/\//
    if (imageUrl && !reg.test(imageUrl)) {
      formData.append('image', dataURLtoBlob(imageUrl))
    }
    if (logoUrl && !reg.test(logoUrl)) {
      formData.append('logo', dataURLtoBlob(logoUrl))
    }
    if (whiteList && !reg.test(whiteList)) {
      formData.append('whitelist', dataURLtoBlob(whiteList))
    }

    values.name && formData.append('name', values.name)
    values.eventId && formData.append('eventId', values.eventId)
    values.description && formData.append('description', values.description)
    values.chainType && formData.append('chainType', values.chainType)
    values.eligibility && formData.append('eligibility', values.eligibility)
    values.startTime && formData.append('startTime', new Date(values.startTime).getTime())
    values.endTime && formData.append('endTime', new Date(values.endTime).getTime())
    selectType && formData.append('type', selectType)
    if (isEdit) {
      formData.append('originalEventId', createEventForm.originalEventId)
    }
    const taskArr = JSON.parse(JSON.stringify(taskList))
    if (values.eligibility === 'task') {
      try {
        taskArr.map(item => {
          if (item.type === 'follow_on_twitter' && !item.twitterId) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'retweet' && !item.url) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'tweet' && !item.content) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'like_tweet' && !item.url) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'quote_tweet' && (!item.url || !item.content)) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'join_discord' && !item.url) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'join_telegram' && !item.url) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'visit_website' && !item.url) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'token_balance' && (!item.tokenAddress || !item.chainType || !item.balance)) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'transaction_count' && (!item.chainType || !item.count)) {
            message.error('Task cannot be empty')
            throw Error();
          }
          if (item.type === 'token_balance') {
            if (!item.tokenAddress) {
              item.tokenAddress = item.token.address
            }
            item.balance = dealBigNum(item.balance, getDecimalByAddress(item.tokenAddress, erc20TokenList))
          }
          if (item.type === 'course' && (!item.title || !item.content || item.content==='<p><br></p>') ) {
            message.error('Task cannot be empty')
            throw Error();
          }
        })
      } catch (error) {
        setConfirmLoading(false)
        return false
      }
    }

    saveEvent(formData).then((res) => {
      message.success("Success")
      if (selectType === 'blindBox') {
        if (res?.blindBox) {
          putBlindTaskList(values.eventId, res.blindBox.type, values.eligibility === 'task' ? taskArr : [], taskDescription).then(r => {
            history.push(`/badgeManage/eventBlindDetail/${res.project}`)
          }).catch(error => {
            setConfirmLoading(false);
            message.error("Failed to submit task list. Please edit and add again");
          })
        } else {
          history.push(`/badgeManage/eventBlindDetail/${res.project}`)
        }
      } else {
        if (res?.project) {
          history.push(`/badgeManage/eventDetail/${res.project}`)
        }
      }
    }).finally(() => {
      setConfirmLoading(false);
    })
  };

  const dealBigNum = (value, decimals) => {
    const val = new BigNumber(value)
    return val.multipliedBy(new BigNumber(10).pow(decimals)).toString()
  }
  const reductionBigNum = (value, decimals) => {
    const val = new BigNumber(value)
    return val.div(new BigNumber(10).pow(decimals)).toString()
  }

  const taskInputChange = (e, item, index, value) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        if (item.type === 'follow_on_twitter') {
          item.twitterId = e.target.value
        } else if (item.type === 'retweet') {
          item.url = e.target.value
        } else if (item.type === 'tweet') {
          item.content = e.target.value
        } else if (item.type === 'like_tweet') {
          item.url = e.target.value
        } else if (item.type === 'quote_tweet') {
          item.url = e.target.value
        } else if (item.type === 'join_discord') {
          item.url = e.target.value
        } else if (item.type === 'join_telegram') {
          item.url = e.target.value
        } else if (item.type === 'visit_website') {
          item.url = e.target.value
        } else if (item.type === 'token_balance') {
          if (value === "tokenAddress") {
            item.tokenAddress = e.target.value
          } else {
            item.balance = e.target.value
          }
        } else if (item.type === 'total_gas_fee') {
          item.amount = e.target.value
        } else if (item.type === 'transaction_count') {
          let reg = /^[1-9]\d*$/
          if(e.target.value){
            if(reg.test(e.target.value)) {
              item.count = e.target.value
            }else {
              message.error('Please enter a positive integer')
            }
          }
        }
        else if (item.type === 'course') {
          item.title = e.target.value
        }
      }
    })
    setTaskList(arr)
  }
  const taskTextareaChange = (e, item, index) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        if (item.type === 'tweet') {
          item.content = e.target.value
        } else if (item.type === 'quote_tweet') {
          item.content = e.target.value
        }
      }
    })
    setTaskList(arr)
  }
  const taskSelectChange = (e, item, index, value) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        if (value === 'tokenAddress') {
          item.tokenAddress = e
        } else {
          item.chainType = e
        }
        if (item.type === 'token_balance') {
          setTokenBalanceIcon(e)
        }
        if (item.type === 'total_gas_fee') {
          setGasFeeIcon(e)
        }
      }
    })
    setTaskList(arr)
  }
  const taskRediaChange = (e, item, index) => {
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        item.channelType = e.target.value
      }
    })
    setTaskList(arr)
  }
  const onChangeTaskDes = (e) => {
    setTaskDescription(e.target.value)
  }
  const checkOnlyEventId = async (e) => {
    let val = e.target.value
    setCheckAutoEventId(val)
    if (val) {
      let reg = /^[a-zA-Z0-9-]{6,20}$/
      if (!reg.test(val)) {
        setOnlyEventId(false)
        return message.error('The event id must be longer than 6 characters and shorter than 24 characters, and can only contain letters, numbers, and ‘-’')
      }
      let res = await checkEventId(val)
      if (res === 'invalid') {
        setOnlyEventId(false)
        return message.error('That event id has been taken. Please choose another.')
      }
      setOnlyEventId(true)
    } else {
      setOnlyEventId(false)
    }
  }

  const initData = () => {
    let content = location.search
    if (content.indexOf("=") > 0) {
      let eventId = decodeURIComponent(content.substring(9))
      setEventId(eventId)
      setIsEdit(true)
      getEventInfo(eventId)
      getList(eventId)
    } else {
      setCreateEventForm({ eligibility: 'all' })
    }
  }
  const getEventInfo = async (eventId) => {
    let res = await getEventByProject(eventId)
    setImageUrl(res.image)
    setLogoUrl(res.logo)
    let obj = {
      originalEventId: res.project,
      name: res.name,
      chainType: res.chainType,
      startTime: moment(res.eventStartTime),
      endTime: moment(res.eventEndTime),
      eventId: res.project,
      description: res.description,
      eligibility: res.type === 'blindBox' ? res.blindBox.eligibility : res.eligibility
    }
    setEventName(res.name)
    setEventChainType(res.chainType)
    setCheckAutoEventId(res.project)
    setSelectType(res.type)
    if (res.type === 'blindBox') {
      setEligibilityType(res.blindBox.eligibility)
      if (res.blindBox.contractAddress) {
        setHasContractAddress(true)
      }
      setTaskDescription(res.taskDescription)
      setWhiteListName(res.blindBox.whitelistFile)
      setWhiteList(res.blindBox.whitelistFile)
    } else {
      setEligibilityType(res.eligibility)
      if (res.contractAddress) {
        setHasContractAddress(true)
      }
      setWhiteListName(res.whitelistFile)
      setWhiteList(res.whitelistFile)
    }
    if (res.type === 'blindBox' && res.blindBox.eligibility === 'task') {
      res.blindBox.tasks.map(item => {
        if (item.type === 'token_balance') {
          item.balance = reductionBigNum(item.balance, getDecimalByAddress(item.token.address, erc20TokenList))
        }
      })
      setTaskList(res.blindBox.tasks)
    }

    setCreateEventForm(obj)
    setOnlyEventId(true)
    setChainId(getChainId(res.chainType))
  }
  const getList = async (eventId) => {
    let res = await getBadgeListByProject(eventId)
    setHasBadges(res.list.length > 0 ? true : false)
  }
  const changeQuill = (e, index) => {
    console.log(e);
    let arr = JSON.parse(JSON.stringify(taskList))
    arr.map((item, idx) => {
      if (index === idx) {
        item.content = e
      }
    })
    setTaskList(arr)
    console.log(arr);
  }

  useEffect(() => {
    initData()
  }, [])
  useEffect(() => {
    account && getArtistInfo(account, account).then(res => {
      if (res.privileges) {
        if (res.privileges.includes('course')) {
          let arr = []
          let obj = { uniqueId: guid(), sortType: "Custom-Made", type: 'course', event: 'course', desc: 'Graphic course', frequency: 'more', nameColor: 'blue', disabled: false }
          arr.push(obj)
          setCustomMade(arr)
        }
      }
    })
  }, [account])

  return (
    <div className={styles.createBadge}>
      <div className='space-between-center'>
        <div className={`fs36 fw600`}>{isEdit ? 'Edit Event' : 'Create Event'}</div>
        <Steps className={styles.steps} current={stepBox - 1}>
          <Step />
          <Step />
          <Step />
        </Steps>
      </div>
      {
        createEventForm ?
          <Form form={formRef} layout={'vertical'} onFinish={onFinish}>
            <div className={`${styles.step_box} ${stepBox === 1 ? styles.step_box_show : styles.step_box_hidden}`}>
              <div className={styles.box_header}>1.Event information</div>
              <div className={styles.box_content}>
                <Row gutter={16}>
                  <Col span={7}>
                    <Form.Item label={'Project logo'} name="logo" initialValue={createEventForm.logo}>
                      <p className={styles.subtitle}>100 x 100 recommended.</p>
                      <div className={styles.event_logo} style={{ backgroundImage: `url(${logoUrl})` }}>
                        {
                          !logoUrl && <img src={iconUpload}></img>
                        }
                        <Upload className='mt10' customRequest={() => { }} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}><Button className={`btn_border w120 h40`}>Upload</Button></Upload>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item label={'Event Cover Image'} name="image" initialValue={createEventForm.image}>
                      <p className={styles.subtitle}>This image will be used as your event cover on 600 x 600 recommended.</p>
                      <div className={styles.event_cover} style={{ backgroundImage: `url(${imageUrl})` }}>
                        {
                          !imageUrl && <img src={iconUpload}></img>
                        }
                        <Upload className='mt10' customRequest={() => { }} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChangeCover}><Button className={`btn_border w120 h40`}>Upload</Button></Upload>
                      </div>
                    </Form.Item></Col>
                  <Col span={10}>
                    <Form.Item label={'Event Introduction'} name="description" initialValue={createEventForm.description}>
                      <TextArea showCount autoSize={{ minRows: 10, maxRows: 10 }} maxLength={2000} className={styles.name_input} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={14}>
                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item label={'Event Name'} name="name" initialValue={createEventForm.name}>
                          <Input onChange={onChangeName} onBlur={blurName}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={'Blockchain'} name="chainType" initialValue={createEventForm.chainType}>
                          <Select disabled={hasContractAddress} onChange={onChangeChain}>
                            {
                              chainArr.map(item => (
                                <Option key={item.label + new Date().getTime()} value={item.value}>
                                  <img className={styles.option_img} src={chainTypeComImgObj[item.value]} alt={item.label} />
                                  <span className={'pl8'}>{item.label}</span>
                                </Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item label={'Event Time'} name="startTime" initialValue={createEventForm.startTime}>
                          <DatePicker disabled={hasContractAddress} placeholder='Start Time' format={'YYYY-MM-DD HH:mm:ss'} showTime></DatePicker>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={' '} name="endTime" initialValue={createEventForm.endTime}>
                          <DatePicker disabled={hasContractAddress} placeholder='End Time' format={'YYYY-MM-DD HH:mm:ss'} showTime></DatePicker>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Form.Item label={'Event ID'}>
                      <p className={styles.subtitle}>The event id must be longer than 6 characters and shorter than 20 characters, and can only contain letters, numbers, and ‘-’</p>
                      <Form.Item name="eventId" initialValue={createEventForm.eventId}>
                        <Input disabled={hasContractAddress} onBlur={(e) => { checkOnlyEventId(e) }}></Input>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <hr style={{ border: '1px dashed #DEDEDE', width: '1200px', margin: 'auto' }}></hr>
              <div className='space-between-center p30'>
                <Popover placement="topRight" overlayClassName={'event_popover'} content={
                  <div className={`cp`}>
                    <div className={'event_item'}>
                      <img className={'item_image'} src={imageUrl}></img>
                      <img className={'card_chain'} src={chainTypeImgObj[eventChainType]}></img>
                      <div className={'item_footer'}>
                        <div className={'footer_content'}>
                          <img className={'badge_logo'} width={54} src={logoUrl}></img>
                          <span className={'item_name'}>{eventName}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }>
                  <span className={styles.preview}>Preview</span>
                </Popover>
                <Button type="primary" className={`w200 h50`} onClick={() => { nextStep(2) }}>Next</Button>
              </div>
            </div>
            <div className={`${styles.step_box} ${stepBox === 2 ? styles.step_box_show : styles.step_box_hidden}`} >
              <div className={styles.box_header}>2.Select Event Type</div>
              <div className={`${styles.box_content} space-between`}>
                <div className={styles.selectType_box} onClick={() => { slectType('general') }}>
                  <div className={`${styles.selectType} ${selectType === 'general' ? styles.selectType_active : ''}`}>
                    <img className={styles.selectType_img} src={badgeType1}></img>
                    <div className={`fs18 fw600`}>General Event</div>
                  </div>
                  <p className={styles.selectType_des}>A general badge event with known NFTs as rewards.</p>
                </div>
                <div className={styles.selectType_box} onClick={() => { slectType('blindBox') }}>
                  <div className={`${styles.selectType} ${selectType === 'blindBox' ? styles.selectType_active : ''}`}>
                    <img className={styles.selectType_img} src={badgeType2}></img>
                    <div className={`fs18 fw600`}>Mystery Box</div>
                  </div>
                  <p className={styles.selectType_des}>A mystery box event with NFT rewards that contain a random but unknown NFT or set of NFTs with different rarity levels.</p>
                </div>
                <div className={styles.selectType_box} onClick={() => { slectType('airdrop') }}>
                  <div className={`${styles.selectType} ${selectType === 'airdrop' ? styles.selectType_active : ''}`}>
                    <img className={styles.selectType_img} src={badgeType3}></img>
                    {/* <div className={styles.selectType_soon}>Airdrop</div> */}
                    <div className={`fs18 fw600`}>Airdrop</div>

                  </div>
                  <p className={styles.selectType_des}>Event creators will drop the badges to the eligible users automatically. Users don't need to claim badges themselves.</p>
                </div>
              </div>
              <hr style={{ border: '1px dashed #DEDEDE', width: '1200px', margin: 'auto' }}></hr>
              <div className='space-between-center p30'>
                <Button className={`btn_border w200 h50`} onClick={() => { nextStep(1) }}>Back</Button>
                <Button type="primary" className={`w200 h50`} onClick={() => { nextStep(3) }}>Next</Button>
              </div>
            </div>
            <div className={`${styles.step_box} ${stepBox === 3 ? styles.step_box_show : styles.step_box_hidden}`}>
              <div className={styles.box_header}>3.Set event Eligibility</div>
              <div className={`${styles.box_content}`}>
                <Form.Item name={'eligibility'} initialValue={createEventForm.eligibility} label={'Please set the eligibility to participate the event'}>
                  <Select defaultValue={'all'} className={styles.select_eligibility} onChange={handleSelectEligibility}>
                    <Option value="all">All Users</Option>
                    <Option value="whitelist">Upload Addresses</Option>
                    {
                      selectType === "blindBox" &&
                      <Option value="task">Choose Tasks</Option>
                    }
                  </Select>
                </Form.Item>
                {
                  eligibilityType === 'all' &&
                  <div className={styles.allUser}>
                    <div className='c_9a fs16'>Anyone can participate in this event.</div>
                  </div>
                }
                {
                  eligibilityType === 'whitelist' &&
                  <div className={styles.allUser}>
                    {
                      whiteListName ?
                        <div className='c_9a'>{whiteListName}</div> :
                        <img src={iconUpload}></img>
                    }
                    <Upload headers={{ authorization: 'authorization-text' }} className='mt10' customRequest={() => { }} showUploadList={false} beforeUpload={beforeUploadWhiteList} onChange={handleChangeAddress}><Button className={`btn_border w200 h40`}>Upload Addresses</Button></Upload>
                    <p className='mt10'>Download this <a download className={styles.download_temp} href={whiteListTemplate}>reference template</a>.</p>
                  </div>
                }
                {
                  eligibilityType === 'task' &&
                  <div className={styles.filter_box}>
                    <Tabs defaultActiveKey="2" type='card'>
                      <TabPane key='1' disabled={true} tab="Choose a template"></TabPane>
                      <TabPane key='2' tab="All">
                        <div className={styles.add_list}>
                          {
                            offChain.concat(onChain).concat(customMade).map((item, index) => (
                              <div key={item.id + index} className={styles.add_item}>
                                <div>{item.desc}</div>
                                {
                                  !item.disabled &&
                                  <div className={styles.add_btn} onClick={() => { addEventList(item, index) }}>+</div>
                                }
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                      <TabPane key='3' tab="Off-Chain">
                        <div className={styles.add_list}>
                          {
                            offChain.map(item => (
                              <div key={item.id} className={styles.add_item}>
                                <div>{item.desc}</div>
                                {
                                  !item.disabled &&
                                  <div className={styles.add_btn} onClick={() => { addEventList(item) }}>+</div>
                                }
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                      <TabPane key='4' tab="On-Chain">
                        <div className={styles.add_list}>
                          {
                            onChain.map(item => (
                              <div key={item.id} className={styles.add_item}>
                                <div>{item.desc}</div>
                                {
                                  !item.disabled &&
                                  <div className={styles.add_btn} onClick={() => { addEventList(item) }}>+</div>
                                }
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                      <TabPane key='5' tab="Custom-Made">
                        <div className={styles.add_list}>
                          {
                            customMade.map(item => (
                              <div key={item.id} className={styles.add_item}>
                                <div>{item.desc}</div>
                                {
                                  !item.disabled &&
                                  <div className={styles.add_btn} onClick={() => { addEventList(item) }}>+</div>
                                }
                              </div>
                            ))
                          }
                        </div>
                      </TabPane>
                    </Tabs>
                    <div className='mt10'>
                      {
                        taskList.map((item, index) => (
                          <>
                            {
                              item.type === 'follow_on_twitter' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Follow on Twitter</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Username</div>
                                    <div className='f1 pt10 pb10'><Input prefix={'@'} defaultValue={item.twitterId} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'retweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Retweet a Tweet</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Tweet URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }

                            {
                              item.type === 'like_tweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Like a Tweet on Twitter</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Tweet URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'join_discord' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Join a Discord server</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Discord server URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'tweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue2']}`}>Tweet on Twitter</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Tweet</div>
                                    <div className={`f1 pt10 pb10`}><TextArea defaultValue={item.content} onChange={(e) => { taskTextareaChange(e, item, index) }} placeholder='Input template, can contain #tag or URL'></TextArea></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'quote_tweet' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Quote Tweet on Twitter</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Quote Tweet</div>
                                      <div className={`f1 pt10 pb10`}><TextArea defaultValue={item.content} onChange={(e) => { taskTextareaChange(e, item, index) }} placeholder='Input template, can contain #tag or URL'></TextArea></div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Tweet URL</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter a tweet URL'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'visit_website' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_blue']}`}>Visit a website</div>
                                  <div className={`df_center f1 ${styles.task_content}`}>
                                    <div className={`pl20 w200`}>Website URL</div>
                                    <div className='f1 pt10 pb10'><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder={item.placeholder}></Input></div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'join_telegram' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Join a Telegram channel</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Channel link</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.url} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter a channel URL'></Input></div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Channel type</div>
                                      <div className={`f1 pt10 pb10`}><Radio.Group defaultValue={item.channelType} buttonStyle="solid" onChange={(e) => { taskRediaChange(e, item, index) }}>
                                        <Radio.Button value="public">Public</Radio.Button>
                                        <Radio.Button value="private">Private</Radio.Button>
                                      </Radio.Group></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'token_balance' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Verify token balance</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Select a network</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.chainType} onChange={(e) => { taskSelectChange(e, item, index) }}>
                                          {
                                            selectObj[env.ENV].map(item => (
                                              <Option key={item.name + guid()} value={item.value}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Token contract address</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.token?.address} onChange={(e) => { taskSelectChange(e, item, index, 'tokenAddress') }}>
                                          {
                                            erc20TokenList.filter(el => el.chainIds.includes(getChainId(item.chainType))).map(item => (
                                              <Option key={item.address} value={item.address}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                      {/* <div className={`f1 pt10 pb10`}><Input defaultValue={item.token?.address} onChange={(e) => { taskInputChange(e, item, index, 'tokenAddress') }} placeholder='Enter the token contract address'></Input></div> */}
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Token balance</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.balance} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter amount'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'total_gas_fee' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Total amount of gas fees spent</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Select a network</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.chainType} onChange={(e) => { taskSelectChange(e, item, index) }}>
                                          {
                                            selectObj[env.ENV].map(item => (
                                              <Option key={item.name} value={item.value}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Gas fees spend value</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.amount} suffix={gasFeeIcon ? tokenObj[gasFeeIcon] : item.chainType ? tokenObj[item.chainType] : ''} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter value [ETH, BNB, MATIC]'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'transaction_count' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Number of Transactions</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Select a network</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Select className='w200' defaultValue={item.chainType} onChange={(e) => { taskSelectChange(e, item, index) }}>
                                          {
                                            selectObj[env.ENV].map(item => (
                                              <Option key={item.name + guid()} value={item.value}>{item.name}</Option>
                                            ))
                                          }
                                        </Select>
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Number of Transactions</div>
                                      <div className={`f1 pt10 pb10`}><Input defaultValue={item.count} onChange={(e) => { taskInputChange(e, item, index) }} placeholder='Enter value'></Input></div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                            {
                              item.type === 'course' &&
                              <div key={() => { guid() }} className={styles.task_item}>
                                <div className='df_center hp100 f1 pr'>
                                  <div className={`${styles.task_name} ${styles['task_purple']}`}>Graphic course</div>
                                  <div className={` f1 ${styles.task_content}`}>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Title</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <Input defaultValue={item.title} onChange={(e) => { taskInputChange(e, item, index) }} />
                                      </div>
                                    </div>
                                    <div className={`df_center`}>
                                      <div className={`pl20 w200`}>Content</div>
                                      <div className={`f1 pt10 pb10`}>
                                        <ReactQuill ref={quillRef} value={item.content} modules={modules}
                                          onChange={(e) => { changeQuill(e, index) }}
                                          formats={formats}
                                        >
                                        </ReactQuill>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${styles.add_btn} ml40 mr40`} onClick={() => { removeEventList(item, index) }}>-</div>
                              </div>
                            }
                          </>
                        ))
                      }
                    </div>
                  </div>
                }
                {
                  selectType === 'blindBox' &&
                  <Form.Item className='mt20' label={'Task description'}>
                    <TextArea value={taskDescription} onChange={(e) => { onChangeTaskDes(e) }}></TextArea>
                  </Form.Item>
                }
              </div>
              <hr style={{ border: '1px dashed #DEDEDE', width: '1200px', margin: 'auto' }}></hr>
              <div className='space-between-center p30'>
                <Button className={`btn_border w200 h50`} onClick={() => { nextStep(2) }}>Back</Button>
                <Button type="primary" className={`w200 h50 ${styles.create_btn}`} htmlType="submit">Save {confirmLoading && <LoadingOutlined />}</Button>
              </div>
            </div>
          </Form> :
          <div className={`tac mt50`}>
            <Spin size={`large`}></Spin>
          </div>
      }

    </div>
  )
}
