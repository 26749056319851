import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import moment from 'moment';

import { useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import { formatCreateTime, formatAmountWithDecimal } from "utils/format"
import { useList } from "../../services/topic"
import RecommendBar from "components/RecommendBar";
import NoData from "components/NoData";
import hot from "assets/img/topic/hot.svg"
import hotRed from 'assets/img/topic/hot_red.svg'
import styles from "./styles.module.scss";


export default function TopicList() {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const [refresh, setRefresh] = useState(1);
    const { list, total } = useList(pageNo, pageSize, setLoading, refresh)

    const getList = () => {
        setPageNo(pageNo + 1)
    }
    const gotoItem = (content) => {
        history.push(`/topic/${encodeURIComponent(content)}`)
    }
    const gotoWOW = () => {
        history.push(`/wow`)
    }
    const gotoNotification = () => {
        history.push(`/message/likeme`)
    }
    const dealNftName = (name,num) => {
        let str = ''
        if(!name) {
            return '--'
        }else if(name.length>num){
            str = name.substring(0,num)+'...'
            return str
        }else {
            return name
        }
    }
    useEffect(() => {
        setRefresh(refresh + 1)
    }, [])
    return (
        <div className="df">
            <div className={styles.content_box}>
                <div className={`df ${styles.nav_h5}`}>
                    <div className={`f1 tac pr16 border_right`} onClick={gotoWOW}>WoW</div>
                    <div className={`f1 tac fw700 border_right pl16`}>Topic</div>
                    <div className={`f1 tac pl16`} onClick={gotoNotification}>Notification</div>
                </div>
                {
                    loading ? <Spin className={`wp100 mt40`} size="large"></Spin> :
                        <InfiniteScroll
                            dataLength={list.length}
                            next={getList}
                            hasMore={list.length < total}
                            loader={
                                <div style={{ width: 216, textAlign: 'center', margin: '10px auto' }}>
                                    <Spin size={`large`} />
                                </div>
                            }
                        >
                            {
                                list.length > 0 && list.map((item, index) => (
                                    <>
                                        <div key={item.latestActivityTime} className={`${styles.topic_item}`} onClick={() => { gotoItem(item.topic) }}>
                                            {
                                                index > 2 ? <><div>{index + 1}.</div></> :
                                                    <>
                                                        <div className={`${styles.item_hot} bg_hot_${index + 1}`}>
                                                            <img className={`mr5`} src={hot}></img>
                                                            TOP {index + 1}
                                                        </div>
                                                    </>
                                            }
                                            <div className={`f1 ml10 ${index > 2 ? styles.item_title : styles.item_title_hot}`}>{dealNftName(item.topic,50)}
                                                {/* {
                                                    item.latestActivityImage &&
                                                    <img className={`br4 ml10`} width={26} height={24} src={item.latestActivityImage}></img>
                                                } */}
                                            </div>
                                            <div>
                                                <div className={`tar fs14`}>{formatAmountWithDecimal(item.activityCount, 0, 0)} Wows</div>
                                                <div className={`c_9a fs12`}>{formatCreateTime(item.latestActivityTime, t)}</div>
                                            </div>
                                        </div>
                                        <div className={`space-between-center cp ${index > 2 ? 'fw500' : 'fw700'} ${styles.topic_item_h5}`} onClick={() => { gotoItem(item.topic) }}>
                                            <div>{index + 1}. {dealNftName(item.topic,20)}</div>
                                            <div>
                                                {
                                                    index < 3 &&
                                                    <img src={hotRed}></img>
                                                }
                                                <span className={`pl5`}>{formatAmountWithDecimal(item.activityCount, 0, 0)} Wows</span>
                                            </div>
                                        </div>

                                    </>

                                ))
                            }
                            {
                                list.length === 0 && <div className={`wp100 tac mt60`}><NoData></NoData></div>
                            }
                        </InfiniteScroll>
                }
            </div>
            <RecommendBar />
        </div>
    )
}
