import React, { useState } from "react";
import { Spin, Switch, Button, Select, Radio } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getChainType } from "../../web3/address";
import { useActiveWeb3React } from "../../web3";
import NftCard from 'components/NftCard';
import { useList } from "../../services/aoz";
import styles from "./styles.module.scss";
import filter from "../../assets/img/Vector.svg";
import aozBg from "../../assets/img/aoz/aoz.jpg"
// import empty from "../../assets/img/empty.svg"
import NoData from "components/NoData";


let timer;
const { Option } = Select;

const Aoz = () => {
    const [loading, setLoading] = useState(false);
    const { library, chainId, account } = useActiveWeb3React()
    const chainType = getChainType(chainId)
    const [current, setCurrent] = useState(1);
    const [isOnSale, setIsOnSale] = useState('')
    const { t } = useTranslation()
    const [collection, setCollection] = useState('aoz')
    // const { list, total } = useList(current, 30, setLoading, isOnSale, 'aoz')
    const { list, total } = useList(current, 30, setLoading, isOnSale, collection)


    const toggleOnSale = (checked) => {
        setCurrent(1);
        setIsOnSale(checked ? 'sale' : '')
    }

    const getList = () => {
        setCurrent(current + 1)
    }

    const onChangeRadio = (e) => {
        setCollection(e.target.value)
    }

    return (
        <div className={styles.container}>
            <div className={`tac auto ${styles.page_head_img}`} style={{ backgroundImage: `url(${aozBg})` }}>
                <div className={`${styles.page_body}`}>
                    <div className={`${styles.title}`}>{t('aoz_event_text_1')} {t('event_title2')}</div>
                    <div className="subtitle_wrapper">
                        <div className={`${styles.subtitle}`}>
                            {t('aoz_event_text_2')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.page_body}`}>
                <div className={`space-between-center mt20`}>
                    <div className={styles.page_tab_radio}>
                        <Radio.Group size={'large'} defaultValue="aoz" onChange={onChangeRadio} buttonStyle="solid">
                            <Radio.Button value="aoz">Persale</Radio.Button>
                            <Radio.Button value="aoz-sr">Aution</Radio.Button>
                        </Radio.Group>
                    </div>
                    {/* <div>
                        <Switch onChange={toggleOnSale} />
                        <span className={"fs14 c_l ml10 fw600"}>{t('on_sale')}</span>
                    </div> */}
                </div>
                <div className={`page_collection_list`}>
                    {
                        loading ? <Spin className={`wp100 mt40`} size="large"></Spin> :
                            <InfiniteScroll
                                dataLength={list.length}
                                next={getList}
                                hasMore={list.length < total}
                                loader={
                                    <div style={{ width: 216, textAlign: 'center', margin: 'auto' }}>
                                        <Spin size={`large`} />
                                    </div>
                                }
                            >
                                {
                                    list.length > 0 && list.map(item => (
                                        <NftCard key={item.contractAddress + item.id} tokenInfo={item} />
                                    ))
                                }
                                {
                                    list.length === 0 && <div className={`wp100 tac mt50`}><NoData></NoData></div>
                                }
                            </InfiniteScroll>
                    }
                </div>
                <div>
                    <div className={`${styles.des}`}>
                        <div className={`p20 c_000`}>
                            <h3>{t('aoz_desc_title_1')}</h3>
                            <ul>
                                <li className={'fs14'}>{t('aoz_desc_text_1')}</li>
                            </ul>
                        </div>
                        <div className={`p20 c_000`}>
                            <h3>{t('aoz_desc_title_2')}</h3>
                            <ul>
                                <li className={'fs14'}>{t('aoz_desc_text_2')}</li>
                            </ul>
                        </div>
                        <div className={`p20 c_000`}>
                            <h3>{t('aoz_desc_title_3')}</h3>
                            <ul>
                                <li className={'fs14'}>{t('aoz_desc_text_3')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_4')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_5')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_6')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_7')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_8')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_9')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_10')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_11')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_12')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_13')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_14')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_15')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_16')}</li>
                                <li className={'fs14'}>{t('aoz_desc_text_17')}</li>
                            </ul>
                        </div>
                        <div className={`p20 c_000`}>
                            <h5>{t('aoz_desc_text_18')}</h5>
                            <p className={'fs14'}>Homepage: https://aoz.org</p>
                            <p className={'fs14'}>Twitter: https://twitter.com/AoZ_Official</p>
                            <p className={'fs14'}>Discord: https://discord.com/invite/aoz</p>
                            <p className={'fs14'}>Medium: https://medium.com/@AoZ_Official</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Aoz;
