import React, { useContext } from "react";
import Lottie from "react-lottie";
import { Modal } from "antd"

import { HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL } from "../../const";
import { mainContext } from "../../reducer";
import loading from '../../assets/loading.json'
import styles from "./styles.module.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const WaitingWalletConfirmModal = ({ visible }) => {
  const { dispatch, state } = useContext(mainContext);
  const { showWaitingWalletConfirmModal } = state;

  const handleCancel = () => {
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: {
        show: false,
        title: "",
        content: ""
      }
    });
  }

  return (
    <Modal
      width={450}
      className={styles.modal_main}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div className={styles.waitting_loading}>
        <Lottie width={200} height={200} options={defaultOptions} />
      </div>
      <h3 className={styles.waitting_title}>
        {showWaitingWalletConfirmModal.title}
      </h3>
      <p className={styles.waitting_content}>
        {showWaitingWalletConfirmModal.content}
      </p>
    </Modal>
  );
};
