import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu, Icon, Modal, Input, message, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Web3 from "web3";
import { QRCodeSVG } from 'qrcode.react';
import copy from 'copy-to-clipboard';

import { getEventByProject, addGroup, delGroup, delBadge, delEvent, getBadgeDetail, queryAccount, queryBaseGasFee, setGasPrice, claimGas, getParticipants } from '../../../services/badgeManage'
import { getBadgeListByProject } from '../../../services/badge'
import { chainTypeComImgObj, chainTxtObj } from '../../../utils/networkConnect';
import { abbrTxHash } from "../../../utils/format";
import iconManage from "assets/img/creatBadge/icon_manage.svg"
import { ReactComponent as IconDownload } from "assets/img/creatBadge/icon_subtract.svg";
import { ReactComponent as IconEdit } from "assets/img/creatBadge/edit.svg";
import { ReactComponent as IconDel } from "assets/img/creatBadge/delete.svg";
import { ReactComponent as IconQRCode } from "assets/img/creatBadge/qrcode.svg";
import { ReactComponent as IconQRSave } from "assets/img/creatBadge/save.svg";
import styles from './style.module.scss'
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React } from "../../../web3";
import { useActiveTronWeb } from "hooks/activeTronWeb";
import env from '../../../env'
import { mainContext } from "../../../reducer";
import {
  HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
  waitingForConfirm,
} from "../../../const";

const tokenObj = {
  mainnet: 'ETH',
  goerli: 'ETH',
  bsc: 'BNB',
  bsctestnet: 'BNB',
  polygon: 'MATIC',
  mumbai: 'MATIC',
  optimism: 'ETH',
  optimisticgoerli: 'ETH',
  arbitrum: 'ETH',
  nitrogoerli: 'ETH',
  zksyncera:'ETH',
  zksynceratest:'ETH',
  base:'ETH',
  basegoerli:'ETH',
  loot:'AGLD',
  loottest:'AGLD',
}
const baseUrl = {
  development: 'https://staging.app.define.one/define-badge/',
  production: 'https://app.define.one/define-badge/',
}

export default function EventDetailPage() {
  const { account, active, library } = useActiveWeb3React()
  const { tronLibrary, tronAccount, tronActive } = useActiveTronWeb();
  const history = useHistory()
  const { eventId } = useParams()
  const [groupModal, setGroupModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delBadgeModal, setDelBadgeModal] = useState(false);
  const [dashboardModal, setDashboardModal] = useState(false);
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [delBadgeType, setDelBadgeType] = useState('');
  const [grouptitle, setGroupTitle] = useState('Add group');
  const [groupName, setGroupName] = useState('');
  const [eventInfo, setEventInfo] = useState({})
  const [groupList, setGroupList] = useState([])
  const [currentGroup, setCurrentGroup] = useState()
  const [currentIdx, setCurrentIdx] = useState()
  const [badgeList, setBadgeList] = useState([])
  const [isPublished, setIsPublished] = useState(false)
  const [isDeployBadge, setIsDeployBadge] = useState(false)
  const [dashbordInfo, setDashbordInfo] = useState({})
  const [gasFee, setGasFee] = useState()
  const [availableGasFee, setAvailableGasFee] = useState()
  const [executionAddress, setExecutionAddress] = useState()
  const [baseOneGasFee, setBaseOneGasFee] = useState()
  const [gwei, setGwei] = useState()
  const [baseGwei, setBaseGwei] = useState()
  const [qrCodeBadgeType, setQrCodeBadgeType] = useState()
  const [expectedGasFee, setExpectedGasFee] = useState()
  const { dispatch } = useContext(mainContext);
  const [gasPriceSet, setGasPriceSet] = useState(false)



  const getEventInfo = async () => {
    let res = await getEventByProject(eventId)
    if (res.creator != account) {
      history.push('/badgeManage')
      return false
    }
    if (res.status !== "draft") {
      setIsPublished(true)
    }
    setGroupList([])
    if (res.groups) {
      setGroupList(res.groups)
    }
    setEventInfo(res)
  }
  const saveGroup = async (currentGroup) => {
    if (groupName) {
      let formData = new FormData();
      formData.append('group', groupName)
      if (currentGroup) {
        formData.append('originalGroup', currentGroup)
      }
      let res = await addGroup(eventId, formData)
      if (res === 'success') {
        setGroupModal(false)
        getEventInfo()
      }
    }
  }
  const openAdd = () => {
    setGroupTitle('Add Group')
    setCurrentGroup('')
    setGroupName('')
    setGroupModal(true)
  }
  const openEdit = (name, index) => {
    setGroupTitle('Edit Group')
    setCurrentGroup(name)
    setCurrentIdx(index)
    setGroupModal(true)
    setGroupName(name)
  }
  const openDelBadgeModal = (item) => {
    if (item.contractAddress) {
      message.error('Badge is deployed')
      return false
    } else {
      setDelBadgeType(item.type)
      setDelBadgeModal(true)
    }
  }
  const removeGroup = async (groupName, index) => {
    let res = await delGroup(eventId, groupName)
    if (res === 'success') {
      setGroupModal(false)
      getEventInfo()
    }
  }
  const gotoCreateBadge = () => {
    history.push(`/badgeManage/createBadge/${eventId}`)
  }
  const getList = async () => {
    let res = await getBadgeListByProject(eventId)
    setBadgeList(res.list)
    if (res.list[0]?.contractAddress) {
      setIsDeployBadge(true)
    }
  }
  const removeBadge = async (badgeType) => {
    let res = await delBadge(eventId, badgeType)
    setDelBadgeModal(false)
    if (res) {
      message.success('Success')
      getList()
    }
  }
  const gotoCreateEvent = () => {
    history.push(`/badgeManage/createEvent?eventId=${eventId}`)
  }
  const removeEvent = async () => {
    let res = await delEvent(eventId)
    if (res) {
      history.push('/badgeManage')
    }
  }
  const gotoBadgeInfo = (badgeType) => {
    history.push(`/badgeManage/createBadge/${eventId}?badgeType=${badgeType}`)
  }
  const gotoPublish = () => {
    if (badgeList.length === 0) {
      return message.error("Please create badge")
    }
    history.push(`/badgeManage/publish/${eventId}`)
  }
  const openDashbord = (item) => {
    // queryBadgeInfo(type)
    setDashbordInfo(item)
    setDashboardModal(true)
    queryExecutionAccount(item)
  }
  const queryBadgeInfo = async (type) => {
    let res = await getBadgeDetail(type)
    // console.log(res);
  }
  const queryExecutionAccount = async (item) => {
    let res = await queryAccount()
    setExecutionAddress(res.address)
    queryBalance(res.address)
    if (res.gasPrices[eventInfo.chainType]) {
      setGwei(countPriceToGwei(res.gasPrices[eventInfo.chainType]))
      getGasFeeBase(item.chainType, countPriceToGwei(res.gasPrices[eventInfo.chainType])).then(baseGasInfo => {
        countGaeFee(item.maxCount, baseGasInfo.gwei, baseGasInfo.baseGwei, baseGasInfo.baseOneGasFee)

      })
    } else {
      setGwei(null)
      getGasFeeBase(item.chainType, null).then(baseGasInfo => {
        countGaeFee(item.maxCount, baseGasInfo.gwei, baseGasInfo.baseGwei, baseGasInfo.baseOneGasFee)
      })
    }
  }
  const getGasFeeBase = async (chainType, gwei) => {
    let res = await queryBaseGasFee(chainType)
    setBaseOneGasFee(res.gasFee)
    if (!gwei) {
      setGwei(countPriceToGwei(res.gasPrice))
    }
    setBaseGwei(res.gasPrice)
    let obj = {
      gwei: gwei ? gwei : countPriceToGwei(res.gasPrice),
      baseGwei: res.gasPrice,
      baseOneGasFee: res.gasFee,
    }
    return obj
  }
  const countPriceToGwei = (price) => {
    return new BigNumber(price).div(new BigNumber(10).pow(9)).toFixed()
  }
  const countGweiToPrice = (gwei) => {
    return new BigNumber(gwei).multipliedBy(new BigNumber(10).pow(9)).toFixed()
  }
  const countGaeFee = (maxCount, gwei, baseGwei, baseOneGasFee) => {
    let oneGasFee = new BigNumber(new BigNumber(countGweiToPrice(gwei)).div(new BigNumber(baseGwei)).multipliedBy(new BigNumber(baseOneGasFee))).toFixed()
    const gasFees = new BigNumber(maxCount * oneGasFee).toFixed()
    setGasFee(gasFees)
  }
  const formatBigNumberDecimal = (bigNumber) => {
    return new BigNumber(bigNumber).div(new BigNumber(10).pow(18)).toFixed()
  }
  const queryBalance = (address) => {
    if (window.ethereum && active) {
      const web3 = new Web3(library.provider);
      // console.log(web3);
      web3.eth.getBalance(address).then(res => {
        // console.log(res);
        setAvailableGasFee(res)
      })
    } else if (window.tronWeb && tronActive) {
      window.tronWeb.trx.getBalance(address).then(res => {
        setAvailableGasFee(res)
      })
    }
  }
  const countExpectedGasFee = (gasFee, availableGasFee) => {
    if (gasFee && availableGasFee) {
      if (formatBigNumberDecimal(new BigNumber(gasFee).minus(new BigNumber(availableGasFee))) > 0) {
        return formatBigNumberDecimal(new BigNumber(gasFee).minus(new BigNumber(availableGasFee)))
      } else {
        return 0
      }
    }
  }
  const openSet = () => {
    setGasPriceSet(true)
  }
  const changeGwei = (e) => {
    setGwei(e.target.value)
  }
  const saveGasPrice = async () => {
    let newGwei = new BigNumber(gwei * new BigNumber(10).pow(9)).toFixed()
    let res = await setGasPrice(eventInfo.chainType, newGwei)
    countGaeFee(dashbordInfo.maxCount, gwei, baseGwei, baseOneGasFee)
    message.success('Success')
    setGasPriceSet(false)
  }

  const openQrcode = (badgeType) => {
    setQrCodeModal(true)
    setQrCodeBadgeType(badgeType)
  }
  const transfer = () => {
    dispatch({
      type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
      showWaitingWalletConfirmModal: waitingForConfirm
    });
    const web3 = new Web3(library.provider);
    let exp = new BigNumber(new BigNumber(gasFee).minus(new BigNumber(availableGasFee))).toFixed()
    let obj = {
      from: account,
      to: executionAddress,
      value: exp
    }
    web3.eth.sendTransaction(obj).then(res => {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
      message.success("Success")
    }).catch(error => {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
      message.error("Error")
    })
  }
  const handleCopy = (address) => {
    copy(address);
    message.success('Copied!')
  }
  const claimGasFee = async () => {
    try {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: waitingForConfirm
      });
      // let count = new BigNumber(availableGasFee).minus(new BigNumber(0.001).multipliedBy(new BigNumber(10).pow(18)))
      let res = await claimGas(eventInfo.chainType, account)
      // console.log(res);
      if (res) {
        dispatch({
          type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
          showWaitingWalletConfirmModal: { show: false }
        });
        message.success("Success")
        setDashboardModal(false)
      }
    } catch (error) {
      dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: { show: false }
      });
      message.error("Insufficient balance")

    }
  }
  const downloadCSV = async (type) => {
    let url = await getParticipants(type)
    let domA = document.createElement('a'); 
    domA.setAttribute('download', ''); 
    domA.setAttribute('href', url);
    domA.click();
  }

    useEffect(() => {
      getEventInfo()
      getList()
    }, [])
    return (
      <>
        <Modal visible={groupModal} title={grouptitle} width={500} footer={null} onCancel={() => { setGroupModal(false) }}>
          <Input placeholder='Add your group' value={groupName} onChange={(e) => { setGroupName(e.target.value) }}></Input>
          {
            grouptitle === 'Add group' ?
              <div className='space-between mt20'>
                <Button className={`w200  h50 btn_border`} onClick={() => { setGroupModal(false) }}>Cancel</Button>
                <Button type={'primary'} className={`w200  h50`} onClick={() => { saveGroup() }}>Save group</Button>
              </div> :
              <div className='space-between mt20'>
                <Button className={`w200  h50 btn_border`} onClick={() => { removeGroup(currentGroup, currentIdx) }}>Delete Empty Group </Button>
                <Button type={'primary'} className={`w200  h50`} onClick={() => { saveGroup(currentGroup) }}>Save group</Button>
              </div>
          }
        </Modal>
        <Modal visible={delModal} title={'Delete event'} width={500} footer={null} onCancel={() => { setDelModal(false) }}>
          <div>The badge in the event will also be cleared,  Are you sure you want to delete this event?</div>
          <div className='space-between mt20'>
            <Button className={`w200  h50 btn_border`} onClick={() => { setDelModal(false) }}>Cancel</Button>
            <Button type={'primary'} className={`w200  h50`} onClick={removeEvent}>Delete event</Button>
          </div>
        </Modal>
        <Modal visible={delBadgeModal} title={'Delete badge'} width={500} footer={null} onCancel={() => { setDelBadgeModal(false) }}>
          <div>Are you sure you want to delete this badge?</div>
          <div className='space-between mt20'>
            <Button className={`w200  h50 btn_border`} onClick={() => { setDelBadgeModal(false) }}>Cancel</Button>
            <Button type={'primary'} className={`w200  h50`} onClick={() => { removeBadge(delBadgeType) }}>Delete badge</Button>
          </div>
        </Modal>
        <Modal visible={dashboardModal} title={'Dashboard'} width={790} footer={null} onCancel={() => { setDashboardModal(false) }}>
          <div className={styles.dashbord_tip}>The estimated gas is based on the network status and may vary during airdrop.</div>
          <Row className='mt20 pl20'>
            <Col span={8}>
              <div>Airdrop Time</div>
              <div className='fw600 mt4'>{moment(dashbordInfo.claimStartTime).format('YYYY-MM-DD HH:mm:ss')}</div>
            </Col>
            <Col span={8}>
              <div>Expiration Time</div>
              <div className='fw600 mt4'>{moment(dashbordInfo.claimEndTime).format('YYYY-MM-DD HH:mm:ss')}</div>
            </Col>
            <Col span={8}>
              <div>Total Amount</div>
              <div className='fw600 mt4'>{dashbordInfo.maxCount}</div>
            </Col>
          </Row>
          <Row className='mt20 pl20'>
            <Col span={8}>
              <div>Available Gas Fee</div>
              <div className='fw600 mt4'>{formatBigNumberDecimal(availableGasFee)} {tokenObj[dashbordInfo.chainType]} </div>
            </Col>
            <Col span={8}>
              <div>Airdroped Addresses</div>
              <div className='fw600 mt4'>{dashbordInfo.grantedCount}</div>
            </Col>
            <Col span={8}>
              <div>Unairdroped Addresses</div>
              <div className='fw600 mt4'>{dashbordInfo.maxCount - dashbordInfo.grantedCount}</div>
            </Col>
          </Row>
          <Row className={styles.dashbord_gasFee}>
            <Col span={8}>
              <div>Expected Airdrop Address</div>
              <div className='fw600 mt4'>{dashbordInfo.maxCount}</div>
            </Col>
            <Col span={8}>
              <div>Expected Gas Fee</div>
              <div className='fw600 mt4'>{countExpectedGasFee(gasFee, availableGasFee)} {tokenObj[dashbordInfo.chainType]}</div>
            </Col>
            <Col span={5}>
              <div>Execution Address</div>
              <div className={`fw600 mt4 cp ${styles.executionAddress}`} onClick={() => { handleCopy(executionAddress) }}>{abbrTxHash(executionAddress)}</div>
            </Col>
            <Col span={3}>
              <QRCodeSVG width={70} height={70} value={executionAddress} />
            </Col>
          </Row>
          {
            gasPriceSet ?
              <div className={styles.saveGasPrice}>
                <div>GasPrice(GWEI)</div>
                <Input value={gwei} onChange={changeGwei}></Input>
                <div className={`cp df_center c_purple`} onClick={saveGasPrice}><IconQRSave className='mr4' /> Save</div>
              </div> :
              <div className={styles.setGasPrice}>
                <div>GasPrice(GWEI)</div>
                <div className='pl20 fw600 pr20'>{gwei}</div>
                {/* <Input value={gwei} onChange={changeGwei}></Input> */}
                <div className={`cp df_center c_purple`} onClick={openSet}> Set</div>
              </div>
          }

          <div className='space-between mt20'>
            <Button className={`w200  h50 btn_border`} onClick={() => { setDashboardModal(false) }}>Close</Button>
            {
              dashbordInfo.status === 'ended' ?
                <Button disabled={!availableGasFee > 0} type={'primary'} className={`w200  h50`} onClick={claimGasFee}>Claim</Button> :
                <Button disabled={!countExpectedGasFee(gasFee, availableGasFee) > 0} type={'primary'} className={`w200  h50`} onClick={transfer}>Transfer Gas</Button>
            }
          </div>
        </Modal>
        <Modal visible={qrCodeModal} title={'Claim Page'} width={440} footer={null} onCancel={() => { setQrCodeModal(false) }}>
          <div className='tac mb40'>
            <QRCodeSVG value={baseUrl[env.ENV] + qrCodeBadgeType}></QRCodeSVG>
          </div>
        </Modal>

        <div className={styles.eventDetailPage}>
          <div className={styles.event_baseInfo}>
            <div className={styles.event_logo}><img width={100} src={eventInfo.logo}></img></div>
            <div className={`pl30 f1`}>
              <div className={styles.baseInfo_name}>{eventInfo.title}</div>
              <div className={styles.baseInfo_desc}>{eventInfo.description}</div>
              <div className={styles.info_list}>
                <div className={`${styles.info_item} ${styles.w170}`}>
                  <div className='tac'>Event Type</div>
                  <div className={'fw600 tac'}>{eventInfo.type === 'general' ? 'General' : eventInfo.type === 'airdrop' ? 'Airdrop' : 'Mystery Box'}</div>
                </div>
                <div className={`${styles.info_item} ${styles.w250}`}>
                  <div>Start time</div>
                  <div className='fw600'>{moment(eventInfo.eventStartTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
                <div className={`${styles.info_item} ${styles.w250}`}>
                  <div>End time</div>
                  <div className='fw600'>{moment(eventInfo.eventEndTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
                <div className={`${styles.info_item} ${styles.w170}`}>
                  <div className='tac'>{chainTxtObj[eventInfo.chainType]}</div>
                  <div className='tac'>
                    <img src={chainTypeComImgObj[eventInfo.chainType]}></img>
                  </div>
                </div>
                <div className={`${styles.info_item} ${styles.w170}`}>
                  <div className='tac'>Claimed</div>
                  <div className='fw600 tac'>{eventInfo.claimedCount}/{eventInfo.totalCount}</div>
                </div>
              </div>
              <div className={styles.func_box}>
                {
                  isPublished ? <Button disabled className={`w200 h50 btn_border mr6`}>Published</Button> :
                    <Button className={styles.publish_btn} onClick={gotoPublish}>Publish</Button>
                }
                <Dropdown className={`cp ${isPublished ? styles.manage_disable : ''} `} disabled={isPublished}
                  overlay={
                    <Menu>
                      <Menu.Item key='1' disabled={isDeployBadge} onClick={gotoCreateEvent}>Edit Event</Menu.Item>
                      <Menu.Item key='2' onClick={gotoCreateBadge}>Create Badge</Menu.Item>
                      <Menu.Item key='3' onClick={openAdd} disabled={eventInfo.type === 'airdrop'}>Add Group</Menu.Item>
                      <Menu.Item key='4' onClick={() => { setDelModal(true) }}>Delete Draft</Menu.Item>
                    </Menu>
                  }>
                  <div className={styles.eventManage}><img src={iconManage}></img></div>
                </Dropdown>
              </div>
            </div>
          </div>
          {
            groupList.map((li, index) => (
              <div className={styles.badgeManage}>
                {
                  eventInfo.type === 'general' &&
                  <div className='fs20 fw600 cp'>{li.title} {!isPublished && <IconEdit fill={'#6F4293'} onClick={() => { openEdit(li.title, index) }}></IconEdit>} </div>
                }
                <div className={styles.badgeList}>
                  {
                    badgeList.map((item) => (
                      <>
                        {
                          item.group === li.title &&
                          <div className={styles.badge_item}>
                            <div className={styles.badge_box}>
                              <img className={styles.badge_img} src={item.image}></img>
                              {
                                (!isPublished && !item.contractAddress) &&
                                <div className={styles.badge_func}>
                                  <div className='f1 tac cp'>
                                    <IconEdit fill={'#fff'} onClick={() => { gotoBadgeInfo(item.type) }}></IconEdit>
                                  </div>
                                  <div className={`f1 tac cp ${styles.line_left}`} onClick={() => { openDelBadgeModal(item) }}>
                                    <IconDel></IconDel>
                                  </div>
                                </div>
                              }
                              {
                                (!isPublished && !item.contractAddress) ?
                                  <div className={styles.badge_func}>
                                    <div className='f1 tac cp'>
                                      <IconEdit fill={'#fff'} onClick={() => { gotoBadgeInfo(item.type) }}></IconEdit>
                                    </div>
                                    <div className={`f1 tac cp ${styles.line_left}`} onClick={() => { openDelBadgeModal(item) }}>
                                      <IconDel></IconDel>
                                    </div>
                                  </div> :
                                  <div className={styles.badge_func}>
                                    <div className={`f1 cp ${styles.download}`} onClick={() => { downloadCSV(item.type) }}>
                                      <IconDownload fill={'#fff'}></IconDownload>
                                      <span className='pl10 c_fff'>Participants List</span>
                                    </div>
                                  </div>
                              }
                            </div>
                            <div className='text_hidden_1 fs14'>{item.name}</div>
                            {
                              eventInfo.type === 'airdrop' &&
                              <div className={styles.modal_btns}>
                                <div className='f3 tac cp fs14' onClick={() => { openDashbord(item) }}>Dashbord</div>
                                {
                                  item.eligibility === 'all' &&
                                  <div className={`f4 tac cp fs14 ${styles.btns_right}`} onClick={() => { openQrcode(item.type) }}>Claim Page <IconQRCode className='ml4'></IconQRCode></div>
                                }
                              </div>
                            }
                          </div>
                        }
                      </>
                    ))
                  }
                  {
                    !isPublished &&
                    <div className='cp' onClick={gotoCreateBadge}>
                      <div className={styles.add_box}>+</div>
                      <div className='fs14'>Create Badge</div>
                    </div>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </>
    )
  }
