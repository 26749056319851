import React, { useState, useMemo } from 'react';
import { Radio } from 'antd';
import styles from './styles.module.scss';

export default function RadioFilter({onChange, list }) {
    // console.log(list);
    const [filterType, setFilterType] = useState(0)

    const handleTradeTypeChange = (key) => {
        setFilterType(key);
        onChange(key)
    }
    return (
        <div className={"mt20 mb10"}>
            <Radio.Group
                className={styles.radio}
                defaultValue={0}
                value={filterType}
                onChange={e => handleTradeTypeChange(e.target.value)}
            >
                {
                    list.length>0 && list.map((item, index) => {
                        return (<Radio.Button key={item.name} value={index}>{item.displayName}</Radio.Button>)
                    })

                }
            </Radio.Group>
        </div>
    )
}
