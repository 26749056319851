import React, { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button,Row, Col } from 'antd';
import SwiperCore,{ Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import "swiper/swiper-bundle.min.css";
// import 'swiper/css/autoplay';

import styles from "./styles.module.scss";
import bg from "assets/img/anniversary/anniversary_bg.png"
import badge1 from "assets/img/anniversary/badge_1st.png"
import how from "assets/img/anniversary/how.png"
import how2 from "assets/img/anniversary/how_h5.png"
import partnerBg from "assets/img/anniversary/partner_bg.png"
import organizerBg from "assets/img/anniversary/organizer_bg.png"
import imtoken from "assets/img/anniversary/imtoken_shadow.png"
import mathWallet from "assets/img/anniversary/mathwallet_shadow.png"
import oin from "assets/img/anniversary/oin_shadow.png"
import aoz from "assets/img/anniversary/aoz_shadow.png"
// import cere from "assets/img/anniversary/cere_shadow.png"
import cybergalz from "assets/img/anniversary/cybergalz_shadow.png"
// import polygon from "assets/img/anniversary/polygon_shadow.png"
import sypool from "assets/img/anniversary/sypool_shadow.png"
import naos from "assets/img/anniversary/naos_shadow.png"
import perpetual from "assets/img/anniversary/perpetual_shadow.jpg"
import sunmiya from "assets/img/anniversary/sunmiya_shadow.png"
import dvision from "assets/img/anniversary/dvision_shadow.png"
import klayswap from "assets/img/anniversary/klayswap_shadow.png"
import mtdz from "assets/img/anniversary/mtdz_shadow.png"
import withs from "assets/img/anniversary/with_shadow.jpg"
import pImtoken from "assets/img/anniversary/p_imtoken.png"
import pOin from "assets/img/anniversary/p_oin.png"
import pMathWallet from "assets/img/anniversary/p_mathWallet.png"
import pAoz from "assets/img/anniversary/p_aoz.png"
// import pCere from "assets/img/anniversary/p_cere.png"
import pCybergalz from "assets/img/anniversary/p_cybergalz.png"
import pPolygon from "assets/img/anniversary/p_polygon.png"
import pSypool from "assets/img/anniversary/p_sypool.png"
import pNaos from "assets/img/anniversary/p_naos.png"
import pPerpetual from "assets/img/anniversary/p_perpetual.png"
import pAbyss from "assets/img/anniversary/p_abyss.png"
import pSunmiya from "assets/img/anniversary/p_sunmiya.png"
import pFingerLabs from "assets/img/anniversary/p_fingerLabs.png"
import pDvision from "assets/img/anniversary/p_dvision.png"
import pKlapswap from "assets/img/anniversary/p_klapswap.png"
import pMtdz from "assets/img/anniversary/p_mtdz.png"
import pWith from "assets/img/anniversary/p_with.png"
import pSandbox from "assets/img/anniversary/p_sandbox.png"

SwiperCore.use([Autoplay])
export default function Anniversary() {

    const history = useHistory();
    const organizerList = [
        { name: 'AOZ', src: aoz, type:'aoz'},
        { name: 'Cybergalz', src: cybergalz, type:'cybergalz' },
        { name: 'Dvision', src: dvision, type:'dvision' },
        { name: 'imToken', src: imtoken, type:'imtoken'},
        { name: 'KLAY swap', src: klayswap, type:'klayswap' },
        { name: 'MathWallet', src: mathWallet, type:'mathwallet',},
        { name: 'MetaToyDragonZ', src: mtdz, type:'mtdz' },
        { name: 'Naos Finance', src: naos, type:'naos' },
        { name: 'Oin', src: oin, type:'oin', simg: pOin },
        { name: 'Perpetual Finance', src: perpetual, type:'perpetual' },
        { name: 'Sypool', src: sypool, type:'sypool' },
        { name: 'Sunmiya club', src: sunmiya, type:'sunmiya' },
        { name: 'WITH', src: withs, type:'with' },
    ]
    const partnerList = [
        { name: 'ABYSS Company', type:'', simg: pAbyss },
        { name: 'AOZ', type:'aoz', simg: pAoz },
        { name: 'Cybergalz', type:'cybergalz', simg: pCybergalz },
        { name: 'Dvision Network',  type:'dvision', simg: pDvision },
        { name: 'FingerLabs', type:'', simg: pFingerLabs },
        { name: 'imToken', type:'imtoken', simg: pImtoken },
        { name: 'KLAY swap',  type:'klayswap', simg: pKlapswap },
        { name: 'MathWallet', type:'mathwallet', simg: pMathWallet },
        { name: 'MetaToyDragonZ',  type:'mtdz', simg: pMtdz },
        { name: 'Naos Finance',  type:'naos', simg: pNaos },
        { name: 'Oin',  type:'oin', simg: pOin },
        { name: 'Polygon', type:'', simg: pPolygon },
        { name: 'Perpetual Protocol', type:'perpetual', simg: pPerpetual },
        { name: 'Sypool',  type:'sypool', simg: pSypool },
        { name: 'Sunmiya Club', type:'sunmiya', simg: pSunmiya },
        { name: 'SandBox', type:'', simg: pSandbox },
        { name: 'WITH', type:'with', simg: pWith },
    ]
    const [sliderViews, setSliderViews] = useState(5)

    const initData = () => {
        if (document.body.clientWidth < 800) {
            setSliderViews(2)
        }else if(document.body.clientWidth > 800 && document.body.clientWidth <1145 ) {
            setSliderViews(3)
        }else if(document.body.clientWidth > 1144 && document.body.clientWidth < 1431 ) {
            setSliderViews(4)
        }else if(document.body.clientWidth > 1430 && document.body.clientWidth < 1717 ) {
            setSliderViews(5)
        }else if(document.body.clientWidth > 1716 && document.body.clientWidth < 1921 ) {
            setSliderViews(6)
        }
    }
    const gotoClaim = (type) => {
        console.log(type);
        if(type){
            history.push(`/badge/one-year-anniversary/${type}`)
        }
    }
    
    useEffect(() => {
        initData()
    })
  return (
    <div className={styles.anniversary}>
        <div className={`${styles.page_head_img}`} style={{ backgroundImage: `url(${bg})` }}>
            <div className={styles.head_title}>
                <div className={styles.title}>DeFine Platform One Year Anniversary Carnival</div>
                <div className={styles.subtitle}>To celebrate its One Year Anniversary, DeFine together with our partners will be giving away anniversary NFT badges to commemorate your support. </div>
            </div>
            <div>
                <img className={styles.anniversary_badge} src={badge1}></img>
            </div>
        </div>
        <div className={styles.organizer} style={{ backgroundImage: `url(${organizerBg})` }}>
            <div className={`c_f fw700 tac ${styles.organizer_title}`}>Partner Badges</div>
            <Swiper className={`df mt20 `}
            autoplay={{
                delay: 0,
                stopOnLastSlide: false,
                disableOnInteraction: true,
              }}
            speed={6000}
            loop={true}
            spaceBetween={0}
            slidesPerView={sliderViews}
            onSwiper={(swiper) => {swiper.$el[0].addEventListener('mouseover',()=>swiper.autoplay.stop());
            swiper.$el[0].addEventListener('mouseleave',()=>swiper.autoplay.start());}}
            >
            {
                organizerList.map(item =>(
                    <SwiperSlide><img onClick={()=>{gotoClaim(item.type)}} className={`cp ${styles.slide_img}`} src={item.src}></img></SwiperSlide>
                ))
            }
            </Swiper>
        </div>
        <div className={styles.anniversary_body}>
            <div className={styles.intro}>
                <div className={styles.how_box}>
                    <img className={`${styles.how_img} ${styles.how_pc}`} src={how}></img>
                    <img className={`${styles.how_img} ${styles.how_h5}`} src={how2}></img>
                    <div className={`c_f ${styles.how_txt}`}>How to get DeFine Anniversary Badge??</div>
                </div>
                <div className={styles.intro_plan}>
                    <div className={`df ${styles.plan_box}`}>
                        {/* <div className={styles.plan_tag}>
                            <div className={styles.plan_txt}>Plan</div>
                            <div className={styles.plan_a}>A</div>
                        </div> */}
                        <div>
                            <div className={'fs24 fw700 mb10'}>Early Contributors</div>
                            <div>Users who have interacted with the DeFine smart contract in the past year will automatically receive an airdrop of DeFine Anniversary Badge through the polygon network.</div>
                            <div className={`fw600 fs16 mt10`}>Snapshot taken at March 21st, 00:00 KST</div>
                            <div className={`fw700 mt10 mb10 ${styles.plan_txt}`}> 2021.03.16 ~ 2022.03.21</div>
                        </div>
                    </div>
                    <div className={`df ${styles.plan_box}`}>
                        {/* <div className={styles.plan_tag}>
                            <div className={styles.plan_txt}>Plan</div>
                            <div className={styles.plan_a}>B</div>
                        </div> */}
                        <div>
                            <div className={'fs24 fw700 mb10'}>DeFine Advocates</div>
                            <div>DeFine will run a joint campaign with partners. Users who complete the tasks of each partner will be eligible to claim a partner badge. When the user collects at least 3 partner badges, the user will receive an airdrop of DeFine Anniversary Badge through the polygon network. </div>
                            <div className={styles.more_btn}>
                                <Button className={`btn_border`}>
                                    <a target='_blank' href='https://defineplatform.medium.com/define-platform-1-year-anniversary-carnival-cccc2ab03a6d'>More details &gt;</a></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  style={{ backgroundImage: `url(${partnerBg})` }}>
            <div className={styles.anniversary_partner}>
                <div className={styles.partner_title}>DeFine One Year Anniversary Partners</div>
                <Row className={`mt40 ${styles.partnerList}`}>
                    {
                        partnerList.map(item => (
                            <Col xs={12} lg={6}>
                                <div className={styles.partner_box} onClick={()=>{gotoClaim(item.type)}} >
                                    <img className={styles.partner_img} src={item.simg} width={100}></img>
                                    <div className={`mt10 fw700`}>{item.name}</div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </div>    
    </div>
  )
}
