import React, { useEffect, useState } from "react";
import { BackTop } from 'antd';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import PublishBox from "components/PublishBox";
import RecommendBar from "components/RecommendBar";
import ActivityList from "components/ActivityList";
import { ReactComponent as RefreshIcon } from "assets/img/refresh.svg";
import { ReactComponent as MulticolorDownIcon } from "assets/img/home/mDown.svg";
import { ReactComponent as UpIcon } from "assets/img/home/arrowUp.svg";
import { ReactComponent as DownIcon } from "assets/img/home/arrowDown.svg";
import { ReactComponent as MulticolorUpIcon } from "assets/img/home/mUp.svg";
import publishIcon from "assets/img/home/edit.svg"
import backtotop from "assets/img/home/backtotop.svg"
import styles from "./styles.module.scss";

const Home = () => {
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState('all')
  const [refresh, setRefresh] = useState(0)
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const { tronAccount } = useActiveTronWeb()
  const [sort, setSort] = useState('time')

  const handleRefresh = () => {
    setRefresh(old => (old + 1) % 100)
  }

  const handlePublish = () => {
    handleRefresh()
  }
  const timeSort = () => {
    setSort('time')
    // setPageNo(1)
}
const hotSort = () => {
  setSort('hot')
    // setPageNo(1)
}
  const gotoTopic = () => {
    history.push('/topic')
  }
  const gotoNotification = () => {
    history.push('/message/likeme')
  }
  const gotopublish = () => {
    history.push('/publish')
  }

  useEffect(() => {
  }, [])

  return (
    <div className="df">
      <BackTop style={{ bottom: 160, right: 33 }} >
        <img src={backtotop}></img>
      </BackTop>
      <div className={styles.publish_icon_h5} onClick={gotopublish}>
        <img src={publishIcon}></img>
      </div>
      <div className={styles.content_box}>
        <div className={`df ${styles.nav_h5}`}>
          <div className={`f1 fw700 pr16 border_right tac`}>WOW</div>
          <div className={`f1 border_right tac`} onClick={gotoTopic}>Topic</div>
          <div className={`f1 pl16 tac`} onClick={gotoNotification}>Notification</div>
        </div>
        <div className={styles.publish_pc}>
          <PublishBox onPublished={handlePublish} />
        </div>
        <div className={styles.tab_box}>
          <div className="df_align_center">
            <div
              className={`${styles.tab_btn} ${currentTab === 'all' ? styles.active : ''}`}
              onClick={() => { setCurrentTab('all') }}
            >{t('all')}</div>
            <div
              className={`${styles.tab_btn} ${currentTab === 'followed' ? styles.active : ''}`}
              onClick={() => { setCurrentTab('followed') }}
            >{t('followed')}</div>
          </div>
          <div className={`df_center fs14`}>
            <div className={`pl12 pr12 df_center ${styles.b_l} cp ${`${sort==='time'?'fw700 color_text':null}`}`} onClick={timeSort}>Time&nbsp; </div>
            <div className={`pl12 pr12 df_center ${styles.b_l} cp ${`${sort==='hot'?'fw700 color_text':null}`}`} onClick={hotSort}>Hot&nbsp; </div>
            <div className={`pl10 df_center`}><RefreshIcon width={30} height={30} className={`cp`} onClick={handleRefresh} /></div>
          </div>
        </div>
        <div className={styles.activity_box}>
          <ActivityList home={true} sort={sort} follower={currentTab === 'followed' ? (tronAccount || account) : undefined} refresh={refresh} />
        </div>
      </div>

      <RecommendBar />
    </div>
  )
}
export default Home;
