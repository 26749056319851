import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Drawer, BackTop, Radio, Tabs } from 'antd';
import { useTranslation } from "react-i18next";

import BackButton from "components/BackButton";
import { getContract, useActiveWeb3React } from "../../web3"
import { useActiveTronWeb } from "hooks/activeTronWeb";
import Avatar from "components/Avatar";
import FollowButton from "components/FollowButton";
import ActivityCard from "components/ActivityCard";
import PublishCommentBox from "components/PublishCommentBox";
import CommentList from "components/CommentList";
import RecommendBar from "components/RecommendBar";
import { ReactComponent as RefreshIcon } from "assets/img/refresh.svg";
import stp from "assets/img/banner/stp.jpg";
import arrows from "assets/img/arrows.png";
import { getActivity } from "services/home"
import { handleIsSignExpired } from "utils/txSign";
import styles from "./styles.module.scss";

const Comment = () => {
  const [currentTab, setCurrentTab] = useState('all')
  const [refresh, setRefresh] = useState(0)
  const [detail, setDetail] = useState({})
  const { t, i18n } = useTranslation()
  const location = useLocation();
  const { library, account, chainId, active } = useActiveWeb3React()
  const { tronAccount } = useActiveTronWeb()
  const { activityId } = useParams();

  const handleFollow = () => {

  }

  const handlePublish = () => {
    setRefresh(old => (old + 1) % 100)
    getDetail()
  }

  const getDetail = () => {
    getActivity(activityId).then(res => {
      setDetail(res)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (activityId) {
      getDetail()
    }
  }, [activityId])

  return (
    <div className="df">
      <BackTop style={{bottom: 90}} />
      <div className={styles.content_box}>
        <div className={styles.activity_box}>
          <div className={styles.back_box}>
            <BackButton />
          </div>
          <ActivityCard cardType="comment" data={detail} />
          <div className={styles.publish_box}>
            <PublishCommentBox id={activityId} onPublished={handlePublish} />
          </div>
          <CommentList refresh={refresh} id={activityId} />
        </div>
      </div>

      <RecommendBar />
    </div>
  )
}
export default Comment;
