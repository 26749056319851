import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons';
import { Modal, Button, Form, Input, message } from 'antd';
import { useTranslation } from "react-i18next";
import BigNumber from 'bignumber.js'
import moment from 'moment';

import CountDown from 'components/CountDown';
import { stakeDfa, unstakeDfa, stakeBadge, unstakeBadge, claimEarn, getERC20Contract, stakeContract,stakeLockContract, reserveWithdrawDfa, cancelReserveWithdrawDfa,reserveReward, withdrawDfa } from '../../../utils/handleContract'
import { getContract, useActiveWeb3React } from "../../../web3";
import BackButton from "components/BackButton";
import DFA721NFT from "../../../web3/abi/DFA721NFT.json";
import { setupEthMainnet } from '../../../utils/networkConnect';
import { mainContext } from "../../../reducer";
import { formatAmountWithDecimal } from "../../../utils/format";
import { getPoolInfo } from "../../../services/farm.js";
import styles from "../styles.module.scss";
import {
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
} from "../../../const";
import dfa from "../../../assets/img/dfa.png"
import clock from "assets/img/fram/clock.svg"
import check from "assets/img/fram/check.svg"

let btnFlag = ''
export default function Staking() {

    const { t } = useTranslation()
    const history = useHistory()
    const { dispatch } = useContext(mainContext);
    const [visible, setVisible] = useState(false)
    const [poolInfo, setPoolInfo] = useState()
    const [isPc, setIsPc] = useState(true)
    const [btnType, setBtnType] = useState('stake')
    const [modalTitle, setModalTitle] = useState('Stake DFA')
    const [dfaCount, setDfaCount] = useState(0)
    const [modalBalance, setModalBalance] = useState(0)
    const [myEarned, setMyEarned] = useState()
    const [myStaked, setMyStaked] = useState()
    const [totalSupply, setTotalSupply] = useState()
    const [lockAmount, setLockAmount] = useState()
    const [lockTime, setLockTime] = useState()
    const [form] = Form.useForm();
    const { chainType, contractAddress, poolId } = useParams();
    const { library, account, chainId } = useActiveWeb3React()
    const [ canClaim, setCanClaim ] = useState(false)


    const goBack = () => {
        history.goBack()
    }
    const dealEndTime = (continuable,endTime) => {
        if(continuable){
            return 'active'
        }else {
            if (new Date(endTime).getTime()- Date.now() < 0) {
                return 'closed'
            }else {
                return 'active'
            }
        }
    }
    const openStake = () => {
        form.setFieldsValue({
            amount: ''
        })
        getDfaCount().then(_ => {
            setVisible(true)
            setBtnType("stake")
        })
    }
    const openUnstake = () => {
        form.setFieldsValue({
            amount: ''
        })
        if (Number(myStaked) > 0) {
            setVisible(true)
            setBtnType("unstake")
            setModalTitle("Unstake DFA")
            setModalBalance(myStaked)
        }
    }
    const openUnstakeReserve = () => {
        form.setFieldsValue({
            amount: ''
        })
        if (Number(myStaked) > 0) {
            setVisible(true)
            setBtnType("reserveWithdraw")
            setModalTitle("Unstake DFA")
            setModalBalance(myStaked)
        }
    }
    const openClaimEarn = () => {
        if (Number(myEarned) > 0) {
            setBtnType("claim")
            handleBtn(1, false, "claim")
        }
    }
    const openReserveEarn = () => {
        if (Number(myEarned) > 0) {
            setBtnType("reserveEarned")
            handleBtn(myEarned, false, "reserveEarned")
        }
    }
    const openCancelReserve = () => {
        if (Number(lockAmount) > 0) {
            setBtnType("cancelReserveWithdraw")
            handleBtn(myEarned, false, "cancelReserveWithdraw")
        }
    }
    const openWithdraw = () => {
        if (Number(lockAmount) > 0) {
            setBtnType("withdrawAllDfa")
            handleBtn(1, false, "withdrawAllDfa")
        }
    }
    const openStakeBadge = (item) => {
        setBtnType("stakeBadge")
        handleBtn(1, item, "stakeBadge")
    }
    const openUnstakeBadge = (item) => {
        setBtnType("unstakeBadge")
        handleBtn(1, item, "unstakeBadge")
    }
    const handleCancel = () => {
        setVisible(false)
    }
    const max = () => {
        let num = null;
        if (btnType === 'stake') {
            num = new BigNumber(dfaCount)
        } else if (btnType === 'unstake' || btnType === 'reserveWithdraw') {
            num = new BigNumber(myStaked)
        }
        form.setFieldsValue({
            amount: num.div(new BigNumber(10).pow(poolInfo.stakeToken.decimals)).toString()
        })
    }
    const getDfaCount = async () => {
        try {
            let res = await getERC20Contract(library, poolInfo.stakeToken.address).methods.balanceOf(account).call()
            setDfaCount(res)
            setModalBalance(res)

        } catch (error) {
        }
    }
    const onFinish = (value) => {
        let limitNum = new BigNumber(modalBalance).div(new BigNumber(10).pow(poolInfo.stakeToken.decimals)).toString()
        if (parseFloat(value.amount) > 0 && parseFloat(value.amount) <= parseFloat(limitNum)) {
            setVisible(false)
            handleBtn(dealBigNumMultiplied(value.amount, poolInfo.stakeToken.decimals), false, btnType)
        } else if (parseFloat(value.amount) > parseFloat(limitNum)) {
            message.error(t("insufficient_balance"))
        } else {
            message.error(t("greater_than_0"))
        }
    }
    const dealBigNumMultiplied = (num, decimals) => {
        let count = new BigNumber(num)
        return count.multipliedBy(new BigNumber(10).pow(decimals)).toString()
    }
    const dealBigNum = (num, decimals, xs) => {
        return formatAmountWithDecimal(num, decimals, xs)
    }
    const initData = () => {
        if (document.body.clientWidth < 800) {
            setIsPc(false)
        }
        getPoolInfo({ library, account, chainType, contractAddress, poolId }).then(res => {
            res.poolStatus = dealEndTime(res.continuable,res.endTime * 1000)
            setPoolInfo(res)
            res.supportBadgeStaking?
            toGetMyPoolInfo(res.poolId, res.contractAddress, res.chainType):
            toGetMyLockPoolInfo(res.poolId, res.contractAddress, res.chainType)
        })
    }
    const handleBtn = (amount, badgeItem, stakeType) => {
        if (chainId !== 1 && chainId !== 4) {
            message.error("please switch to Ether")
            setupEthMainnet()
            return;
        }
        if (btnFlag) {
            return false
        } else {
            btnFlag = true
        }
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        if (stakeType === 'stakeBadge' || stakeType === 'unstakeBadge') {
            toApproveBadge(amount, badgeItem, stakeType)
        } else if (stakeType === 'stake' || stakeType === 'unstake') {
            toApprove(amount, badgeItem)
        } else {
            allFun[stakeType](amount)
        }
    }
    const toApproveBadge = async (amount, badgeItem, stakeType) => {
        try {

            const dfanft = getContract(library, DFA721NFT.abi, badgeItem.badgeAddress)
            const isApproved = await dfanft.methods.isApprovedForAll(account, poolInfo.contractAddress).call()
            if (isApproved) {
                allFun[stakeType](badgeItem)
            } else {
                await dfanft.methods.setApprovalForAll(poolInfo.contractAddress, true).send({ from: account }).on('receipt', (_, receipt) => {
                    allFun[stakeType](badgeItem)
                });
            }
        } catch (err) {
            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { show: false }
            });
        }
    }
    const toApprove = async (amount) => {
        try {
            const erc20Contract = await getERC20Contract(library, poolInfo.stakeToken.address);
            let approved = await erc20Contract.methods.allowance(account, poolInfo.contractAddress).call()
            if (approved > 0) {
                allFun[btnType](amount)
            } else {
                await erc20Contract.methods.approve(poolInfo.contractAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                    .send({ from: account })
                    .on('receipt', (_, receipt) => {
                        allFun[btnType](amount)
                    })
            }
        } catch (err) {
            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: { show: false }
            });
        }
    }
    const toStakeDfa = async (amount) => {
        let res = {
            poolId: poolInfo.poolId,
            amount: amount,
        }
        await stakeDfa(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
    const toUnstakeDfa = async (amount) => {
        let res = {
            poolId: poolInfo.poolId,
            amount: amount,
        }
        await unstakeDfa(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
    const toClaim = async () => {
        let res = {
            poolId: poolInfo.poolId,
        }
        await claimEarn(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
    const toStakeBadge = async (badgeItem) => {
        let res = {
            poolId: poolInfo.poolId,
            badgeAddress: badgeItem.badgeAddress,
            tokenId: badgeItem.badgeId,
            badgeType: 1155
        }
        // debugger;
        await stakeBadge(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
    const toUnstakeBadge = async (badgeItem) => {
        let res = {
            poolId: poolInfo.poolId,
            badgeAddress: badgeItem.badgeAddress,
            tokenId: badgeItem.badgeId,
            badgeType: 1155
        }
        // debugger;
        await unstakeBadge(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }

    const toGetMyPoolInfo = async (poolId, contractAddress,chainType) => {
        try {
            let myEarned = await stakeContract('', contractAddress,chainType).methods.earned(poolId, account).call();
            setMyEarned(myEarned)
            let myStaked = await stakeContract('', contractAddress,chainType).methods.balanceOf(poolId, account).call();
            setMyStaked(myStaked)
            let totalSupply = await stakeContract('', contractAddress,chainType).methods.totalSupply(poolId).call();
            setTotalSupply(totalSupply)
        } catch (error) {
            console.log(error);
        }
    }
    const toGetMyLockPoolInfo = async (poolId, contractAddress,chainType) => {
        try {
            let myEarned = await stakeLockContract('', contractAddress,chainType).methods.earned(poolId, account).call();
            setMyEarned(myEarned)
            let myStaked = await stakeLockContract('', contractAddress,chainType).methods.balanceOf(poolId, account).call();
            setMyStaked(myStaked)
            let totalSupply = await stakeLockContract('', contractAddress,chainType).methods.totalSupply(poolId).call();
            setTotalSupply(totalSupply)
            let lockAmount = await stakeLockContract('', contractAddress,chainType).methods.getReserveAmount(poolId, account).call();
            setLockAmount(lockAmount)
            let lockTime = await stakeLockContract('', contractAddress,chainType).methods.getUnlockTime(poolId, account).call();
            setLockTime(lockTime)
            if(lockTime>0){
                dealLockTime(lockTime)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const tow = (n) => {

        return n >= 0 && n < 10 ? '0' + n : '' + n;
    }
    const dealLockTime = (time) => {
        if (time) {
            const date = new Date(time * 1000);
            const now = new Date();
            const lefttime = date - now;
            if (lefttime > 0) {
                setCanClaim(false)
            }else {
                setCanClaim(true)
            }
        }
    }

    const toReserveWithdrawDfa = async (amount) => {
        let res = {
            poolId: poolInfo.poolId,
            amount: amount,
        }
        await reserveWithdrawDfa(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
    const toCancelReserve = async () => {
        let res = {
            poolId: poolInfo.poolId,
        }
        await cancelReserveWithdrawDfa(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
    const toReserveEarned = async () => {
        let res = {
            poolId: poolInfo.poolId,
        }
        await reserveReward(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
    const toWithdrawDfa = async () => {
        let res = {
            poolId: poolInfo.poolId,
        }
        await withdrawDfa(library, poolInfo.contractAddress, account, {
            ...res,
            _onTranscationHash: (hash) => { },
            _onReceipt: (receipt) => { callbackSucess() },
            _onError: (err) => { callbackError() },
        })
    }
  
    const allFun = {
        stake: toStakeDfa,
        unstake: toUnstakeDfa,
        claim: toClaim,
        stakeBadge: toStakeBadge,
        unstakeBadge: toUnstakeBadge,
        reserveWithdraw: toReserveWithdrawDfa,
        reserveEarned: toReserveEarned,
        cancelReserveWithdraw: toCancelReserve,
        withdrawAllDfa: toWithdrawDfa
    }
    const callbackSucess = () => {
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
        message.success(t("stake_success"))
        btnFlag = false
        initData()
    }
    const callbackError = () => {
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: { show: false }
        });
        btnFlag = false
    }
    
   
    const onEnd = () => {
        setCanClaim(true)
    }

    useEffect(() => {
        library && initData()
    }, [library])

    return (
        <div className={`page_content_h5 ${styles.farm_detail}`}>
            <div>
              <BackButton />
            </div>
            {poolInfo &&
                <section>
                    <div className={`mb5 fw700 fs32 lh13`}>{t("stake_title", { stakeName: poolInfo.stakeToken.symbol, rewardName: poolInfo.rewardToken.symbol })} </div>
                    {/* <div className={`mb5 fw600 fs18`}>{t('stake_sub_title')}</div> */}
                    {
                        poolInfo.continuable?
                        <div>Start at {moment(poolInfo.startTime * 1000).format("YYYY/MM/DD HH:mm:ss")} </div>
                        :<div>{moment(poolInfo.startTime * 1000).format("YYYY/MM/DD HH:mm:ss")} -- {moment(poolInfo.endTime * 1000).format("YYYY/MM/DD HH:mm:ss")}</div>
                    }
                    {
                        poolInfo.supportBadgeStaking?
                        <>
                        <div className={`mt20 ${styles.card_baseInfo}`}>
                            <div className={`${styles.card_flex_box}`}>
                                <div className={`tac`}>{t("base_apy")}</div>
                                <div className={`tac fw700 fs32`}>{dealBigNum(poolInfo.baseApy, 0, 2)}%</div>
                            </div>
                            <div className={`${styles.card_flex_box}`}>
                                <div className={`tac`}>{t("expected_apy")}</div>
                                <div className={`tac fw700 fs32`}>{dealBigNum(poolInfo.maxApy, 0, 2)}%</div>
                            </div>
                            <div className={`${styles.card_flex_box} tac ${styles.total_staked}`}>
                                <div className={`tac`}>{t("total_staked")}</div>
                                <div className={`f1 ${styles.flex_center} fs32 fw700`}><img className={styles.card_tag_dfa} src={dfa}></img><div className={'pl5'}>{totalSupply?dealBigNum(totalSupply, poolInfo.stakeToken.decimals, 0):'--'}</div></div>
                            </div>
                        </div>
                        <div className={`mt20 pb20 ${styles.card_table}`}>
                            <div className={`df_h5`}>
                                <div className={`f1 pt20 pb20`}>
                                    <div className={`tac`}>{t("my_apy")}</div>
                                    <div className={`tac ${styles.flex_center}`}>
                                        <span className={`tac fw700 fs32`}>{poolInfo.userStakeInfo ? dealBigNum(poolInfo.userStakeInfo.apy, 0, 2) : 0}%</span>
                                        {poolInfo.userStakeInfo && poolInfo.userStakeInfo.stakedBadgeCount > 0 && <span className={styles.boost_active}>{poolInfo.userStakeInfo.weight/(poolInfo.userStakeInfo.weightDenominator?poolInfo.userStakeInfo.weightDenominator:100)}x</span>}
                                    </div>
                                    {
                                        poolInfo.userStakeInfo && poolInfo.userStakeInfo.stakedBadgeCount > 0 ?
                                            <div className={`tac fs16 mt10`}>
                                                <div className={`${styles.add_badge_num}`}>{t("staked_badge")}: <span className={`fw600`}>{poolInfo.userStakeInfo.stakedBadgeCount}</span></div>
                                            </div> :
                                            <>
                                            {
                                                poolInfo.badges.length > 0 ?
                                                <div className={`tac mt10`}>{t('stake_badge_boost_apy')}</div>
                                                :<div className={`tac mt10 ${styles.coming_soon}`}>Stake Badge Coming Soon</div>
                                            }
                                            </>
                                    }
                                </div>
                                <div className={`f1 pt20 pb20 ${styles.card_h5_line}`}>
                                    <div className={`tac`}>{t("my_staked")}</div>
                                    <div className={`${styles.flex_center} fs32 fw600`}><img src={dfa} className={styles.card_tag_dfa}></img><span className={'pl10'}>{myStaked ? dealBigNum(myStaked, poolInfo.stakeToken.decimals, 0) : 0}</span></div>
                                    <div className={styles.btn_stakes}>
                                        {
                                            poolInfo.poolStatus === 'active' && <div className={`tac mt10 mr10`}><Button type="primary" className={styles.btn_l} onClick={openStake}> {t("stake")} </Button></div>
                                        }
    
                                        <div className={`tac mt10`}><Button className={`btn_border w120 h40`} onClick={openUnstake} disabled={!Number(myStaked)}>{t("unstake")}</Button></div>
                                    </div>
                                </div>
                                <div className={`f1 tac pt20 pb20 ${styles.card_h5_line}`}>
                                    <div className={`tac`}>{t("my_earned")}</div>
                                    <div className={`${styles.flex_center} fs32 fw600`}><img src={dfa} className={styles.card_tag_dfa}></img><span className={'pl6'}>{myEarned ? dealBigNum(myEarned, poolInfo.rewardToken.decimals) : 0}</span></div>
                                    <div className={`mt10`}>
                                        <Button className={`btn_border w120 h40`} onClick={openClaimEarn} disabled={!Number(myEarned)}>Claim</Button>
                                    </div>
                                </div>
                            </div>
                            {
                                poolInfo.badges.length > 0 &&
                                <div>
                                    <div className={`${styles.flex_center}  ${styles.table_head}`}>
                                        <span className={`${styles.table_badge} tac`}>{t("badges")}</span>
                                        {
                                            isPc && <span className={`${styles.table_badgeType}`}></span>
                                        }
                                        <span className={`${styles.table_col} tac`}>{t("boost")}</span>
                                        <span className={`${styles.table_col} tac`}>{t("staked")}</span>
                                        <span className={`${styles.table_btn} tac`}></span>
                                    </div>
                                    {
                                        poolInfo.badges.length > 0 && poolInfo.badges.map((item, index) => (
                                            <div key={item.badgeId} className={`${styles.flex_center} ${styles.table_row} ${index % 2 === 0 ? styles.table_row_bg : null}`}>
                                                <div className={`${styles.table_badge} ${styles.flex_center}`}>
                                                    <img src={item.image} width={60} height={60}></img>
                                                </div>
                                                {
                                                    isPc && <div className={`${styles.table_badgeType}`}>{<span className={'fw600'}>{item.badgeName}</span>}</div>
                                                }
                                                <div className={`${styles.table_col} ${styles.flex_center}`}>
                                                    <div className={`${item.currentUserStakedCount === 1 ? styles.boost_active : styles.boost}`}>{item.weight/item.weightDenominator}x</div></div>
                                                <div className={`${styles.table_col} tac`}>{item.currentUserStakedCount}</div>
                                                <div className={`${styles.table_btn} ${styles.flex_center}`}>
                                                    {
                                                        item.currentUserOwned ?
                                                            <div>
                                                                {
                                                                    item.currentUserStakedCount === 1 ? <Button type="text" className={styles.btn_border_l} onClick={() => { openUnstakeBadge(item) }}>{t("unstake")}</Button> : <Button className={styles.btn_l} type="primary" onClick={() => { openStakeBadge(item) }}>{t("stake_badge")}</Button>
                                                                }
                                                            </div> : <div className={styles.color_9a}>N/A</div>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                        </>:
                        <>
                        <div className={`mt20  ${styles.card_table}`}>
                            <div className={`df_h5`}>
                                <div className={`f4 pt20 pb30 ${styles.pool_info_item}`}>
                                    <div className={`${styles.flex_between} ${styles.box_item}`}>
                                        <div className={`tac fs14`}>{t("Apy")}</div>
                                        <div className={`tac ${styles.flex_center}`}>
                                            <span className={`tac fw700 fs28`}>{poolInfo.maxApy ? dealBigNum(poolInfo.maxApy, 0, 2) : 0}%</span>
                                            {/* {poolInfo.userStakeInfo && poolInfo.userStakeInfo.stakedBadgeCount > 0 && <span className={styles.boost_active}>{poolInfo.userStakeInfo.weight/(poolInfo.userStakeInfo.weightDenominator?poolInfo.userStakeInfo.weightDenominator:100)}x</span>} */}
                                        </div>
                                    </div>
                                    <div className={`${styles.flex_between} ${styles.box_item} mt20`}>
                                        <div className={`tac fs14`}>{t("total_staked")}</div>
                                        <div className={` ${styles.flex_center} fs28 fw700`}><img className={styles.card_tag_dfa_s} src={dfa}></img><div className={'pl6'}>{totalSupply?dealBigNum(totalSupply, poolInfo.stakeToken.decimals, 0):'--'}</div></div>
                                    </div>
                                </div>
                                <div className={`f3 pt20 pb30 ${styles.card_h5_line}`}>
                                    <div className={`tac`}>{t("my_staked")}</div>
                                    <div className={`${styles.flex_center} fs28 fw600`}><img src={dfa} className={styles.card_tag_dfa_s}></img><span className={'pl6'}>{myStaked ? dealBigNum(myStaked, poolInfo.stakeToken.decimals, 0) : 0}</span></div>
                                    <div className={`${styles.btn_stakes} ${styles.box_item}`}>
                                        {
                                            poolInfo.poolStatus === 'active' && <div className={`tac mt10 mr10`}><Button type="primary" className={styles.btn_l} onClick={openStake}> {t("stake")} </Button></div>
                                        }

                                        <div className={`tac mt10`}><Button className={`btn_border w120 h40`} onClick={openUnstakeReserve} disabled={!Number(myStaked)}>{t("unstake")}</Button></div>
                                    </div>
                                </div>
                                <div className={`f2 tac pt20 pb30 ${styles.card_h5_line}`}>
                                    <div className={`tac`}>{t("my_earned")}</div>
                                    <div className={`${styles.flex_center} fs28 fw600`}><img src={dfa} className={styles.card_tag_dfa_s}></img><span className={'pl6'}>{myEarned ? dealBigNum(myEarned, poolInfo.rewardToken.decimals) : 0}</span></div>
                                    <div className={`mt10`}>
                                        <Button className={`btn_border w120 h40`} onClick={openReserveEarn} disabled={!Number(myEarned)}>Claim</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (lockAmount > 0 && !canClaim) &&
                            <div className={`${styles.card_table}  ${styles.unstake_item} mt20  pt20 pb20 `}>
                                <div className={`${styles.flex_center} f1 ${styles.p10_h5}`}><img src={clock}></img><span className={`pl5`}>Withdraw(on the way)</span></div>
                                <div className={`${styles.flex_center} f1 fs28 fw700 ${styles.p10_h5}`}><img className={styles.card_tag_dfa_s} src={dfa}></img><div className={'pl5'}>{lockAmount?dealBigNum(lockAmount, poolInfo.stakeToken.decimals, 3):'--'}</div></div>
                                <div className={`${styles.flex_center} f1 ${styles.p10_h5}`}><span className={`${styles.dot} mr5`}></span><CountDown onEnd={onEnd} type={"farm"} time={lockTime}></CountDown></div>
                                <div className={`${styles.flex_center} f1 ${styles.p10_h5}`}>
                                    <Button className={`btn_border w120 h40`} onClick={openCancelReserve} >Cancel</Button>
                                </div>
                            </div>
                        }
                        {
                            lockAmount>0 && canClaim &&
                            <div className={`${styles.card_table}  ${styles.unstake_item_claimable} mt20 pt20 pb20 `}>
                                <div className={`${styles.flex_center} f1 ${styles.p10_h5}`}><img src={check}></img><span className={`pl5`}>Withdraw(claimable)</span></div>
                                <div className={`${styles.flex_center} f1 fs28 fw700 ${styles.p10_h5}`}><img className={styles.card_tag_dfa_s} src={dfa}></img><div className={'pl5'}>{lockAmount?dealBigNum(lockAmount, poolInfo.stakeToken.decimals, 3):'--'}</div></div>
                                <div className={`${styles.flex_center} f1 ${styles.p10_h5}`}>
                                    <Button className={`btn_border w120 h40`} onClick={openWithdraw} disabled={!Number(lockAmount)}>Claim</Button>
                                </div>
                            </div>
                        }
                        </>
                    }
                </section>
            }
            <Modal
                title={modalTitle}
                visible={visible}
                footer={null}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={onFinish} >
                    {
                        poolInfo && !poolInfo.supportBadgeStaking &&
                        <div className='mb10'>There is a 7-day unstaking period before the DFA token can be withdrawn.</div>
                    }
                    <div className={`${styles.modal_main}`}>
                        <div className={`space-between fs16 pl10 ${styles.color_font}`}>
                            <span>{t("input")}</span>
                            {
                                btnType === 'stake' && <span>{t("balance")}：{modalBalance ? dealBigNum(modalBalance, poolInfo.stakeToken.decimals, 3) : 0} DFA</span>
                            }
                            {
                                btnType === 'unstake' && <span>{t("available")}：{modalBalance ? dealBigNum(modalBalance, poolInfo.stakeToken.decimals, 3) : 0} DFA</span>
                            }
                            {
                                btnType === 'reserveWithdraw' && <span>{t("available")}：{modalBalance ? dealBigNum(modalBalance, poolInfo.stakeToken.decimals, 3) : 0} DFA</span>
                            }
                        </div>
                        <div className={`space-between mt10`}>
                            <Form.Item
                                noStyle
                                name="amount"
                            >
                                <Input className={`${styles.input} fs24 fw500`} type='number' placeholder="0.00" />
                            </Form.Item>
                            <div className={`df_center`}>
                                <span className={`fw600 fs24 mr10`}>DFA</span>
                                <Button className={`btn_border h30`} onClick={max}>Max</Button>
                            </div>
                        </div>
                    </div>
                    {
                        btnType === 'stake' && <Button style={{ width: '100%' }} className={`mt30 h50`} type="primary" htmlType="submit">Stake</Button>
                    }
                    {
                        btnType === 'unstake' && <Button style={{ width: '100%' }} className={`mt30 btn_border h50`} htmlType="submit">Unstake</Button>
                    }
                    {
                        btnType === 'reserveWithdraw' && <Button style={{ width: '100%' }} className={`mt30 h50`} type="primary" htmlType="submit">Unstake</Button>
                    }

                </Form>
            </Modal>

        </div>
    )
}
